import {

    GET_NEW_ACTIVATIONS,
    GET_NEW_ACTIVATIONS_SUCCESS,
    GET_NEW_ACTIVATIONS_FAILURE,
    GET_NEW_ACTIVATIONS_INIT,

    GET_ONLINE_DEVICES,
    GET_ONLINE_DEVICES_SUCCESS,
    GET_ONLINE_DEVICES_FAILURE,
    GET_ONLINE_DEVICES_INIT,

    GET_TREND_GRAPH,
    GET_TREND_GRAPH_SUCCESS,
    GET_TREND_GRAPH_FAILURE,
    GET_TREND_GRAPH_INIT,

    GET_STORAGE_STATUS,
    GET_STORAGE_STATUS_SUCCESS,
    GET_STORAGE_STATUS_FAILURE,
    GET_STORAGE_STATUS_INIT,

    GET_DEVICES_INFO,
    GET_DEVICES_INFO_SUCCESS,
    GET_DEVICES_INFO_FAILURE,
    GET_DEVICES_INFO_INIT,
    
    DOWNLOAD_DEVICE_INFO_REPORT,
    DOWNLOAD_DEVICE_INFO_REPORT_SUCCESS,
    DOWNLOAD_DEVICE_INFO_REPORT_FAILURE,
    DOWNLOAD_DEVICE_INFO_REPORT_INIT

} from './actionTypes'


// ==== Actions for Get New Activations ====
export const getNewActivations = (data) => {
    return {
        type : GET_NEW_ACTIVATIONS,
        payload : data
    }
}
export const getNewActivationsSuccess = (data) => {
    return {
        type : GET_NEW_ACTIVATIONS_SUCCESS,
        payload : data
    }
}
export const getNewActivationsFailure = (data) => {
    return {
        type : GET_NEW_ACTIVATIONS_FAILURE,
        payload : data
    }
}
export const getNewActivationsInit = (data) => {
    return {
        type : GET_NEW_ACTIVATIONS_INIT,
        payload : data
    }
}

// ==== Actions for Get Online Devices ====
export const getOnlineDevices = (data) => {
    return {
        type : GET_ONLINE_DEVICES,
        payload : data
    }
}
export const getOnlineDevicesSuccess = (data) => {
    return {
        type : GET_ONLINE_DEVICES_SUCCESS,
        payload : data
    }
}
export const getOnlineDevicesFailure = (data) => {
    return {
        type : GET_ONLINE_DEVICES_FAILURE,
        payload : data
    }
}
export const getOnlineDevicesInit = (data) => {
    return {
        type : GET_ONLINE_DEVICES_INIT,
        payload : data
    }
}

// ==== Actions for Get Trend Graph ====
export const getTrendGraph = (data) => {
    return {
        type : GET_TREND_GRAPH,
        payload : data
    }
}
export const getTrendGraphSuccess = (data) => {
    return {
        type : GET_TREND_GRAPH_SUCCESS,
        payload : data
    }
}
export const getTrendGraphFailure = (data) => {
    return {
        type : GET_TREND_GRAPH_FAILURE,
        payload : data
    }
}
export const getTrendGraphInit = (data) => {
    return {
        type : GET_TREND_GRAPH_INIT,
        payload : data
    }
}

// ==== Actions to Get Storage Status ====
export const getStorageStatus = (data) => {
    return {
        type : GET_STORAGE_STATUS,
        payload : data
    }
}
export const getStorageStatusSuccess = (data) => {
    return {
        type : GET_STORAGE_STATUS_SUCCESS,
        payload : data
    }
}
export const getStorageStatusFailure = (data) => {
    return {
        type : GET_STORAGE_STATUS_FAILURE,
        payload : data
    }
}
export const getStorageStatusInit = (data) => {
    return {
        type : GET_STORAGE_STATUS_INIT,
        payload : data
    }
}

// ==== Actions to Get Device Info ====
export const getDevicesInfo = (data) => {
    return {
        type : GET_DEVICES_INFO,
        payload : data
    }
}
export const getDevicesInfoSuccess = (data) => {
    return {
        type : GET_DEVICES_INFO_SUCCESS,
        payload : data
    }
}
export const getDevicesInfoFailure = (data) => {
    return {
        type : GET_DEVICES_INFO_FAILURE,
        payload : data
    }
}
export const getDevicesInfoInit = (data) => {
    return {
        type : GET_DEVICES_INFO_INIT,
        payload : data
    }
}

// ==== Actions to download device info report ====
export const downloadDeviceInfoReport = (data) => {
    return {
        type : DOWNLOAD_DEVICE_INFO_REPORT,
        payload : data
    }
}
export const downloadDeviceInfoReportSuccess = (data) => {
    return {
        type : DOWNLOAD_DEVICE_INFO_REPORT_SUCCESS,
        payload : data
    }
}
export const downloadDeviceInfoReportFailure = (data) => {
    return {
        type : DOWNLOAD_DEVICE_INFO_REPORT_FAILURE,
        payload : data
    }
}
export const downloadDeviceInfoReportInit = (data) => {
    return {
        type : DOWNLOAD_DEVICE_INFO_REPORT_INIT,
        payload : data
    }
}