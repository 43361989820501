export const CREATE_BATCH = "CREATE_BATCH"
export const CREATE_BATCH_SUCCESS = "CREATE_BATCH_SUCCESS"
export const CREATE_BATCH_FAILURE = "CREATE_BATCH_FAILURE"
export const CREATE_BATCH_INIT = "CREATE_BATCH_INIT"

export const EDIT_BATCH = "EDIT_BATCH"
export const EDIT_BATCH_SUCCESS = "EDIT_BATCH_SUCCESS"
export const EDIT_BATCH_FAILURE = "EDIT_BATCH_FAILURE"
export const EDIT_BATCH_INIT = "EDIT_BATCH_INIT"

export const DELETE_BATCH = "DELETE_BATCH"
export const DELETE_BATCH_SUCCESS = "DELETE_BATCH_SUCCESS"
export const DELETE_BATCH_FAILURE = "DELETE_BATCH_FAILURE"
export const DELETE_BATCH_INIT = "DELETE_BATCH_INIT"

export const GET_BATCH = "GET_BATCH"
export const GET_BATCH_SUCCESS = "GET_BATCH_SUCCESS"
export const GET_BATCH_FAILURE = "GET_BATCH_FAILURE"
export const GET_BATCH_INIT = "GET_BATCH_INIT"

export const DOWNLOAD_BATCH_FILE = "DOWNLOAD_BATCH_FILE"
export const DOWNLOAD_BATCH_FILE_SUCCESS = "DOWNLOAD_BATCH_FILE_SUCCESS"
export const DOWNLOAD_BATCH_FILE_FAILURE = "DOWNLOAD_BATCH_FILE_FAILURE"
export const DOWNLOAD_BATCH_FILE_INIT = "DOWNLOAD_BATCH_FILE_INIT"

export const UPLOAD_BATCH_FILE = "UPLOAD_BATCH_FILE"
export const UPLOAD_BATCH_FILE_SUCCESS = "UPLOAD_BATCH_FILE_SUCCESS"
export const UPLOAD_BATCH_FILE_FAILURE = "UPLOAD_BATCH_FILE_FAILURE"
export const UPLOAD_BATCH_FILE_INIT = "UPLOAD_BATCH_FILE_INIT"