import {
CREATE_PRODUCT,
CREATE_PRODUCT_SUCCESS,
CREATE_PRODUCT_FAILURE,
CREATE_PRODUCT_INIT,

EDIT_PRODUCT,
EDIT_PRODUCT_SUCCESS,
EDIT_PRODUCT_FAILURE,
EDIT_PRODUCT_INIT,

DELETE_PRODUCT,
DELETE_PRODUCT_SUCCESS,
DELETE_PRODUCT_FAILURE,
DELETE_PRODUCT_INIT,

GET_PRODUCT,
GET_PRODUCT_SUCCESS,
GET_PRODUCT_FAILURE,
GET_PRODUCT_INIT,

GET_PRODUCT_TYPE,
GET_PRODUCT_TYPE_SUCCESS,
GET_PRODUCT_TYPE_FAILURE,
GET_PRODUCT_TYPE_INIT,

GET_PRODUCT_BY_CATEGORY,
GET_PRODUCT_BY_CATEGORY_SUCCESS,
GET_PRODUCT_BY_CATEGORY_FAILURE,
GET_PRODUCT_BY_CATEGORY_INIT

} from './actionTypes'

// ==== actions for create ====
export const createProduct = (data) => {
    return {
        type : CREATE_PRODUCT,
        payload : data
    }
}

export const createProductSuccess = (data) => {
    return {
        type : CREATE_PRODUCT_SUCCESS,
        payload : data
    }
}

export const createProductFailure = (data) => {
    return {
        type : CREATE_PRODUCT_FAILURE,
        payload : data
    }
}

export const createProductInit = (data) => {
    return {
        type : CREATE_PRODUCT_INIT,
        payload : data
    }
}

// ==== Actions for Edit Product ====
export const editProduct = (data) => {
    return {
        type : EDIT_PRODUCT,
        payload : data
    }
}

export const editProductSuccess = (data) => {
    return {
        type : EDIT_PRODUCT_SUCCESS,
        payload : data
    }
}

export const editProductFailure = (data) => {
    return {
        type : EDIT_PRODUCT_FAILURE,
        payload : data
    }
}

export const editProductInit = (data) => {
    return {
        type : EDIT_PRODUCT_INIT,
        payload : data
    }
}

// ==== Actions for Delete product ====
export const deleteProduct = (data) => {
    return {
        type : DELETE_PRODUCT,
        payload : data
    }
}

export const deleteProductSuccess = (data) => {
    return {
        type : DELETE_PRODUCT_SUCCESS,
        payload : data
    }
}

export const deleteProductFailure = (data) => {
    return {
        type : DELETE_PRODUCT_FAILURE,
        payload : data
    }
}

export const deleteProductInit = (data) => {
    return {
        type : DELETE_PRODUCT_INIT,
        payload : data
    }
}

// ==== Actions for Create product ====
export const getProduct = (data) => {
    return {
        type : GET_PRODUCT,
        payload : data
    }
}

export const getProductSuccess = (data) => {
    return {
        type : GET_PRODUCT_SUCCESS,
        payload : data
    }
}

export const getProductFailure = (data) => {
    return {
        type : GET_PRODUCT_FAILURE,
        payload : data
    }
}

export const getProductInit = (data) => {
    return {
        type : GET_PRODUCT_INIT,
        payload : data
    }
}

// ==== Actions for get product type ====
export const getProductType = (data) => {
    return {
        type : GET_PRODUCT_TYPE,
        payload : data
    }
}

export const getProductTypeSuccess = (data) => {
    return {
        type : GET_PRODUCT_TYPE_SUCCESS,
        payload : data
    }
}

export const getProductTypeFailure = (data) => {
    return {
        type : GET_PRODUCT_TYPE_FAILURE,
        payload : data
    }
}

export const getProductTypeInit = (data) => {
    return {
        type : GET_PRODUCT_TYPE_INIT,
        payload : data
    }
}

//==== Actions for get product by category ====
export const getProductByCategory = (data) => {
    return {
        type : GET_PRODUCT_BY_CATEGORY,
        payload : data
    }
}

export const getProductByCategorySuccess = (data) => {
    return {
        type : GET_PRODUCT_BY_CATEGORY_SUCCESS,
        payload : data
    }
}

export const getProductByCategoryFailure = (data) => {
    return {
        type : GET_PRODUCT_BY_CATEGORY_FAILURE,
        payload : data
    }
}

export const getProductByCategoryInit = (data) => {
    return {
        type : GET_PRODUCT_BY_CATEGORY_INIT,
        payload : data
    }
}