export const GET_PENDING_TRANSACTIONS = 'GET_PENDING_TRANSACTIONS';
export const GET_PENDING_TRANSACTIONS_SUCCESS = 'GET_PENDING_TRANSACTIONS_SUCCESS';
export const GET_PENDING_TRANSACTIONS_FAILURE = 'GET_PENDING_TRANSACTIONS_FAILURE';
export const CLEAR_PENDING_TRANSACTIONS = 'CLEAR_PENDING_TRANSACTIONS';
export const GET_PENDING_TRANSACTIONS_EXCEL = 'GET_PENDING_TRANSACTIONS_EXCEL';
export const GET_PENDING_TRANSACTIONS_EXCEL_SUCCESS = 'GET_PENDING_TRANSACTIONS_EXCEL_SUCCESS';
export const GET_PENDING_TRANSACTIONS_EXCEL_FAILURE = 'GET_PENDING_TRANSACTIONS_EXCEL_FAILURE';
export const GET_PENDING_TRANSACTIONS_EXCEL_INIT = 'GET_PENDING_TRANSACTIONS_EXCEL_INIT';

export const GET_TRANSACTION_DETAILS = 'GET_TRANSACTION_DETAILS';
export const GET_TRANSACTION_DETAILS_SUCCESS = 'GET_TRANSACTION_DETAILS_SUCCESS';
export const GET_TRANSACTION_DETAILS_FAILURE = 'GET_TRANSACTION_DETAILS_FAILURE';

export const GET_PAYMENT_DETAILS = 'GET_PAYMENT_DETAILS';
export const GET_PAYMENT_DETAILS_SUCCESS = 'GET_PAYMENT_DETAILS_SUCCESS';
export const GET_PAYMENT_DETAILS_FAILURE = 'GET_PAYMENT_DETAILS_FAILURE';

export const GET_BOOK = 'GET_BOOK';
export const GET_BOOK_SUCCESS = 'GET_BOOK_SUCCESS';
export const GET_BOOK_FAILURE = 'GET_BOOK_FAILURE';

export const DOWNLOAD_INIT = 'DOWNLOAD_INIT';
export const DOWNLOAD_BOOK = 'DOWNLOAD_BOOK';
export const DOWNLOAD_BOOK_SUCCESS = 'DOWNLOAD_BOOK_SUCCESS';
export const DOWNLOAD_BOOK_FAILURE = 'DOWNLOAD_BOOK_FAILURE';

export const DOWNLOAD_PAYMENT_DETAILS = 'DOWNLOAD_PAYMENT_DETAILS';
export const DOWNLOAD_PAYMENT_DETAILS_SUCCESS = 'DOWNLOAD_PAYMENT_DETAILS_SUCCESS';
export const DOWNLOAD_PAYMENT_DETAILS_FAILURE = 'DOWNLOAD_PAYMENT_DETAILS_FAILURE';

export const GET_BILLDESK_TRANSACTIONS = 'GET_BILLDESK_TRANSACTIONS';
export const GET_BILLDESK_TRANSACTIONS_SUCCESS = 'GET_BILLDESK_TRANSACTIONS_SUCCESS';
export const GET_BILLDESK_TRANSACTIONS_FAILURE = 'GET_BILLDESK_TRANSACTIONS_FAILURE';
export const GET_BILLDESK_TRANSACTIONS_INIT = 'GET_BILLDESK_TRANSACTIONS_INIT';

export const DOWNLOAD_BILLDESK_TRANSACTIONS = 'DOWNLOAD_BILLDESK_TRANSACTIONS';
export const DOWNLOAD_BILLDESK_TRANSACTIONS_SUCCESS = 'DOWNLOAD_BILLDESK_TRANSACTIONS_SUCCESS';
export const DOWNLOAD_BILLDESK_TRANSACTIONS_FAILURE = 'DOWNLOAD_BILLDESK_TRANSACTIONS_FAILURE';

export const CLEAR_ERROR = 'CLEAR_ERROR';