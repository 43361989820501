import {

    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILURE,
    GET_ALL_USERS_INIT,

    GET_USER_DETAILS,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILURE,
    GET_USER_DETAILS_INIT,

    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILURE,
    EDIT_USER_INIT,

    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    DELETE_USER_INIT,

    INVITE_USER,
    INVITE_USER_SUCCESS,
    INVITE_USER_FAILURE,
    INVITE_USER_INIT,

    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,
    GET_ROLES_INIT,

    GET_PERMISSIONS,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS_FAILURE,
    GET_PERMISSIONS_INIT

} from './actionTypes'


// ==== Actions for Get User ===
export const getAllUser = (data) => {
    return{
        type: GET_ALL_USERS,
        payload: data
    }
}
export const getAllUserSuccess = (data) => {
    return{
        type: GET_ALL_USERS_SUCCESS,
        payload: data
    }
}
export const getAllUserFailure = (data) => {
    return{
        type: GET_ALL_USERS_FAILURE,
        payload: data
    }
}
export const getAllUserInit = (data) => {
    return{
        type: GET_ALL_USERS_INIT,
        payload: data
    }
}

// ==== Actions for Get User Details ====
export const getUserDetails = (data) => {
    return{
        type: GET_USER_DETAILS,
        payload: data
    }
}
export const getUserDetailsSuccess = (data) => {
    return{
        type: GET_USER_DETAILS_SUCCESS,
        payload: data
    }
}
export const getUserDetailsFailure = (data) => {
    return{
        type: GET_USER_DETAILS_FAILURE,
        payload: data
    }
}
export const getUserDetailsInit = (data) => {
    return{
        type: GET_USER_DETAILS_INIT,
        payload: data
    }
}

// ==== Actions for Edit User ====
export const editUser = (data) => {
    return{
        type: EDIT_USER,
        payload: data
    }
}
export const editUserSuccess = (data) => {
    return{
        type: EDIT_USER_SUCCESS,
        payload: data
    }
}
export const editUserFailure = (data) => {
    return{
        type: EDIT_USER_FAILURE,
        payload: data
    }
}
export const editUserInit = (data) => {
    return{
        type: EDIT_USER_INIT,
        payload: data
    }
}

// ==== Actions for Delete user ====
export const deleteUser = (data) => {
    return{
        type: DELETE_USER,
        payload: data
    }
}
export const deleteUserSuccess = (data) => {
    return{
        type: DELETE_USER_SUCCESS,
        payload: data
    }
}
export const deleteUserFailure = (data) => {
    return{
        type: DELETE_USER_FAILURE,
        payload: data
    }
}
export const deleteUserInit = (data) => {
    return{
        type: DELETE_USER_INIT,
        payload: data
    }
}

// ==== Actions for Invite Users ====
export const inviteUser = (data) => {
    return{
        type: INVITE_USER,
        payload: data
    }
}
export const inviteUserSuccess = (data) => {
    return{
        type: INVITE_USER_SUCCESS,
        payload: data
    }
}
export const inviteUserFailure = (data) => {
    return{
        type: INVITE_USER_FAILURE,
        payload: data
    }
}
export const inviteUserInit = (data) => {
    return{
        type: INVITE_USER_INIT,
        payload: data
    }
}

// ==== Actions for Get Roles ====
export const getRoles = (data) => {
    return{
        type: GET_ROLES,
        payload: data
    }
}
export const getRolesSuccess = (data) => {
    return{
        type: GET_ROLES_SUCCESS,
        payload: data
    }
}
export const getRolesFailure = (data) => {
    return{
        type: GET_ROLES_FAILURE,
        payload: data
    }
}
export const getRolesInit = (data) => {
    return{
        type: GET_ROLES_INIT,
        payload: data
    }
}

// ==== Actions for Get Permissions ====
export const getPermissions = (data) => {
    return{
        type: GET_PERMISSIONS,
        payload: data
    }
}
export const getPermissionsSuccess = (data) => {
    return{
        type: GET_PERMISSIONS_SUCCESS,
        payload: data
    }
}
export const getPermissionsFailure = (data) => {
    return{
        type: GET_PERMISSIONS_FAILURE,
        payload: data
    }
}
export const getPermissionsInit = (data) => {
    return{
        type: GET_PERMISSIONS_INIT,
        payload: data
    }
}
