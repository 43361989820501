import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
    GET_ALL_PLANS, GET_PLAN_DETAILS,
    GET_PLAN_FREQUENCY, CREATE_PLAN,
    EDIT_PLAN, DISABLE_PLAN,
    GET_ALL_PROMO, GET_PROMO_DETAILS,
    CREATE_PROMO, EDIT_PROMO,
    DISABLE_PROMO, GET_LN_PLAN
} from './actionTypes'

import {
    getAllPlansSuccess, getAllPlansFailure,
    getPlanDetailsSuccess, getPlanDetailsFailure,
    getPlanFrequencySuccess, getPlanFrequencyFailure,
    createPlanSuccess, createPlanFailure, 
    editPlanSuccess, editPlanFailure,
    disablePlanSuccess, disablePlanFailure,
    getLnPlanSuccess, getLnPlanFailure,
    getAllPromoSuccess, getAllPromoFailure,
    getPromoDetailsSuccess, getPromoDetailsFailure,
    createPromoSuccess, createPromoFailure,
    editPromoSuccess, editPromoFailure,
    disablePromoSuccess, disablePromoFailure, getPromoDetails
} from './actions'

import { getAllPlanService, getAllPromoService, getPlanFrequencyService, createPlanService, getPlanDetailsService, editPlanService, createPromoService, getPromoDetailsService, updatePromoService,
        disablePlanService, fetchLnPlanListService } from '../../services/subscriptionService'

import { checkError } from '../../helpers/errorHelper'

// ==== Functions for Saga start here ====

// ==== Functions for PLANS ====

// ==== Function to Get All Plans ====
function* fetchAllPlans(action) {
    try{
        const response = yield call(getAllPlanService)
        if(response.data.status === true){
            yield put(getAllPlansSuccess(response.data.data))
        }else{
            yield put(getAllPlansFailure(response.data.message))
        }
    }catch(error){
        yield put(getAllPlansFailure(checkError(error)))
    }
}

// ==== Function to Get Plan Details ====
function* fetchPlanDetails(action) {
    try{
        const response = yield call(getPlanDetailsService, action.payload)
        if(response.data.status === true){
            yield put(getPlanDetailsSuccess(response.data.data))
        }else{
            yield put(getPlanDetailsFailure(response.data.message))
        }
    }catch(error){
        yield put(getPlanDetailsFailure(checkError(error)))
    }
}

// ==== Function to Get Plan Frequency ====
function* fetchPlanFrequency(action) {
    try{
        const response = yield call(getPlanFrequencyService)
        if(response.data.status === true){
            yield put(getPlanFrequencySuccess(response.data.data))
        }else{
            yield put(getPlanFrequencyFailure(response.data.message))
        }
    }catch(error){
        yield put(getPlanFrequencyFailure(checkError(error)))
    }
}

// ==== Function for Create Plan ====
function* addPlan(action) {
    try{
        const response = yield call(createPlanService, action.payload.data, action.payload.isTrialReset)
        if(response.data.status === true){
            yield put(createPlanSuccess(response.data.data))
        }else{
            yield put(createPlanFailure(response.data.message))
        }
    }catch(error){
        yield put(createPlanFailure(checkError(error)))
    }
}

// ==== Function for Edit Plan ====
function* updatePlan(action) {
    try{        
        const response = yield call(
            editPlanService,
            action.payload.data.data,
            action.payload.data.isTrialReset,
            action.payload.planId
        )
        if(response.data.status === true){
            yield put(editPlanSuccess(response.data.data))
        }else{
            yield put(editPlanFailure(response.data.message))
        }
    }catch(error){
        yield put(editPlanFailure(checkError(error)))
    }
}

// ==== Function for Disable Plan ====
function* deactivatePlan(action) {
    try{
        const response = yield call(disablePlanService, action.payload.planId)
        if(response.data.status === true){
            yield put(disablePlanSuccess(response.data.data))
        }else{
            yield put(disablePlanFailure(response.data.message))
        }
    }catch(error){
        yield put(disablePlanFailure(checkError(error)))
    }
}

function* fetchLnPlanList(action) {
    try{
        const response = yield call(fetchLnPlanListService)
        if(response.data.status === true){
            yield put(getLnPlanSuccess(response.data.data))
        }else{
            yield put(getLnPlanFailure(response.data.message))
        }
    }catch(error){
        yield put(getLnPlanFailure(checkError(error)))
    }
}



// ==== Functions for PROMO CODE ====

// ==== Function for Get All Promo ====
function* fetchAllPromo(action) {
    try{
        const response = yield call(getAllPromoService)
        if(response.data.status === true){
            yield put(getAllPromoSuccess(response.data.data))
        }else{
            yield put(getAllPromoFailure(response.data.message))
        }
    }catch(error){
        yield put(getAllPromoFailure(checkError(error)))
    }
}

// ==== Function for Get Promo Details ====
function* fetchPromoDetails(action) {
    try{
        const response = yield call(getPromoDetailsService, action.payload.promoId)
        if(response.data.status === true){
            yield put(getPromoDetailsSuccess(response.data.data))
        }else{
            yield put(getPromoDetailsFailure(response.data.message))
        }
    }catch(error){
        yield put(getPromoDetailsFailure(checkError(error)))
    }
}

// ==== Function for Create Promo ====
function* addPromo(action) {
    try{
        const response = yield call(createPromoService, action.payload.details, action.payload.params)
        if(response.data.status === true){
            yield put(createPromoSuccess(response.data.data))
        }else{
            yield put(createPromoFailure(response.data.message))
        }
    }catch(error){
        yield put(createPromoFailure(checkError(error)))
    }
}

// ==== Function for Edit Promo ====
function* updatePromo(action) {
    try{
        const response = yield call(
            updatePromoService,
            action.payload.details.details, 
            action.payload.details.params, 
            action.payload.promoId
            )
        if(response.data.status === true){
            yield put(editPromoSuccess(response.data.data))
        }else{
            yield put(editPromoFailure(response.data.message))
        }
    }catch(error){
        yield put(editPromoFailure(checkError(error)))
    }
}

// ==== Function for Disable Promo ====
function* deactivatePromo(action) {
    try{
        const response = yield call()
        if(response.data.status === true){
            yield put(disablePromoSuccess(response.data.data))
        }else{
            yield put(disablePromoFailure(response.data.message))
        }
    }catch(error){
        yield put(disablePromoFailure(checkError(error)))
    }
}



// ==== Watcher functions ====
export function* watchFetchAllPlans() {
    yield takeEvery(GET_ALL_PLANS, fetchAllPlans)
}
export function* watchFetchPlanDetails() {
    yield takeEvery(GET_PLAN_DETAILS, fetchPlanDetails)
}
export function* watchFetchPlanFrequency() {
    yield takeEvery(GET_PLAN_FREQUENCY, fetchPlanFrequency)
}
export function* watchAddPlan() {
    yield takeEvery(CREATE_PLAN, addPlan)
}
export function* watchUpdatePlan() {
    yield takeEvery(EDIT_PLAN, updatePlan)
}
export function* watchDeactivatePlan() {
    yield takeEvery(DISABLE_PLAN, deactivatePlan)
}
export function* watchFetchLnPlanList() {
    yield takeEvery(GET_LN_PLAN, fetchLnPlanList)
}

export function* watchFetchAllPromo() {
    yield takeEvery(GET_ALL_PROMO, fetchAllPromo)
}
export function* watchFetchPromoDetails() {
    yield takeEvery(GET_PROMO_DETAILS, fetchPromoDetails)
}
export function* watchAddPromo() {
    yield takeEvery(CREATE_PROMO, addPromo)
}
export function* watchUpdatePromo() {
    yield takeEvery(EDIT_PROMO, updatePromo)
}
export function* watchDeactivatePromo() {
    yield takeEvery(DISABLE_PROMO, deactivatePromo)
}



// ==== Exported Default functions ====
function* SubscriptionSaga() {
    yield all([
        fork(watchFetchAllPlans),
        fork(watchFetchPlanDetails),
        fork(watchFetchPlanFrequency),
        fork(watchAddPlan),
        fork(watchUpdatePlan),
        fork(watchDeactivatePlan),
        fork(watchFetchLnPlanList),

        fork(watchFetchAllPromo),
        fork(watchFetchPromoDetails),
        fork(watchAddPromo),
        fork(watchUpdatePromo),
        fork(watchDeactivatePromo),
    ]);
}

export default SubscriptionSaga;