import API from './interceptor';
import constants from '../common/constants'
const urlDevicePrefix = constants.urlDevicePrefix
const urlPrefix = constants.urlPrefix

export function addNewBatch(payload) {
    return API({
        method: 'POST',
        url: urlDevicePrefix+`batches`,
        data: payload
            // params: { vendorId: 1}
    });
}

export function fetchBatches() {
    return API({
        method: 'GET',
        url: urlDevicePrefix+'batches'
    });
}

export function searchBatches(query) {
    return API({
        method: 'GET',
        url: urlDevicePrefix+'admins/batches',
        params: query
    });
}

export function deleteBatch(id) {
    return API({
        method: 'DELETE',
        url: urlDevicePrefix+`batches/${id}`
    });
}

export function editBatch(batchId, batch) {
    return API({
        method: 'PUT',
        url: urlDevicePrefix+`batches/${batchId}`,
        data: JSON.stringify(batch)
    });
}

export function downloadExcel(id) {
    return API({
        method: 'POST',
        url: urlDevicePrefix+`batches/${id}/download`,
        responseType: 'arraybuffer'
    });
}

export function uploadExcel(id, payload) {
    return API({
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        url: urlDevicePrefix+`batches/${id}/upload`,
        data: payload
    });

}