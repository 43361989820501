import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {} from '../../store/actions';

// MetisMenu
import MetisMenu from 'metismenujs';
import SimpleBar from 'simplebar-react';

import { Link } from 'react-router-dom';

const SidebarContent = (props) => {
  return (
    <>
      <div id='sidebar-menu'>
        <ul
          className='metismenu list-unstyled'
          id='side-menu'>
          <li className='menu-title'>Menu</li>

          {props.allPermissions.includes('Dashboard') ? (
            <li>
              <Link
                to='/dashboard'
                className='waves-effect'>
                <i className='bx bx-home-circle'></i>
                <span>Dashboard</span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {props.allPermissions.includes('Database') ? (
            <li>
              <Link to='/vendors'>
                <i className='bx bx-file'></i>
                <span>Vendors</span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {props.allPermissions.includes('Database') ? (
            <li>
              <Link to='/categories'>
                <i class='fas fa-box'></i>
                <span>Product Categories</span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {props.allPermissions.includes('Database') ? (
            <li>
              <Link to='/products'>
                <i class='fab fa-product-hunt'></i>
                <span>Products</span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {props.allPermissions.includes('Database') ? (
            <li>
              <Link to='/batches'>
                <i className='bx bx-tone'></i>
                <span>Batches</span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {props.allPermissions.includes('Customers') ? (
            <li>
              <Link
                to='/customers'
                className='waves-effect'>
                <i className='bx bxs-user-detail'></i>
                <span>Customers</span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {props.allPermissions.includes('System') ? (
            <li>
              <Link to='/system' className='waves-effect'>
                <i class='fas fa-microchip'></i>
                <span>System</span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {props.allPermissions.includes('Subscription') ? (
            <li>
              <Link
                to='/subscription'
                className='waves-effect'>
                <i class='fas fa-rupee-sign'></i>
                <span>Subscription</span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {props.allPermissions.includes(
            'Manage Access'
          ) ? (
            <li>
              <Link
                to='/manage-access'
                className='waves-effect'>
                <i class='fas fa-unlock-alt'></i>
                <span>Manage Access</span>
              </Link>
            </li>
          ) : (
            ''
          )}

          {props.allPermissions.includes(
            'Manage Access'
          ) ? (
            <li>
              <Link
                to='/ln-report'
                className='waves-effect'>
                <i class='fas fa-table'></i>
                <span>LN Report</span>
              </Link>
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    </>
  );
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionArr: [],
    };
  }

  componentDidMount() {
    console.log(this.props.tokenDetails.permissions);
    if (
      this.props.tokenDetails.permissions !== '' &&
      this.props.tokenDetails.permissions !== null &&
      this.props.tokenDetails.permissions !== undefined
    ) {
      this.setState({
        permissionArr:
          this.props.tokenDetails.permissions.split('|'),
      });
    } else if (
      this.props.tokenDetails.permissions === null ||
      this.props.tokenDetails.permissions === undefined
    ) {
      this.setState({
        permissionArr:
          'Subscription|Portal Activity|Manage Access|Database|Customers|Dashboard|System|LN Report',
      });
    }
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    // if (this.props.type !== "condensed" || this.props.isMobile) {
    new MetisMenu('#side-menu');

    var matchingMenuItem = null;
    var ul = document.getElementById('side-menu');
    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      if (
        this.props.location.pathname === items[i].pathname
      ) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
    // }
  }

  activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    let { permissionArr } = this.state;

    return (
      <React.Fragment>
        <div className='vertical-menu'>
          <div data-simplebar className='h-100'>
            {this.props.type !== 'condensed' ? (
              <SimpleBar style={{ maxHeight: '100%' }}>
                <SidebarContent
                  allPermissions={permissionArr}
                />
              </SimpleBar>
            ) : (
              <SidebarContent allPermissions={[]} />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
    tokenDetails: state.Login,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(Sidebar));
