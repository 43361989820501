import React, { Component } from 'react';
import { Container, Button, Modal, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import InputBox from '../../components/Common/input-box';
import Breadcrumbs from '../../components/Common/breadcrumb';
import { createAndDownloadBlobFile } from '../../helpers/downloadHelper';
import moment from 'moment';
import DropdownList from 'react-widgets/lib/DropdownList';

import * as BatchActions from '../../store/batch/actions'
import * as VendorActions from '../../store/vendor/actions'
import * as LayoutActions from '../../store/layout/actions'
import * as ProductCategory from '../../store/productCategory/actions'
import * as Product from '../../store/product/actions'
import { sortIntegerTypeData, sortStringTypeData } from '../../helpers/sortHelper';
import { checkDuplicateValues } from '../../helpers/duplicateCheckHelper'

import { connect } from 'react-redux';
import { retry } from 'redux-saga/effects';

class Batch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipShow: false,
            idsAdded: false,
            collapseRowId: '',
            openedCollapse: [],
            popovertop: false,
            showCreateModal: false,
            modalType: 'add',
            searchValue: '',
            vendorId: '',
            categoryId: '',
            productTypeId: '',
            productId: '',
            deviceCount: '',
            poNumber: '',
            batchName: '',
            groupedBy: '',
            filter: {
                isVendor: null,
                isProduct: null,
                isProdCat: null,
                isBatch: null,
                pageCount: 1,
                pageSize: 10, // ==== Also revert in group ====
                // pageSize : 3
            },
            changedFilter: {
                isVendor: null,
                isProduct: null,
                isProdCat: null,
                isBatch: null,
                pageCount: 1,
                pageSize: 10,
            },
            filterChanged: false,
            searchValueError: false,
            batchNameError: false,
            poNumberError: false,
            deviceCountError: false,
            vendorIdError: false,
            categoryIdError: false,
            productIdError: false,
            categoryList: [],
            productList: [],
            vendorList: [],
            batchList: [],
            searchList: [],
            totalCount : 0,
            showLoadMore : true,
            totalSearchCount : 0,
            deleteConfirmPopUp: false,
            deleteConfirmPopupId: null,
            isSearchState: false,
            direction: 1,
            deleteIndex : null,
            deleteId : null,
            fileActionIndex : null,
            fileActionId : null,
            editIndex : null,
            editId : null,
            sortValue : null,
            sortDirection : null,
            groupValue : null,
            duplicateCheckObj : {},
            isGrouped : false,
            createdBatches : [],
            emptySearchResult : ''
        }
    }

    componentDidMount() {
        this.props.getVendors()
        this.props.changePreloader(true)
    }

    modalToggle = () => {
        let { showCreateModal } = this.state;
        this.setState({
            vendorId: '', categoryId: '', productId: '', deviceCount: '', poNumber: '', batchName: '', batchId: '', modalType: 'add', showCreateModal: !showCreateModal,
            vendorIdError: false, categoryIdError: false, productIdError: false, deviceCountError: false, batchNameError: false
        });
    }

    toggleSection = (val, e) => {
        let { openedCollapse, idsAdded, batchList } = this.state;
        let collapsedSection = document.getElementById(val);
        if (idsAdded === false) {
            let batchIdData = [];
            for (let i = 0; i < batchList.length; i++) {
                batchIdData.push(batchList[i].batchId);
            }
            this.setState({ openedCollapse: [...batchIdData], idsAdded: true }, ()=>{
                console.log("First if")
                console.log(this.state.openedCollapse)
            });
        }

        if (openedCollapse.indexOf(val) === -1) {
            collapsedSection.classList.remove('hide-collapse');
            let updatedArray = [...openedCollapse, val];
            this.setState({ openedCollapse: [...updatedArray] }, () => {
                console.log("second if")
                console.log(this.state.openedCollapse)
            });
        } else {
            collapsedSection.classList.add('hide-collapse');
            let updatedArray = openedCollapse.filter((value) => { return value !== val });
            this.setState({ openedCollapse: [...updatedArray] }, ()=>{
                console.log("third if")
                console.log(this.state.openedCollapse)
            });
        }

    }

    addClickHandler = (type, data) => {
        let { categoryId, productId, vendorId, batchName, poNumber, deviceCount, batchId } = this.state;
        let trimmedBatchName = batchName.trim()
        let trimmedDeviceCount = deviceCount.toString().trim()
        if ((vendorId !== '') && (categoryId !== '') && (productId !== '') &&
            (deviceCount !== '') && (trimmedBatchName !== '') && (trimmedDeviceCount !== '')) {
            let data = {
                batchName: trimmedBatchName,
                deviceCount: trimmedDeviceCount,
                ponumber: poNumber,
                productCategoryId: categoryId,
                productId: productId,
                vendorId: vendorId
            };

            if (type === 'add') {
                console.log("#####  Gets into ADD Success method !!!")
                this.props.createBatch(data)
                this.props.changePreloader(true)

            } else if (type === 'edit') {
                //call edit api
                let newdata = {
                    vendorId: vendorId,
                    productId: productId,
                    productCategoryId: categoryId,
                    ponumber: poNumber,
                    deviceCount: trimmedDeviceCount,
                    batchName: trimmedBatchName
                };
                let payload = {
                    newData: newdata,
                    batchId: batchId
                }

                this.props.editBatch(payload)
                this.props.changePreloader(true)
            }

        }

        if (vendorId === '') {
            this.setState({ vendorIdError: true });
        }
        if (categoryId === '') {
            this.setState({ categoryIdError: true });
        }
        if (productId === '') {
            this.setState({ productIdError: true });
        }
        if (trimmedBatchName === '') {
            this.setState({ batchNameError: true });
        }
        if (trimmedDeviceCount === '') {
            this.setState({ deviceCountError: true });
        }
    }

    onSearch = (e) => {
        if (e.keyCode === 13 || e.target.name === 'searchButton') {
            let { searchValue, filterChanged, changedFilter, filter } = this.state;
            let trimmedSearchValue = searchValue.toString().trim()
            if (trimmedSearchValue !== '') {

                // if (filterChanged === false) {
                //     filter.search = searchValue;
                //     console.log(filter);
                //     this.props.getBatch(filter)
                //     this.props.changePreloader(true)
                // } else {
                //     changedFilter.search = searchValue;
                //     this.props.getBatch(changedFilter)
                //     this.props.changePreloader(true)
                // }
                console.log("#####  Starting search")
                console.log(searchValue)                
                console.log(trimmedSearchValue)
                this.setState({ searchList : [], searchValue : trimmedSearchValue, createdBatches : [], emptySearchResult : '' }, () => {
                    changedFilter.search = trimmedSearchValue;
                    this.props.getBatch(changedFilter)
                    this.props.changePreloader(true)
                })
                this.setState({ searchValueError: false, isSearchState: true, searchValue : searchValue });

            } else {
                this.props.getBatch(filter);
                this.setState({ searchValueError: true });
            }
            // this.setState({ searchValueError: false });
        }
    }

    inputHandler = (e) => {

        if (e.target.name === 'searchValue') {
            this.setState({ searchValue: e.target.value, searchValueError: false });
        } else if (e.target.name === 'searchType') {
            this.setState({ searchType: e.target.value });
        } else if (e.target.name === "searchProductid") {
            this.setState({ productIdValue: e.target.value });
        }
        else if (e.target.name === 'vendorDropdown') {
            this.setState({ vendorId: e.target.value, vendorIdError: false, categoryId: '', productId: '', categoryList: [], productList: [] });
            let data = {
                vendorId: e.target.value
            }
            this.props.getProductCategory(data)
        }
        else if (e.target.name === 'categoryDropdown') {
            this.setState({ categoryId: e.target.value, productList:[], productId:'', categoryIdError: false });
            let data = {
                categoryId: e.target.value
            }
            this.props.getProductByCategory(data)
        }
        else if (e.target.name === 'productName') {
            this.setState({ productId: e.target.value, productIdError: false });
        }
        else if (e.target.name === 'deviceCount') {        
            let countVal = parseInt(e.target.value)
            if(countVal <= 0 || countVal > 100000000){
                return
            }
            if ((e.target.value - e.target.value) === 0) {
                this.setState({ deviceCount: e.target.value, deviceCountError: false });
            } else {
                return
            }
        }
        else if (e.target.name === 'poNumber') {
            this.setState({ poNumber: e.target.value });
        }
        else if (e.target.name === 'batchName') {
            this.setState({ batchName: e.target.value, batchNameError: false });
        }
    }

    editBatchHandler = (batch, editableFlag, index) => {
        if (editableFlag === 1) {
            return
        }
        let data = {
            vendorId: batch.vendorId,
            categoryId: batch.productCategoryId
        }
        this.props.getProductCategory(data)
        this.props.getProductByCategory(data)
        this.setState({ vendorId: batch.vendorId, categoryId: batch.productCategoryId, productId: batch.productId, deviceCount: batch.deviceCount, poNumber: batch.ponumber, batchName: batch.batchName, batchId: batch.batchId, modalType: 'edit', showCreateModal: true , editIndex : index, editId : batch.batchId});
    }

    deleteBatchHandler = (val, index) => {
        let isEditableFlag = val.isDownloaded
        let batchID = val.batchId
        if (isEditableFlag === 1) {
            return
        }
        let data = {
            batchId: batchID
        }
        this.props.deleteBatch(data)
        this.props.changePreloader(true);
        this.setState({ deleteConfirmPopUp: false, deleteConfirmPopupId: '', deleteIndex : index, deleteId : val.batchId });
    }
    
    deleteFromBatchList = () => {
        let { deleteId, deleteIndex, isSearchState, batchList, searchList } = this.state
        if(isSearchState){
            searchList.splice(deleteIndex, 1)
            let batchListIndex = batchList.findIndex(value => value.vendorId === deleteId)
            batchList.splice(batchListIndex, 1)
        }else{
            batchList.splice(deleteIndex, 1)
            console.log(batchList)
        }
        console.log(deleteIndex)
        console.log(batchList)
        this.setState ({ batchList : batchList, searchList : searchList, deleteIndex : null, deleteId : null})
    }

    groupBy = (type) => {
        console.log(type)
        let { groupedBy } = this.state;
        let query = {
            pageCount: 1,
            pageSize: 10,
            group: type
        }
        groupedBy === type ? this.setState({ groupedBy: '', isGrouped : true }, () => this.checkSortAndFilter()) : this.setState({ groupedBy: type, isGrouped : true }, () => this.checkSortAndFilter())
        // groupedBy === type ? this.props.getBatch(this.state.filter) : this.props.getBatch(query)
        // this.props.changePreloader(true)
        // this.checkSortAndFilter()
    }

    downloadFile = (id, index) => {
        let data = {
            id: id
        }
        this.props.downloadBatchFile(data)
        this.setState ({ fileActionId : id, fileActionIndex : index})
    }

    onFileUpload = (batch, e, index) => {
        let { filter } = this.state;
        if (e.target.files.length !== 0) {
            let testRegex = new RegExp(/^.*xlsx$|^.*xls$|.*csv$/)
            if(!testRegex.test(e.target.files[0].name)){
                toast.error("Please add a valid Report File")
                return
            }
            const formBox = new FormData();
            formBox.append('file', e.target.files[0]);
            let data = {
                id: batch.batchId,
                formBox: formBox
            }
            this.props.uploadBatchFile(data);
            this.setState ({ fileActionId : batch.batchId, fileActionIndex : index})
        }
    }

    editOnBatchList = (caseType, data) => {
        let { fileActionId, fileActionIndex, editIndex, editId, batchList, isSearchState, searchList } = this.state

        switch(caseType) {
            case "edit" : {
                if(isSearchState){
                    searchList[editIndex] = data
                    let batchIndex = batchList.findIndex( value => value.batchId === editId)
                    batchList[batchIndex] = data
                }else{
                    batchList[editIndex] = data
                }
                break
            }

            case "download" : {
                if(isSearchState){
                    searchList[fileActionIndex].isDownloaded = 1
                    let batchIndex = batchList.findIndex( value => value.batchId === fileActionId)
                    batchList[batchIndex].isDownloaded = 1
                }else{
                    batchList[fileActionIndex].isDownloaded = 1
                }
                break
            }

            case "upload" : {
                if(isSearchState){
                    searchList[fileActionIndex].isUploaded = 1
                    let batchIndex = batchList.findIndex( value => value.batchId === fileActionId)
                    batchList[batchIndex].isUploaded = 1
                }else{
                    batchList[fileActionIndex].isUploaded = 1
                }
                break
            }

            defaut :
                console.log("none")
        }
        this.setState ({ fileActionId : null, fileActionIndex : null, editId : null, editIndex : null, batchList : batchList, searchList : searchList})
    }

    toggleFilterPopup = () => { this.setState({ popovertop: !this.state.popovertop }); }

    toggleConfirmPopup = (id, isEditableFlag) => {
        if (isEditableFlag === 1) {
            return
        }
        this.setState({ deleteConfirmPopUp: !this.state.deleteConfirmPopUp, deleteConfirmPopupId: id });
    }

    onFilterChange = (e) => {
        let { changedFilter, filter } = this.state;
        if (e.target.value == 0) { // ==== Added "==" because value returned is string 0 (zero) ====
            console.log("Inside Null")
            changedFilter[e.target.name] = 1;
            this.setState({ changedFilter: { ...changedFilter }, filterChanged: true }, console.log(changedFilter))

        } else {
            console.log("Inside not null")
            changedFilter[e.target.name] = null;
            this.setState({ changedFilter: { ...changedFilter }, filterChanged: true }, console.log(changedFilter))

        }
    }

    onResetFilter = () => {
        let { filter } = this.state
        this.setState({
            filter : {...filter, pageCount : 1, pageSize : 10}
        })
    }

    onClearSearch = () => {
        this.setState({
            isSearchState: false,
            searchList: [],
            searchValue : '',
            changedFilter : {...this.state.changedFilter, pageCount : 1, pageSize : 10},
            searchValueError : false,
            changedFilter: {
                isVendor: null,
                isProduct: null,
                isProdCat: null,
                isBatch: null,
                pageCount: 1,
                pageSize: 10,
            },
            
        },
        () => this.checkLoadMoreState(this.state.totalCount))
        
    }

    onLoadMore = () => {
        console.log("#####  calling Load more")
        let { filter, filterChanged, changedFilter, isSearchState } = this.state
        let tempPageCount
        if(isSearchState){
            this.setState({
                changedFilter : {
                    ...changedFilter,
                    pageCount : changedFilter.pageCount + 1
                }
            })
            changedFilter.pageCount = changedFilter.pageCount + 1
            // tempPageCount = changedFilter.pageCount
            this.props.getBatch(changedFilter)
        }else{
            this.setState({
                filter: {
                    ...filter,
                    pageCount: filter.pageCount + 1
                }
            })
            filter.pageCount = filter.pageCount + 1
            // tempPageCount = filter.pageCount
            this.props.getBatch(filter)
        }
        // (tempPageCount * filter.pageSize) > totalCount ? this.setState({ showLoadMore : false }) : this.setState({ showLoadMore : true })
        this.props.changePreloader(true)
    }

    checkLoadMoreState = (totalPageCount) => {
        let { showLoadMore, batchList, searchList, totalSearchCount, isSearchState, filter, changedFilter, filterChanged } = this.state
        console.log(`### caling from checkLoadMore, Search : ${isSearchState}, Filter : ${JSON.stringify(filter)}`)
        if(isSearchState){
            (changedFilter.pageCount * changedFilter.pageSize) > totalPageCount ? this.setState({showLoadMore : false}) : this.setState({showLoadMore : true})

        }else{
            (filter.pageCount * filter.pageSize) > totalPageCount ? this.setState({showLoadMore : false}) : this.setState({showLoadMore : true})
        }
    }

    checkBatchDuplicate = (newList, totalCount) => {
        let result = checkDuplicateValues(this.state.duplicateCheckObj, newList)
        this.setState({
            batchList : [...this.state.batchList, ...result.newList],
            duplicateCheckObj : result.duplicateObj,
            totalCount : totalCount
        }, 
        () => {this.checkSortAndFilter()})
    }

    checkSortAndFilter = () => {
        let {sortValue, groupValue, groupedBy, direction, isGrouped } = this.state
        console.log(`value of isGroup : ${isGrouped}`)
        if(groupedBy !== ''){
            console.log("grouped by")
            console.log(groupedBy)
            let groupSortValue 
            if(groupedBy === 'vendor') { groupSortValue = 'vendorName'}
            else if( groupedBy === 'category' ) { groupSortValue = 'productCategoryName'}
            else if( groupedBy === 'product') { groupSortValue = 'productName'}
            this.sort(groupSortValue, "string", "asc", false)
        }
        else if(sortValue){
            console.log("sort value")
            console.log(`SORT VALUE : ${sortValue}`)
            this.sort(sortValue, "string", direction)
        }
        else if(isGrouped){
            console.log("Gets in is Grouped!")
            let originalSortValue = "createdOn"
            this.sort(originalSortValue, "number", "desc", false)
            this.setState({
                isGrouped : false
            })
        }
    }

    // ==== Checker methods starting here ====
    checkGetVendors() {
        if (this.props.layout.isPreloader) {
            if (this.props.vendor.getVendorsSuccess === true) {
                this.setState({ vendorList: this.props.vendor.vendors })
                this.props.getVendorsInit()
                this.props.getBatch(this.state.filter)
            } else if (this.props.vendor.getVendorsFailure === true) {
                this.props.changePreloader(true)
                toast.error(this.props.vendor.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getVendorsInit()
            }
        }
    }

    checkGetBatch() {
        let { isSearchState } = this.state
        if (this.props.layout.isPreloader) {
            if (this.props.batch.getBatchSuccess === true) {
                isSearchState ? this.setState({ searchList : [...this.state.searchList, ...this.props.batch.batchList], totalSearchCount : this.props.batch.totalBatchCount}) : 
                    // this.setState({ batchList: [... this.state.batchList, ...this.props.batch.batchList], totalCount : this.props.batch.totalBatchCount})
                    // this.setState({ batchList:  this.props.batch.batchList })
                    this.checkBatchDuplicate(this.props.batch.batchList, this.props.batch.totalBatchCount)
                this.checkLoadMoreState(this.props.batch.totalBatchCount)
                this.props.getBatchInit()
                this.props.changePreloader(false)
                // this.bat
            } else if (this.props.batch.getBatchFailure=== true) {
                this.setState({ emptySearchResult : 'No Batches Found.'})
                this.props.changePreloader(false)
                this.props.getBatchInit()
            }
        }
    }

    checkCreateBatch() {
        let { batchList, createdBatches } = this.state
        if (this.props.layout.isPreloader) {
            if (this.props.batch.createBatchSuccess === true) {
                this.setState({ showCreateModal: false })
                let data = this.props.batch.batchList
                batchList.unshift(data)
                createdBatches.push(data.batchId)
                this.setState({ batchList : batchList, createdBatches : createdBatches})                
                this.props.createBatchInit()
                this.props.changePreloader(false)
                toast.success("Batch Added Successfully")
                // this.props.getBatch(this.state.filter)
            } else if (this.props.batch.createBatchFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.batch.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.createBatchInit()
            }
        }
    }

    checkEditBatch() {
        if (this.props.layout.isPreloader) {
            if (this.props.batch.editBatchSuccess === true) {
                this.setState({ showCreateModal: false })
                let data = this.props.batch.editBatchDetails[0]
                this.editOnBatchList("edit", data)
                this.props.changePreloader(false)
                this.props.editBatchInit()
                toast.success("Batch Updated Successfully")
                // this.props.getBatch(this.state.filter)
            } else if (this.props.batch.editBatchFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.batch.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.editBatchInit()
            }
        }
    }

    checkGetProductCategory() {
        if (this.props.productCategory.getProductCategorySuccess === true) {
            this.setState({ categoryList: this.props.productCategory.productCategoriesList })
            this.props.getProductCategoryInit()
        } else if (this.props.productCategory.getProductCategoryFailure === true) {
            this.props.getProductCategoryInit()
        }
    }

    checkGetProductByCategory() {
        if (this.props.product.getProductByCategorySuccess === true) {
            this.setState({ productList: this.props.product.productList })
            this.props.getProductByCategoryInit()
        } else if (this.props.product.getProductByCategoryFailure === true) {
            this.props.getProductByCategoryInit()
        }
    }

    checkDeleteBatch() {
        if (this.props.layout.isPreloader) {
            if (this.props.batch.deleteBatchSuccess === true) {
                this.props.deleteBatchInit()
                this.onResetFilter()
                this.setState({
                    batchList : [],
                    searchList : [],
                    isSearchState : false,
                    duplicateCheckObj : {},
                    isGrouped : false,
                    sortValue : null
                },
                () => this.props.getBatch(this.state.filter))
                // this.props.getBatch(this.state.filter)
                toast.success("Batch Deleted Successfully")
                // this.deleteFromBatchList()
                // this.props.changePreloader(false)

            } else if (this.props.batch.deleteBatchFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.batch.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.deleteBatchInit()
            }
        }
    }

    checkDownloadBatchFile() {
        if (this.props.batch.downloadBatchFileSuccess === true) {
            let arrayBuffer = this.props.batch.batchFile
            createAndDownloadBlobFile(arrayBuffer, 'Batch')
            toast.success("Report Downloaded")
            this.props.downloadBatchFileInit()
            this.editOnBatchList("download", null)
        } else if (this.props.batch.downloadBatchFileFailure === true) {
            toast.error(this.props.batch.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.downloadBatchFileInit()
        }
    }

    checkUploadBatchFile() {
        if (this.props.batch.uploadBatchFileSuccess === true) {
            toast.success("File Uploaded Successfully")
            this.props.uploadBatchFileInit()
            this.editOnBatchList("upload", null)
        } else if (this.props.batch.uploadBatchFileFailure === true) {
            toast.error(this.props.batch.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.uploadBatchFileInit()
        }
    }

    sort = (sortBy, sortType, direction=null, holdSortValue=true) => {

        let order
        direction ? order = direction : order = this.state.direction === -1 ? 'desc' : 'asc';
        if(holdSortValue) { this.setState ({ sortValue : sortBy, sortDirection : order })  }
        this.setState({createdBatches : []})
        if(this.state.isSearchState){
            if (sortType === 'string') {
                let sortedData = sortStringTypeData(this.state.searchList, sortBy, order);
                this.setState({ searchList : sortedData });
            } else {
                let sortedData = sortIntegerTypeData(this.state.searchList, sortBy, order);
                this.setState({ searchList: sortedData })
            }
        }else{
            if (sortType === 'string') {
                let sortedData = sortStringTypeData(this.state.batchList, sortBy, order);
                // console.log(sortedData)                
                this.setState({ batchList: sortedData });
            } else {
                let sortedData = sortIntegerTypeData(this.state.batchList, sortBy, order);
                this.setState({ batchList: sortedData })
            }
        }
        if(direction === null){
            this.setState({direction: this.state.direction === -1 ? 1 : -1})
        }
    }


    render() {
        let { filter, changedFilter, productId, tipShow, searchValue, searchValueError, vendorId, categoryId, deviceCount, deviceCountError, productName, poNumber,
            poNumberError, vendorIdError, categoryIdError, batchName, batchNameError, productIdError, modalType, groupedBy, vendorList, productList, categoryList, batchList,
            deleteConfirmPopUp, deleteConfirmPopupId, isSearchState, searchList, showLoadMore, createdBatches, openedCollapse } = this.state;

        console.log("Calling from render!")
        let displayBatchList = []
        isSearchState ? displayBatchList = searchList : displayBatchList = batchList
        let myClass
        this.checkGetBatch()
        this.checkGetVendors()
        this.checkCreateBatch()
        this.checkEditBatch()
        this.checkGetProductCategory()
        this.checkGetProductByCategory()
        this.checkDeleteBatch()
        this.checkDownloadBatchFile()
        this.checkUploadBatchFile()
        return (
            <React.Fragment>
                <div className="page-content batch-page">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        {/* ==== Uncomment Below to show bread crumbs ==== */}
                        {/* <Breadcrumbs title="Admin" breadcrumbItem="Manage Batches" /> */}
                    </Container>
                    <div className="actions-section">

                        <div className="actions-grid">
                            <InputBox type="text" label="Search Batches" key={`seachText`} name="searchValue" value={searchValue} onChange={(e) => { this.inputHandler(e) }} onKeyDown={(e) => this.onSearch(e)} hasError={searchValueError} />
                            <Button id="FilterPopover" ><i className="fas fa-sliders-h font-sixteen"></i></Button>
                            <UncontrolledPopover trigger="legacy" placement="bottom" isOpen={this.state.popovertop} target="FilterPopover" toggle={this.toggleFilterPopup}>
                                <PopoverHeader>Search By : </PopoverHeader>
                                <PopoverBody>
                                    <div className="font-fourteen">
                                        <div>
                                            <input type="checkbox" className="filter-checkbox" name="isVendor" onClick={(e) => { this.onFilterChange(e) }} value={changedFilter.isVendor ? 1 : 0} checked={changedFilter.isVendor} />&nbsp;
                                            <label>Vendor</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="filter-checkbox" name="isProdCat" onChange={(e) => { this.onFilterChange(e) }} value={changedFilter.isProdCat ? 1 : 0} checked={changedFilter.isProdCat} />&nbsp;
                                            <label>Product Category</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="filter-checkbox" name="isProduct" onChange={(e) => { this.onFilterChange(e) }} value={changedFilter.isProduct ? 1 : 0} checked={changedFilter.isProduct} />&nbsp;
                                            <label>Product</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="filter-checkbox" name="isBatch" onChange={(e) => { this.onFilterChange(e) }} value={changedFilter.isBatch ? 1 : 0} checked={changedFilter.isBatch} />&nbsp;
                                            <label>Batch Name</label>
                                        </div>

                                    </div>
                                </PopoverBody>
                            </UncontrolledPopover>
                            <Button name="searchButton" onClick={(e) => { this.onSearch(e) }}>Search</Button>
                            <div className="clear-btn" onClick={() => { this.onClearSearch() }}>
                                <span>Clear</span>
                            </div>
                            <span className="divider"></span>
                            <Button onClick={this.modalToggle}>Add Batch</Button>
                        </div>

                    </div>

                    <div className="table-section">
                        {tipShow === true ?
                            (<p className="table-note" onClick={() => { this.setState({ tipShow: !tipShow }) }}><i className="fas fa-info"></i> : <span> Once the batch data is downloaded, the batch will be marked as read only.
                             You can still view the batch details and download the template in future.</span>
                            </p>) :
                            (<p className="table-note-icon" onClick={() => { this.setState({ tipShow: !tipShow }) }}><i className="fas fa-info"></i></p>)
                        }
                        <div>
                            <div className="custom-table-head">
                                <div></div>
                                <div></div>
                                <div>Batch Name <i className="fas fa-sort select-hover" onClick={() => { this.sort('batchName', 'string') }}></i></div>
                                {/* <div>Batch ID <i className="fas fa-sort"></i></div> */}
                                <div className={`${groupedBy === 'vendor' ? 'selected-groupby' : ''}`}>Vendor <i className="fas fa-sort select-hover" onClick={() => { this.sort('vendorName', 'string') }}> </i></div> {/* <i className={`fas ${groupedBy === 'vendor' ? 'fa-times-circle' : 'fa-filter'} p-l-10 cur-pointer`} onClick={() => { this.groupBy('vendor') }}></i> */}
                                <div className={`${groupedBy === 'category' ? 'selected-groupby' : ''}`}>Product Category <i className="fas fa-sort select-hover" onClick={() => { this.sort('productCategoryName', 'string') }}> </i></div>{/* <i className={`fas ${groupedBy === 'category' ? 'fa-times-circle' : 'fa-filter'} p-l-10 cur-pointer`} onClick={() => { this.groupBy('category') }}></i> */}
                                <div className={`${groupedBy === 'product' ? 'selected-groupby' : ''}`}>Product <i className="fas fa-sort select-hover" onClick={() => { this.sort('productName', 'string') }}> </i></div> {/* <i className={`fas ${groupedBy === 'product' ? 'fa-times-circle' : 'fa-filter'} p-l-10 cur-pointer `} onClick={() => { this.groupBy('product') }}></i> */}
                                {/* <div>PO Number </div> */}
                                <div>Consignment </div>
                                <div>Status </div>
                                <div>Actions </div>

                            </div>
                            {/* ==== Add value here ==== */}

                            {
                                displayBatchList.length !== 0 ? displayBatchList.map((val, i) => {
                                    createdBatches.includes(val.batchId) ? myClass = "table-row-section-new-batch" :  myClass = "table-row-section"
                                    return (<div className={myClass} key={i}>
                                        <div className="custom-table-body table-data-style" >
                                            <div className="expand-icon-holder">
                                                {/* <i className={`far fa-plus-square golden-color`} onClick={(e) => { this.toggleSection(val.batchId, e) }} ></i> */}
                                                {/* <i className={`far fa-plus-square golden-color`} onClick={(e) => { this.toggleSection(val.batchId, e) }} ></i>                                             */}
                                                <i className={openedCollapse.includes(val.batchId) ? `far fa-minus-square golden-color` : `far fa-plus-square golden-color`} onClick={(e) => { this.toggleSection(val.batchId, e) }} ></i>    
                                            </div>
                                            <div>
                                                <img src={val.productImage} width="50px" height="40px" className="product-image-col" alt=''></img>
                                            </div>
                                            <div className="name-fieldbox">
                                                {val.batchName}

                                            </div>
                                            <div>{val.vendorName} </div>
                                            <div>{val.productCategoryName} </div>
                                            <div className="name-fieldbox">
                                                {val.productName}
                                            </div>
                                            <div>
                                                <span className="font-weight-bold">{moment(val.createdOn).format('MMMM YYYY')}</span><br></br> {val.deviceCount} Units
                                            </div>
                                            <div>
                                                <div className="initial-status color-blue">

                                                    <i className={`fas fa-download cur-pointer`} onClick={() => { this.downloadFile(val.batchId, i) }}></i>
                                                    {(val.isUploaded === 1 || val.isDownloaded === 0) ? <i className="fas fa-upload cur-pointer color-grey" style={{ cursor: "not-allowed" }}></i> : <i className="fas fa-upload cur-pointer" onClick={() => { this[`${val.batchId}`].click(); console.log(this.inputElement) }}></i>}
                                                    <input type="file" style={{ display: 'none' }} onChange={(e) => { this.onFileUpload(val, e, i); }} ref={input => this[`${val.batchId}`] = input}></input>

                                                </div>
                                                <div className="status-change">
                                                    <Button>Batch Closed</Button>
                                                </div>
                                            </div>
                                            <div className="p-10">
                                                <i className={`fas fa-pencil-alt font-size-18 m-l-10 ${(val.isDownloaded === 1) ? ' color-grey cur-notAllowed' : ' cur-pointer'}`} onClick={() => { this.editBatchHandler(val, val.isDownloaded) }} ></i>
                                                <i id={`pop${val.batchId}`} className={`fa fa-trash font-size-18 m-l-10 color-red cur-pointer ${(val.isDownloaded) ? ' color-grey cur-notAllowed' : ' cur-pointer'}`} aria-hidden="true"></i>
                                                <Popover placement="top" isOpen={(deleteConfirmPopUp && deleteConfirmPopupId === val.batchId) ? true : false} target={`pop${val.batchId}`} toggle={() => { this.toggleConfirmPopup(val.batchId, val.isDownloaded) }}>
                                                    <PopoverHeader>Are you sure?</PopoverHeader>
                                                    <PopoverBody>
                                                        <div className="popconfirm-body">
                                                            <span onClick={() => { this.deleteBatchHandler(val, i) }}>Yes</span>
                                                            <span onClick={() => { this.toggleConfirmPopup('') }}>No</span>
                                                        </div>
                                                    </PopoverBody>
                                                </Popover>
                                            </div>
                                        </div>
                                        <div className="collapse-table-section hide-collapse" id={val.batchId}>

                                            <div></div>
                                            <div></div>
                                            <div>
                                                <label className="color-grey">Batch ID :</label>
                                                <span>{' ' + val.batchId}</span>
                                            </div>
                                            <div>
                                                <label className="color-grey">PO Number :</label>
                                                <span>{' ' + val.ponumber}</span>
                                            </div>
                                        </div>
                                    </div>)
                                }) : 
                                <div>
                                    <br></br>
                                    <center><b>{this.state.emptySearchResult}</b></center>
                                </div>
                                
                            }
                            {/* ==== Uncomment below Load more Code to enable load more button !! ==== */}
                            {displayBatchList.length !== 0 && showLoadMore ? (
                                <div className="table-paginate" >
                                    <span onClick={() => { this.onLoadMore() }}>Load More</span>
                                    {/* <span onClick={() => { console.log(batchList) }}>Load More</span> */}
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.showCreateModal}
                    toggle={this.modalToggle}
                    className="modal-md m-t-10"
                >
                    <p className="modal-body-title">{modalType === 'add' ? 'Add' : 'Update'} Batch</p>
                    <div className="modal-body custom-body-style">
                        <div className="form-section">

                            <DropdownList
                                className={`m-b-six  ${(vendorIdError) ? ' select-error' : ''}`}
                                name='vendorDropdown'
                                placeholder="Select Vendor"
                                data={vendorList}
                                value={vendorId}
                                textField="vendorName"
                                valueField="vendorId"
                                onChange={(e) => {
                                    e.target = { value: e.vendorId, name: "vendorDropdown" }
                                    this.inputHandler(e)
                                }}
                            />

                            <DropdownList
                                className={`m-b-six  ${(categoryIdError) ? ' select-error' : ''}`}
                                name='categoryDropdown'
                                placeholder="Select Category"
                                data={categoryList}
                                value={categoryId}
                                textField="productCategoryName"
                                valueField="productCategoryId"
                                onChange={(e) => {
                                    e.target = { value: e.productCategoryId, name: "categoryDropdown" }
                                    this.inputHandler(e)
                                }}
                            />

                            <DropdownList
                                className={`m-b-six  ${(productIdError) ? ' select-error' : ''}`}
                                name='productName'
                                placeholder="Select Product"
                                data={productList}
                                value={productId}
                                textField="productName"
                                valueField="productId"
                                onChange={(e) => {
                                    this.setState({ productId: e.productId, productIdError: false });
                                }}
                            />

                            <InputBox type="text" className="m-b-six" label="No. of Devices" name="deviceCount" value={deviceCount} onChange={(e) => { this.inputHandler(e) }} hasError={deviceCountError} />

                            <InputBox type="text" className="m-b-six" label="PO Number (if available)" name="poNumber" value={poNumber} onChange={(e) => { this.inputHandler(e) }} hasError={false} />
                            <InputBox type="text" className="m-b-six" label="Batch Name" name="batchName" value={batchName} onChange={(e) => { this.inputHandler(e) }} hasError={batchNameError} />

                            <div className="footer-btns">
                                {modalType === 'add' ? (<Button onClick={() => { this.addClickHandler('add') }}>Add</Button>) : (<Button onClick={() => { this.addClickHandler('edit') }}>Update</Button>)}
                                <span onClick={() => { this.modalToggle() }} >Cancel</span>
                            </div>
                        </div>
                    </div>
                </Modal>
                <ToastContainer />

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    vendor: state.Vendor,
    batch: state.Batch,
    productCategory: state.ProductCategory,
    layout: state.Layout,
    product: state.Product
})

const mapDispatchToProps = dispatch => ({

    createBatch: (data) => dispatch(BatchActions.createBatch(data)),
    createBatchInit: () => dispatch(BatchActions.createBatchInit()),

    editBatch: (data) => dispatch(BatchActions.editBatch(data)),
    editBatchInit: () => dispatch(BatchActions.editBatchInit()),

    deleteBatch: (data) => dispatch(BatchActions.deleteBatch(data)),
    deleteBatchInit: () => dispatch(BatchActions.deleteBatchInit()),

    getBatch: (data) => dispatch(BatchActions.getBatch(data)),
    getBatchInit: () => dispatch(BatchActions.getBatchInit()),

    downloadBatchFile: (data) => dispatch(BatchActions.downloadBatchFile(data)),
    downloadBatchFileInit: () => dispatch(BatchActions.downloadBatchFileInit()),

    uploadBatchFile: (data) => dispatch(BatchActions.uploadBatchFile(data)),
    uploadBatchFileInit: () => dispatch(BatchActions.uploadBatchFileInit()),

    getProductCategory: (data) => dispatch(ProductCategory.getProductCategory(data)),
    getProductCategoryInit: () => dispatch(ProductCategory.getProductCategoryInit()),

    getVendors: (data) => dispatch(VendorActions.getVendors(data)),
    getVendorsInit: () => dispatch(VendorActions.getVendorsInit()),

    changePreloader: (flag) => dispatch(LayoutActions.changePreloader(flag)),

    getProductByCategory: (data) => dispatch(Product.getProductByCategory(data)),
    getProductByCategoryInit: () => dispatch(Product.getProductByCategoryInit())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Batch)