import {
    CREATE_PRODUCT_CATEGORY,
    CREATE_PRODUCT_CATEGORY_SUCCESS,
    CREATE_PRODUCT_CATEGORY_FAILURE,
    CREATE_PRODUCT_CATEGORY_INIT,

    EDIT_PRODUCT_CATEGORY,
    EDIT_PRODUCT_CATEGORY_SUCCESS,
    EDIT_PRODUCT_CATEGORY_FAILURE,
    EDIT_PRODUCT_CATEGORY_INIT,

    DELETE_PRODUCT_CATEGORY,
    DELETE_PRODUCT_CATEGORY_SUCCESS,
    DELETE_PRODUCT_CATEGORY_FAILURE,
    DELETE_PRODUCT_CATEGORY_INIT,

    GET_PRODUCT_CATEGORY,
    GET_PRODUCT_CATEGORY_SUCCESS,
    GET_PRODUCT_CATEGORY_FAILURE,
    GET_PRODUCT_CATEGORY_INIT, 

    GET_PRODUCT_CATEGORY_BY_NAME,
    GET_PRODUCT_CATEGORY_BY_NAME_SUCCESS,
    GET_PRODUCT_CATEGORY_BY_NAME_FAILURE,
    GET_PRODUCT_CATEGORY_BY_NAME_INIT
} from './actionTypes'

// ==== Get Product category actions ====
export const getProductCategory = (data) => {
    console.log("#####  inside action get!!")
    return {
        type : GET_PRODUCT_CATEGORY,
        payload : data
    }
}

export const getProductCategorySuccess = (data) => {
    return {
        type : GET_PRODUCT_CATEGORY_SUCCESS,
        payload : data
    }
}

export const getProductCategoryFailure = (data) => {
    return {
        type : GET_PRODUCT_CATEGORY_FAILURE,
        payload : data
    }
}

export const getProductCategoryInit = (data) => {
    return {
        type : GET_PRODUCT_CATEGORY_INIT,
        payload : data
    }
}

// ==== Create Product Category Actions ====
export const createProductCategory = (data) => {
    return {
        type : CREATE_PRODUCT_CATEGORY,
        payload : data
    }
}

export const createProductCategorySuccess = (data) => {
    return {
        type : CREATE_PRODUCT_CATEGORY_SUCCESS,
        payload : data
    }
}

export const createProductCategoryFailure = (data) => {
    return {
        type : CREATE_PRODUCT_CATEGORY_FAILURE,
        payload : data
    }
}

export const createProductCategoryInit = (data) => {
    return {
        type : CREATE_PRODUCT_CATEGORY_INIT,
        payload : data
    }
}


// ==== Edit Product Category Actions ====
export const editProductCategory = (data) => {
    return {
        type : EDIT_PRODUCT_CATEGORY,
        payload : data
    }
}

export const editProductCategorySuccess = (data) => {
    return {
        type : EDIT_PRODUCT_CATEGORY_SUCCESS,
        payload : data
    }
}

export const editProductCategoryFailure = (data) => {
    return {
        type : EDIT_PRODUCT_CATEGORY_FAILURE,
        payload : data
    }
}

export const editProductCategoryInit = (data) => {
    return {
        type : EDIT_PRODUCT_CATEGORY_INIT,
        payload : data
    }
}

// ==== Delete Product Category Actions ====
export const deleteProductCategory = (data) => {
    return {
        type : DELETE_PRODUCT_CATEGORY,
        payload : data
    }
}

export const deleteProductCategorySuccess = (data) => {
    return {
        type : DELETE_PRODUCT_CATEGORY_SUCCESS,
        payload : data
    }
}

export const deleteProductCategoryFailure = (data) => {
    return {
        type : DELETE_PRODUCT_CATEGORY_FAILURE,
        payload : data
    }
}

export const deleteProductCategoryInit = (data) => {
    return {
        type : DELETE_PRODUCT_CATEGORY_INIT,
        payload : data
    }
}

// ==== Actions for getting product category by name ====
export const getProductCategoryByName = (data) => {
    return {
        type : GET_PRODUCT_CATEGORY_BY_NAME,
        payload : data
    }
}

export const getProductCategoryByNameSuccess = (data) => {
    return {
        type : GET_PRODUCT_CATEGORY_BY_NAME_SUCCESS,
        payload : data
    }
}

export const getProductCategoryByNameFailure = (data) => {
    return {
        type : GET_PRODUCT_CATEGORY_BY_NAME_FAILURE,
        payload : data
    }
}

export const getProductCategoryByNameInit = (data) => {
    return {
        type : GET_PRODUCT_CATEGORY_BY_NAME_INIT,
        payload : data
    }
}
