import React, { Component } from 'react';
import { Container, Button, Modal, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import DropdownList from 'react-widgets/lib/DropdownList';
import InputBox from '../../components/Common/input-box';
import FileUpload from '../../components/Common/file-upload';
import Breadcrumbs from '../../components/Common/breadcrumb';

import * as VendorActions from '../../store/vendor/actions'
import * as ProductCategoryActions from '../../store/productCategory/actions'
import * as ProductActions from '../../store/product/actions'
import * as LayoutActions from '../../store/layout/actions'
import { sortIntegerTypeData, sortStringTypeData } from '../../helpers/sortHelper';

// Store
import { connect } from 'react-redux';

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateModal: false,
            modalType: 'add',
            searchValue: '',
            filter: {
                search : null,
                isVendor: null,
                isProduct: null,
                isProdCat: null,
                isBatch: null,
                pageCount: 1,
                pageSize: 100,
            },
            changedFilter: {
                search : null,
                isVendor: null,
                isProduct: null,
                isProdCat: null,
                isBatch: null,
                pageCount: 1,
                pageSize: 100,
            },
            filterChanged: false,
            vendorId: '',
            categoryId: '',
            productTypeId: '',
            productId: '',
            productName: '',
            productImage: null,
            vendorIdError: false,
            categoryIdError: false,
            productIdError: false,
            productTypeError: false,
            productNameError: false,
            productImageError: false,
            searchValueError: false,
            categoryList: [],
            productList: [],
            vendorList: [],
            productTypeList: [],
            deleteConfirmPopUp: false,
            deleteConfirmPopupId: null,
            popovertop: false,
            direction: 1,
            deleteIndex : null,
            editIndex : null,
            emptySearchResult : ''
        }
    }

    componentDidMount() {
        this.props.getVendors();
        this.props.changePreloader(true);
    }

    modalToggle = () => {
        let { showCreateModal } = this.state;
        this.setState({
            productName: '', categoryId: '', vendorId: '', productTypeId: '', productImage: null, modalType: 'add', showCreateModal: !showCreateModal,
            vendorIdError: false, categoryIdError: false, productTypeError: false, productNameError: false, productImageError: false
        });
    }

    addClickHandler = (type, data) => {
        let { productImage, categoryId, productTypeId, vendorId, productName, productId } = this.state;
        let trimmedProductName = productName.trim()
        if (type === 'add') {
            if ((vendorId !== '') && (categoryId !== '') && (productImage !== undefined && productImage !== null) &&
                (productTypeId !== null && productTypeId !== '' && trimmedProductName !== '')) {
                const formBox = new FormData();
                let data = {
                    productName: trimmedProductName,
                    productTypeId: productTypeId
                };

                formBox.append('productImage', productImage);
                formBox.append('jsonData', JSON.stringify(data));

                let sharableData = {
                    formBoxData: formBox,
                    categoryId: categoryId
                }
                this.props.createProduct(sharableData)
                this.props.changePreloader(true)
            }
            if (productImage === undefined || productImage === null || productImage === '') {
                this.setState({ productImageError: true });
            } else {
                this.setState({ productImageError: false });
            }
        } else {
            if ((vendorId !== '') && (categoryId !== '') && (trimmedProductName !== '') &&
                (productTypeId !== undefined && productTypeId !== null)) {
                const editForm = new FormData();
                let newdata = {
                    'productName': trimmedProductName,
                    'productTypeId': productTypeId
                };
                editForm.append('productImage', productImage);
                editForm.append('jsonData', JSON.stringify(newdata));
                let payload = {
                    formData: editForm,
                    categoryId: categoryId,
                    productId: productId
                }
                this.props.editProduct(payload)
                this.props.changePreloader(true)
            }
        }

        if (vendorId === undefined || vendorId === null || vendorId === '') {
            this.setState({ vendorIdError: true });
        } else {
            this.setState({ vendorIdError: false });
        }
        if (categoryId === undefined || categoryId === null || categoryId === '') {
            this.setState({ categoryIdError: true });
        } else {
            this.setState({ categoryIdError: false });
        }
        if (productTypeId === undefined || productTypeId === null || productTypeId === '') {
            this.setState({ productTypeError: true });
        } else {
            this.setState({ productTypeError: false });
        }
        if (trimmedProductName === undefined || trimmedProductName === null || trimmedProductName === '') {
            this.setState({ productNameError: true });
            console.log(this.state.productNameError);
        } else {
            this.setState({ productNameError: false });
        }
    }

    onSearch = (e) => {
        if (e.keyCode === 13 || e.target.name === 'searchButton') {
            let { searchValue, filterChanged, filter, changedFilter } = this.state
            
            let trimmedSearchValue = searchValue.trim()
            if (trimmedSearchValue !== '') {
                if (filterChanged === false) {
                    filter.search = trimmedSearchValue;
                    console.log(filter);
                    // if(filter.isProduct === null && filter.isProdCat === null && filter.isVendor === null){
                    //     this.toggleFilterPopup()
                    //     return
                    // }
                    this.setState({productList : [], emptySearchResult : ''}, ()=>{
                        this.props.getProduct(filter)
                        this.props.changePreloader(true)
                    })

                } else {
                    changedFilter.search = trimmedSearchValue;
                    // if(changedFilter.isProduct === null && changedFilter.isProdCat === null && changedFilter.isVendor === null){
                    //     this.toggleFilterPopup()
                    //     return
                    // }
                    this.setState({productList : [], emptySearchResult : ''}, ()=>{
                        this.props.getProduct(changedFilter)
                        this.props.changePreloader(true)
                    })

                }
                this.setState({ searchValueError: false, searchValue : trimmedSearchValue });

            } else {
                // this.props.getBatches();
                this.setState({ searchValueError: true, searchValue : trimmedSearchValue });
            }
        }
    }

    onClearProducts = () => {
        let cleanFilter = {
            search : null,
            isVendor: null,
            isProduct: null,
            isProdCat: null,
            isBatch: null,
            pageCount: 1,
            pageSize: 100,
        }
        this.setState({ 
            filter : cleanFilter,
            changedFilter : cleanFilter,
            filterChanged : false,
            searchValue : '',
            searchValueError : false,
        })
        this.props.getProduct(cleanFilter)
        this.props.changePreloader(true)
    }

    inputHandler = (e) => {
        if (e.target.name === 'searchValue') {
            this.setState({ searchValue: e.target.value, searchValueError: false });
        } else if (e.target.name === 'vendorDropdown') {
            this.setState({ categoryId: '', vendorId: e.target.value, vendorIdError: false });
            let data = {
                vendorId: e.target.value
            }
            this.props.getProductCategory(data);
        }
        else if (e.target.name === 'categoryDropdown') {
            this.setState({ categoryId: e.target.value, categoryIdError: false });
        }
        else if (e.target.name === 'productName') {
            this.setState({ productName: e.target.value, productNameError: false });
        }
        else if (e.target.name === 'productType') {
            this.setState({ productTypeId: e.target.value, productTypeError: false });
        }
    }

    editProductHandler = (product, index) => {
        console.log(product)
        let data = {
            vendorId: product.vendorId
        }
        this.props.getProductCategory(data);
        this.setState({
            vendorId: product.vendorId, categoryId: product.productCategoryId, productTypeId: product.productTypeId, productName: product.productName, productId: product.productId,
            modalType: 'edit', showCreateModal: true, editIndex : index
        });
    }

    deleteProductHandler = (val, index) => {
        let data = {
            categoryId: val.productCategoryId,
            productId: val.productId
        }
        this.props.deleteProduct(data)
        this.props.changePreloader(true);
        this.setState({ deleteConfirmPopUp: false, deleteConfirmPopupId: '', deleteIndex : index });
    }

    deleteFromProductList = () => {
        let {deleteIndex, productList } = this.state
        productList.splice(deleteIndex, 1)
        this.setState({ productList : productList, deleteIndex : null})
    }

    editOnProductList = (data) => {
        let { productList, editIndex } = this.state
        productList[editIndex] = data
        this.setState({ productList : productList, editIndex : null})
    }

    takeFile = (e) => {
        // console.log(e.target.files[0]);
        let imageRegex = new RegExp(/^.*image.*$/)
        
        if (e.target.files.length !== 0) {
            if(!imageRegex.test(e.target.files[0].type)){
                toast.error("Please Select an image file!!")
                return
            }
            // console.log(e.target.files[0]);
            this.setState({ productImage: e.target.files[0] });
        }
    }

    removeFile = (e) => {
        this.setState({ productImage: null });
    }

    toggleFilterPopup = () => { this.setState({ popovertop: !this.state.popovertop }); }

    toggleConfirmPopup = (id) => { this.setState({ deleteConfirmPopUp: !this.state.deleteConfirmPopUp, deleteConfirmPopupId: id }); }

    onFilterChange = (e) => {
        let { changedFilter } = this.state;
        if (e.target.value == 0) {  // ==== Added "==" as value returns string 0 (zero) ====
            console.log("Inside null")
            changedFilter[e.target.name] = 1;
            this.setState({ changedFilter: { ...changedFilter }, filterChanged: true },
                console.log(changedFilter)
            )
        } else {
            console.log("Inside  not null")
            changedFilter[e.target.name] = null;
            this.setState({ changedFilter: { ...changedFilter }, filterChanged: true })
        }
    }


    // ==== Checker Functions added below ====
    checkGetVendor() {
        let { vendorList } = this.state
        if (this.props.layout.isPreloader) {
            if (this.props.vendor.getVendorsSuccess === true) {
                this.setState({ vendorList: this.props.vendor.vendors })
                this.props.getVendorsInit()
                this.props.getProductType()
            } else if (this.props.vendor.getVendorsFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.vendor.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getVendorsInit()
            }
        }
    }

    checkGetProductType() {
        let { productTypeList } = this.state
        if (this.props.layout.isPreloader) {
            if (this.props.product.getProductTypeSuccess === true) {
                this.setState({ productTypeList: this.props.product.productTypeList })
                this.props.getProductTypeInit()
                this.props.getProduct(this.state.filter)
            } else if (this.props.product.getProductTypeFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.product.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getProductTypeInit()
            }
        }
    }

    checkGetProduct() {
        let { productList } = this.state
        if (this.props.layout.isPreloader) {
            if (this.props.product.getProductSuccess === true) {
                this.setState({ productList: this.props.product.productList })
                this.props.getProductInit()
                this.props.changePreloader(false)
            } else if (this.props.product.getProductFailure === true) {
                this.setState({emptySearchResult : 'No Products found'})
                this.props.changePreloader(false)
                this.props.getProductInit()
            }
        }
    }

    checkGetProductCategory() {
        let { categoryList } = this.state
        if (this.props.productCategory.getProductCategorySuccess === true) {
            this.setState({ categoryList: this.props.productCategory.productCategoriesList })
            this.props.getProductCategoryInit()
        } else if (this.props.productCategory.getProductCategoryFailure === true) {
            this.setState({ categoryList: [] });
            this.props.getProductCategoryInit()
        }
    }

    checkCreateProduct() {
        if (this.props.layout.isPreloader) {
            if (this.props.product.createProductSuccess === true) {
                this.setState({showCreateModal : false})
                toast.success("Product Created Successfully.")
                this.props.createProductInit()
                // this.props.getProduct(this.state.filter)
                this.onClearProducts()
            } else if (this.props.product.createProductFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.product.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getProductInit()
            }
        }
    }

    checkEditProduct() {
        if (this.props.layout.isPreloader) {
            if (this.props.product.editProductSuccess === true) {
                this.setState({ showCreateModal: false })
                // let data = this.props.product.editProductDetails
                // this.editOnProductList(data)
                // this.props.changePreloader(false)
                toast.success("Product Updated Successfully.")
                this.props.editProductInit()
                this.onClearProducts()
            } else if (this.props.product.editProductFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.product.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.editProductInit()
            }
        }
    }

    checkDeleteProduct() {
        if (this.props.layout.isPreloader) {
            if (this.props.product.deleteProductSuccess === true) {
                toast.success("Product Deleted Successfully")
                this.props.changePreloader(false)        
                this.props.deleteProductInit()
                // this.props.getProduct(this.state.filter)
                this.deleteFromProductList()                        
            } else if (this.props.product.deleteProductFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.product.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.deleteProductInit()
            }
        }
    }

    sort = (sortBy, sortType) => {
        let order = this.state.direction === -1 ? 'desc' : 'asc';
        if (sortType === 'string') {
            let sortedData = sortStringTypeData(this.state.productList, sortBy, order);
            this.setState({ productList: sortedData, direction: this.state.direction === -1 ? 1 : -1 });
            console.log(sortedData);
        } else {
            let sortedData = sortIntegerTypeData(this.state.productList, sortBy, order);
            this.setState({ productList: sortedData, direction: this.state.direction === -1 ? 1 : -1 });
        }
    }

    render() {
        let { changedFilter, searchValue, searchValueError, vendorId, categoryId, productTypeId, productName, productImage, vendorIdError, categoryIdError, productTypeError, productNameError,
            productIdError, productImageError, modalType, productList, vendorList, productTypeList, categoryList, deleteConfirmPopUp, deleteConfirmPopupId } = this.state;
        // ==== Checker methods ===
        this.checkGetVendor()
        this.checkGetProductType()
        this.checkGetProduct()
        this.checkGetProductCategory()
        this.checkCreateProduct()
        this.checkEditProduct()
        this.checkDeleteProduct()
        return (
            <React.Fragment>
                <div className="page-content product-page">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        {/* ==== Uncomment Below to show bread crumbs ==== */}
                        {/* <Breadcrumbs title="Admin" breadcrumbItem="Manage Product" /> */}
                    </Container>
                    <div className="actions-section">

                        <div className="actions-grid">

                            <InputBox type="text" label="Search Products" key={`seachText`} name="searchValue" value={searchValue} onChange={(e) => { this.inputHandler(e) }} onKeyDown={(e) => this.onSearch(e)} hasError={searchValueError} />
                            <Button id="FilterPopover" ><i className="fas fa-sliders-h font-sixteen"></i></Button>
                            <UncontrolledPopover trigger="legacy" placement="bottom" isOpen={this.state.popovertop} target="FilterPopover" toggle={this.toggleFilterPopup}>
                                <PopoverHeader>Search By : </PopoverHeader>
                                <PopoverBody>
                                    <div className="font-fourteen">
                                        <div>
                                            <input type="checkbox" className="filter-checkbox" name="isVendor" onClick={(e) => { this.onFilterChange(e) }} value={changedFilter.isVendor ? 1 : 0} checked={changedFilter.isVendor} />&nbsp;
                                            <label>Vendor</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="filter-checkbox" name="isProdCat" onChange={(e) => { this.onFilterChange(e) }} value={changedFilter.isProdCat ? 1 : 0} checked={changedFilter.isProdCat} />&nbsp;
                                            <label>Product Category</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="filter-checkbox" name="isProduct" onChange={(e) => { this.onFilterChange(e) }} value={changedFilter.isProduct ? 1 : 0} checked={changedFilter.isProduct} />&nbsp;
                                            <label>Product</label>
                                        </div>
                                        {/* <div>
                                            <input type="checkbox" className="filter-checkbox" name="isBatch" onChange={(e) => { this.onFilterChange(e) }} value={changedFilter.isBatch} checked={changedFilter.isBatch} />&nbsp;
                                            <label>Batch</label>
                                        </div> */}

                                    </div>
                                </PopoverBody>
                            </UncontrolledPopover>
                            <Button name="searchButton" onClick={(e) => { this.onSearch(e) }}>Search</Button>
                            <div className="clear-btn" onClick = {() => {this.onClearProducts()}}>
                                <span>Clear</span>
                            </div>
                            <span className="divider"></span>
                            <Button onClick={this.modalToggle}>Add Product</Button>
                        </div>

                    </div>

                    <div className="table-section">
                        <div>
                            <div className="custom-table-head">
                                {/* <div>Product ID <i className="fas fa-sort"></i></div> */}
                                <div>Product Image </div>
                                <div>Product Name <i className="fas fa-sort select-hover" onClick={() => { this.sort('productName', 'string') }}></i></div>
                                <div>Product Category <i className="fas fa-sort select-hover" onClick={() => { this.sort('productCategoryName', 'string') }}></i></div>
                                <div>Vendor <i className="fas fa-sort select-hover" onClick={() => { this.sort('vendorName', 'string') }}></i></div>
                                <div>Actions </div>
                            </div>

                            {productList.length !== 0 ? productList.map((val, i) => {
                                return (
                                    <div className="table-row-section" key={i}>
                                        <div className="custom-table-body table-data-style">
                                            <div style={{ padding: '10px' }}>
                                                <img src={val.productImage} width="50px" height="50px" className="objectFit" alt=""></img>
                                            </div>
                                            <div className="name-fieldbox">
                                                {val.productName}
                                            </div>                                
                                            <div className="has-edit-icon">{val.productCategoryName}</div>
                                            <div className="has-edit-icon">{val.vendorName}</div>
                                            <div className="p-10">
                                                <i className="fas fa-pencil-alt font-size-18 golden-color select-hover" onClick={() => { this.editProductHandler(val, i) }} ></i>
                                                <i id={`pop${val.productId}`} className="fa fa-trash font-size-18 m-l-10 color-red select-hover" aria-hidden="true"></i>
                                                <Popover placement="top" isOpen={(deleteConfirmPopUp && deleteConfirmPopupId === val.productId) ? true : false} target={`pop${val.productId}`} toggle={() => { this.toggleConfirmPopup(val.productId) }}>
                                                    <PopoverHeader>Are you sure?</PopoverHeader>
                                                    <PopoverBody>
                                                        <div className="popconfirm-body">
                                                            <span onClick={() => { this.deleteProductHandler(val, i) }}>Yes</span>
                                                            <span onClick={() => { this.toggleConfirmPopup('') }}>No</span>
                                                        </div>
                                                    </PopoverBody>
                                                </Popover>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : 
                            <div>
                                    <br></br>
                                    <center><b>{this.state.emptySearchResult}</b></center>
                            </div>
                            }

                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.showCreateModal}
                    toggle={this.modalToggle}
                    className="modal-md m-t-12"
                >
                    <p className="modal-body-title">{modalType === 'add' ? 'Add' : 'Update'} Product</p>
                    <div className="modal-body custom-body-style">
                        <div className="form-section">


                            <DropdownList
                                className={"m-b-six " + ((vendorIdError) ? 'select-error' : '')}
                                name='vendorDropdown'
                                placeholder="Select Vendor"
                                data={vendorList}
                                defaultValue={vendorId}
                                textField="vendorName"
                                valueField="vendorId"
                                onChange={(e) => {
                                    e.target = { value: e.vendorId, name: 'vendorDropdown' }
                                    this.inputHandler(e)
                                }}
                            />


                            <DropdownList
                                className={"m-b-six " + ((categoryIdError) ? 'select-error' : '')}
                                name='categoryDropdown'
                                placeholder="Select Category"
                                data={categoryList}
                                value={categoryId}
                                textField="productCategoryName"
                                valueField="productCategoryId"
                                onChange={(e) => {
                                    e.target = { value: e.productCategoryId, name: 'categoryDropdown' }
                                    this.inputHandler(e)
                                }}
                            />


                            <DropdownList
                                className={"m-b-six " + ((productTypeError) ? 'select-error' : '')}
                                name='productType'
                                placeholder="Select Product Type"
                                data={productTypeList}
                                defaultValue={productTypeId}
                                textField="productType"
                                valueField="productTypeId"
                                onChange={(e) => {
                                    e.target = { value: e.productTypeId, name: 'productType' }
                                    this.inputHandler(e)
                                }}
                            />

                            <InputBox type="text" className="m-b-six" label="New Product Name" name="productName" value={productName} onChange={(e) => { this.inputHandler(e) }} hasError={productNameError} />

                            <FileUpload name="productImage" fileType="image" chooseFileType="Choose Image" onChange={(e) => { this.takeFile(e) }} fileName={productImage !== null ? productImage.name : ''} onRemoveFile={this.removeFile} hasError={productImageError} />

                            <div className="footer-btns">
                                {modalType === 'add' ? (<Button onClick={() => { this.addClickHandler('add') }}>Add</Button>) : (<Button onClick={() => { this.addClickHandler('edit') }}>Update</Button>)}
                                <span onClick={() => { this.modalToggle() }} >Cancel</span>
                            </div>
                        </div>
                    </div>
                </Modal>
                <ToastContainer/>

            </React.Fragment>
        )
    }
}
// const mapStatetoProps = state => {
//     const { countries, vendors, vendorResponse, productCategories, productTypes, products } = state.Database;
//     return { countries, vendors, vendorResponse, productCategories, productTypes, products };
// }

// export default connect(mapStatetoProps, {
//     getCountriesList, getVendorList, createProduct, saveVendorList
//     , getProductTypes, saveProducts, editProductSuccess
// })(Product)

const mapStateToProps = state => ({
    product: state.Product,
    layout: state.Layout,
    vendor: state.Vendor,
    productCategory: state.ProductCategory
})

const mapDispatchToProps = dispatch => ({

    getProduct: (data) => dispatch(ProductActions.getProduct(data)),
    getProductInit: () => dispatch(ProductActions.getProductInit()),

    createProduct: (data) => dispatch(ProductActions.createProduct(data)),
    createProductInit: () => dispatch(ProductActions.createProductInit()),

    editProduct: (data) => dispatch(ProductActions.editProduct(data)),
    editProductInit: () => dispatch(ProductActions.editProductInit()),

    deleteProduct: (data) => dispatch(ProductActions.deleteProduct(data)),
    deleteProductInit: () => dispatch(ProductActions.deleteProductInit()),

    getVendors: (data) => dispatch(VendorActions.getVendors(data)),
    getVendorsInit: () => dispatch(VendorActions.getVendorsInit()),

    getProductCategory: (data) => dispatch(ProductCategoryActions.getProductCategory(data)),
    getProductCategoryInit: () => dispatch(ProductCategoryActions.getProductCategoryInit()),

    getProductType: () => dispatch(ProductActions.getProductType()),
    getProductTypeInit: () => dispatch(ProductActions.getProductTypeInit()),

    changePreloader: (flag) => dispatch(LayoutActions.changePreloader(flag)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Product)