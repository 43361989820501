import { takeEvery, fork, put, all, call, actionChannel, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { GET_PAYMENT_DETAILS, GET_PENDING_TRANSACTIONS, GET_TRANSACTION_DETAILS, GET_PENDING_TRANSACTIONS_EXCEL, GET_BOOK, DOWNLOAD_BOOK, DOWNLOAD_PAYMENT_DETAILS, GET_BILLDESK_TRANSACTIONS, DOWNLOAD_BILLDESK_TRANSACTIONS } from './actionTypes';
import {
    getPendingTransactionsSuccess,
    getTransactionDetailsSuccess,
    getPaymentDetailsSuccess,
    getPendingTransactionsFailure,
    getPendingTransactionsExcelSuccess,
    getPendingTransactionsExcelFailure,
    getBookSuccess,
    getBookFailure,
    downloadBookSuccess,
    downloadBookFailure,
    downloadPaymentDetailsSuccess,
    downloadPaymentDetailsFailure,
    getBilldeskTransactionSuccess,
    getBilldeskTransactionFailure,
    downloadBilldeskTransactionsSuccess,
    downloadBilldeskTransactionsFailure

} from './actions';
import { changePreloader } from '../layout/actions';

import {
    fetchPendingTransactions,
    fetchTransactionDetails,
    fetchPaymentDetails,
    downloadTransactionListExcel,
    fetchBook,
    downloadBookFile,
    downloadPaymentDetailsFile,
    fetchBilldeskTransactions,
    downloadBilldeskTransactionsFile
} from "../../services/lnDashboardService";

import { checkError } from '../../helpers/errorHelper';

function* fetchPendingTransactionsList(data) {
    try {
        const response = yield call(fetchPendingTransactions, data.payload);
        let pendingTransactions = [...response.data.data.transactions];
        let insights = Object.assign({}, response.data.data.pendingTransactions);
        let recordCount = response.data.data.recordCount;

        yield put(getPendingTransactionsSuccess({ pendingTransactions, insights, recordCount }));
        yield put(changePreloader(false));

    } catch (error) {
        let errorMsg = checkError(error);
        yield put(getPendingTransactionsFailure({ data: errorMsg }))
        yield put(changePreloader(false));
    }
}

function* fetchBilldeskTransactionsList(data) {
    try {
        const response = yield call(fetchBilldeskTransactions, data.payload);
        let billdeskTransactions = [...response.data.data.billdeskTransactions];
        let insights = Object.assign({}, response.data.data.billdeskTransactionCounnt);
        // let recordCount = response.data.data.billdeskTransactionCounnt.recordsCount;

        yield put(getBilldeskTransactionSuccess({ billdeskTransactions, insights }));
        yield put(changePreloader(false));

    } catch (error) {
        let errorMsg = checkError(error);
        yield put(getBilldeskTransactionFailure({ data: errorMsg }))
        yield put(changePreloader(false));

    }
}

function* fetchTransactionDetail(data) {
    try {
        const response = yield call(fetchTransactionDetails, data.payload);

        yield put(getTransactionDetailsSuccess({ data: response.data.data, transactionId: data.payload }));
        yield put(changePreloader(false));

    } catch (error) {
        console.log(error);
    }
}

function* fetchPaymentDetail(data) {
    try {
        const response = yield call(fetchPaymentDetails, data.payload);

        yield put(getPaymentDetailsSuccess({ data: response.data.data, transactionId: data.payload }));
        yield put(changePreloader(false));

    } catch (error) {
        console.log(error);
    }
}

function* downloadTransactionList(action) {
    try {
        const response = yield call(downloadTransactionListExcel, action.payload);
        yield put(getPendingTransactionsExcelSuccess({ data: response.data }));

    } catch (error) {
        let errorMsg = checkError(error);
        yield put(getPendingTransactionsExcelFailure({ data: errorMsg }));
    }
}

function* fetchRecordBook(data) {
    try {
        const response = yield call(fetchBook, data.payload);

        yield put(getBookSuccess({ data: response.data.data }));
        yield put(changePreloader(false));

    } catch (error) {
        let errorMsg = checkError(error);
        yield put(getBookFailure({ data: errorMsg }));

    }
}

function* downloadRecordBook(data) {
    try {
        const response = yield call(downloadBookFile, data.payload);

        yield put(downloadBookSuccess({ data: response.data }));

    } catch (error) {
        let errorMsg = checkError(error);
        yield put(downloadBookFailure({ data: errorMsg }));
    }
}

function* downloadPaymentDetails(data) {
    try {
        const response = yield call(downloadPaymentDetailsFile, data.payload);
        yield put(downloadPaymentDetailsSuccess({ data: response.data }));

    } catch (error) {
        yield put(downloadPaymentDetailsFailure());
    }
}

function* downloadBilldeskTransactions(data) {
    try {
        const response = yield call(downloadBilldeskTransactionsFile, data.payload);

        yield put(downloadBilldeskTransactionsSuccess({ data: response.data }));
        // yield put(changePreloader(false));

    } catch (error) {
        yield put(downloadBilldeskTransactionsFailure());
    }
}



export function* watchLNDashboardPendingTransactions() {
    yield takeEvery(GET_PENDING_TRANSACTIONS, fetchPendingTransactionsList)
}

export function* watchLNDashboardTransactionDetails() {
    yield takeEvery(GET_TRANSACTION_DETAILS, fetchTransactionDetail)
}

export function* watchLNDashboardPaymentDetails() {
    yield takeEvery(GET_PAYMENT_DETAILS, fetchPaymentDetail)
}

export function* watchLNDashboardDownloadPendingTransactionsExcel() {
    yield takeLatest(GET_PENDING_TRANSACTIONS_EXCEL, downloadTransactionList)
}

export function* watchLNDashboardFetchBook() {
    yield takeEvery(GET_BOOK, fetchRecordBook)
}

export function* watchLNDashboardDownloadBook() {
    yield takeLatest(DOWNLOAD_BOOK, downloadRecordBook)
}

export function* watchLNDashboardPaymentDetailsDownload() {
    yield takeLatest(DOWNLOAD_PAYMENT_DETAILS, downloadPaymentDetails)
}

export function* watchLNDashboardBilldeskTransactions() {
    yield takeEvery(GET_BILLDESK_TRANSACTIONS, fetchBilldeskTransactionsList)
}

export function* watchLNDashboardDownloadBilldeskTransactions() {
    yield takeLatest(DOWNLOAD_BILLDESK_TRANSACTIONS, downloadBilldeskTransactions)
}

function* lnDashboardSaga() {
    yield all([
        fork(watchLNDashboardPendingTransactions),
        fork(watchLNDashboardTransactionDetails),
        fork(watchLNDashboardPaymentDetails),
        fork(watchLNDashboardDownloadPendingTransactionsExcel),
        fork(watchLNDashboardFetchBook),
        fork(watchLNDashboardDownloadBook),
        fork(watchLNDashboardPaymentDetailsDownload),
        fork(watchLNDashboardBilldeskTransactions),
        fork(watchLNDashboardDownloadBilldeskTransactions)
    ]);
}

export default lnDashboardSaga;