import { 
    SEARCH_CUSTOMERS,
    SEARCH_CUSTOMERS_SUCCESS,
    SEARCH_CUSTOMERS_FAILURE,
    SEARCH_CUSTOMERS_INIT,

    GET_CUSTOMER_DETAILS,
    GET_CUSTOMER_DETAILS_SUCCESS,
    GET_CUSTOMER_DETAILS_FAILURE,
    GET_CUSTOMER_DETAILS_INIT,

    CHANGE_CONFIG,
    CHANGE_CONFIG_SUCCESS,
    CHANGE_CONFIG_FAILURE,
    CHANGE_CONFIG_INIT,

    GET_USER_LOGS,
    GET_USER_LOGS_SUCCESS,
    GET_USER_LOGS_FAILURE,
    GET_USER_LOGS_INIT,

    GET_PORTAL_LOGS,
    GET_PORTAL_LOGS_SUCCESS,
    GET_PORTAL_LOGS_FAILURE,
    GET_PORTAL_LOGS_INIT,

    GET_USER_LOG_FILTERS,
    GET_USER_LOG_FILTERS_SUCCESS,
    GET_USER_LOG_FILTERS_FAILURE,
    GET_USER_LOG_FILTERS_INIT,

    GET_PORTAL_LOG_FILTERS,
    GET_PORTAL_LOG_FILTERS_SUCCESS,
    GET_PORTAL_LOG_FILTERS_FAILURE,
    GET_PORTAL_LOG_FILTERS_INIT,

    DOWNLOAD_LOGS,
    DOWNLOAD_LOGS_SUCCESS,
    DOWNLOAD_LOGS_FAILURE,
    DOWNLOAD_LOGS_INIT, 

    SEARCH_CUSTOMERS_GROUPS,
    SEARCH_CUSTOMERS_GROUPS_SUCCESS,
    SEARCH_CUSTOMERS_GROUPS_FAILURE,
    SEARCH_CUSTOMERS_GROUPS_INIT,

    ASSIGN_CUSTOMER_GROUP,
    ASSIGN_CUSTOMER_GROUP_SUCCESS,
    ASSIGN_CUSTOMER_GROUP_FAILURE,
    ASSIGN_CUSTOMER_GROUP_INIT,

    SEARCH_USER,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_FAILURE,
    SEARCH_USER_INIT,

    SEARCH_DEVICES,
    SEARCH_DEVICES_SUCCESS,
    SEARCH_DEVICES_FAILURE,
    SEARCH_DEVICES_INIT
    
 } from './actionTypes'


 // ==== Actions for Search Customers ====
 export const searchCustomers = (data) => {
     return {
         type : SEARCH_CUSTOMERS,
         payload : data
     }
 }
 export const searchCustomersSuccess = (data) => {
    console.log("####  Success Customer in action!!")
    return {
        type : SEARCH_CUSTOMERS_SUCCESS,
        payload : data
    }
}
export const searchCustomersFailure = (data) => {
    return {
        type : SEARCH_CUSTOMERS_FAILURE,
        payload : data
    }
}
export const searchCustomersInit = (data) => {
    return {
        type : SEARCH_CUSTOMERS_INIT,
        payload : data
    }
}

// ==== Action for Get Customer Details ====
export const getCustomerDetails = (data) => {
    return {
        type : GET_CUSTOMER_DETAILS,
        payload : data
    }
}
export const getCustomerDetailsSuccess = (data) => {
    return {
        type : GET_CUSTOMER_DETAILS_SUCCESS,
        payload : data
    }
}
export const getCustomerDetailsFailure = (data) => {
    return {
        type : GET_CUSTOMER_DETAILS_FAILURE,
        payload : data
    }
}
export const getCustomerDetailsInit = (data) => {
    return {
        type : GET_CUSTOMER_DETAILS_INIT,
        payload : data
    }
}

// ==== Actions for Change Config ====
export const changeConfig = (data) => {
    return {
        type : CHANGE_CONFIG,
        payload : data
    }
}
export const changeConfigSuccess = (data) => {
    return {
        type : CHANGE_CONFIG_SUCCESS,
        payload : data
    }
}
export const changeConfigFailure = (data) => {
    return {
        type : CHANGE_CONFIG_FAILURE,
        payload : data
    }
}
export const changeConfigInit = (data) => {
    return {
        type : CHANGE_CONFIG_INIT,
        payload : data
    }
}

// ==== Activity for Get User Logs ====
export const getUserLogs = (data) => {
    return {
        type : GET_USER_LOGS,
        payload : data
    }
}
export const getUserLogsSuccess = (data) => {
    return {
        type : GET_USER_LOGS_SUCCESS,
        payload : data
    }
}
export const getUserLogsFailure = (data) => {
    return {
        type : GET_USER_LOGS_FAILURE,
        payload : data
    }
}
export const getUserLogsInit = (data) => {
    return {
        type : GET_USER_LOGS_INIT,
        payload : data
    }
}

// ==== Actions for Get Portal Logs ====
export const getPortalLogs = (data) => {
    return {
        type : GET_PORTAL_LOGS,
        payload : data
    }
}
export const getPortalLogsSuccess = (data) => {
    return {
        type : GET_PORTAL_LOGS_SUCCESS,
        payload : data
    }
}
export const getPortalLogsFailure = (data) => {
    return {
        type : GET_PORTAL_LOGS_FAILURE,
        payload : data
    }
}
export const getPortalLogsInit = (data) => {
    return {
        type : GET_PORTAL_LOGS_INIT,
        payload : data
    }
}

// ==== Actions for Getting User Log Filters ====
export const getUserLogFilters = (data) => {
    return {
        type : GET_USER_LOG_FILTERS,
        payload : data
    }
}
export const getUserLogFiltersSuccess = (data) => {
    return {
        type : GET_USER_LOG_FILTERS_SUCCESS,
        payload : data
    }
}
export const getUserLogFiltersFailure = (data) => {
    return {
        type : GET_USER_LOG_FILTERS_FAILURE,
        payload : data
    }
}
export const getUserLogFiltersInit = (data) => {
    return {
        type : GET_USER_LOG_FILTERS_INIT,
        payload : data
    }
}

// ==== Actions for Getting Portal Log filters ====
export const getPortalLogFilters = (data) => {
    return {
        type : GET_PORTAL_LOG_FILTERS,
        payload : data
    }
}
export const getPortalLogFiltersSuccess = (data) => {
    return {
        type : GET_PORTAL_LOG_FILTERS_SUCCESS,
        payload : data
    }
}
export const getPortalLogFiltersFailure = (data) => {
    return {
        type : GET_PORTAL_LOG_FILTERS_FAILURE,
        payload : data
    }
}
export const getPortalLogFiltersInit = (data) => {
    return {
        type : GET_PORTAL_LOG_FILTERS_INIT,
        payload : data
    }
}
// ==== Actions for Download Logs ====
export const downloadLogs = (data) => {
    return {
        type : DOWNLOAD_LOGS,
        payload : data
    }
}
export const downloadLogsSuccess = (data) => {
    return {
        type : DOWNLOAD_LOGS_SUCCESS,
        payload : data
    }
}
export const downloadLogsFailure = (data) => {
    return {
        type : DOWNLOAD_LOGS_FAILURE,
        payload : data
    }
}
export const downloadLogsInit = (data) => {
    return {
        type : DOWNLOAD_LOGS_INIT,
        payload : data
    }
}

// ==== Actions for Search Customer Groups ====
export const searchCustomersGroups = (data) => {
    return {
        type : SEARCH_CUSTOMERS_GROUPS,
        payload : data
    }
}
export const searchCustomersGroupsSuccess = (data) => {
    return {
        type : SEARCH_CUSTOMERS_GROUPS_SUCCESS,
        payload : data
    }
}
export const searchCustomersGroupsFailure = (data) => {
    return {
        type : SEARCH_CUSTOMERS_GROUPS_FAILURE,
        payload : data
    }
}
export const searchCustomersGroupsInit = (data) => {
    console.log("Inside Customer Group Init actions!!")
    return {
        type : SEARCH_CUSTOMERS_GROUPS_INIT,
        payload : data
    }
}

// ==== Actions for Assign Customer Groups ====
export const assignCustomerGroups = (data) => {
    return {
        type : ASSIGN_CUSTOMER_GROUP,
        payload : data
    }
}
export const assignCustomerGroupsSuccess = (data) => {
    return {
        type : ASSIGN_CUSTOMER_GROUP_SUCCESS,
        payload : data
    }
}
export const assignCustomerGroupsFailure = (data) => {
    return {
        type : ASSIGN_CUSTOMER_GROUP_FAILURE,
        payload : data
    }
}
export const assignCustomerGroupsInit = (data) => {
    return {
        type : ASSIGN_CUSTOMER_GROUP_INIT,
        payload : data
    }
}

// ==== Actions for Search User ====
export const searchUser = (data) => {
    return{
        type : SEARCH_USER,
        payload : data
    }
}
export const searchUserSuccess = (data) => {
    return{
        type : SEARCH_USER_SUCCESS,
        payload : data
    }
}
export const searchUserFailure = (data) => {
    return{
        type : SEARCH_USER_FAILURE,
        payload : data
    }
}
export const searchUserInit = (data) => {
    return{
        type : SEARCH_USER_INIT,
        payload : data
    }
}

// ==== Actions for Search Devices ====
export const searchDevices = (data) => {
    return {
        type: SEARCH_DEVICES,
        payload: data
    }
}
export const searchDevicesSuccess = (data) => {
    return {
        type: SEARCH_DEVICES_SUCCESS,
        payload: data
    }
}
export const searchDevicesFailure = (data) => {
    return {
        type: SEARCH_DEVICES_FAILURE,
        payload: data
    }
}
export const searchDevicesInit = (data) => {
    return {
        type: SEARCH_DEVICES_INIT,
        payload: data
    }
}

