import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DropdownList from 'react-widgets/lib/DropdownList';
// Actions
import { changePreloader } from '../../store/layout/actions';
import { getTransactionDetails, getPaymentDetails, downloadPaymentDetails } from '../../store/lnDashboard/actions';

import { convertUTCToTimezone, convertUTCTimeToTimezone } from '../../helpers/utcToIstConverter';

import BackIcon from '../../assets/images/arrow-left-solid.svg';
import CheckIcon from '../../assets/images/check-circle-solid.svg';
import CrossIcon from '../../assets/images/times-circle-solid.svg';
import PendingIcon from '../../assets/images/pending-mark.svg';
import BluePendingIcon from '../../assets/images/pending_time_icon.svg';
import Attachment from '../../assets/images/paperclip-solid.svg';
const PopupCard = (props) => {

  const dispatch = useDispatch();
  const [tabState, setTabState] = useState(1);

  const transaction = useSelector(state => state.LNDashboard.pendingTransactions[props.selectedTransactionId]);
  const orderStatusFirstColumn = useSelector(state => {
    if (state.LNDashboard.pendingTransactions[props.selectedTransactionId].transactionDetails !== null) {
      return state.LNDashboard.pendingTransactions[props.selectedTransactionId].transactionDetails.orderStatusDto.businessPartner;
    } else {
      return null;
    }
  });
  const orderStatusFirstColumnKeys = orderStatusFirstColumn !== null ? Object.keys(orderStatusFirstColumn) : null;

  const orderStatusSecondColumn = useSelector(state => {
    if (state.LNDashboard.pendingTransactions[props.selectedTransactionId].transactionDetails !== null) {
      return state.LNDashboard.pendingTransactions[props.selectedTransactionId].transactionDetails.orderStatusDto.salesOrder;
    } else {
      return null;
    }
  });

  const orderStatusSecondColumnKeys = orderStatusSecondColumn !== null ? Object.keys(orderStatusSecondColumn) : null;
  const orderStatusThirdColumn = useSelector(state => {
    if (state.LNDashboard.pendingTransactions[props.selectedTransactionId].transactionDetails !== null) {
      return state.LNDashboard.pendingTransactions[props.selectedTransactionId].transactionDetails.orderStatusDto.invoice;
    } else {
      return null;
    }
  });

  const orderStatusThirdColumnKeys = orderStatusThirdColumn !== null ? Object.keys(orderStatusThirdColumn) : null;

  const orderStatusFourthColumn = useSelector(state => {
    if (state.LNDashboard.pendingTransactions[props.selectedTransactionId].transactionDetails !== null) {
      return state.LNDashboard.pendingTransactions[props.selectedTransactionId].transactionDetails.orderStatusDto.collection;
    } else {
      return null;
    }
  });

  const orderStatusFourthColumnKeys = orderStatusFourthColumn !== null ? Object.keys(orderStatusFourthColumn) : null;

  useEffect(() => {
    fetchTransactionDetailsData();
    fetchPaymentDetails();
  }, []);

  const fetchTransactionDetailsData = () => {
    if (transaction.transactionDetails == null) {
      // make details api call and show loader
      dispatch(changePreloader(true));
      dispatch(getTransactionDetails(props.selectedTransactionId));
    }
  }

  const fetchPaymentDetails = () => {
    if (transaction.paymentDetails == null) {
      dispatch(getPaymentDetails(props.selectedTransactionId))
    }
  }

  const toggleTab = (i) => {
    setTabState(i);
  };

  const renderTransactionType = (type) => {
    switch (type) {
      case type == 0:
        return 'pending';
      case type == 1:
        return 'rejected';
      case type == 2:
        return 'accepted';
    }
  }

  const onDownload = () => {
    dispatch(downloadPaymentDetails(transaction.transactionId));
  }

  const renderStateIcon = (data) => {
    if (data == undefined || data == null || data.responseExpression.toLowerCase() == 'pending') {
      return <img src={PendingIcon} title="Pending" />
    } else if (data.responseExpression.toLowerCase() == 'processed' || data.responseExpression.toLowerCase() == 'accepted') {
      return <img src={CheckIcon} title="Processed" className="processedIcon" />
    } else if (data.responseExpression.toLowerCase() == 'rejected') {
      return <img src={CrossIcon} title="Rejected" />
    }
  }

  const renderTabs = () => {
    return (<div className='tabs-container'>
      <div
        className={
          tabState === 1
            ? 'tabs active-tab'
            : 'tabs'
        }
        onClick={() => toggleTab(1)}>
        Order Status
      </div>
      <div
        className={
          tabState === 3
            ? 'tabs active-tab'
            : 'tabs'
        }
        onClick={() => toggleTab(3)}>
        Payment Details
      </div>
      <div
        className={
          tabState === 2
            ? 'tabs active-tab'
            : 'tabs'
        }
        onClick={() => toggleTab(2)}>
        Customer Details
      </div>
      {/* <div
      className={
        tabState === 2
          ? 'tabs active-tab'
          : 'tabs'
      }
      onClick={() => toggleTab(2)}>
      History
    </div> */}
    </div>)
  }

  const renderOrderStatusHeader = () => {
    return (
      <div className='order-status'>
        <div className="flex-i">
          <p className=''>
            Business Partner Creation
            <div className='horizontal-seperator'></div>
          </p>

        </div>
        <div className="flex-i">
          <p className=''>Sales order
            <div className='horizontal-seperator'></div>
          </p>
        </div>
        <div className="flex-i">
          <p className=''>Invoicing
            <div className='horizontal-seperator'></div>
          </p>
        </div>
        <div className="flex-i">
          <p className='last-flex-i'>Collection
            {/* <select className='drop-menu'>
                                <option
                                  className='dropdown'
                                  type='dropdown'
                                  value='1'
                                  placeholder='1'>
                                  1
                                </option>
                                <option
                                  className='dropdown'
                                  type='dropdown'
                                  value='1'
                                  placeholder='1'>
                                  2
                                </option>
                                <option
                                  className='dropdown'
                                  type='dropdown'
                                  value='3'
                                  placeholder='3'>
                                  3
                                </option>
                              </select> */}
          </p>
        </div>
      </div>
    )
  }

  const renderOrderStatusBody = () => {
    return (
      <div className='order-status-content'>
        <div className='state-error-table'>
          <div className='column'>
            {
              orderStatusFirstColumnKeys !== null ? orderStatusFirstColumnKeys.map((step, i) => {
                return (
                  <Fragment>
                    <div className='elem'>
                      {
                        transaction.typeOfTransaction === 'Renewal' ? (<img src={BluePendingIcon} title="Pending" />) :
                          (<Fragment>
                            {renderStateIcon(orderStatusFirstColumn[step])}
                          </Fragment>)
                      }
                      <div>
                        <p>
                          {step},{orderStatusFirstColumn[step] !== null && orderStatusFirstColumn[step].time !== null ? convertUTCTimeToTimezone(orderStatusFirstColumn[step].time + ':00.000') : ''}
                        </p>
                        <small>Company: {orderStatusFirstColumn[step] !== null ? orderStatusFirstColumn[step].company : '--'}</small>
                      </div>
                    </div>

                    <div className={`vertical ${orderStatusFirstColumnKeys.length == i + 1 ? 'no-appearance' : ''}`}></div>
                  </Fragment>
                )
              }) : null
            }
          </div>
          <div className='column '>
            {
              orderStatusSecondColumnKeys !== null ? orderStatusSecondColumnKeys.map((step, i) => {
                return (
                  <Fragment>
                    <div className='elem'>
                      {renderStateIcon(orderStatusSecondColumn[step])}
                      <div>
                        <p>
                          {step},{orderStatusSecondColumn[step] !== null && orderStatusSecondColumn[step].time !== null ? convertUTCTimeToTimezone(orderStatusSecondColumn[step].time + ':00.000') : ''}
                          <br />
                          <small>Company: {orderStatusSecondColumn[step] !== null ? orderStatusSecondColumn[step].company : '--'}</small>
                          <br />
                          <small>Sales Order: {orderStatusSecondColumn[step] !== null && orderStatusSecondColumn[step].soNumber !== null ? orderStatusSecondColumn[step].soNumber : '--'}</small>
                        </p>
                      </div>
                    </div>
                    <div className={`vertical ${orderStatusSecondColumnKeys.length == i + 1 ? 'no-appearance' : ''}`}></div>
                  </Fragment>
                )
              }) : null
            }

          </div>
          <div className='column '>
            {
              orderStatusThirdColumnKeys !== null ? orderStatusThirdColumnKeys.map((step, i) => {
                return (
                  <Fragment>
                    <div className='elem'>
                      {renderStateIcon(orderStatusThirdColumn[step])}
                      <div>
                        <p>
                          {step},{orderStatusThirdColumn[step] !== null && orderStatusThirdColumn[step].time !== null ? convertUTCTimeToTimezone(orderStatusThirdColumn[step].time + ':00.000') : ''}
                          <br />
                          <small>Company: {orderStatusThirdColumn[step] !== null ? orderStatusThirdColumn[step].company : '--'}</small>
                          <br />
                          <small>Invoice No.: {orderStatusThirdColumn[step] !== null && orderStatusThirdColumn[step].invoiceNumber !== null ? orderStatusThirdColumn[step].invoiceNumber : '--'}</small>
                        </p>
                      </div>
                    </div>
                    <div className={`vertical ${orderStatusThirdColumnKeys.length == i + 1 ? 'no-appearance' : ''}`}></div>
                  </Fragment>
                )
              }) : null
            }

          </div>
          <div className='column'>
            {
              orderStatusFourthColumnKeys !== null ? orderStatusFourthColumnKeys.map((step, i) => {
                return (
                  <Fragment>
                    <div className='elem'>
                      {renderStateIcon(orderStatusFourthColumn[step])}
                      <div>
                        <p>
                          {step},{orderStatusFourthColumn[step] !== null && orderStatusFourthColumn[step].time !== null ? convertUTCTimeToTimezone(orderStatusFourthColumn[step].time + ':00.000') : ''}
                          <br />
                          <small>Company: {orderStatusFourthColumn[step] !== null ? orderStatusFourthColumn[step].company : '--'}</small>
                        </p>
                      </div>
                    </div>
                    <div className={`vertical ${orderStatusFourthColumnKeys.length == i + 1 ? 'no-appearance' : ''}`}></div>
                  </Fragment>
                )
              }) : null
            }

          </div>
        </div>
      </div>
    )
  }
  const renderCustomerDetailsSection = () => {
    return (
      <div className="customer-details-section">
        <h4>Customer Details :</h4>
        <p className=''>
          First Name : <span> {transaction.transactionDetails.customerDetail.name}</span>
        </p>
        <p className=''>
          Mobile Number : <span> {transaction.transactionDetails.customerDetail.mobileNo}</span>
        </p>
        <p className=''>
          City: <span> {transaction.transactionDetails.customerDetail.city}</span>
        </p>
        <p className=''>
          Subscription : <span> {transaction.transactionDetails.customerDetail.subscription}</span>
        </p>
        <p className=''>
          Email ID : <span> {transaction.transactionDetails.customerDetail.emailId}</span>
        </p>
        <p className=''>
          Pincode : <span> {transaction.transactionDetails.customerDetail.pincode}</span>
        </p>
        <p className=''>
          Active Plan : <span> {transaction.transactionDetails.customerDetail.subscription}</span>
        </p>
        <p className=''>
          BP Code : <span> {transaction.transactionDetails.customerDetail.bpCode !== undefined && transaction.transactionDetails.customerDetail.bpCode !== null ? transaction.transactionDetails.customerDetail.bpCode : '--'}</span>
        </p>
      </div>
    )
  }

  const renderDeviceDetailsSection = () => {
    return (
      <div className="customer-details-section">
        <h4>Device Details :</h4>
        {
          transaction.transactionDetails.deviceDetail.map((device) => {
            return (
              <Fragment>
                <p className=''>
                  Camera Model : <span> {device.cameraModel}</span>
                </p>
                <p className=''>
                  Device Serial No. : <span> {device.deviceSerial}</span>
                </p>
              </Fragment>
            )
          })
        }
      </div>
    )
  }

  const renderPaymentDetailsSection = () => {
    return (
      <Fragment>
        {
          transaction.paymentDetails !== null ? (
            <Fragment>
              <div className='payment-head'>
                <p>Payment Details</p>
              </div>
              <div className='payment-content'>
                <table className='table'>
                  <tr>
                    <th>Godrej ID</th>
                    <th>Billdesk ID</th>
                    <th>Transaction Date</th>
                    <th>Type of Transaction</th>
                    <th>Value</th>
                  </tr>

                  <tbody>
                    <tr>
                      <td>{transaction.paymentDetails.godrejId}</td>
                      <td>{transaction.paymentDetails.billdeskId}</td>
                      <td>{transaction.paymentDetails.transactionDate !== undefined && transaction.paymentDetails.transactionDate !== null ? convertUTCToTimezone(transaction.paymentDetails.transactionDate + ':00.', 'GMT+05:30_Asia/Kolkata', true) : '--'}</td>
                      <td>{transaction.paymentDetails.typeOftransaction}</td>
                      <td>{transaction.paymentDetails.value !== undefined && transaction.paymentDetails.value !== null ? `Rs.${transaction.paymentDetails.value}` : '--'}</td>
                    </tr>
                  </tbody>
                </table>
                <div className='card'>
                  <div className='card-body'>
                    <p>Billdesk Gross Amount:</p>
                    <p>Billdesk Charges:</p>
                    <p>Billdesk GST on Charges</p>
                    <p>Net Amount:</p>
                    <p>Settlement Date:</p>
                  </div>
                  <div className='card-body'>
                    <p>{transaction.paymentDetails.grossAmount !== null ? transaction.paymentDetails.grossAmount : '--'}</p>
                    <p>{transaction.paymentDetails.charges !== null ? transaction.paymentDetails.charges : '--'}</p>
                    <p>{transaction.paymentDetails.gstOnCharges !== null ? transaction.paymentDetails.gstOnCharges : '--'}</p>
                    <p>{transaction.paymentDetails.netAmount !== null ? transaction.paymentDetails.netAmount : '--'}</p>
                    <p>{transaction.paymentDetails.settlementDate !== null ? convertUTCToTimezone(transaction.paymentDetails.settlementDate + ':00.', undefined, true) : '--'}</p>
                  </div>
                  <hr className='vertical-seperator' />
                  <div className='card-body'>
                    <p>Payment Method:</p>
                    <p>Discount Coupon:</p>
                    <p>Discount Amount:</p>
                    {/* <p>Subsciption Expiry Date:</p> */}
                  </div>
                  <div className='card-body'>
                    <p>{transaction.paymentDetails.paymentMethod !== null ? transaction.paymentDetails.paymentMethod : '--'}</p>
                    <p>{transaction.paymentDetails.discountCoupon !== null ? transaction.paymentDetails.discountCoupon : '--'}</p>
                    <p>{transaction.paymentDetails.discountAmount !== null ? transaction.paymentDetails.discountAmount : '--'}</p>
                    {/* <p>{transaction.paymentDetails.settlementDate !== null ? moment(transaction.paymentDetails.settlementDate).format('DD MMMM YYYY, hh:mm a') : '--'}</p> */}
                  </div>
                </div>
                <button className='btn-grey mar-r' onClick={() => { onDownload() }}>
                  Download Reports
                </button>
              </div>
            </Fragment>
          ) : null
        }
      </Fragment>
    )
  }

  return (
    <Fragment>
      {
        transaction.transactionDetails !== null ? (
          <div className='CustomCard'>
            <div className='card-head'>
              <div onClick={() => props.onResetSelectedTransaction(null)} className='cursor-pointer'>
                <img src={BackIcon} />
                {/* <span>Advance Search</span> */}
              </div>
              <h1 className='main-heading'>
                Transaction ID # {transaction.transactionId} - {moment(transaction.transactionDate).format('DD/MM/YYYY')}
              </h1>
            </div>
            <div className='card-content'>
              <div className='card-content-left'>
                <div className='tab'>
                  {
                    renderTabs()
                  }
                  <div className='content-tabs'>
                    <div>
                      <div
                        className={
                          tabState === 1
                            ? 'content active-content'
                            : 'content'
                        }>
                        {
                          renderOrderStatusHeader()
                        }

                        {
                          renderOrderStatusBody()
                        }
                        <hr />
                        {/* <div className='order-status-bottom'>
                          <p>
                            Error: Incorrect HSN Code, Change the
                            code and try again
                          </p>
                          <button className='btn-grey'>
                            Resolve Now
                          </button>
                        </div> */}
                      </div>
                    </div>
                    {/* <div
                      className={
                        tabState === 2
                          ? 'content active-content'
                          : 'content'
                      }>
                      <div className='history-top'>
                        <p>Order History</p>
                        <button>Add Comment</button>
                      </div>
                      <div className='history-content'>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Status</th>
                              <th>User</th>
                              <th>Comments</th>
                              <th>Attachments(if any)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>22 January 2020, 10:09 am</td>
                              <td>Pending</td>
                              <td>onkarc</td>
                              <td>
                                contacted Mr.karve, resolved in
                                system
                              </td>
                              <td>
                                <img src={Attachment} />
                              </td>
                            </tr>
                            <tr>
                              <td>22 January 2020, 10:09 am</td>
                              <td>Pending</td>
                              <td>onkarc</td>
                              <td>
                                contacted Mr.karve, resolved in
                                system
                              </td>
                              <td>
                                <img src={Attachment} />
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <hr />
                        <div className='history-bottom'>
                          <button className='btn-grey'>
                            Download Reports
                          </button>
                        </div>
                      </div>
                    </div> */}
                    <div
                      className={
                        tabState === 3
                          ? 'content active-content'
                          : 'content'
                      }>
                      {
                        renderPaymentDetailsSection()
                      }
                    </div>

                    <div
                      className={
                        tabState === 2
                          ? 'content active-content'
                          : 'content'
                      }>
                      {
                        renderCustomerDetailsSection()
                      }

                      {
                        renderDeviceDetailsSection()
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='card-content-right'>
                <div className='main-heading'>
                  Customer Details
                </div>
                <div className='card-body'>
                  <div className='car-body-left'>
                    <div className='card-inner-data'>
                      First Name:<p> {transaction.transactionDetails.customerDetail.name}</p>
                    </div>
                    <div className='card-inner-data'>
                      Mobile Number:<p> {transaction.transactionDetails.customerDetail.mobileNo}</p>
                    </div>
                    <div className='card-inner-data'>
                      City:<p> {transaction.transactionDetails.customerDetail.city}</p>
                    </div>
                    <div className='card-inner-data'>
                      Subscription:<p> {transaction.transactionDetails.customerDetail.subscription}</p>
                    </div>
                  </div>
                  <div className='car-body-right'>
                    <div className='card-inner-data'>
                      Email ID:<p> {transaction.transactionDetails.customerDetail.emailId}</p>
                    </div>
                    <div className='card-inner-data'>
                      Pincode:<p> {transaction.transactionDetails.customerDetail.pincode}</p>
                    </div>
                    <div className='card-inner-data'>
                      Active Plan:<p> {transaction.transactionDetails.customerDetail.subscription}</p>
                    </div>
                    <div className='card-inner-data'>
                      BP Code:<p> {transaction.transactionDetails.customerDetail.bpCode !== undefined && transaction.transactionDetails.customerDetail.bpCode !== null ? transaction.transactionDetails.customerDetail.bpCode : '--'}</p>
                    </div>
                  </div>
                </div>
                <hr className='card-seperator' />
                <div className='main-heading'>Device Details</div>
                {
                  transaction.transactionDetails.deviceDetail.map((device) => {
                    return (<div className='device-details-section'>
                      <div className='card-inner-data'>
                        <span> Camera Model:</span><br></br>
                        <span> {device.cameraModel}</span>
                      </div>
                      <div className='card-inner-data'>
                        <span>Device Serial No.:</span><br></br>
                        <span> {device.deviceSerial}</span>
                      </div>
                    </div>)
                  })
                }
              </div> */}
            </div>
          </div>
        ) : null
      }
    </Fragment>
  );
};
export default PopupCard;
