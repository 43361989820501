
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../../store/auth/login/actions';
import { logout } from '../../../helpers/authUtils'

// users
import user1 from '../../../assets/images/users/user-1.png';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    logout = (e) => {
        e.preventDefault();
        this.props.logoutUser();
        logout();
    }

    render() {
        return (
            <React.Fragment>
                {/* <span className="admin-role-tag d-none d-xl-inline-block ml-2 mr-1">{this.props.role !== undefined && this.props.role['custom:RoleName'] !== undefined ? this.props.role['custom:RoleName'] : 'Super Admin' }</span> */}
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ml-2 mr-1">{this.props.role !== undefined && this.props.role['custom:RoleName'] !== undefined ? this.props.role['custom:RoleName'] : 'Super Admin' }</span>
                        {/* <span>User Name</span> */}
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <a className="dropdown-item" onClick={(e)=>this.logout(e)}>
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>Logout</span>
                        </a>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { access_token , role } = state.Login;
    // console.log(access_token);
    return { access_token, role };
}

export default withRouter(connect(mapStatetoProps, { logoutUser })(ProfileMenu));


