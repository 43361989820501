import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers';
import rootSaga from './sagas';
import { saveUser } from '../helpers/authUtils';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;



const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

store.subscribe(()=>{
    saveUser(store.getState());
})
sagaMiddleware.run(rootSaga);

export default store;