import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from "react-router-dom";

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {/* <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Open Menu
      </Button> */}
      <i  aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className="fa fa-download fa-lg select-hover force-download-color"></i>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* {
          props.listItem.map((value) => {
            return(
              <MenuItem onClick={() => {props.clickHandler(value)}}>{value}</MenuItem>
            )
          })
        } */}

        <a href={props.templateUrl} target="_blank" download><MenuItem >Download Blank QA Template</MenuItem></a>
        <a href={props.previousQa} target="_blank" download><MenuItem >Download Previous QA Report</MenuItem></a>
      </Menu>
    </React.Fragment>
  );
}
