import moment from 'moment';
export function generateTransactionMonths() {
    let currentMonth = parseInt(moment().format('M'));
    let currentYear = parseInt(moment().format('YYYY'));
    let previousYear = parseInt(moment().subtract(1, 'year').format('YYYY'));
    let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // console.log('Month');
    let monthsData = [];
    for (let i = 1; i <= 12; i++) {
        monthsData.push({ label: `${monthsArray[i - 1]} ${i <= currentMonth ? currentYear : previousYear}`, value: i, year: `${i <= currentMonth ? currentYear : previousYear}` })
    }

    return monthsData;
}