import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearError } from '../store/lnDashboard/actions';

export const useErrorHandlingService = () => {
    const dispatch = useDispatch();
    const errorMessage = useSelector(state => state.LNDashboard.error);

    useEffect(() => {
        handleError();
    }, [errorMessage]);

    function handleError() {
        if (errorMessage !== null) {
            toast.error(errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            dispatch(clearError());
        }
    }
}