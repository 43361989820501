import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { GET_ACTIVATIONS, GET_ONLINE_DEVICES } from './actionTypes';
import { getActivationsSuccess, saveOnlineDevices } from './actions';
import { changePreloader } from '../layout/actions';

import { fetchActivationNumber, fetchOnlineUsers } from "../../services/dashboardService";

function* fetchActivations(data) {
    try {
        const response = yield call(fetchActivationNumber, data.payload);
        // console.log(response);
        yield put(getActivationsSuccess(response.data.data.newActivations));
        yield put(changePreloader(false));

    } catch (error) {
        // yield put(apiError(error.message));
        console.log(error);
    }
}

function* fetchOnlineDevices() {
    try {
        const response = yield call(fetchOnlineUsers);
        if (response !== undefined) {
            yield put(saveOnlineDevices(response.data.data.onlineDevices));
        }
    } catch (error) {
        console.log(error);
    }
}



export function* watchDashboardActivations() {
    yield takeEvery(GET_ACTIVATIONS, fetchActivations)
}

export function* watchOnlineDevices() {
    yield takeEvery(GET_ONLINE_DEVICES, fetchOnlineDevices)
}

function* dashboardSaga() {
    yield all([
        fork(watchDashboardActivations),
        fork(watchOnlineDevices)
    ]);
}

export default dashboardSaga;