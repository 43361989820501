import React, { useState } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Modal, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";
import AdvancedSearch from '../../components/Common/advanced-search';
import Search from '../../assets/images/search-solid.svg';
import DropdownList from 'react-widgets/lib/DropdownList';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

import { generateTransactionMonths } from '../../services/transactionMasterService';
import { convertDateStringToUTCDateTimeString } from '../../helpers/formatDateHelper'

// Hooks
import { useCheckDownloadStatus } from '../../services/downloadExcelService';
import { useErrorHandlingService } from '../../services/errorHandlingService';

// Actions
import { downloadBook, getBook } from '../../store/lnDashboard/actions';
const Finance = () => {
  const dispatch = useDispatch();
  const [selectSearch, setSelectSearch] = useState(null);
  const [selectDetails, setSelectDetails] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [filterMonth, setFilterMonth] = useState(null);
  const [searchBy, setSearchBy] = useState('date');

  const bookRecords = useSelector(state => state.LNDashboard.book);

  const downloadStatus = useCheckDownloadStatus();
  const errorMessageService = useErrorHandlingService();

  const dateHandler = (type, event) => {
    // console.log(moment(event.target.value).format('YYYY/MM/DD'));
    switch (type) {
      case 'startDate':
        setFilterStartDate(moment(event.target.value).format('YYYY/MM/DD'));
        break;
      case 'endDate':
        setFilterEndDate(moment(event.target.value).format('YYYY/MM/DD'));
    }
  }

  const renderSearchCTAState = () => {
    if (searchBy == 'date') {
      if (filterStartDate !== null && filterEndDate !== null) {
        return true
      } else {
        return false
      }
    } else {
      if (filterMonth !== null) {
        return true
      } else {
        return false
      }
    }
  }

  const onSearch = (isDownload = false) => {
    let isEnabled = renderSearchCTAState();
    if (isEnabled && !isDownload) {
      let params;
      if (searchBy == 'date') {
        params = {
          fromDate: convertDateStringToUTCDateTimeString(filterStartDate),
          toDate: convertDateStringToUTCDateTimeString(filterEndDate),
        }
      } else {
        params = {
          month: filterMonth.value,
          year: filterMonth.year,
        }
      }


      dispatch(getBook(params));
    } else if (isEnabled && isDownload) {
      let downloadParams;
      if (searchBy == 'date') {
        downloadParams = {
          fromDate: convertDateStringToUTCDateTimeString(filterStartDate),
          toDate: convertDateStringToUTCDateTimeString(filterEndDate),
        }
      } else {
        downloadParams = {
          month: filterMonth.value,
          year: filterMonth.year,
        }
      }

      dispatch(downloadBook(downloadParams));
    }
  }

  const setSearchRadio = (type) => {
    setSearchBy(type);
  }

  return (
    <div className='ln-report'>
      <div className='finance'>
        {selectSearch !== null ? (
          <AdvancedSearch
            onBack={setSelectSearch}
            showTransactionTypeFilter={true}
            defaultTransactionType={null}
            pageType='ln'
          />
        ) : (
          <Fragment>
            <div className='title-section'>
              <div className='title'>Compare Books</div>
              <div
                className='search'
                onClick={setSelectSearch}>
                <img src={Search} />
                Advanced Search
              </div>
            </div>
            <div className='select-wrapper-1'>

              <div className="action-box-wrapper">
                <div onClick={() => { setSearchRadio('date'); }}>
                  <input type="radio" className="filter-checkbox" checked={searchBy === 'date' ? true : false} />&nbsp;
                  <label className={`label radio ${searchBy === 'date' ? '' : 'light'}`}>Transaction date, From : </label>
                </div>
                <div className={searchBy === 'date' ? 'inp-field' : 'inp-field not-allowed blur'}>
                  <input
                    max={moment().format('YYYY-MM-DD')}
                    onChange={(e) => dateHandler('startDate', e)}
                    type='date'
                    disabled={searchBy === 'date' ? false : true}
                  />
                  <label className='inline-label'>To</label>
                  <input
                    min={moment(filterStartDate).format('YYYY-MM-DD')}
                    max={moment().format('YYYY-MM-DD')}
                    type='date'
                    onChange={(e) => dateHandler('endDate', e)}
                    disabled={searchBy === 'date' ? false : true}
                  />
                </div>
              </div>

              <div className="action-box-wrapper">
                <div onClick={() => { setSearchRadio('month'); }}>
                  <input type="radio" className="filter-checkbox" checked={searchBy === 'month' ? true : false} />&nbsp;
                  <label className={`label radio ${searchBy === 'month' ? '' : 'light'}`}>Transaction Month : </label>
                </div>
                <div className={searchBy === 'month' ? 'inp-field' : 'inp-field not-allowed blur'}>
                  <DropdownList
                    className='drop-menu'
                    data={generateTransactionMonths()}
                    placeholder='Select month'
                    onChange={(val) => { setFilterMonth(val) }}
                    dataKey=''
                    textField='label'
                    disabled={searchBy === 'month' ? false : true}
                  />
                </div>
              </div>
            </div>

            <div className="ml-15">
              <Button name="searchButton" onClick={(e) => { onSearch() }} disabled={renderSearchCTAState() ? false : true}>Get Details</Button>
            </div>
            {bookRecords !== null ? (
              <div className='display'>
                <div className='cards'>
                  <div className='card-left'>
                    <div className='card-data'>
                      <div className='bold'>
                        Spotlight System
                      </div>
                    </div>
                    <div className='card-data'>
                      <div className=''>
                        Total Transactions:
                      </div>
                      <div>{bookRecords.spotlight.totalTransactions} Nos</div>
                    </div>
                    <div className='card-data left'>
                      <div className='bold'>
                        Transaction Total:
                      </div>
                      <div className='bold'>Rs.{bookRecords.spotlight.totalAmount.toFixed(2)}/-</div>
                    </div>
                    <div className='card-data'>
                      <div>Discounts on Promo Code:</div>
                      <div>Rs.{bookRecords.spotlight.promoCodeDiscount}/-</div>
                    </div>
                    <div className='card-data final'>
                      <div className='bold'>
                        Final Amount Recieved
                      </div>
                      <div className='bold'>Rs.{bookRecords.spotlight.netAmount.toFixed(2)}/-</div>
                    </div>
                  </div>
                  <div className='vertical-seperator'></div>
                  <div className='card-right'>
                    <div className='card-data'>
                      <div className='bold'>
                        Billdesk System:
                      </div>
                    </div>
                    <div className='card-data'>
                      <div>Total Transactions:</div>
                      <div>{bookRecords.billdesk.totalTransactions} Nos</div>
                    </div>
                    <div className='card-data'>
                      <div>Gross Transaction Value</div>
                      <div>Rs.{bookRecords.billdesk.grossAmount}</div>
                    </div>
                    <div className='card-data'>
                      <div>Billdesk Charges</div>
                      <div>Rs.{bookRecords.billdesk.billdeskCharges}</div>
                    </div>
                    <div className='card-data'>
                      <div>GST on Billdesk Charges</div>
                      <div>Rs.{bookRecords.billdesk.gstOnBilldeskCharges}</div>
                    </div>
                    <div className='card-data final'>
                      <div className='bold'>
                        Net Amount Recieved:
                      </div>
                      <div className='bold'>Rs.{bookRecords.billdesk.netAmount.toFixed(2)}/-</div>
                    </div>
                  </div>
                </div>
                <div className='bottom'>
                  <button className='btns' onClick={() => { onSearch(true) }}>
                    Download as Excel
                  </button>
                </div>
              </div>
            ) : null}
          </Fragment>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};
export default Finance;
