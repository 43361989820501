import {
    GET_COUNTRIES,
    GET_COUNTRIES_SUCCESS,
    GET_COUNTRIES_FAILURE,
    GET_COUNTRIES_INIT
} from './actionTypes'

export const getCountries = (data) => {
    console.log("####Inside countries!!")
    return {
        type : GET_COUNTRIES,
        payload : data
    }
}

export const getCountriesSuccess = (data) => {
    return {
        type : GET_COUNTRIES_SUCCESS,
        payload : data
    }
}

export const getCountriesFailure = (data) => {
    return {
        type : GET_COUNTRIES_FAILURE,
        payload : data
    }
}

export const getCountriesInit = (data) => {
    return {
        type : GET_COUNTRIES_INIT,
        payload : data
    }
}