import API from './interceptor';
import constants from '../common/constants'
const urlDevicePrefix = constants.urlDevicePrefix
const urlPrefix = constants.urlPrefix

export function getNewActivationService(params){
    return API({
        method : 'GET',
        url : urlDevicePrefix+'dashboard/activations',
        params
    })
}

export function getOnlineDevicesService(){
    return API({
        method : 'GET',
        url : urlDevicePrefix+'dashboard/online/devices'
    })
}

export function getTrendGraphService(params){
    return API({
        method : 'GET',
        url : urlDevicePrefix+'dashboard/trends/graph',
        params
    })
}

export function getDeviceInfoService(){
    return API({
        method : "GET",
        url : urlDevicePrefix+'dashboard/devices/report'
    })
}

export function downloadDeviceInfoReportService(){
    return API({
        method : 'GET',
        url : urlDevicePrefix+'dashboard/devices/report/download',
        responseType: 'arraybuffer'
    })
}
