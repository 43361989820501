import React, { Component } from 'react';
import { Container, Button, Modal, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import DropdownList from 'react-widgets/lib/DropdownList';
import InputBox from '../../components/Common/input-box';
import FileUpload from '../../components/Common/file-upload';
import Breadcrumbs from '../../components/Common/breadcrumb';
import ActivityComp from '../Dashboard/activityComp'

import { Calendar, DateRange } from 'react-date-range'
import {Tabs, Tab, TabPanel, TabList} from 'react-web-tabs'
import moment from 'moment'

import * as ManageAccessActions from '../../store/manageAccess/actions'
import * as CustomerActions from '../../store/customer/actions'
import * as LayoutActions from '../../store/layout/actions'
import { sortIntegerTypeData, sortStringTypeData } from '../../helpers/sortHelper';

// === Assets ====
import detailBack from '../../assets/images/detail-back.png'


import { connect } from 'react-redux';

class ManageAccess extends Component{

    constructor(props){
        super(props)
        this.state = {
            email: '',
            name: '',        
            selectedRole: null,

            validations:{
                email: false,
                name: false,
                selectedRole: false
            },

            allRoles: [],
            allUsers: [],  
            userPermissions: [],          

            viewType: "List",   // ==== Enum : List | Detail ====
            userDetailData : null,

            selectionRange : {
                startDate : new Date(),
                endDate : new Date(),
                key : "selection"
            },
            allFilters: [],
            lastSortKeyContainer: 0,
            filterPopperOpen: false,
            datePopperOpen: false,
            filtersSelected: {},
            portalLogs: [],
            paginationState: false,
            logKey: 0,

            showMiniModal : false,
            miniModalType : 'delete',   // ==== Enum : delete | invite
            tempMiniModalDetails : null,

            showEditModal : false,
            editUserValues : null

        }
    }

    componentDidMount(){
        // this.props.getAllUser()
        this.setDates()
        let logData={
            userType: "admin"
        }
        this.props.getRoles()
        this.props.getPortalLogFilters(logData)
        this.props.changePreloader(true)
    }

    setDates() {
        let { selectionRange } = this.state
        let oldDate = new Date()
        oldDate.setDate( oldDate.getDate() - 80)
        let tempOldDate = new Date(oldDate)
        selectionRange.startDate = tempOldDate
        this.setState({ selectionRange : selectionRange})
    }

    inputHandler = (e) => {
        let {validations} = this.state

        if(e.target.name === "name"){
            validations.name = false
            this.setState({
                name: e.target.value,
                validations: validations
            })
        }
        else if(e.target.name === "emailId"){
            validations.email = false
            this.setState({
                email: e.target.value,
                validations: validations
            })
        }
    }

    selectDropdownValue = (e) => {
        let {validations} = this.state
        validations.selectedRole = false
        this.setState({
            selectedRole: e,
            validations: validations
        })
    }

    selectEditDropdownValue = (e) => {
        let {editUserValues} = this.state
        editUserValues.mappedRole = e
        this.setState({
            editUserValues: editUserValues
        })
    }

    onClearAll = () => {
        this.setState({
            email: "",
            name: "",        
            selectedRole: null,

            validations:{
                email: false,
                name: false,
                selectedRole: false
            },
            miniModalType: 'delete',
            tempMiniModalDetails: null,
            editUserValues: null
        })
    }

    // ==== Actions on user ====
    getSpecificUser = (userDetails) => {
        let data = {
            userId : userDetails.userId
        }
        this.props.getUserDetails(data)
        this.props.changePreloader(true)
    }

    jumpToListView = () => {
        this.setState({
            userDetailData: null,
            viewType: "List"
        })
    }

    inviteUserSelected = () => {
        let {email, name , selectedRole, validations} = this.state
        let validationError = false

        let trimmedName = name.trim()
        let trimmedMail = email.trim()

        if(trimmedName === ''){
            validationError = true
            validations.name = true
        }
        
        if(trimmedMail === ''){
            validationError = true
            validations.email = true
        }

        if(selectedRole === null){
            validationError = true
            validations.selectedRole = true
        }

        if(validationError){
            this.setState({
                validations : validations
            })
            return
        }else{
            this.toggleMiniModal('invite')
        }
    }

    initiateInviteUser = () => {
        let {email, name, selectedRole} = this.state
        let data = {
            userEmail : email,
            userName : name,
            roleId : selectedRole.roleId
        }
        this.props.inviteUser(data)
        this.props.changePreloader(true)
    }

    initiateUserDelete = (userDetails) => {
        let data = {
            userId : userDetails.userId
        }

        this.props.deleteUser(data)
        this.props.changePreloader(true)
    }

    initiateUserEdit = (userDetails) => {
        let data = {
            roleId: userDetails.mappedRole.roleId,
            userId: userDetails.userId
        }
        this.props.editUser(data)
        this.props.changePreloader(true)
    }

    // ==== Modal Toggle ====
    toggleMiniModal = (customToggle=false, details=null) => {
        let {showMiniModal} = this.state
        let customDetails = {}
        if(customToggle !== false){
            customDetails = {
                miniModalType : customToggle,
                tempMiniModalDetails : details
            }
        }

        this.setState({
            showMiniModal : !showMiniModal,
            ...customDetails
        })
    }

    toggleEditModal = (userDetails=null) => {
        let { showEditModal, editUserValues } = this.state
        if(userDetails !== null){
            let mappedRole = {
                roleName: userDetails.roleName,
                roleId: userDetails.roleId
            }
            editUserValues = {
                ...userDetails,
                mappedRole: mappedRole
            }
            this.setState({
                showEditModal: !showEditModal,
                editUserValues: editUserValues
            })

        }else{
            this.setState({
                showEditModal: !showEditModal,
                editUserValues: null
            })
        }
    }


    // ==== Functions for Logs ====
    toggleLogsPopover(type){
        let {filterPopperOpen, datePopperOpen } = this.state
        if(type === "date"){
            if(datePopperOpen){
                this.setState({ datePopperOpen : false, lastSortKeyContainer : 0 , paginationState : false }, ()=>{
                    console.log("Popper closed!!!!")
                    // ==== UNCOMMENT THIS ONCE LOGS ARE FIXED ====
                    this.getLogs()
                })                 
                
            }else {
                this.setState({ datePopperOpen : true }, ()=>{console.log("Popper Open!!!!")} )
            }
        }
        else if(type === "filter"){
            if(filterPopperOpen){
                this.setState({ filterPopperOpen : false, lastSortKeyContainer : 0, paginationState : false }, ()=>{
                    console.log("Popper closed!!!!")                    
                    // ==== UNCOMMENT THIS ONCE LOGS ARE FIXED ====
                    this.getLogs()
                }) 
            }else {
                this.setState({ filterPopperOpen : true }, ()=>{console.log("Popper Open!!!!")} )
            }
        }

    }

    setSelectedDate(selectedDateRange) {
        console.log(selectedDateRange)
        // return
        this.setState({ selectionRange : selectedDateRange.selection})
    }

    updateLogList() {
        let {paginationState} = this.state
        if(paginationState === false){
            this.setState({
                paginationState : true
            }, ()=> {this.getLogs()}
            )
        }
    }

    setInitialFilter = (userType) => {
        let {allFilters, filtersSelected} = this.state
        console.log("#### start filter process : ")
        allFilters.map((filter)=> {
            filter.isChecked = true
            filtersSelected[filter.permissionName] = filter.permissionName
        })
        console.log("#### Mid filter process : ")
        console.log(allFilters)
        this.setState({
            allFilters : allFilters,
            filtersSelected : filtersSelected
        },
        () => {
            console.log("#### Filters : ")
            console.log(this.state.filtersSelected)
            console.log(this.state.allFilters)
            // ==== UNCOMMENT THIS ONCE LOGS ARE FIXED ====
            // this.getLogs()
        })
    }

    changeFilter(filterValue, index){
        let { allFilters, filtersSelected } = this.state
            let checkValue = allFilters[index].isChecked
            let permissionName = allFilters[index].permissionName
            if(checkValue){
                allFilters[index].isChecked = false
                delete filtersSelected[permissionName]
            }else{
                allFilters[index].isChecked = true
                filtersSelected[permissionName] = permissionName
            } 
            this.setState({
                allFilters : allFilters, filtersSelected : filtersSelected
            })        
    }

    getLogs() {
        let {selectionRange, filtersSelected, userId, lastSortKeyContainer, userDetailData } = this.state
        console.log("##### Portal Log data!")
        let eventArr = Object.keys(filtersSelected)
        let data = {
            activityLogs : "userActivity",
            eventType : eventArr,
            // eventType : [],
            fromDate : selectionRange.startDate,
            toDate : selectionRange.endDate
        }
        console.log(data)
        let paramData = {
            inputActivityLogsDto : data,
            userId : userDetailData.userId,
            serialNumber : null,
            pageLimit : 15,
            lastSortKey : lastSortKeyContainer
        }
        this.props.getPortalLogs(paramData)
    }

    filterPortalLogs = (logDetails) => {
        let { paginationState } = this.state
        console.log("##### LOG Details : ")
        console.log(logDetails)
        logDetails.filter((logValue) => {
            logValue.displayDate = moment(logValue.eventDate).format("MMM Do YYYY")            
        })

        console.log("#### Log details filtered :")
        console.log(logDetails)
        let lastSortKeyDetails = logDetails[logDetails.length - 1].sortKey
        console.log(lastSortKeyDetails)

        if(paginationState){
            this.setState({ portalLogs : [...this.state.portalLogs, ...logDetails], lastSortKeyContainer : lastSortKeyDetails, paginationState : false})
        }else{
            this.setState({ portalLogs : logDetails, logKey : Math.random().toFixed(2)*100, lastSortKeyContainer : lastSortKeyDetails })
        }
        
    }

    failedLogs = () => {
        let {paginationState} = this.state
        if(paginationState){
            this.setState({
                paginationState : false
            })
        }else{
            this.setState({
                paginationState: false,
                portalLogs : []
            })
        }
    }


    // ==== Saga checker methods ====
    checkGetAllUsers = () => {
        if(this.props.manageAccess.getAllUsersSuccess === true){
            this.setState({
                allUsers : this.props.manageAccess.allUsers
            })
            this.props.getAllUserInit()
            this.props.changePreloader(false)
        }else if(this.props.manageAccess.getAllUSersFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.manageAccess.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getAllUserInit()
        }
    }

    checkGetRoles = () => {
        if(this.props.layout.isPreloader){
            if(this.props.manageAccess.getRolesSuccess){
                this.setState({
                    allRoles: this.props.manageAccess.allRoles
                })                
                this.props.getAllUser()
                this.props.getRolesInit() 
                // this.props.getAllUser()
                // this.props.changePreloader(false)
            }
            else if(this.props.manageAccess.getRolesFailure === true){
                this.props.changePreloader(false)
                toast.error(this.props.manageAccess.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getRolesInit()
            }
        }
    }

    checkGetUser = () => {
        if(this.props.manageAccess.getUserDetailsSuccess === true){
            this.setState({
                userDetailData : this.props.manageAccess.userDetails,
            }, ()=>{
                this.getLogs()
            })
            let roleData = {
                roleId: this.props.manageAccess.userDetails.roleId
            }
            this.props.getPermissions(roleData)
            this.props.changePreloader(false)
            this.props.getUserDetailsInit()
        }
        else if(this.props.manageAccess.getUserDetailsFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.manageAccess.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getUserDetailsInit()
        }
    }

    checkGetPermissions = () => {
        if(this.props.manageAccess.getPermissionsSuccess === true){
            this.setState({
                userPermissions: this.props.manageAccess.allPermissions,
                viewType: "Detail", 
            })
            this.props.changePreloader(false)
            this.props.getPermissionsInit()
        }
        else if(this.props.manageAccess.getPermissionsFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.manageAccess.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getPermissionsInit()
        }
    }

    checkGetPortalLogFilters(){
        if(this.props.customer.getPortalLogFiltersSuccess === true){
            this.setState({ allFilters : this.props.customer.logFilters}, 
                () => {this.setInitialFilter("admin")})
            this.props.getPortalLogFiltersInit()
        }
        else if(this.props.customer.getPortalLogFiltersFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.customer.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getPortalLogFiltersInit()
        }
    }

    checkGetPortalLogs(){
        if(this.props.customer.getPortalLogsSuccess === true){
            // this.setState({ portalLogs : this.props.customer.logDetails})
            let logDetails = this.props.customer.logDetails
            this.filterPortalLogs(logDetails)
            // console.log("Logs")
            this.props.getPortalLogsInit()
        }
        else if(this.props.customer.getPortalLogsFailure === true){
            this.failedLogs()
            // toast.error(this.props.customer.errorMessage, {
            //     position: "bottom-right",
            //     autoClose: 2000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            this.props.getPortalLogsInit()
        }
    }

    checkInviteUser = () => {
        if(this.props.layout.isPreloader){
            if(this.props.manageAccess.inviteUserSuccess ===true){   
                console.log("Showing Updated User", this.props.manageAccess.updatedUser)
                this.setState({
                    showMiniModal : false
                })  
                toast.success("Invite Sent Successfully.") 
                this.onClearAll()        
                this.props.getAllUser()
                this.props.inviteUserInit() 
                // this.props.getAllUser()
            }
            else if(this.props.manageAccess.inviteUserFailure === true){
                this.props.changePreloader(false)
                toast.error(this.props.manageAccess.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.inviteUserInit()
            }
        }
    }

    checkDeleteUser = () => {
        if(this.props.manageAccess.deleteUserSuccess ===true){   
            console.log("Showing Updated User", this.props.manageAccess.updatedUser)
            this.setState({
                showMiniModal : false
            })           
            this.props.getAllUser()
            this.props.deleteUserInit()
            toast.success("Admin User Deleted Successfully.") 
            // this.props.getAllUser()
            // this.props.changePreloader(false)
        }
        else if(this.props.manageAccess.deleteUserFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.manageAccess.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.deleteUserInit()
        }
    }

    checkEditUser = () => {
        if(this.props.manageAccess.editUserSuccess ===true){  
            this.setState({
                showEditModal : false
            })           
            this.props.getAllUser()
            this.props.editUserInit()
            toast.success("Admin User Edited Successfully.") 
            // this.props.getAllUser()
            // this.props.changePreloader(false)
        }
        else if(this.props.manageAccess.editUserFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.manageAccess.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.editUserInit()
        }
    }
    

    render(){
        let {name, email, validations, selectedRole, allRoles, allUsers, userDetailData, viewType, selectionRange, allFilters, logKey, portalLogs, showMiniModal, showEditModal,
            miniModalType, tempMiniModalDetails, editUserValues, userPermissions} = this.state

        // ==== Calling check methods ====
        this.checkGetAllUsers()
        this.checkGetRoles()
        this.checkGetUser()
        this.checkGetPortalLogFilters()
        this.checkGetPortalLogs()
        this.checkInviteUser()
        this.checkDeleteUser()
        this.checkEditUser()
        this.checkGetPermissions()

        return(
            <React.Fragment>
                <div className="page-content manageAccess-page">
                    <div className="actions-section">
                        <div className="actions-grid">
                            <InputBox type="text" label="Email ID*" key={`email`} name="emailId" value={email} onChange={(e) => { this.inputHandler(e) }} hasError={validations.email} />
                            <InputBox type="text" label="Name*" key={`seachText`} name="name" value={name} onChange={(e) => { this.inputHandler(e) }} hasError={validations.name} />

                            <DropdownList
                                className={((validations.selectedRole) ? 'select-error' : '')}
                                name='roleDropdown'
                                placeholder="Select Role*"
                                data={allRoles}
                                value={selectedRole}                                
                                textField="roleName"
                                valueField="roleId"
                                onChange={(e) => {
                                    this.selectDropdownValue(e)
                                }}
                            />

                            <Button name="inviteButton" onClick={this.inviteUserSelected} >Invite</Button>
                            <Button name="clearButton" onClick={this.onClearAll} >Clear</Button>

                        </div>
                    </div>

                    {
                        viewType === "List" ? 
                        (
                            <div className="table-section">
                                <div>
                                    <div className="custom-table-head">
                                        <div>User ID</div>
                                        <div>Name</div>
                                        <div>Role</div>
                                        <div>Email Id</div>
                                        <div>Actions</div>
                                    </div>
        
                                    {
                                        allUsers.length !== 0 ? allUsers.map((user, index)=>{
                                            return(
                                                <div className="table-row-section" >
                                                    <div className="custom-table-body">
                                                        <div className="table-content-padding">{user.userId}</div>
                                                        <div className="table-content-padding">{user.userName}</div>
                                                        <div className="table-content-padding">{user.roleName !== null ? user.roleName : '--'}</div>
                                                        <div className="table-content-padding">{user.email !== null ? user.email : '--'}</div>
                                                        <div>
                                                            <i className="fas fa-pencil-alt font-size-18 cur-pointer" onClick={()=>{this.toggleEditModal(user)}}></i>
                                                            <i className="fa fa-trash font-size-18 m-l-10 color-red cur-pointer" onClick={()=>{this.toggleMiniModal('delete', user)}}></i>
                                                            <i class="fas fa-share-square font-size-18 m-l-10 cur-pointer" onClick={()=>{this.getSpecificUser(user)}}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : 
                                        <div className="text-center"> No Records found </div>
                                        
                                    }
                                </div>
                            </div>
                        )
                        :
                        (
                            userDetailData !== null ? 
                                <div className="grid-box">
                                    <div className="detail-section">
                                        <div>
                                            <img className="select-hover back-btn" src={detailBack} alt="Lets go back" onClick={this.jumpToListView} />
                                        </div>
                                        <div className="equal-grid">
                                            <div>
                                                <p className="font-size-18">Name: <span>{userDetailData.userName}</span></p>
                                                <p className="font-size-18">Email: <span className="word-break-all">{userDetailData.email}</span></p>
                                                <p className="font-size-18">User Id: <span>{userDetailData.userId}</span></p>
                                            </div>
                                            <div>
                                                <p className="font-size-18">Role Assigned: <span>{userDetailData.roleName}</span></p>
                                                <p className="font-size-18">Role Id: <span>{userDetailData.roleId}</span></p>
                                            </div>
                                             {/* <div>
                                                <p className="font-size-18">Name: <span>Coconut</span></p>
                                                <p className="font-size-18">Email: <span>Coconut</span></p>
                                                <p className="font-size-18">User Id: <span>Coconut</span></p>
                                            </div>
                                            <div>
                                                <p className="font-size-18">Role Assigned: <span>Coconut</span></p>
                                                <p className="font-size-18">Role Id: <span>Coconut</span></p>
                                            </div> */}
                                        </div>
                                        <div className="details-permissions-listing">
                                            <div>Permissions Assigned:</div>
                                            <ul>
                                                {
                                                    userPermissions.length !== 0 ? userPermissions.map((perm)=>{
                                                        return(
                                                            
                                                                perm.permissionFunctionality !== null ?
                                                                    <li>
                                                                        <b>{perm.permissionFunctionality}</b>
                                                                    </li>
                                                                    :
                                                                    ''
                                                            
                                                        )
                                                    })
                                                    :
                                                    ''
                                                }
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <ActivityComp key={1} logList={activeLogList} updateList={()=>{this.updateLogList()}}/> */}
                                    
                                    <div className="second-child">
                                        <div className="head-section">
                                            <div className="title-logo-div">
                                                <i className="fas fa-wave-square icon-sizing"></i>
                                                <p className="large-header-text text-muted ml-20">Portal Activity Log</p>
                                            </div>
                                            <div className="setting-icons-div">
                                                <div className="fas fa-file-download select-hover" onClick={()=>{this.downloadLogsCaller()}}></div>
                                                <div id="filtersDiv" className="fas fa-filter select-hover"></div>
                                                <div id="datePickerDiv" className="fas fa-calendar-alt select-hover"></div>
                                                <UncontrolledPopover trigger="legacy" placement="bottom-end" target="datePickerDiv" isOpen={this.state.datePopperOpen} toggle={()=>{this.toggleLogsPopover("date")}}>
                                                    <PopoverBody className="popper-width-inc">
                                                        <DateRange
                                                            date={new Date()}
                                                            rangeColors="#3d91ff"
                                                            onChange={(val)=>{this.setSelectedDate(val)}}
                                                            ranges={[selectionRange]}
                                                            maxDate={new Date()}
                                                        />
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                                <UncontrolledPopover trigger="legacy" placement="bottom-end" target="filtersDiv" isOpen={this.state.filterPopperOpen} toggle={()=>{this.toggleLogsPopover("filter")}}>
                                                    <PopoverBody className="popper-width-inc">
                                                        <div className="font-fourteen">
                                                            {
                                                                allFilters.map((filter, index) => {
                                                                    return(
                                                                        <div>
                                                                            <input className="filter-checkbox" type="checkbox" onChange={()=>{this.changeFilter(filter, index)}} value={filter.isChecked ? 1 : 0} checked={filter.isChecked}/>&nbsp;
                                                                            <label>{filter.permissionDesc}</label>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                            </div>
                                        </div>
                                        <ActivityComp key={logKey} logList={portalLogs} updateList={()=>{this.updateLogList()}} />
                                    </div>
                                </div>
                            :
                            ''
                        )
                    }
                    
                </div>
                <Modal
                    isOpen={showMiniModal}
                    toggle={()=>{this.toggleMiniModal()}}
                    className="modal-md m-t-12 manageAccess-page"
                >
                    {
                        miniModalType === 'delete' ? 
                            <div className="m-t-10 m-b-ten modal-padding">
                                <center>
                                    <div className="font-size-24">Are you sure you wish to</div>
                                    <div className="font-size-24 m-b-five word-break-all"><b>Delete</b> {tempMiniModalDetails !== null ? tempMiniModalDetails.userName : ''}</div>                        
                                    
                                    <Button onClick={()=>{this.initiateUserDelete(tempMiniModalDetails)}}>Yes, Delete</Button>
                                    &nbsp; &nbsp;
                                    <Button onClick={()=>{this.toggleMiniModal()}}>No</Button>
                                </center>
                            </div>
                        :
                            <div className="m-t-10 m-b-ten modal-padding">
                                <center>
                                    <div className="font-size-20 m-b-three">Please confirm the Details before sending an Invitation</div>
                                    <div className="font-size-17 word-break-all">Name: <b>{name}</b></div>
                                    <div className="font-size-17 work-break-all">Email: <b>{email}</b></div>
                                    <div className="font-size-17 m-b-five">Role: <b>{selectedRole.roleName}</b></div>

                                    <Button onClick={this.initiateInviteUser}>Send Invite</Button>
                                    &nbsp; &nbsp;
                                    <Button onClick={()=>{this.toggleMiniModal()}}>Edit Details</Button>
                                </center>
                            </div>
                    }

                </Modal>

                <Modal
                    isOpen={showEditModal}
                    toggle={()=>{this.toggleEditModal()}}
                    className="modal-md m-t-12 manageAccess-page"
                    >
                    <div className="m-t-10 m-b-ten modal-padding">
                        <div>

                            <div className="font-size-17 edit-modal-grid m-b-five">
                                <div>Name: </div>
                                <div className="word-break-all"><b>{editUserValues !== null ? editUserValues.userName : "--"}</b></div>
                            </div>

                            <div className="font-size-17 edit-modal-grid m-b-five">
                                <div>Email: </div>
                                <div className="word-break-all"><b>{editUserValues !== null ? editUserValues.email : "--"}</b></div>
                            </div>

                            <div className="font-size-17 edit-modal-grid m-b-five">
                                <div>Role: </div>
                                <DropdownList
                                    name='roleDropdown'
                                    placeholder="Select Role*"
                                    data={allRoles}
                                    value={editUserValues !== null ? editUserValues.mappedRole : null}                                
                                    textField="roleName"
                                    valueField="roleId"
                                    onChange={(e) => {
                                        this.selectEditDropdownValue(e)
                                    }}
                                />
                            </div>

                            <center>
                                <Button onClick={()=>{this.initiateUserEdit(editUserValues)}}>Edit Admin</Button>
                                &nbsp; &nbsp;
                                <Button onClick={()=>{this.toggleEditModal()}}>Cancel</Button>
                            </center>

                        </div>
                    </div>
                </Modal>
                <ToastContainer/>
            </React.Fragment>
        )
    }
}

// export default ManageAccess;
const mapStateToProps = state => ({
    layout: state.Layout,
    manageAccess: state.ManageAccess,
    customer: state.Customer
})

const mapDispatchToProps = dispatch => ({

    getRoles: (data) => dispatch(ManageAccessActions.getRoles(data)),
    getRolesInit: () => dispatch(ManageAccessActions.getRolesInit()),

    getAllUser: (data) => dispatch(ManageAccessActions.getAllUser(data)),
    getAllUserInit: () => dispatch(ManageAccessActions.getAllUserInit()),

    getUserDetails: (data) => dispatch(ManageAccessActions.getUserDetails(data)),
    getUserDetailsInit: () => dispatch(ManageAccessActions.getUserDetailsInit()),

    editUser: (data) => dispatch(ManageAccessActions.editUser(data)),
    editUserInit: () => dispatch(ManageAccessActions.editUserInit()),

    deleteUser: (data) => dispatch(ManageAccessActions.deleteUser(data)),
    deleteUserInit: () => dispatch(ManageAccessActions.deleteUserInit()),

    inviteUser: (data) => dispatch(ManageAccessActions.inviteUser(data)),
    inviteUserInit: () => dispatch(ManageAccessActions.inviteUserInit()),

    getPermissions: (data) => dispatch(ManageAccessActions.getPermissions(data)),
    getPermissionsInit: () => dispatch(ManageAccessActions.getPermissionsInit()),

    getPortalLogs : (data) => dispatch(CustomerActions.getPortalLogs(data)),
    getPortalLogsInit : () => dispatch(CustomerActions.getPortalLogsInit()),

    getPortalLogFilters : (data) => dispatch(CustomerActions.getPortalLogFilters(data)),
    getPortalLogFiltersInit : () => dispatch(CustomerActions.getPortalLogFiltersInit()),

    changePreloader: (flag) => dispatch(LayoutActions.changePreloader(flag)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageAccess)