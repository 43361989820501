import API from './interceptor';
import constants from '../common/constants'
const urlDevicePrefix = constants.urlDevicePrefix
const urlPrefix = constants.urlPrefix

export function addProductCategory(vendorId, payload) {
    return API({
        method: 'POST',
        url: urlDevicePrefix+`vendors/${vendorId}/productCategory`,
        data: payload
            // params: { vendorId: 1}
    });
}

export function searchCategory(vendorId) {
    return API({
        method: 'GET',
        // url: `vendors/${vendorID}/productCategory`,
        url: urlDevicePrefix+`vendors/productCategory`,
        params: { vendorId: vendorId }
    });
}

export function searchCategoryByName(payload){
    return API({
        method: 'GET',
        // url: `vendors/${vendorID}/productCategory`,
        url: urlDevicePrefix+`vendors/productCategory/search`,
        params : payload
    });
}

export function deleteCategory(vendorID, productCategoryID) {
    return API({
        method: 'DELETE',
        url: urlDevicePrefix+`vendors/${vendorID}/productCategory/${productCategoryID}`,
        params: { vendorId: vendorID, producCategoryId: productCategoryID }
    });
}

export function editCategory(vendorID, categoryID, data) {
    console.log("This is inside the API !!!")
    return API({
        method: 'PUT',
        url: urlDevicePrefix+`vendors/${vendorID}/productCategory/${categoryID}`,
        data: JSON.stringify(data)
    });
}