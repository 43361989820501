import React, { Component } from "react";
import { Container, Card, CardBody, CardTitle, PopoverBody, UncontrolledPopover, Button } from "reactstrap";
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Calendar, DateRange } from 'react-date-range'
import { changePreloader  } from '../../store/layout/actions';
import {Tabs, Tab, TabPanel, TabList} from 'react-web-tabs'
import moment from 'moment';
import DropdownList from 'react-widgets/lib/DropdownList';
import Combobox from 'react-widgets/lib/Combobox'
import { createAndDownloadBlobFile } from '../../helpers/downloadHelper';
import InputBox from "../../components/Common/input-box";
import TextBox from "../../components/Common/text-box"
import { SvgIcon } from "@material-ui/core";
import { uniq } from "lodash";
import * as _ from 'lodash'


class AddPromo extends Component {

    constructor(props){
        super(props)
        this.state = {
            viewOptions : "add",    // ==== Enum : add | edit | view ====

            promoCodeType : "generic", // ==== Enum : generic | unique ====
            codeDetails : {
                codeKeyword : "",
                description : ''
            },
            genericDetails : {
                planEligible : {},
                claimLimit : '',
                customPlanEligible: {}
                
            },
            uniqueDetails : {
                email : {userId : 0, emailId : ''},
                serialNo : '',
            },
            newSerialNo : '',
            startDate : new Date(),
            endDate : new Date(),
            endDateEligibility : false,
            discount : "",
            uniqueCodeType : ["Discount", "Migrate"],
            selectedUniqueCode : null,
            eligiblePlanDetails : {},

            validationCheck : {
                codeKeyword : false,
                description : false,
                planEligible : false,
                discount : false,
                claimLimit : false,
                codeType : false,
                email : false,
                serialNo : false,
                newSerialNo : false,
                uniqueCodeType : false,
                customPlanEligible: false,
            },

            emailBusy : false,
            serialBusy : false,

            emailList : [],
            deviceList : [],
            email : {userId : 0, emailId : ''},
            serialNo : '',

            viewBasePromoId : null
        }
    }

    componentDidMount(){
        this.generatePlanEligibility()

    }

    initializeViewMode(){
        let {viewOptions, promoCodeType, codeDetails, genericDetails, uniqueDetails, startDate, email, serialNo, newSerialNo, selectedUniqueCode, discount, endDateEligibility, endDate } = this.state
        let allPromoDetails = this.props.promoInfo

        viewOptions = "view"
        allPromoDetails.isUniquePromoCode === 0 ? promoCodeType = "generic" : promoCodeType = "unique"
        codeDetails.codeKeyword = allPromoDetails.promoCodeName
        codeDetails.description = allPromoDetails.promoCodeDescription
        genericDetails.claimLimit = allPromoDetails.claimLimit
        startDate = new Date(allPromoDetails.startDate)
        if(allPromoDetails.endDate !== null){
            endDate = new Date(allPromoDetails.endDate)
            endDateEligibility = true
        }


        if(allPromoDetails.isUniquePromoCode === 0){
            // allPromoDetails.promoDays.map((value)=>{
            //     genericDetails.planEligible[value] = true
            // })

            allPromoDetails.planIds.map((value)=>{
                genericDetails.customPlanEligible[value.planId].selected = true
            })
            discount = allPromoDetails.discount
        }
        else{
            email = {userId : allPromoDetails.userId, emailId : allPromoDetails.userEmail}
            serialNo = {serialNumber : allPromoDetails.oldSerialNo}

            if(allPromoDetails.promoCodeType === "migration"){
                selectedUniqueCode = "Migrate"
                newSerialNo = allPromoDetails.newSerialNo
            }else{
                selectedUniqueCode = "Discount"
                discount = allPromoDetails.discount
            }
        }

        this.setState({
            viewOptions : viewOptions,
            promoCodeType : promoCodeType,
            codeDetails : codeDetails,
            genericDetails : genericDetails, 
            email : email,
            serialNo : serialNo,
            newSerialNo : newSerialNo,
            selectedUniqueCode : selectedUniqueCode,
            discount : discount,
            viewBasePromoId : allPromoDetails.promoCodeId,
            startDate : startDate,
            endDate : endDate,
            endDateEligibility : endDateEligibility
        })
    }

    generatePlanEligibility(){
        let {genericDetails} = this.state
        let recordingDaysObj = this.props.recordingDays
        let allPlans = this.props.allPlanList
        let recordingDaysList = Object.keys(recordingDaysObj)
        // recordingDaysList.map((value)=>{
        //     genericDetails.planEligible[value] = false
        // })

        allPlans.map((value)=>{
            if(value.isTrialPlan !== 1 && value.isPromotionalEligible !== 0){
                genericDetails.customPlanEligible[value.planId] = {
                    planName: `${value.planName} - ${value.recordingDays} Days`,
                    selected: false
                }
            }
        })

        this.setState({
            genericDetails : genericDetails,
            eligiblePlanDetails : recordingDaysObj
        },()=>{
            if(this.props.viewType === "view"){
                this.initializeViewMode()
            }
        })
    }

    switchCodeType (codeType){
        if(this.state.viewOptions === "view"){
            return
        }
        this.setState({ 
            promoCodeType : codeType,
            validationCheck : {
                codeKeyword : false,
                description : false,
                planEligible : false,
                discount : false,
                claimLimit : false,
                codeType : false,
                email : false,
                serialNo : false,
                newSerialNo : false,
                uniqueCodeType : false,
            },
        })
    }
    

    submitDetails(){
        let {promoCodeType, codeDetails, genericDetails, newSerialNo, startDate, endDate, discount, uniqueCodeType, selectedUniqueCode, eligiblePlanDetails, validationCheck, 
            email, serialNo, viewOptions, endDateEligibility} = this.state

        let detailData = {
            claimLimit: 1,
            discount: 0,
            endDate: null,
            endUserId: 0,
            inputPlanIdsDto: [],
            newSerialNo: null,
            oldSerialNo: null,
            promoCodeDescription: "",
            promoCodeName: "",
            startDate: startDate
          }

        let paramDetails = {
            codeType : 0,
            type : ""
        }
        let validationFail = false

        let tempCodeKeywordTrim = codeDetails.codeKeyword.trim()
        let tempCodeDescTrim = codeDetails.description.trim()
        let tempDiscountTrim = discount.toString().trim()
        let tempClaimTrim = genericDetails.claimLimit.toString().trim()

        if(tempCodeKeywordTrim === ""){
            validationCheck.codeKeyword = true
            validationFail = true
        }
        if(tempCodeDescTrim === ""){
            validationCheck.description = true
            validationFail = true
        }
        detailData.promoCodeName = tempCodeKeywordTrim
        detailData.promoCodeDescription = tempCodeDescTrim

        if(endDateEligibility){
            detailData.endDate = endDate
        }

        console.log(detailData)
        // return

        // ==== Check for Types ====
        if(promoCodeType === "generic"){
            if(tempDiscountTrim === ""){
                validationCheck.discount = true
                validationFail = true
            }

            if(tempClaimTrim === ""){
                validationCheck.claimLimit = true
                validationFail = true
            }

            let eligibleSafetyCheckFail = true
            // let allEligibilities = Object.keys(genericDetails.planEligible)
            // let eligibleRecordingDays = []
            // for (let x of allEligibilities){
            //     console.log(genericDetails.planEligible[x])
            //     if(genericDetails.planEligible[x] === true){
            //         eligibleRecordingDays.push(x)                    
            //         eligibleSafetyCheckFail = false
            //         // break
            //     }
            // }
            // console.log("shifting!!", allEligibilities)
            // console.log("Fitting", genericDetails.planEligible)

            // if(eligibleSafetyCheckFail === true){
            //     validationCheck.planEligible = true
            //     validationFail = true
            // }



            // ==== Starting custom eligibile plans ====

            let customFinalPlanList = []
            Object.keys(genericDetails.customPlanEligible).map((value)=>{
                if(genericDetails.customPlanEligible[value].selected === true){
                    customFinalPlanList.push({
                        planId: value
                    })
                    eligibleSafetyCheckFail = false
                }
            })

            if(eligibleSafetyCheckFail === true){
                validationCheck.planEligible = true
                validationFail = true
            }
            // ==== Ending eligible plans check ====



            if(validationFail){
                this.setState({ validationCheck : validationCheck })
                return
            }

            // ==== building planEligible data ====
            // let finalPlanList = []
            //     for(let x of eligibleRecordingDays){
            //     finalPlanList = [...finalPlanList, ...eligiblePlanDetails[x]]
            // }
            // let planSet = _.uniqWith(finalPlanList, _.isEqual)
            // finalPlanList = [...planSet]


            // ==== building details ====
            detailData.discount = tempDiscountTrim
            detailData.claimLimit = tempClaimTrim
            // detailData.inputPlanIdsDto = finalPlanList
            detailData.inputPlanIdsDto = customFinalPlanList
            paramDetails.codeType = 1
            delete paramDetails.type
        }
        else{
            if(selectedUniqueCode === null){
                validationCheck.uniqueCodeType = true
                validationFail = true
            }
            else if(selectedUniqueCode === "Migrate"){

                // ==== Check how to verify email ====
                if(email.emailId === '' || email.userId === 'na'){
                    validationFail = true
                    validationCheck.email = true
                }

                if(serialNo === ''){
                    validationCheck.serialNo = true
                    validationFail = true
                }
                if(newSerialNo === ''){
                    validationCheck.newSerialNo = true
                    validationFail = true
                }

                if(validationFail){
                    this.setState({ validationCheck : validationCheck })
                    return
                }

                detailData.endUserId = email["userId"]
                detailData.oldSerialNo = serialNo.serialNumber
                detailData.newSerialNo = newSerialNo
                paramDetails.codeType = 2
                paramDetails.type = "migration"

            }
            else if(selectedUniqueCode === "Discount"){
                
                // ==== Check how to verify email ====
                if(email.emailId === '' || email.userId === 'na'){
                    validationFail = true
                    validationCheck.email = true
                }

                if(serialNo === ''){
                    validationCheck.serialNo = true
                    validationFail = true
                }
                if(tempDiscountTrim === ""){
                    validationCheck.discount = true
                    validationFail = true
                }

                if(validationFail){
                    this.setState({ validationCheck : validationCheck })
                    return
                }

                detailData.endUserId = email["userId"]
                detailData.oldSerialNo = serialNo.serialNumber
                detailData.discount = discount
                paramDetails.codeType = 2
                paramDetails.type = "discount"
            }

            if(validationFail){
                this.setState({ validationCheck : validationCheck })
                return
            }
        }

        console.log(detailData)
        console.log(paramDetails)

        let details = {
            details : detailData,
            params : paramDetails
        }

        if(viewOptions === 'edit'){
            this.props.updatePromoCallback(details, this.props.promoInfo.promoCodeId)
        }else{
            this.props.newPromoCallback(details)
        }        

        // console.log(details)
    }


    inputHandler(e){
        if(this.state.viewOptions === "view"){
            return
        }
        let { validationCheck, codeDetails, genericDetails, newSerialNo, discount, uniqueDetails } = this.state

        if(e.target.name === "promoKeyword"){
            if(e.target.value.length > 10){
                return
            }
            codeDetails.codeKeyword = e.target.value
            validationCheck.codeKeyword = false
            this.setState({ codeDetails : codeDetails, validationCheck : validationCheck })
        }
        else if(e.target.name === "promoDescription"){
            codeDetails.description = e.target.value
            validationCheck.description = false
            this.setState({ codeDetails : codeDetails, validationCheck : validationCheck })
        }
        else if(e.target.name === "discountValue"){
            let regexValue = new RegExp(/^\d*$/)
            if(!regexValue.test(e.target.value) || parseInt(e.target.value) < 1 || parseInt(e.target.value) > 100 ){
                return
            }
            discount = e.target.value
            validationCheck.discount = false
            this.setState({ discount : discount, validationCheck : validationCheck })
        }
        else if(e.target.name === "claimLimit"){
            let regexValue = new RegExp(/^\d*$/)
            if(!regexValue.test(e.target.value) || parseInt(e.target.value) < 1 || parseInt(e.target.value) > 100000000 ){
                return
            }
            genericDetails.claimLimit = e.target.value
            validationCheck.claimLimit = false
            this.setState({ genericDetails : genericDetails, validationCheck : validationCheck })
        }
        else if(e.target.name === "serialNo"){
            uniqueDetails.serialNo = e.target.value
            validationCheck.serialNo = false
            this.setState({ uniqueDetails : uniqueDetails, validationCheck : validationCheck })
        }
        else if(e.target.name === "email"){            
            uniqueDetails.email = e.target.value
            // validationCheck.email = false
            // this.setState({uniqueDetails : uniqueDetails, validationCheck : validationCheck })
        }
        else if(e.target.name === "newSerialNo"){
            newSerialNo = e.target.value
            validationCheck.newSerialNo = false
            this.setState({ newSerialNo : newSerialNo, validationCheck : validationCheck })
        }

    }

    comboBoxInputHandler(e){    
        if(this.state.viewOptions === "view"){
            return
        }
        if(typeof(e) !== "string"){
            return
        }
        let { email, emailBusy} = this.state
        email.emailId = e
        email.userId = 0
        this.setState({
            email : email,
            serialNo : ''
        })
        if(e.length >= 3){
            this.setState({emailBusy : true})
            this.props.searchUser(e)
        }
    }

    userSelect(e){
        if(this.state.viewOptions === "view"){
            return
        }
        let {validationCheck} = this.state
        console.log("PRINTING FROM USER SELECT")
        console.log(e)
        validationCheck.email = false
        if(e.userId === "na"){
            this.setState({ email : e })
            return
        }
        this.setState({
            email : e,
            serialBusy : true,
            deviceList : [],
            serialNo : '',
            validationCheck : validationCheck

        })
        this.props.searchDevices(e.userId)
    }

    selectSerialNo(e){
        let {validationCheck} = this.state
        validationCheck.serialNo = false
        this.setState({
            serialNo : e,
            validationCheck : validationCheck
        })
    }

    dateHandler(dateType, date){
        let {startDate, endDate, endDateEligibility} = this.state
        if(dateType === "startDate"){
            this.setState({ startDate : date}, ()=>{console.log("startDate: ", this.state.startDate)})
        }
        else if(dateType === "endDate"){
            if(endDateEligibility === false){
                endDateEligibility = true
            }
            this.setState({
                endDate : date,
                endDateEligibility : endDateEligibility
            })
            
        }

    }

    selectUniqueCodeType(type){
        let {uniqueDetails } = this.state
        uniqueDetails.email = ""
        uniqueDetails.serialNo = ""
        this.setState({ uniqueDetails : uniqueDetails, selectedUniqueCode : type})
    }

    checkPlanEligibility(eligibilityValue){
        if(this.state.viewOptions === "view"){
            return
        }

        let { genericDetails, validationCheck } = this.state
        // genericDetails.planEligible[eligibilityValue] = !genericDetails.planEligible[eligibilityValue]
        // validationCheck.planEligible = false
        // this.setState({genericDetails : genericDetails, validationCheck : validationCheck})

        genericDetails.customPlanEligible[eligibilityValue].selected = !genericDetails.customPlanEligible[eligibilityValue].selected
        validationCheck.customPlanEligible = false
        this.setState({genericDetails : genericDetails, validationCheck : validationCheck})
    }



    // ==== Reference callable Methods ====
    updateEmailList(newEmailList){
        this.setState({
            emailList : newEmailList,
            emailBusy : false
        })
    }

    updateDeviceList(newDeviceList){        
        this.setState({
            deviceList : newDeviceList,
            serialBusy : false
        })
    }

    render(){
        let { promoCodeType, codeDetails, genericDetails, validationCheck, uniqueCodeType, uniqueDetails, selectedUniqueCode, newSerialNo, startDate, endDate, endDateEligibility, 
            discount, emailList, deviceList, emailBusy, email, serialBusy, serialNo, viewOptions} = this.state

        let optionArr = ["20%", "30%", "50%"]
        

        return (
            <React.Fragment>
                <div className="subscription-page">
                    <div className="individual-block">
                        <div className="modal-body-frame">
                            <div>
                            
                                {/* <div className="subscription-plan-list"><span>New Promo Code Details</span></div> */}

                                {
                                    viewOptions === "add" ? 
                                        <div className="m-b-three">
                                            <input type="radio" className="filter-checkbox" name="genericPromo" value={0} checked={promoCodeType === "generic"} onClick={()=>{this.switchCodeType("generic")}}/>&nbsp;
                                            <label className="font-fourteen" onClick={()=>{this.switchCodeType("generic")}}>Generic Promo Code</label>
                                            &nbsp; &nbsp; &nbsp; &nbsp;
                                            <input type="radio" className="filter-checkbox" name="UniquePromo" value={0} checked={promoCodeType === "unique"} onClick={()=>{this.switchCodeType("unique")}}/>&nbsp;
                                            <label className="font-fourteen" onClick={()=>{this.switchCodeType("unique")}}>Unique Promo Code</label>
                                            <br></br>       
                                            {
                                                promoCodeType !== "generic" ? <div><span><em>A Unique Promo Code can only be used once</em></span></div> : ''
                                            }                     
                                        </div> :
                                    
                                        <div className="m-b-three">
                                            <div className="font-sixteen"><span><b>{promoCodeType === "generic" ? "Generic Promo Code" : "Unique Promo Code"}</b></span></div>
                                        </div>
                                }

                                <InputBox className="m-b-three" type="text" label="Promo Code Keyword*" name="promoKeyword" value={codeDetails.codeKeyword} key={`promoKeyword`} onChange={(e)=>{this.inputHandler(e)}} hasError={validationCheck.codeKeyword} disabled={viewOptions === "view" ? true : false} />

                                <TextBox rows="4" cols="50" className="m-b-three height-auto" type="text" label="Promo Code Description*" name="promoDescription" value={codeDetails.description} key={`promoDescription`} onChange={(e)=>{this.inputHandler(e)}} hasError={validationCheck.description} disabled={viewOptions === "view" ? true : false} />

                                {
                                    promoCodeType === "generic" ? 
                                        <div>                    

                                            <InputBox className="m-b-three" type="text" label="Discount(%) *" name="discountValue" value={discount} key={`promoDiscount`} onChange={(e)=>{this.inputHandler(e)}} hasError={validationCheck.discount} disabled={viewOptions === "view" ? true : false}/>


                                            <div className="custom-group-body">
                                                <div className="custom-group-header"><span>Eligibility</span></div>

                                                <div className="m-b-three">

                                                    <div className="m-b-three">
                                                        <div className="sectional-grid-level-1">
                                                            <div className="font-fourteen"><span>Start Date : {moment(startDate).format("DD/MM/YYYY")} &nbsp; &nbsp; <i id="startDate-picker-icon-01" class="far fa-calendar-alt"></i></span></div>
                                                            <div className="font-fourteen"><span> End Date : &nbsp; {endDateEligibility ? moment(endDate).format("DD/MM/YYYY") : "--" }
                                                                &nbsp; &nbsp;
                                                                <i id="endDate-picker-icon-01" class="far fa-calendar-alt"></i> {endDateEligibility ? <i class="far fa-trash-alt" onClick={()=>{viewOptions === "view" ? console.log() : this.setState({endDateEligibility : false})}}></i> : ''} </span></div>
                                                        </div>
                                                            <UncontrolledPopover trigger="legacy" placement="bottom-start" target="startDate-picker-icon-01" disabled={viewOptions === "view" ? true : false} >
                                                                    <PopoverBody className="popper-width-inc">
                                                                        <Calendar
                                                                            date={startDate}
                                                                            onChange={(date)=>{this.dateHandler("startDate", date)}}
                                                                            color="#3d91ff"
                                                                            minDate={new Date()}                    
                                                                        />
                                                                    </PopoverBody>
                                                            </UncontrolledPopover>

                                                            <UncontrolledPopover trigger="legacy" placement="bottom-start" target="endDate-picker-icon-01" disabled={viewOptions === "view" ? true : false} >
                                                                    <PopoverBody className="popper-width-inc">
                                                                        <Calendar
                                                                            date={endDate}
                                                                            onChange={(date)=>{this.dateHandler("endDate", date)}}
                                                                            color="#3d91ff"
                                                                            minDate={startDate}
                                                                        />
                                                                    </PopoverBody>
                                                            </UncontrolledPopover>
                                                    </div> 

                                                    <div className="m-b-two  font-fourteen"><span>Plan Eligibility* :</span></div>
                                                    {
                                                        validationCheck.planEligible ? <div className="m-b-two action-validation-text"><span>Please Select Plan Eligibility</span></div> : ''
                                                    }

                                                    <div >
                                                    {/* {
                                                        Object.keys(genericDetails.planEligible).map((value)=>{
                                                            return(
                                                                <div>
                                                                    <input type="checkbox" className="filter-checkbox checkbox-input" name={value + "days"} checked={genericDetails.planEligible[value]} onChange={()=>{this.checkPlanEligibility(value)}} />&nbsp;
                                                                    <label className="font-fourteen" onClick={()=>{this.checkPlanEligibility(value)}}>{value} Days Recording</label>
                                                                    <br></br>
                                                                </div>
                                                            )
                                                        })
                                                    } */}

                                                    {
                                                        Object.keys(genericDetails.customPlanEligible).map((value)=>{
                                                            return(
                                                                <div>
                                                                    <input type="checkbox" className="filter-checkbox checkbox-input" name={value + "days"} checked={genericDetails.customPlanEligible[value].selected} onChange={()=>{this.checkPlanEligibility(value)}} />&nbsp;
                                                                    <label className="font-fourteen" onClick={()=>{this.checkPlanEligibility(value)}}>{genericDetails.customPlanEligible[value].planName}</label>
                                                                    <br></br>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    </div>
                                                </div>


                                                <InputBox className="m-b-three" type="text" label="Claim Limit (numbers)*" name="claimLimit" value={genericDetails.claimLimit} key={`claimLimit`} onChange={(e)=>{this.inputHandler(e)}} hasError={validationCheck.claimLimit} disabled={viewOptions === "view" ? true : false} />
                                            </div>
                                            
                                        </div> : 

                                        <div>
                                        
                                            <div className="m-b-three">
                                                <div className="m-b-two  font-fourteen"><span>Select Type of Code* : </span></div>
                                                <DropdownList
                                                    className={"t-a-l " + ((validationCheck.uniqueCodeType) ? "select-error" : "")}
                                                    placeholder="Type of Code"
                                                    data={uniqueCodeType}
                                                    value={selectedUniqueCode}
                                                    disabled={viewOptions === "view" || viewOptions === "edit" ? true : false}
                                                    // defaultValue={searchType}
                                                    textField="filterName"
                                                    valueField="filterName"
                                                    onChange={(e) => {
                                                        this.selectUniqueCodeType(e)
                                                    }}                                
                                                />
                                            </div>
                                            
                                            {
                                                selectedUniqueCode !== null ? selectedUniqueCode === "Discount" ? 


                                                    <div className="custom-group-body">
                                                        <div className="custom-group-header"><span>Unique Discount Code</span></div>

                                                        <div>
                                                            {/* <InputBox className="m-b-three" type="text" label="Enter Email*" name="email" value={uniqueDetails.email} key={`email`} onChange={(e)=>{this.inputHandler(e)}} hasError={validationCheck.email} /> */}

                                                            <div className="m-b-three">
                                                                <div className="m-b-two  font-fourteen"><span>Select Type* : </span></div>
                                                                <Combobox
                                                                    className={"t-a-l " + ((validationCheck.email) ? "select-error" : "")}
                                                                    placeholder="Search User's Email"
                                                                    data={emailList}
                                                                    busy={emailBusy}
                                                                    // defaultValue={searchType}
                                                                    disabled={viewOptions === "view" ? true : false}
                                                                    name="email"
                                                                    value={email}
                                                                    textField="emailId"
                                                                    valueField="userId"
                                                                    onChange={(e) => {
                                                                        this.comboBoxInputHandler(e)
                                                                    }}
                                                                    onSelect={(e)=>{
                                                                        this.userSelect(e)                                            
                                                                    }}                            
                                                                />
                                                            </div>

                                                            {/* <InputBox className="m-b-three" type="text" label="Device Serial Number*" name="serialNo" value={uniqueDetails.serialNo} key={`serialNo`} onChange={(e)=>{this.inputHandler(e)}} hasError={validationCheck.serialNo} /> */}

                                                            <div className="m-b-three">
                                                            <div className="m-b-two  font-fourteen"><span>Select Device Serial Number* : </span></div>
                                                            <DropdownList
                                                                className={"t-a-l " + ((validationCheck.serialNo) ? "select-error" : "")}
                                                                placeholder="Device Serial Number"
                                                                data={deviceList}
                                                                busy={serialBusy}
                                                                value={serialNo}
                                                                disabled={viewOptions === "view" ? true : false}
                                                                // defaultValue={searchType}
                                                                textField="serialNumber"
                                                                valueField="filterName"
                                                                onChange={(e) => {
                                                                    this.selectSerialNo(e)
                                                                }}                                
                                                            />
                                                        </div>

                                                            <div className="m-b-three">
                                                                <div className="sectional-grid-level-1">
                                                                    <div className="font-fourteen"><span>Start Date : {moment(startDate).format("DD/MM/YYYY")} &nbsp; &nbsp; <i id="startDate-picker-icon-02" class="far fa-calendar-alt"></i></span></div>
                                                                    <div className="font-fourteen"><span> End Date : &nbsp; {endDateEligibility ? moment(endDate).format("DD/MM/YYYY") : "--" }
                                                                        &nbsp; &nbsp;
                                                                        <i id="endDate-picker-icon-02" class="far fa-calendar-alt"></i> {endDateEligibility ? <i class="far fa-trash-alt" onClick={()=>{viewOptions === "view" ? console.log() : this.setState({endDateEligibility : false})}}></i> : ''} </span></div>
                                                                </div>

                                                                    <UncontrolledPopover trigger="legacy" placement="bottom-start" target="startDate-picker-icon-02" disabled={viewOptions === "view" ? true : false} >
                                                                        <PopoverBody className="popper-width-inc">
                                                                            <Calendar
                                                                                date={startDate}
                                                                                onChange={(date)=>{this.dateHandler("startDate", date)}}
                                                                                color="#3d91ff"
                                                                                minDate={new Date()}                   
                                                                            />
                                                                        </PopoverBody>
                                                                    </UncontrolledPopover>

                                                                    <UncontrolledPopover trigger="legacy" placement="bottom-start" target="endDate-picker-icon-02" disabled={viewOptions === "view" ? true : false} >
                                                                        <PopoverBody className="popper-width-inc">
                                                                            <Calendar
                                                                                date={endDate}
                                                                                onChange={(date)=>{this.dateHandler("endDate", date)}}
                                                                                color="#3d91ff"
                                                                                minDate={startDate}
                                                                            />
                                                                        </PopoverBody>
                                                                    </UncontrolledPopover>

                                                            </div>

                                                            <InputBox className="m-b-three" type="text" label="Discount(%) *" name="discountValue" value={discount} key={`promoDiscount`} onChange={(e)=>{this.inputHandler(e)}} hasError={validationCheck.discount} disabled={viewOptions === "view" ? true : false} />

                                                        </div>
                                                    </div> : 

                                                    <div className="custom-group-body">
                                                        <div className="custom-group-header">Unique Migrate Code</div>

                                                        {/* <InputBox className="m-b-three" type="text" label="Enter Email*" name="email" value={uniqueDetails.email} key={`email`} onChange={(e)=>{this.inputHandler(e)}} hasError={validationCheck.email} /> */}

                                                        
                                                        <div className="m-b-three">
                                                            <div className="m-b-two  font-fourteen"><span>Select Email Address* : </span></div>
                                                            <Combobox
                                                                className={"t-a-l " + ((validationCheck.email) ? "select-error" : "")}
                                                                placeholder="Search User's Email"
                                                                data={emailList}
                                                                busy={emailBusy}
                                                                // defaultValue={searchType}
                                                                name="email"
                                                                value={email}
                                                                disabled={viewOptions === "view" ? true : false}
                                                                textField="emailId"
                                                                valueField="userId"
                                                                onChange={(e) => {
                                                                    this.comboBoxInputHandler(e)
                                                                }}
                                                                onSelect={(e)=>{
                                                                    this.userSelect(e)                                            
                                                                }}                            
                                                            />
                                                        </div>

                                                        {/* <InputBox className="m-b-three" type="text" label="Device Serial Number*" name="serialNo" value={uniqueDetails.serialNo} key={`serialNo`} onChange={(e)=>{this.inputHandler(e)}} hasError={validationCheck.serialNo} /> */}

                                                        <div className="m-b-three">
                                                            <div className="m-b-two  font-fourteen"><span>Select Device Serial Number* : </span></div>
                                                            <DropdownList
                                                                className={"t-a-l " + ((validationCheck.serialNo) ? "select-error" : "")}
                                                                placeholder="Device Serial Number"
                                                                data={deviceList}
                                                                busy={serialBusy}
                                                                value={serialNo}
                                                                disabled={viewOptions === "view" ? true : false}
                                                                // defaultValue={searchType}
                                                                textField="serialNumber"
                                                                valueField="filterName"
                                                                onChange={(e) => {
                                                                    this.selectSerialNo(e)
                                                                }}                                
                                                            />
                                                        </div>

                                                        <InputBox className="m-b-three" type="text" label="New Device Serial Number*" name="newSerialNo"  value={newSerialNo} key={`newSerialNo`} onChange={(e)=>{this.inputHandler(e)}} hasError={validationCheck.newSerialNo} disabled={viewOptions === "view" ? true : false} />

                                                    </div> : ''
                                            }

                                        </div>


                                }

                                <div className="button-grid-level-1">
                                    {/* ==== The Edit promo is disabled for now ==== */}
                                    {
                                        viewOptions === "view" ?   
                                            <Button name="editPromo" onClick={()=>{this.setState({viewOptions : 'edit'})}} disabled={false}>Edit Code</Button> : viewOptions === "edit" ? 
                                                <Button name="updatePromo" onClick={()=>{this.submitDetails()}} >Update Code</Button> : <Button name="createPromo" onClick={()=>{this.submitDetails()}} >Create Code</Button>
                                    }
                                    {/* <Button name="createPromo" onClick={()=>{this.submitDetails()}} >Create Code</Button> */}
                                    <Button name="Cancel" onClick={this.props.cancel}>Cancel</Button>
                                    {/* <Button name="Cancel" onClick={()=>{console.log(this.state.eligiblePlanDetails)}}>Cancel</Button> */}
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </React.Fragment>
        )
    }
}

export default AddPromo;