import React, { Component } from "react";
import { Card, CardBody, CardTitle, Media } from "reactstrap";
import { Link } from "react-router-dom";

class ActivityComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            portalLogs : []
        };
    }

    componentDidMount(){
        this.setState({
            portalLogs : this.props.logList
        })
        console.log("##### Printing from Activity log")
        console.log(this.props.logList)
    }

    scrollHandler(e){
        // console.log(e.target)
        // console.log(e.target.scrollHeight)
        // console.log(e.target.scrollTop)
        // console.log(e.target.clientHeight)

        let totalScrollCover = e.target.scrollTop + e.target.clientHeight
        if(totalScrollCover >= e.target.scrollHeight){
            console.log("Congratulations You're at the end. !!")
            this.props.updateList()
        }
    }

    render() {
        // let { portalLogs } = this.state
        let dummyArr = [...Array(4).keys()]
        console.log("Logs List from Component !!!!")
        console.log(this.props.logList)
        let portalLogs = this.props.logList
        return (
            <React.Fragment>
                <Card className="task-timeline-container" onScroll={(e) => {this.scrollHandler(e)}}>
                    <CardBody>  
                        <ul className="verti-timeline list-unstyled">
                        {
                            portalLogs.length !== 0 ? portalLogs.map((logValue) => {
                                return(
                                <li className="event-list">
                                    <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle font-size-18"></i>
                                    </div>
                                        <Media>
                                            <div className="mr-3">
                                                <h5 className="font-size-14 task-title">{logValue.eventDescription}</h5>
                                                <span className="font-size-14"><i>Status : { logValue.success ? "Successful" : "Failed" }</i></span><br></br>
                                                <span>Date : {logValue.displayDate}</span>

                                            </div>
                                        </Media>
                                </li>
                                )

                            }) :

                            <div className="m-t-5"><center><span>No Logs found.</span></center></div>
                            // dummyArr.map(()=>{
                            //     return(
                            //     <li className="event-list">
                            //         <div className="event-timeline-dot">
                            //             <i className="bx bx-right-arrow-circle font-size-18"></i>
                            //         </div>
                            //         <Media>
                            //             <div className="mr-3">
                            //                 <h5 className="font-size-14 task-title">Closed Service Ticket Number 12345 </h5>
                            //                 <span className="font-size-14"><i>Status : Successfull</i></span><br></br>
                            //                 <span>Date : 18 Feb 2020</span>

                            //             </div>
                            //         </Media>
                            //     </li>
                            //     )

                            // })

                        }


                            {/* <li className="event-list">
                                <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                                </div>
                                <Media>
                                    <div className="mr-3">
                                        <h5 className="font-size-14 task-title">Closed Service Ticket Number 12345 </h5>
                                        <span className="font-size-14"><i>Status : Successfull</i></span><br></br>
                                        <span>Date : 18 Feb 2020</span>

                                    </div>
                                </Media>
                            </li>

                            <li className="event-list">
                                <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                                </div>
                                <Media>
                                    <div className="mr-3">
                                        <h5 className="font-size-14 task-title">Closed Service Ticket Number 12345 </h5>
                                        <span className="font-size-14"><i>Status : Successfull</i></span><br></br>
                                        <span>Date : 18 Feb 2020</span>

                                    </div>
                                </Media>
                            </li>

                            <li className="event-list">
                                <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                                </div>
                                <Media>
                                    <div className="mr-3">
                                        <h5 className="font-size-14 task-title">Closed Service Ticket Number 12345 </h5>
                                        <span className="font-size-14"><i>Status : Successfull</i></span><br></br>
                                        <span>Date : 18 Feb 2020</span>

                                    </div>
                                </Media>
                            </li>
                            <li className="event-list">
                                <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                                </div>
                                <Media>
                                    <div className="mr-3">
                                        <h5 className="font-size-14 task-title">Closed Service Ticket Number 12345 </h5>
                                        <span className="font-size-14"><i>Status : Successfull</i></span><br></br>
                                        <span>Date : 18 Feb 2020</span>

                                    </div>
                                </Media>
                            </li> */}

                            
                        </ul>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default ActivityComp;
