import moment from 'moment';

export function convertUTCToTimezone(dateTimeString, timezone = 'GMT+05:30_Asia/Kolkata', formatted = false) {

    let dateString = dateTimeString.split(' ')[0];
    let timeStringWithTimezone = dateTimeString.split(' ')[1];
    let timeString = timeStringWithTimezone.split('.')[0];
    let responseDate = moment(new Date());

    let year = dateString.slice(0, 4);
    let month = dateString.slice(5, 7);
    let day = dateString.slice(8, 10);

    let timeArr = timeString.split(':');
    let hour = timeArr[0];
    let minutes = timeArr[1];
    let secs = timeArr[2];

    responseDate.set('year', year);
    responseDate.set('month', month - 1);
    responseDate.set('date', day);
    responseDate.set('hour', hour);
    responseDate.set('minute', minutes);
    responseDate.set('second', secs);
    // console.log('response Date', timeArr);
    // when camera device timezone is ahead of UTC
    if (timezone.indexOf('+') !== -1) {
        let offset = timezone.slice(timezone.indexOf('+'), timezone.indexOf('_'))
        let offsetHours = offset.slice(offset.indexOf('+') + 1, offset.indexOf(':'));
        let offsetMinutes = offset.slice(offset.indexOf(':') + 1, offset.length);

        responseDate.add(offsetHours, 'hours');
        responseDate.add(offsetMinutes, 'minutes');
        console.log('timezone', moment(responseDate).format('MMMM Do YYYY, HH:mm a'));
        if (formatted) {
            return moment(responseDate).format('MMMM Do YYYY, hh:mm a');
        } else {
            return moment(responseDate).format('DD/MM/YYYY');
        }

    } else { // when camera device timezone is behind UTC
        let offset = timezone.slice(timezone.indexOf('-'), timezone.indexOf('_'))
        let offsetHours = offset.slice(offset.indexOf('-') + 1, offset.indexOf(':'));
        let offsetMinutes = offset.slice(offset.indexOf(':') + 1, offset.length);

        responseDate.subtract(parseInt(offsetHours), 'hours')
        responseDate.subtract(parseInt(offsetMinutes), 'minutes')
        return moment(responseDate).format('DD/MM/YYYY');
    }
}


export function convertUTCTimeToTimezone(dateTimeString, timezone = 'GMT+05:30_Asia/Kolkata', formatted = false) {

    let dateString = dateTimeString.split(' ')[0];
    let timeStringWithTimezone = dateTimeString.split(' ')[1];
    let timeString = timeStringWithTimezone.split('.')[0];
    let responseDate = moment(new Date());

    let year = dateString.slice(0, 4);
    let month = dateString.slice(5, 7);
    let day = dateString.slice(8, 10);

    let timeArr = timeString.split(':');
    let hour = timeArr[0];
    let minutes = timeArr[1];
    let secs = timeArr[2];

    responseDate.set('year', year);
    responseDate.set('month', month - 1);
    responseDate.set('date', day);
    responseDate.set('hour', hour);
    responseDate.set('minute', minutes);
    responseDate.set('second', secs);
    // console.log('response Date', timeArr);
    // when camera device timezone is ahead of UTC
    if (timezone.indexOf('+') !== -1) {
        let offset = timezone.slice(timezone.indexOf('+'), timezone.indexOf('_'))
        let offsetHours = offset.slice(offset.indexOf('+') + 1, offset.indexOf(':'));
        let offsetMinutes = offset.slice(offset.indexOf(':') + 1, offset.length);

        responseDate.add(offsetHours, 'hours');
        responseDate.add(offsetMinutes, 'minutes');
        // console.log('timezone', moment(responseDate));
        if (formatted) {
            return moment(responseDate).format('MMMM Do YYYY, hh:mm a');
        } else {
            return moment(responseDate).format('hh:mm a');
        }

    } else { // when camera device timezone is behind UTC
        let offset = timezone.slice(timezone.indexOf('-'), timezone.indexOf('_'))
        let offsetHours = offset.slice(offset.indexOf('-') + 1, offset.indexOf(':'));
        let offsetMinutes = offset.slice(offset.indexOf(':') + 1, offset.length);

        responseDate.subtract(parseInt(offsetHours), 'hours')
        responseDate.subtract(parseInt(offsetMinutes), 'minutes')
        return moment(responseDate).format('DD/MM/YYYY');
    }
}