// import {  } from './actionTypes';

import {
    CREATE_VENDOR,
    CREATE_VENDOR_SUCCESS,
    CREATE_VENDOR_FAILURE,
    CREATE_VENDOR_INIT,
    EDIT_VENDOR,
    EDIT_VENDOR_SUCCESS,
    EDIT_VENDOR_FAILURE,
    EDIT_VENDOR_INIT,
    DELETE_VENDOR,
    DELETE_VENDOR_SUCCESS,
    DELETE_VENDOR_FAILURE,
    DELETE_VENDOR_INIT,
    FETCH_VENDORS,
    FETCH_VENDORS_SUCCESS,
    FETCH_VENDORS_FAILURE,
    FETCH_VENDORS_INIT
} from "./actionTypes";
import { getVendorsFailure } from "./actions";

const initialState = {
    vendors: [],
    editVendorDetails : null,
    errorMessage: '',
    createVendorSuccess: false,
    createVendorFailure: false,
    editVendorSuccess: false,
    editVendorFailure: false,
    deleteVendorSuccess: false,
    deleteVendorFailure: false,
    getVendorsSuccess: false,
    getVendorsFailure: false

}



const Vendor = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_VENDOR:
            return {
                ...state,
                data: action.payload,
                createVendorSuccess: false,
                createVendorFailure: false
            }

        case CREATE_VENDOR_SUCCESS:
            return {
                ...state,
                vendors: action.payload,
                createVendorSuccess: true,
                createVendorFailure: false
            }

        case CREATE_VENDOR_FAILURE:
            return {
                ...state,
                errorMessage: action.payload,
                createVendorSuccess: false,
                createVendorFailure: true
            }

        case CREATE_VENDOR_INIT:
            return {
                ...state,
                productCategories: action.payload,
                createVendorSuccess: false,
                createVendorFailure: false
            }

            // ==== Edit vendor cases ====
        case EDIT_VENDOR:
            return {
                ...state,
                products: action.payload,
                editVendorSuccess: false,
                editVendorFailure: false
            }

        case EDIT_VENDOR_SUCCESS:
            return {
                ...state,
                editVendorDetails : action.payload,
                editVendorSuccess: true,
                editVendorFailure: false
            }

        case EDIT_VENDOR_FAILURE:
            return {
                ...state,
                // vendors: [...state.vendors, action.payload]
                errorMessage: action.payload,
                editVendorSuccess: false,
                editVendorFailure: true
            }

        case EDIT_VENDOR_INIT:
            return {
                ...state,
                editVendorDetails : null,
                errorMessage: '',
                editVendorSuccess: false,
                editVendorFailure: false
            }

            // ==== Delete Vendor Cases ====
        case DELETE_VENDOR:
            return {
                ...state,
                products: [action.payload],
                deleteVendorSuccess: false,
                deleteVendorFailure: false
            }

        case DELETE_VENDOR_SUCCESS:
            return {
                ...state,
                deleteVendorSuccess: true,
                deleteVendorFailure: false
                    // vendorResponse: {...newResponse }

            }

        case DELETE_VENDOR_FAILURE:
            return {
                ...state,
                deleteVendorSuccess: false,
                deleteVendorFailure: true,
                errorMessage: action.payload
                    // vendors: [...newVendorList]
            }
        case DELETE_VENDOR_INIT:
            return {
                ...state,
                // productCategories: [action.payload],
                deleteVendorSuccess: false,
                deleteVendorFailure: false,
                errorMessage: ''
            }

        case FETCH_VENDORS:
            console.log("####   inside reducer for vendors")
            return {
                ...state,
                getVendorsSuccess: false,
                getVendorsFailure: false
            }

        case FETCH_VENDORS_SUCCESS:
            return {
                ...state,
                getVendorsSuccess: true,
                getVendorsFailure: false,
                // vendors: [...action.payload]
                vendors: action.payload
            }

        case FETCH_VENDORS_FAILURE:
            return {
                ...state,
                getVendorsSuccess: false,
                getVendorsFailure: true,
                errorMessage: action.payload
            }
        case FETCH_VENDORS_INIT:
            return {
                ...state,
                getVendorsSuccess: false,
                getVendorsFailure: false,
                errorMessage: '',
                vendors: []
            }
        default:
            return state;
    }
}

export default Vendor;