import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { CREATE_PRODUCT_CATEGORY, EDIT_PRODUCT_CATEGORY, DELETE_PRODUCT_CATEGORY, GET_PRODUCT_CATEGORY, GET_PRODUCT_CATEGORY_BY_NAME } from './actionTypes';
import {
    createProductCategorySuccess, createProductCategoryFailure,
    editProductCategorySuccess, editProductCategoryFailure,
    deleteProductCategorySuccess, deleteProductCategoryFailure,
    getProductCategorySuccess, getProductCategoryFailure,
    getProductCategoryByNameSuccess, getProductCategoryByNameFailure
} from './actions';
import { searchCategory, addProductCategory, editCategory, deleteCategory, searchCategoryByName } from '../../services/productCategoryService'
import constants from '../../common/constants'
import { act } from 'react-dom/test-utils';
import { checkError } from '../../helpers/errorHelper'


// ==== Function to get product category ====
function* fetchProductCategory(action) {
    try {
        console.log("#####  inside saga!! get!!")
            // const response = yield call(searchCategory)
        let response
        action.payload ? response = yield call(searchCategory, Number(action.payload.vendorId)) : response = yield call(searchCategory)
        if (response.data.status === true) {
            yield put(getProductCategorySuccess(response.data.data))
        } else {
            yield put(getProductCategoryFailure(response.data.messgae))
        }
    } catch (error) {
        // ==== TO PRINT ERROR --> error.data.message ====
        yield put(getProductCategoryFailure(checkError(error)))
    }
}

// ==== Function to add product category ====
function* insertProductCategory(action) {
    try {
        let vendorId = action.payload.vendorId
        delete action.payload.vendorId
        const response = yield call(
            addProductCategory,
            vendorId,
            action.payload
        )
        if (response.data.status === true) {
            yield put(createProductCategorySuccess(response.data.data))
        } else {
            yield put(createProductCategoryFailure(response.data.message))
        }
    } catch (error) {
        yield put(createProductCategoryFailure(checkError(error)))
    }
}

// ==== Function to update product category ====
function* updateProductCategory(action) {
    try {
        let vendorId = action.payload.vendorId
        let categoryId = action.payload.categoryId
        delete action.payload.vendorId
        delete action.payload.categoryId
        const response = yield call(
            editCategory,
            vendorId,
            categoryId,
            action.payload
        )
        if (response.data.status === true) {
            yield put(editProductCategorySuccess(response.data.data))
        } else {
            yield put(editProductCategoryFailure(response.data.messgae))
        }
    } catch (error) {
        yield put(editProductCategoryFailure(checkError(error)))
    }
}

// ==== Function to delete product category ====
function* removeProductCategory(action) {
    try {
        const response = yield call(
            deleteCategory,
            action.payload.vendorId,
            action.payload.categoryId
        );
        if (response.data.status === true) {
            yield put(deleteProductCategorySuccess(response.data.data))
        } else {
            yield put(deleteProductCategoryFailure(response.data.messgae))
        }
    } catch (error) {
        console.log(error.data.message);
        yield put(deleteProductCategoryFailure(checkError(error)))
    }
}

// ==== Function to fetch product category by name ====
function* fetchProductCategoryByName(action) {
    try {
        let response
        action.payload ? response = yield call(searchCategoryByName, action.payload) : response = yield call(searchCategoryByName)
        if (response.data.status === true) {
            yield put(getProductCategoryByNameSuccess(response.data.data))
        } else {
            yield put(getProductCategoryByNameFailure(response.data.message))
        }
    } catch (error) {
        console.log(error.data.message);
        yield put(getProductCategoryByNameFailure(checkError(error)))
    }
}

// ==== Watcher Functions ====
export function* watchGetProductCategory() {
    yield takeEvery(GET_PRODUCT_CATEGORY, fetchProductCategory)
}
export function* watchCreateProductCategory() {
    yield takeEvery(CREATE_PRODUCT_CATEGORY, insertProductCategory)
}
export function* watchEditProductCategory() {
    yield takeEvery(EDIT_PRODUCT_CATEGORY, updateProductCategory)
}
export function* watchDeleteProductCategory() {
    yield takeEvery(DELETE_PRODUCT_CATEGORY, removeProductCategory)
}
export function* watchGetProductCategoryByName() {
    yield takeEvery(GET_PRODUCT_CATEGORY_BY_NAME, fetchProductCategoryByName)
}


// ==== Fork Functions ====
function* ProductCategorySaga() {
    yield all([
        fork(watchGetProductCategory),
        fork(watchCreateProductCategory),
        fork(watchEditProductCategory),
        fork(watchDeleteProductCategory),
        fork(watchGetProductCategoryByName)

    ])
}

export default ProductCategorySaga