import {

    GET_ALL_PLANS,
    GET_ALL_PLANS_SUCCESS,
    GET_ALL_PLANS_FAILURE,
    GET_ALL_PLANS_INIT,

    GET_PLAN_DETAILS,
    GET_PLAN_DETAILS_SUCCESS,
    GET_PLAN_DETAILS_FAILURE,
    GET_PLAN_DETAILS_INIT,

    GET_PLAN_FREQUENCY,
    GET_PLAN_FREQUENCY_SUCCESS,
    GET_PLAN_FREQUENCY_FAILURE,
    GET_PLAN_FREQUENCY_INIT,

    CREATE_PLAN,
    CREATE_PLAN_SUCCESS,
    CREATE_PLAN_FAILURE,
    CREATE_PLAN_INIT,

    EDIT_PLAN,
    EDIT_PLAN_SUCCESS,
    EDIT_PLAN_FAILURE,
    EDIT_PLAN_INIT,

    DISABLE_PLAN,
    DISABLE_PLAN_SUCCESS,
    DISABLE_PLAN_FAILURE,
    DISABLE_PLAN_INIT,

    GET_LN_PLAN,
    GET_LN_PLAN_SUCCESS,
    GET_LN_PLAN_FAILURE,
    GET_LN_PLAN_INIT,

    GET_ALL_PROMO,
    GET_ALL_PROMO_SUCCESS,
    GET_ALL_PROMO_FAILURE,
    GET_ALL_PROMO_INIT,

    GET_PROMO_DETAILS,
    GET_PROMO_DETAILS_SUCCESS,
    GET_PROMO_DETAILS_FAILURE,
    GET_PROMO_DETAILS_INIT,

    CREATE_PROMO,
    CREATE_PROMO_SUCCESS,
    CREATE_PROMO_FAILURE,
    CREATE_PROMO_INIT,

    EDIT_PROMO,
    EDIT_PROMO_SUCCESS,
    EDIT_PROMO_FAILURE,
    EDIT_PROMO_INIT,

    DISABLE_PROMO,
    DISABLE_PROMO_SUCCESS,
    DISABLE_PROMO_FAILURE,
    DISABLE_PROMO_INIT

} from './actionTypes'


// ==== Actions for Get All Plans ====
export const getAllPlans = (data) => {
    return{
        type : GET_ALL_PLANS,
        payload : data
    }
}
export const getAllPlansSuccess = (data) => {
    return{
        type : GET_ALL_PLANS_SUCCESS,
        payload : data
    }
}
export const getAllPlansFailure = (data) => {
    return{
        type : GET_ALL_PLANS_FAILURE,
        payload : data
    }
}
export const getAllPlansInit = (data) => {
    return{
        type : GET_ALL_PLANS_INIT,
        payload : data
    }
}

// ==== Actions for Get Plan Details ====
export const getPlanDetails = (data) => {
    return{
        type : GET_PLAN_DETAILS,
        payload : data
    }
}
export const getPlanDetailsSuccess = (data) => {
    return{
        type : GET_PLAN_DETAILS_SUCCESS,
        payload : data
    }
}
export const getPlanDetailsFailure = (data) => {
    return{
        type : GET_PLAN_DETAILS_FAILURE,
        payload : data
    }
}
export const getPlanDetailsInit = (data) => {
    return{
        type : GET_PLAN_DETAILS_INIT,
        payload : data
    }
}

// ==== Actions for Get Plan Frequency ==== 
export const getPlanFrequency = (data) => {
    return {
        type : GET_PLAN_FREQUENCY,
        payload : data
    }
}
export const getPlanFrequencySuccess = (data) => {
    return {
        type : GET_PLAN_FREQUENCY_SUCCESS,
        payload : data
    }
}
export const getPlanFrequencyFailure = (data) => {
    return {
        type : GET_PLAN_FREQUENCY_FAILURE,
        payload : data
    }
}
export const getPlanFrequencyInit = (data) => {
    return {
        type : GET_PLAN_FREQUENCY_INIT,
        payload : data
    }
}

// ==== Actions for Create Plan ==== 
export const createPlan = (data) => {
    return{
        type : CREATE_PLAN,
        payload : data
    }
}
export const createPlanSuccess = (data) => {
    return{
        type : CREATE_PLAN_SUCCESS,
        payload : data
    }
}
export const createPlanFailure = (data) => {
    return{
        type : CREATE_PLAN_FAILURE,
        payload : data
    }
}
export const createPlanInit = (data) => {
    return{
        type : CREATE_PLAN_INIT,
        payload : data
    }
}

// ==== Action for Edit Plan ====
export const editPlan = (data) => {
    return {
        type : EDIT_PLAN,
        payload : data
    }
}
export const editPlanSuccess = (data) => {
    return {
        type : EDIT_PLAN_SUCCESS,
        payload : data
    }
}
export const editPlanFailure = (data) => {
    return {
        type : EDIT_PLAN_FAILURE,
        payload : data
    }
}
export const editPlanInit = (data) => {
    return {
        type : EDIT_PLAN_INIT,
        payload : data
    }
}

// ==== Actions for Disable Plan ====
export const disablePlan = (data) => {
    return {
        type : DISABLE_PLAN,
        payload : data
    }
}
export const disablePlanSuccess = (data) => {
    return {
        type : DISABLE_PLAN_SUCCESS,
        payload : data
    }
}
export const disablePlanFailure = (data) => {
    return {
        type : DISABLE_PLAN_FAILURE,
        payload : data
    }
}
export const disablePlanInit = (data) => {
    return {
        type : DISABLE_PLAN_INIT,
        payload : data
    }
}

// ==== Actions for LN Plan Details ====
export const getLnPlan = (data) => {
    return {
        type: GET_LN_PLAN,
        payload: data
    }
}
export const getLnPlanSuccess = (data) => {
    return {
        type: GET_LN_PLAN_SUCCESS,
        payload: data
    }
}
export const getLnPlanFailure = (data) => {
    return {
        type: GET_LN_PLAN_FAILURE,
        payload: data
    }
}
export const getLnPlanInit = (data) => {
    return {
        type: GET_LN_PLAN_INIT,
        payload: data
    }
}


// ==== ACTIONS FOR PROMO CODE BELOW ====

// ==== Actions to Get All Promo ====\
export const getAllPromo = (data) => {
    return {
        type : GET_ALL_PROMO,
        payload : data
    }
}
export const getAllPromoSuccess = (data) => {
    return {
        type : GET_ALL_PROMO_SUCCESS,
        payload : data
    }
}
export const getAllPromoFailure = (data) => {
    return {
        type : GET_ALL_PROMO_FAILURE,
        payload : data
    }
}
export const getAllPromoInit = (data) => {
    return {
        type : GET_ALL_PROMO_INIT,
        payload : data
    }
}

// ==== Actions to Get Promo Details ====
export const getPromoDetails = (data) => {
    return {
        type : GET_PROMO_DETAILS,
        payload : data
    }
}
export const getPromoDetailsSuccess = (data) => {
    return {
        type : GET_PROMO_DETAILS_SUCCESS,
        payload : data
    }
}
export const getPromoDetailsFailure = (data) => {
    return {
        type : GET_PROMO_DETAILS_FAILURE,
        payload : data
    }
}
export const getPromoDetailsInit = (data) => {
    return {
        type : GET_PROMO_DETAILS_INIT,
        payload : data
    }
}

// ==== Actions for Create Promo ====
export const createPromo = (data) => {
    return {
        type : CREATE_PROMO,
        payload : data
    }
}
export const createPromoSuccess = (data) => {
    return {
        type : CREATE_PROMO_SUCCESS,
        payload : data
    }
}
export const createPromoFailure = (data) => {
    return {
        type : CREATE_PROMO_FAILURE,
        payload : data
    }
}
export const createPromoInit = (data) => {
    return {
        type : CREATE_PROMO_INIT,
        payload : data
    }
}

// ==== Actions for Edit Promo ====
export const editPromo = (data) => {
    return {
        type : EDIT_PROMO,
        payload : data
    }
}
export const editPromoSuccess = (data) => {
    return {
        type : EDIT_PROMO_SUCCESS,
        payload : data
    }
}
export const editPromoFailure = (data) => {
    return {
        type : EDIT_PROMO_FAILURE,
        payload : data
    }
}
export const editPromoInit = (data) => {
    return {
        type : EDIT_PROMO_INIT,
        payload : data
    }
}

// ==== Actions for Disable Promo ====
export const disablePromo = (data) => {
    return {
        type : DISABLE_PROMO,
        payload : data
    }
}
export const disablePromoSuccess = (data) => {
    return {
        type : DISABLE_PROMO_SUCCESS,
        payload : data
    }
}
export const disablePromoFailure = (data) => {
    return {
        type : DISABLE_PROMO_FAILURE,
        payload : data
    }
}
export const disablePromoInit = (data) => {
    return {
        type : DISABLE_PROMO_INIT,
        payload : data
    }
}