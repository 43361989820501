export function checkDuplicateValues(duplicateObj, newList) {
    let duplicateIndices = []
    newList.map((val, index) => {
        duplicateObj[val.batchId] ? duplicateIndices.push(index) : duplicateObj[val.batchId] = true
    })
    duplicateIndices = duplicateIndices.reverse()
    for( let indexValue of duplicateIndices){
        newList.splice(indexValue, 1)
    }

    return {
        duplicateObj : duplicateObj,
        newList : newList
    }
}

