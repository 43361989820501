import React, { Component } from 'react';
import { Container, Button, Modal, Popover, PopoverBody, UncontrolledPopover } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import InputBox from '../../components/Common/input-box'
import DropdownList from 'react-widgets/lib/DropdownList';
import { connect } from 'react-redux';
import FileUpload from '../../components/Common/file-upload';
import OverflowMenu from '../../components/Common/overflow-menu'
import productImage from '../../assets/images/product/product.png';
import { Calendar, DateRange } from 'react-date-range'
import moment from 'moment';
// import HorizontalTimeLine from 'react-horizontal-timeline'

// Actions and Redux Components 
import * as FirmwareActions from '../../store/firmware/actions'
import * as LayoutActions from '../../store/layout/actions'
import * as VendorActions from '../../store/vendor/actions'
import * as ProductCategoryActions from '../../store/productCategory/actions'
import * as ProductActions from '../../store/product/actions'
import Stepper from 'react-stepper-horizontal'

// Test menu 
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/breadcrumb';
import { navItem } from 'aws-amplify';
import { parse } from 'date-fns';

class FirmwareUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vendorDropdownList : [],
            categoryDropdownList : [],
            productDropdownList : [],
            buildTypeDropdownList : [],
            allBuildTypes : [],
            buildTypeDisabledList : [],
            selectedVendor : null,
            selectedCategory : null,
            searchValue : null,
            searchValueObj : null,
            validationErrors : {
                vendorError : false,
                categoryError : false,
                productError : false,
                firmwareFileError : false,
                releaseNoteFileError : false,
                qaFileError : false
            },
            firmwareInfo : null,
            firmwareFile : null,
            releaseNoteFile : null,
            qaFile : null,
            releaseDate : new Date(),
            qaUpload : true,
            releaseBuildType : null,
            newReleaseNumber : null,
            newVersionNumber : null,
            steps : [{title : "Alpha"}, {title : "Beta"}, {title : "Final"}],
            overflowMenuArray : [
                {name : "Download Blank Template", value : 0},
                {name : "Download Previous QA Report", value : 1},
            ],
            qaTemplateUrl : "https://ehss-public-bucket.s3.ap-south-1.amazonaws.com/QA_Report_Template.xlsx",

            uploadType: "regular",      // ==== enum : [regular, force]
            forceModalView: false,
            scheduledUpload: false,

        }
    }

    componentDidMount() {
        this.props.getVendors()
        this.props.changePreloader(true)
    }

    takeFile = (e, fileToken) => {
        let { validationErrors } = this.state
        console.log("#### Printing file details", fileToken)
        let testRegex

        if(e.target.files.length !== 0){            
            console.log(e.target.files[0])

            if(fileToken === "firmware"){                
                testRegex = new RegExp(/^.*upf$/)

                // ==== Test for Correct file type ====
                if(!testRegex.test(e.target.files[0].name)){
                    toast.error("Please enter a valid Firmware File")
                    return
                }
                validationErrors.firmwareFileError = false
                this.setState({ firmwareFile : e.target.files[0], validationErrors : validationErrors})
            }
            

            else if(fileToken === "releaseNote"){
                testRegex = new RegExp(/^.*text.*$/)

                // ==== Test for correct report type ====
                if(!testRegex.test(e.target.files[0].type)){
                    toast.error("Please add a valid Release Note")
                    return
                }
                validationErrors.releaseNoteFileError = false
                this.setState({ releaseNoteFile : e.target.files[0], validationErrors : validationErrors})
            }
            
            
            else if(fileToken === "qaReport"){
                testRegex = new RegExp(/^.*xlsx$|^.*xls$/)

                // ==== Test for correct file type ====
                if(!testRegex.test(e.target.files[0].name)){
                    toast.error("Please add a valid Report File")
                    return
                }
                validationErrors.qaFileError = false
                this.setState({ qaFile : e.target.files[0], validationErrors : validationErrors})
            }
        }
    }

    removeFile = (e, fileToken) => {
        if(fileToken === "all"){
            this.setState({
                firmwareFile : null,
                releaseNoteFile : null,
                qaFile : null
            })
        }
        else if(fileToken === "firmware"){
            this.setState({ firmwareFile : null })
        }
        else if(fileToken === "releaseNote"){
            this.setState({ releaseNoteFile : null })
        }
        else if(fileToken === "qaReport"){
            this.setState({ qaFile : null })
        }

    }

    changeReleaseDate(date){
        this.setState({
            releaseDate : date
        })
    }

    selectVendor(e) {
        let {validationErrors} = this.state
        validationErrors.vendorError = false
        console.log(e)
        let data = {
            vendorId : e.vendorId
        }
        this.setState({selectedVendor : e, validationErrors : validationErrors})
        this.props.getProductCategory(data)
    }

    selectCategory(e) {
        let {validationErrors} = this.state
        validationErrors.categoryError = false
        console.log(e)
        let data = {
            categoryId : e.productCategoryId
        }
        this.setState({ selectedCategory : e, validationErrors : validationErrors})
        this.props.getProductByCategory(data)
    }

    selectProduct(e){
        let {validationErrors} = this.state
        validationErrors.productError = false
        this.setState({
            searchValueObj : e,
            searchValue : e.productId,
            validationErrors : validationErrors
        })
    }

    checkDate(){
        console.log("Starting value factor")
        let { releaseDate } = this.state
        console.log(releaseDate)
        console.log(moment(releaseDate).format("YYYY-MM-DD"))
    }


    onSearch () {
        let { searchValue, validationErrors, selectedCategory, selectedVendor } = this.state
        let validationFailed = false
        if(searchValue === null){
            validationErrors.productError = true
            validationFailed = true
        }
        if(selectedCategory === null){
            validationErrors.categoryError = true
            validationFailed = true
        }
        if(selectedVendor === null){
            validationErrors.vendorError = true
            validationFailed = true
        }
        
        // ==== check if validations have failed ====
        if(validationFailed){
            this.setState({
                validationErrors : validationErrors
            })
            return
        }
        let data = {
            productId : searchValue
        }
        this.onClear(true)
        this.props.searchFirmware(data)
        this.props.changePreloader(true)
    }

    onClear(clearFromSearch=null) {
        let newState = {
            firmwareInfo : null,
            buildTypeDisabledList : [],
            firmwareFile : null,
            releaseNoteFile : null,
            qaFile : null,
            releaseDate : new Date(),
            qaUpload : true,
            releaseBuildType : null,
            newReleaseNumber : null,
            newVersionNumber : null,
            validationErrors : {
                vendorError : false,
                categoryError : false,
                productError : false,
                firmwareFileError : false,
                releaseNoteFileError : false,
                qaFileError : false
            },
            forceModalView: false,
            uploadType: "regular",
        }

        if(clearFromSearch === null){
            let tempAddedState = {
                selectedVendor : null,
                selectedCategory : null,
                searchValue : null,
                searchValueObj : null,
            }
            newState = {...newState, ...tempAddedState}
        }
        this.setState(newState)
    }

    inputHandler = (e) => {
        let { vendorForm, productCategoryForm, productForm, batchForm } = this.state;
        if (e.target.name === 'vendorNameField') {
            this.setState({ vendorName: e.target.value });
        } else if (e.target.id === 'originCountryId') {
            this.setState({ originCountryId: e.target.value });
        } else {
            this.setState({ searchValue: e.target.value })
        }
    }


    changeBuildType(e, oldFirmwareInfo){
        console.log(e)
        let oldFirmwareReleaseNumber = oldFirmwareInfo.releaseNumber
        let oldFirmwareVersion = oldFirmwareInfo.firmwareVersion
        let releaseNo 
        let tempReleaseDate
        if(oldFirmwareReleaseNumber === null){
            releaseNo = 1
        }
        else if(e.groupId === 3){
            releaseNo = 1
        }
        else if(e.groupId === oldFirmwareInfo.buildTypeId){
            releaseNo = oldFirmwareReleaseNumber + 1
        }
        else{
            releaseNo = 1
        }

        e.groupId === 3 ? tempReleaseDate = new Date() : tempReleaseDate = null
        this.setState({
            releaseBuildType : e,
            newReleaseNumber :  releaseNo,
            // releaseDate : tempReleaseDate
        })
    }

    setUpNewFirmwareDetails(oldFirmwareInfo){
        let { buildTypeDropdownList, allBuildTypes, qaUpload } = this.state
        let oldFirmwareVersion = parseFloat(oldFirmwareInfo.firmwareVersion)
        let tempFirmwareVersion
        let deactivatedList = []
        console.log("#### Firmware Filtering")

        if(oldFirmwareInfo.buildTypeId === 3){
            tempFirmwareVersion = parseInt(oldFirmwareVersion) + 1
            tempFirmwareVersion = tempFirmwareVersion + 0.0001
        }
        else{
            // tempFirmwareVersion = parseInt((oldFirmwareVersion * 10000) + 1) / 10000
            tempFirmwareVersion = Math.round(oldFirmwareVersion * 10000)
            tempFirmwareVersion = ( parseInt(tempFirmwareVersion) + 1 ) / 10000
        }

        if(oldFirmwareInfo.buildTypeId === 1){
            deactivatedList = allBuildTypes.filter((type) => type.groupId === 3)
        }
        else if(oldFirmwareInfo.buildTypeId === 2){
            deactivatedList = allBuildTypes.filter((type) => type.groupId === 1)
        }
        else if(oldFirmwareInfo.buildTypeId === 0 || oldFirmwareInfo.buildTypeId === 3){
            deactivatedList = allBuildTypes.filter((type) => type.groupId !== 1)
            qaUpload = false
        }
        
        this.setState({ newVersionNumber : tempFirmwareVersion, buildTypeDisabledList : deactivatedList, qaUpload : qaUpload})
    }


    validationCheck = () => {
        let { firmwareFile, releaseNoteFile, qaUpload, validationErrors, qaFile } = this.state
        let validationFailed = false

        // ==== Validations checked ==== 
        if(firmwareFile === null){
            validationFailed = true
            validationErrors.firmwareFileError = true
        }
        if(releaseNoteFile === null){
            validationFailed = true
            validationErrors.releaseNoteFileError = true
        }
        if(qaUpload){
            if(qaFile ===  null){
                validationFailed = true
                validationErrors.qaFileError = true
            }
        }

        if(validationFailed){
            this.setState({validationErrors : validationErrors}, ()=>{
                return false
            })            
        }else{
            return true
        }
    }

    uploadFirmwareBuild(productId, schedule=false, forceUpload=false){
        let {releaseBuildType, newVersionNumber, newReleaseNumber, firmwareFile, qaFile, releaseNoteFile, validationErrors, releaseDate, qaUpload} = this.state
        let validationFailed = false
        let assignedReleaseDate = null
        let attemptForceUpload = forceUpload ? 1 : 0
        console.log(firmwareFile)
        console.log(qaFile)
        console.log(releaseNoteFile)

        let newFile = new File([], '')
        console.log(newFile)
        // console.log(newVersionNumber)
        // return

        // ==== Validations checked ==== 
        // if(firmwareFile === null){
        //     validationFailed = true
        //     validationErrors.firmwareFileError = true
        // }
        // if(releaseNoteFile === null){
        //     validationFailed = true
        //     validationErrors.releaseNoteFileError = true
        // }
        // if(qaUpload){
        //     if(qaFile ===  null){
        //         validationFailed = true
        //         validationErrors.qaFileError = true
        //     }
        // }

        // if(validationFailed){
        //     this.setState({validationErrors : validationErrors})
        //     return
        // }

        if(!this.validationCheck()){
            return
        }

        if(schedule){
            assignedReleaseDate = moment(releaseDate).format("YYYY-MM-DD")
        }


        // ==== Validations Cleared ====
        const formBox = new FormData()
        let data = {
                "buildTypeId" : releaseBuildType.groupId,
                "firmWareVersion" : newVersionNumber.toString(),
                "releaseNumber" : newReleaseNumber,
                "scheduleDate" : assignedReleaseDate
        }
        formBox.append('binFile', firmwareFile)
        formBox.append('releaseNotes', releaseNoteFile)
        formBox.append('jsonData', JSON.stringify(data))
        formBox.append('isForceUpdate', attemptForceUpload)
        if(qaUpload){
            formBox.append('qaReport', qaFile)
        }else{
            formBox.append('qaReport', newFile)
        }
        // formBox.append('qaReport', qaFile)

        let sharableData = {
            formBoxData : formBox,
            productId : productId
        }

        // console.log(data)
        this.props.uploadFirmwareBuild(sharableData)
        this.props.changePreloader(true)
    }
    
    selectUploadType = (uploadType) => {
        if(this.state.uploadType === uploadType){
            return
        }
        this.setState({
            uploadType: uploadType
        })
    }

    initiateForceUpdate = (schedule=false) => {
        if(!this.validationCheck()){
            return
        }
        this.toggleModal(schedule)
    }

    toggleModal = (scheduled = false) => {        
        let {forceModalView} = this.state
        this.setState({
            forceModalView: !forceModalView,
            scheduledUpload: scheduled
        })
    }



    // ==== CHECKER FUNCTIONS ====

    checkGetVendors = () => {
        if (this.props.layout.isPreloader) {
            if (this.props.vendor.getVendorsSuccess === true) {
                // this.props.changePreloader(false);
                this.setState({ vendorDropdownList: this.props.vendor.vendors });
                this.props.getVendorsInit();
                this.props.changePreloader(false)
                let data = {isBuildType : 1}
                this.props.getBuildTypes(data)
            } else if (this.props.vendor.getVendorsFailure === true) {
                this.props.changePreloader(false);
                toast.error(this.props.vendor.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getVendorsInit();
            }
        }
    }

    checkGetProductCategory() {
        if (this.props.productCategory.getProductCategorySuccess === true) {
            this.setState({ categoryDropdownList : this.props.productCategory.productCategoriesList })
            this.props.getProductCategoryInit()
        } else if (this.props.productCategory.getProductCategoryFailure === true) {
            toast.error(this.props.productCategory.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getProductCategoryInit()
        }
    }

    checkGetProductByCategory() {
        if (this.props.product.getProductByCategorySuccess === true) {
            this.setState({ productDropdownList: this.props.product.productList })
            this.props.getProductByCategoryInit()
        } else if (this.props.product.getProductByCategoryFailure === true) {
            toast.error(this.props.product.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getProductByCategoryInit()
        }
    }

    checkSearchFirmware(){
        if (this.props.layout.isPreloader) {
            if (this.props.firmware.searchFirmwareSuccess === true) {
                // this.props.changePreloader(false);
                this.setState({ firmwareInfo: this.props.firmware.firmwareInfo });
                this.setUpNewFirmwareDetails(this.props.firmware.firmwareInfo)
                this.props.searchFirmwareInit();
                this.props.changePreloader(false)
            } else if (this.props.firmware.searchFirmwareFailure === true) {
                this.props.changePreloader(false);
                toast.error(this.props.firmware.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.searchFirmwareInit();
            }
        }
    }

    checkGetBuildTypes(){
        if (this.props.firmware.getBuildTypesSuccess === true) {
            this.setState({ buildTypeDropdownList : this.props.firmware.buildTypes, allBuildTypes : this.props.firmware.buildTypes });
            this.props.getBuildTypesInit();
        } else if (this.props.firmware.getBuildTypesFailure === true) {
            toast.error(this.props.firmware.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getBuildTypesInit();
        }
    }

    checkUploadFirmwareBuild(){
        if(this.props.layout.isPreloader){
            if (this.props.firmware.uploadFirmwareBuildSuccess === true) {
                // this.toggleModal()
                // this.props.changePreloader(false)
                toast.success("Firmware Uploaded Successfully!")
                this.props.uploadFirmwareBuildInit();
                
                // this.onClear()
                this.onSearch()

            } else if (this.props.firmware.uploadFirmwareBuildFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.firmware.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getBuildTypesInit();
            }
        }
    }


    render() {
        let { validationErrors, vendorDropdownList, categoryDropdownList, productDropdownList, firmwareFile, releaseNoteFile, releaseDate, qaUpload, steps,
        firmwareInfo, releaseBuildType, buildTypeDropdownList, newReleaseNumber, newVersionNumber, buildTypeDisabledList, qaFile, qaTemplateUrl, searchValueObj,
        selectedVendor, selectedCategory, uploadType} = this.state

        let arrVal = ["Jack1", "jack2", "jack3"]

        // ==== calling check functions ====
        this.checkGetVendors()
        this.checkGetProductCategory()
        this.checkGetProductByCategory()
        this.checkSearchFirmware()
        this.checkGetBuildTypes()
        this.checkUploadFirmwareBuild()

        return (
            <React.Fragment>
                <div className="page-content-with-tabs firmware-page">

                    <div className="actions-section">
                        <div className="actions-grid">
                            <DropdownList
                                className={"t-a-l " + ((validationErrors.vendorError) ? 'select-error' : '')}
                                name='vendorDropdown'
                                placeholder="Select Vendor"
                                data={vendorDropdownList}
                                // defaultValue={selectedVendor}
                                textField="vendorName"
                                valueField="vendorId"
                                value={selectedVendor}
                                onChange={(e) => {
                                    this.selectVendor(e)
                                }}
                            />

                            <DropdownList
                                className={"t-a-l " + ((validationErrors.categoryError) ? 'select-error' : '')}
                                name='categoryDropdown'
                                placeholder="Select Category"
                                data={categoryDropdownList}
                                // defaultValue={originCountryId}
                                textField="productCategoryName"
                                valueField="productCategoryId"
                                value={selectedCategory}
                                onChange={(e) => {
                                    this.selectCategory(e)
                                }}
                            />

                            
                            <DropdownList
                                className={"t-a-l " + ((validationErrors.productError) ? 'select-error' : '')}
                                name='productDropdown'
                                placeholder="Select Product"
                                data={productDropdownList}
                                // defaultValue={originCountryId}
                                textField="productName"
                                valueField="productId"
                                value={searchValueObj}
                                onChange={(e) => {
                                    this.selectProduct(e)
                                }}
                            />
                            {/* <InputBox type="text" label="Search Product" key={98} name="SearchInputTag" value={searchValue}  onChange={(e) => {this.inputHandler(e)}} hasError={validationErrors.searchValue} /> */}
                            <Button name="searchButton" onClick={(e) => {this.onSearch()}}>Search</Button>
                            <Button name="clearButton" onClick={(e)=>{this.onClear()}}>Clear</Button>
                        </div>
                    </div>

                    {/* ==== Layer 1 Visibility ==== */}
                    {
        
                        firmwareInfo !== null ? 
                            <div className="firmware-detail-block">
                            <div className="current-firmware-block">
                                    <div className="font-size-18"> Current Firmware Details </div>
                                    <div className="firmware-details-grid">
                                        <div>
                                            <div className="firmware-description">Build Type : <b>{firmwareInfo.buildTypeName}</b> </div>
                                            <div className="firmware-description">Firmware Version : <b>{firmwareInfo.firmwareVersion}</b></div>
                                        </div>

                                        <div>
                                            <div className="firmware-description">Date of Release : <b>{firmwareInfo.dateOfRelease}</b></div>
                                            <div className="firmware-description">Release Note : &nbsp; &nbsp; <a href={firmwareInfo.releaseNotesUrl} target="_blank" download><i className="fa fa-download fa-lg"></i></a> </div>
                                        </div>

                                    </div>
                            </div>

                            <div className="timeline-div">
                                <div className="font-size-18"> Firmware History </div>
                                <Stepper
                                    steps={steps}
                                    activeStep={firmwareInfo.buildTypeId - 1}
                                    activeColor="#000"
                                    completeColor="#000"
                                    // defaultColor="##c9c9c9"
                                    completeBorderColor="#000"
                                    activeBorderColor="#000"
                                    circleFontSize="0"
                                    barStyle="solid"
                                />
                            </div>

                            <div className="current-firmware-block">
                                <div className="level-one-grid">
                                    <div>
                                        <div className="text-label-head">Build Type</div>
                                        <DropdownList
                                            className={"t-a-l " + ((validationErrors.productError) ? 'select-error' : '')}
                                            name='buildType'
                                            placeholder="Select Build Type"
                                            data={buildTypeDropdownList}
                                            textField="groupName"
                                            valueField="groupId"
                                            disabled={buildTypeDisabledList}
                                            onChange={(e) => {
                                                this.changeBuildType(e, firmwareInfo)
                                            }}
                                        />
                                    </div>

                                </div>
                                
                            </div>

                            {/* ==== Layer 2 Visibility ==== */}

                            {
                                releaseBuildType !== null ? 
                                    <div>
                                        <div className="current-firmware-block">
                                        <div className="level-one-grid">
                                            <div>
                                                <div className="text-label-head">Release Number :</div>
                                                <div className="detailed-text-value">{newReleaseNumber === null ? "--" : newReleaseNumber}</div>
                                            </div>

                                            <div>
                                                <div className="text-label-head">New Version Number :</div>
                                                <div className="detailed-text-value">{newVersionNumber}</div>
                                            </div>
                                            
                                            <div>
                                                <div className="text-label-head">QA Reports :</div>
                                                {/* <div className="detailed-text-value">QA Reports   <i id="qaReportList" class="fas fa-ellipsis-h"></i> </div> */}
                                                <div className="detailed-text-value"><OverflowMenu listItem={arrVal} previousQa={firmwareInfo.qaPreviousReportUrl} templateUrl={qaTemplateUrl} clickHandler={(value)=>{this.overFlowMenuHandler(value)}}></OverflowMenu></div>
                                            </div>

                                            {
                                                qaUpload ? <div>
                                                <div className="text-label-head">Upload QA Report :</div>
                                                <div className="detailed-file-upload"><FileUpload name="qaReport" fileType="all" chooseFileType="Choose File" onChange={(e) => { this.takeFile(e, "qaReport") }} fileName={qaFile !== null ? qaFile.name : ''} onRemoveFile={()=>{this.removeFile("QA")}} hasError={validationErrors.qaFileError} /></div>
                                                </div> : ''
                                            }
                                        </div>

                                    </div>

                                    <div className="current-firmware-block">
                                        <div className="level-one-grid">
                                            <div>
                                                <div className="text-label-head">Select Firmware File :</div>
                                                <div className="detailed-file-upload"><FileUpload name="firmwareFile" fileType="all" chooseFileType="Choose File" onChange={(e) => { this.takeFile(e, "firmware") }} fileName={firmwareFile !== null ? firmwareFile.name : ''} onRemoveFile={()=>{this.removeFile("firmware")}} hasError={validationErrors.firmwareFileError} /></div>
                                            </div>

                                            <div>
                                                <div className="text-label-head">Release Notes :</div>
                                                <div className="detailed-file-upload"><FileUpload name="releaseNoteFile" fileType="all" chooseFileType="Choose File" onChange={(e) => { this.takeFile(e, "releaseNote") }} fileName={releaseNoteFile !== null ? releaseNoteFile.name : ''} onRemoveFile={()=>{this.removeFile("releaseNote")}} hasError={validationErrors.releaseNoteFileError} /></div>
                                            </div>

                                            {
                                                releaseBuildType.groupId === 3 ? 
                                                <div>
                                                    <div className="text-label-head">Release Date :</div>
                                                    <div className="detailed-text-value"> {moment(releaseDate).format('DD/MM/YYYY')} <i id="date-picker-icon" class="far fa-calendar-alt"></i> </div>
                                                    <UncontrolledPopover trigger="legacy" placement="bottom-end" target="date-picker-icon" >
                                                            <PopoverBody className="popper-width-inc">
                                                                <Calendar
                                                                    date={releaseDate}
                                                                    onChange={(date)=>{this.changeReleaseDate(date)}}
                                                                    color="#3d91ff"
                                                                    minDate={new Date()}
                                                                />
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                </div> : ''
                                            }
                                            
                                        </div>
                                    </div>

                                    <div className="current-firmware-block">
                                        {/* <div className="font-size-18"> Current Firmware Details </div> */}
                                        <div className="text-label-head"> Current Firmware Details </div>
                                        <div className="m-tp-10">
                                            <div className="">                                           
                                                <input type="radio" className="filter-checkbox" name="genericPromo" value={0} checked={uploadType === "regular"} onClick={()=>{this.selectUploadType("regular")}}/>&nbsp;
                                                <label className="font-fourteen" onClick={()=>{this.selectUploadType("regular")}}>Regular Update - Used as Default for Updates</label>
                                                <br></br>
                                                <input type="radio" className="filter-checkbox" name="genericPromo" value={0} checked={uploadType === "force"} onClick={()=>{this.selectUploadType("force")}}/>&nbsp;
                                                <label className="font-fourteen" onClick={()=>{this.selectUploadType("force")}}>Force Update - Used for Bug Fixes, Broken Functionality, Critical issues only</label>
                                            </div>

                                        </div>
                                    </div>

                                    
                                    <div className="current-firmware-block">
                                        <div className="button-panel-grid">
                                            <div>
                                                <Button className="fit-btn" name="releaseNow" onClick={()=>{uploadType === "regular" ? this.uploadFirmwareBuild(firmwareInfo.productId) : this.initiateForceUpdate()}}>Release Now</Button>
                                            </div>

                                            <div>
                                                {
                                                    releaseBuildType.groupId === 3 ? 
                                                        <Button className="fit-btn" name="scheduleRelease" onClick={()=>{uploadType === "regular" ? this.uploadFirmwareBuild(firmwareInfo.productId, true) : this.initiateForceUpdate(true)}}>Schedule Release</Button> : 
                                                        <Button className="fit-btn" name="clear" onClick={(e)=>this.removeFile(e, "all")}> Clear </Button>
                                                }
                                            </div>
                                            
                                            <div>
                                                {
                                                    releaseBuildType.groupId === 3 ? 
                                                        <Button className="fit-btn" name="clear" onClick={(e)=>this.removeFile(e, "all")}> Clear </Button> : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''

                            }

                        </div> : ''
                          
                    }

                    

                </div>

                <Modal
                    isOpen={this.state.forceModalView}                
                    toggle={this.toggleModal}
                    className="modal-lg m-t-12 firmware-page"
                        >
                    <div className="m-t-5 m-b-five modal-padding">
                        {
                            firmwareInfo !== null && releaseBuildType !== null ?
                                <div>

                                    <div className="font-size-20 m-b-five">
                                        <div><b>You have selected "Force Update"</b></div>
                                    </div>

                                    <div className="font-size-17 edit-modal-grid m-b-two">
                                        <div>Build Type: </div>
                                        <div className="word-break-all"><b>{releaseBuildType.groupName}</b></div>
                                    </div>

                                    <div className="font-size-17 edit-modal-grid m-b-two">
                                        <div>Firmware Version: </div>
                                        <div className="word-break-all"><b>{newVersionNumber}</b></div>
                                    </div>

                                    <div className="font-size-17 edit-modal-grid m-b-five">
                                        <div>Date of Release: </div>
                                        <div className="word-break-all"><b>{moment(releaseDate).format("YYYY-MM-DD")}</b></div>
                                    </div>

                                    <div className="font-size-20 m-b-three">
                                        <center>
                                        <div><b>This will Force Update devices for all users without any trigger from user</b></div>
                                        <br></br>
                                        <div><b>Kindly verify before proceeding. </b></div>
                                        </center>
                                    </div>

                                    <center>
                                        <Button onClick={()=>{this.uploadFirmwareBuild(firmwareInfo.productId, this.state.scheduledUpload, true)}}>Push Force Update</Button>
                                        &nbsp; &nbsp;
                                        <Button onClick={()=>{this.toggleModal()}}>Cancel</Button>
                                    </center>

                                </div>
                                :
                                ''
                        }

                    </div>
                </Modal>
                <ToastContainer/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    layout : state.Layout,
    firmware : state.Firmware,
    productCategory : state.ProductCategory,
    vendor : state.Vendor,
    product : state.Product

})

const mapDispatchToProps = dispatch => ({

    searchFirmware : (data) => dispatch(FirmwareActions.searchFirmware(data)),
    searchFirmwareInit : () => dispatch(FirmwareActions.searchFirmwareInit()),

    uploadFirmwareBuild : (data) => dispatch(FirmwareActions.uploadFirmwareBuild(data)),
    uploadFirmwareBuildInit : () => dispatch(FirmwareActions.uploadFirmwareBuildInit()),

    getBuildTypes : (data) => dispatch(FirmwareActions.getBuildTypes(data)),
    getBuildTypesInit : () => dispatch(FirmwareActions.getBuildTypesInit()),

    getProductCategory: (data) => dispatch(ProductCategoryActions.getProductCategory(data)),
    getProductCategoryInit: () => dispatch(ProductCategoryActions.getProductCategoryInit()),

    getVendors: (data) => dispatch(VendorActions.getVendors(data)),
    getVendorsInit: () => dispatch(VendorActions.getVendorsInit()),

    getProductByCategory: (data) => dispatch(ProductActions.getProductByCategory(data)),
    getProductByCategoryInit: () => dispatch(ProductActions.getProductByCategoryInit()),

    changePreloader: (flag) => dispatch(LayoutActions.changePreloader(flag)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FirmwareUpload)