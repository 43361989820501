import {
    CREATE_BATCH,
    CREATE_BATCH_SUCCESS,
    CREATE_BATCH_FAILURE,
    CREATE_BATCH_INIT,

    EDIT_BATCH,
    EDIT_BATCH_SUCCESS,
    EDIT_BATCH_FAILURE,
    EDIT_BATCH_INIT,

    DELETE_BATCH,
    DELETE_BATCH_SUCCESS,
    DELETE_BATCH_FAILURE,
    DELETE_BATCH_INIT,

    GET_BATCH,
    GET_BATCH_SUCCESS,
    GET_BATCH_FAILURE,
    GET_BATCH_INIT,

    DOWNLOAD_BATCH_FILE,
    DOWNLOAD_BATCH_FILE_SUCCESS,
    DOWNLOAD_BATCH_FILE_FAILURE,
    DOWNLOAD_BATCH_FILE_INIT,

    UPLOAD_BATCH_FILE,
    UPLOAD_BATCH_FILE_SUCCESS,
    UPLOAD_BATCH_FILE_FAILURE,
    UPLOAD_BATCH_FILE_INIT
} from './actionTypes'

const initialState = {
    errorMessage : '',
    batchList : [],
    totalBatchCount : null,
    batchFile : null,
    editBatchDetails : null,
    createBatchSuccess : false,
    createBatchFailure : false,
    editBatchSuccess : false,
    editBatchFailure : false,
    deleteBatchSuccess : false,
    deleteBatchFailure : false,
    getBatchSuccess : false,
    getBatchFailure : false,
    downloadBatchFileSuccess : false,
    downloadBatchFileFailure : false,
    uploadBatchFileSuccess : false,
    uploadBatchFileFailure : false
}

const Batch = (state = initialState, action) => {
    switch(action.type) {
        // ==== Cases for create batch ====
        case CREATE_BATCH : 
            return {
                ...state,
                createBatchSuccess : false,
                createBatchFailure : false
            }

        case CREATE_BATCH_SUCCESS : 
            return {
                ...state,
                batchList : action.payload[0],
                createBatchSuccess : true,
                createBatchFailure : false
            }

        case CREATE_BATCH_FAILURE : 
            return {
                ...state,
                createBatchSuccess : false,
                createBatchFailure : true,
                errorMessage : action.payload
            }

        case CREATE_BATCH_INIT : 
            return {
                ...state,
                batchList : [],
                createBatchSuccess : false,
                createBatchFailure : false,
                errorMessage : ''
            }

        // Cases on edit batch ====
        case EDIT_BATCH : 
            return {
                ...state,
                editBatchSuccess : false,
                editBatchFailure : false
            }

        case EDIT_BATCH_SUCCESS : 
            return {
                ...state,
                editBatchDetails : action.payload,
                editBatchSuccess : true,
                editBatchFailure : false
            }

        case EDIT_BATCH_FAILURE : 
            return {
                ...state,
                editBatchSuccess : false,
                editBatchFailure : true,
                errorMessage : action.payload
            }

        case EDIT_BATCH_INIT : 
            return {
                ...state,
                editBatchDetails : null,
                editBatchSuccess : false,
                editBatchFailure : false,
                errorMessage : ''
            }

        
        // ==== Cases on delete batch ====
        case DELETE_BATCH :
            return {
                ...state,
                deleteBatchSuccess : false,
                deleteBatchFailure : false
            }

        case DELETE_BATCH_SUCCESS :
            return {
                ...state,
                data : action.payload,
                deleteBatchSuccess : true,
                deleteBatchFailure : false
            }

        case DELETE_BATCH_FAILURE :
            return {
                ...state,
                deleteBatchSuccess : false,
                deleteBatchFailure : true,
                errorMessage : action.payload
            }

        case DELETE_BATCH_INIT :
            return {
                ...state,
                deleteBatchSuccess : false,
                deleteBatchFailure : false,
                errorMessage : ''
            }

        // Cases on get batch ====
        case GET_BATCH : 
            return { 
                ...state,
                getBatchSuccess : false,
                getBatchFailure : false
            }

        case GET_BATCH_SUCCESS : 
            return { 
                ...state,
                batchList : action.payload.outputBatchListDtos,
                totalBatchCount : action.payload.totalCount,
                getBatchSuccess : true,
                getBatchFailure : false
            }

        case GET_BATCH_FAILURE : 
            return { 
                ...state,
                getBatchSuccess : false,
                getBatchFailure : true,
                errorMessage : action.payload
            }

        case GET_BATCH_INIT : 
            return { 
                ...state,
                batchList : [],
                totalBatchCount : null,
                getBatchSuccess : false,
                getBatchFailure : false,
                errorMessage : ''
            }

        // ==== Cases on Download batch file ====
        case DOWNLOAD_BATCH_FILE : 
            return {
                ...state,
                downloadBatchFileSuccess : false,
                downloadBatchFileFailure : false
            }

        case DOWNLOAD_BATCH_FILE_SUCCESS : 
            return {
                ...state,
                batchFile : action.payload,
                downloadBatchFileSuccess : true,
                downloadBatchFileFailure : false
            }

        case DOWNLOAD_BATCH_FILE_FAILURE : 
            return {
                ...state,
                downloadBatchFileSuccess : false,
                downloadBatchFileFailure : true,
                errorMessage : action.payload
            }

        case DOWNLOAD_BATCH_FILE_INIT : 
            return {
                ...state,
                batchFile : null,
                downloadBatchFileSuccess : false,
                downloadBatchFileFailure : false,
                errorMessage : ''
            }

        // ==== Cases on Upload batch file ====
        case UPLOAD_BATCH_FILE : 
            return {
                ...state,
                uploadBatchFileSuccess : false,
                uploadBatchFileFailure : false
            }
        
        case UPLOAD_BATCH_FILE_SUCCESS : 
            return {
                ...state,
                data : action.payload,
                uploadBatchFileSuccess : true,
                uploadBatchFileFailure : false
            }
            
        case UPLOAD_BATCH_FILE_FAILURE : 
            return {
                ...state,
                uploadBatchFileSuccess : false,
                uploadBatchFileFailure : true,
                errorMessage : action.payload
            }

        case UPLOAD_BATCH_FILE_INIT : 
            return {
                ...state,
                uploadBatchFileSuccess : false,
                uploadBatchFileFailure : false,
                errorMessage : ''
            }
        
        default :
            return state
    }
}

export default Batch