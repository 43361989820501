import React, { useState, useEffect } from 'react';

const FileUpload = (props) => {
    const [focus, setFocus] = useState(false);
    const [valid, setValid] = useState(false);
    const [invalid, setInvalid] = useState(null);

    const validateInput = () => {
        if (props.value === "") {
            setFocus(false);
            setValid(null); setInvalid(null);
        } else {
            setFocus(true);
        }
    }

    useEffect(() => {
        if (props.value !== "") {
            setFocus(true);
        } else if (focus == true && props.value === '') {
            setFocus(true);
        } else if (props.value === '') {
            setFocus(false);
        }

        if (props.hasError === false) {
            setValid(true);
            setInvalid(false);
        } else if (props.hasError === true) {
            setValid(false);
            setInvalid(true);
        }
    }, [props.hasError, props.value]);


    const limitFileName = (fileName) => {
        let limit = 20
        if(fileName.length <= limit){
            return fileName
        }else{
            let tempFilename = fileName
            tempFilename = tempFilename.substring(0, limit) + '...'
            return tempFilename
        }
    }



    return (
        <div className="custom-file">
            <input type="file" className="custom-file-input" id="customFile" {...props} accept={props.fileType === "image" ? "image/*" : '/*'}  />
            <label className={`custom-file-label ${(props.hasError) ? ' select-error' : ''}`} htmlFor="customFile">{props.fileName !== '' ? limitFileName(props.fileName) : props.chooseFileType}</label>
        </div>
    )
}

export default FileUpload;