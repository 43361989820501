import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Const from '../../common/constants';
import AdvancedSearch from '../../components/Common/advanced-search';
import TransactionBox from '../../components/Common/transactionBox';
import Table from '../../components/Common/ln-table';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';

import Search from '../../assets/images/search-solid.svg';
import RoundBtn from '../../assets/images/circle-solid.svg';

import { useCheckDownloadStatus } from '../../services/downloadExcelService';
import { useErrorHandlingService } from '../../services/errorHandlingService';

// Actions
import { changePreloader } from '../../store/layout/actions';
import { getBilldeskTransactions, getBilldeskTransactionInit, downloadBilldeskTransactions } from '../../store/lnDashboard/actions';
const Billdesk = (props) => {
  const dispatch = useDispatch();
  const [selectSearch, setSelectSearch] = useState(false);
  const [selectedFilter, setFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useState(null);
  const insightsData = useSelector(state => state.LNDashboard.billdeskInsightsData);
  const [totalPages, setTotalPages] = useState(0);
  const recordCount = useSelector(state => state.LNDashboard.recordCount);

  const downloadStatus = useCheckDownloadStatus();
  const errorMessageService = useErrorHandlingService();

  useEffect(() => {
    loadTransactionsList();
  }, []);

  useEffect(() => {
    calculatePagesFromRecordCount();
  }, [recordCount]);

  const calculatePagesFromRecordCount = () => {
    if (recordCount !== 0) {
      let totalPages = parseInt(recordCount / 10);
      let lastPage = recordCount % 10 === 0 ? false : true;
      if (recordCount <= 10) {
        setTotalPages(1);
      } else {
        if (lastPage == true) {
          setTotalPages(totalPages + 1);
        } else {
          setTotalPages(totalPages);
        }
      }
    }
  }

  const loadTransactionsList = () => {
    dispatch(changePreloader(true));
    let params = {
      pageCount: currentPage,
      pageSize: Const.pageSize
    }
    setSearchParams(params);
    dispatch(getBilldeskTransactions(params));
  }

  const downloadTransactionsList = () => {
    let params;
    if (selectedFilter == null) {
      params = {
        transactionType: selectedFilter,
      }
    } else {
      switch (selectedFilter) {
        case 0:
          params = {
            transactionType: selectedFilter
          }
          break;
        case 1:
          params = {
            transactionType: selectedFilter
          }
          break;
        case 2:
          params = {
            transactionType: selectedFilter
          }
          break;
      }
    }

    dispatch(downloadBilldeskTransactions(params));
  }

  const toggleAdvanceSearch = () => {
    if (selectSearch) {
      dispatch(getBilldeskTransactionInit());
      loadTransactionsList();
      setSelectSearch(false);
    } else {
      setSelectSearch(true);
    }
  }

  const filterByInsights = (type) => {
    if (selectedFilter == type) {
      setCurrentPage(1);
      dispatch(getBilldeskTransactionInit());
      setFilter(null);
      loadTransactionsList();
      return
    }
    let params;
    switch (type) {
      case 0:
        params = {
          TransactionType: Const.transactionTypes.pending,
          pageCount: 1,
          pageSize: Const.pageSize
        }
        break;
      case 1:
        params = {
          TransactionType: Const.transactionTypes.rejected,
          pageCount: 1,
          pageSize: Const.pageSize
        }
        break;
      case 2:
        params = {
          TransactionType: Const.transactionTypes.accepted,
          pageCount: 1,
          pageSize: Const.pageSize
        }
        break;
    }

    setCurrentPage(1);
    setSearchParams(params);
    setFilter(type);
    dispatch(getBilldeskTransactionInit());
    dispatch(changePreloader(true));
    dispatch(getBilldeskTransactions(params));
  }

  const onPagination = (type) => {
    let newPage;
    switch (type) {
      case 'prev':
        if (currentPage == 1) return
        newPage = currentPage - 1;
        setCurrentPage(newPage);
        break;
      case 'next':
        if (currentPage == totalPages) return
        newPage = currentPage + 1;
        setCurrentPage(newPage);
        console.log(currentPage);
        break;
    }
    dispatch(getBilldeskTransactionInit());
    dispatch(changePreloader(true));
    let params = { ...searchParams };
    params.pageCount = newPage;

    dispatch(getBilldeskTransactions(params));
  }

  return (
    <div className='ln-report'>
      <div className='billdesk'>
        {selectSearch ? (
          <AdvancedSearch
            onBack={() => { toggleAdvanceSearch() }}
            showTransactionTypeFilter={true}
            pageType='billdesk'
          />
        ) : (
          <Fragment>
            <div className='upper-section'>
              <div className='title-section'>
                <div className='title'>
                  Billdesk Transactions
                </div>
                <div
                  className='search'
                  onClick={setSelectSearch}>
                  <img src={Search} />
                  Advanced Search
                </div>
              </div>
              <div className='transaction-section'>
                <div className='transaction-visual'>
                  <div
                    onClick={() => {
                      filterByInsights(2)
                    }}>
                    <TransactionBox
                      selectedFilter={selectedFilter}
                      clearFilter={() => { filterByInsights(2) }}
                      id={2}
                      colorClass={'free-green'}
                      transactionNum={insightsData !== null ? insightsData.success : '--'}
                      transactionInfo='Successful Transactions'
                      transactionImg={
                        <img
                          src={RoundBtn}
                          className='round-btn'
                          alt=''
                        />
                      }
                    />
                  </div>
                  <div className='vertical-seperator'></div>
                  <div
                    onClick={() => {
                      filterByInsights(1)
                    }}>
                    <TransactionBox
                      selectedFilter={selectedFilter}
                      clearFilter={() => { filterByInsights(1) }}
                      id={1}
                      colorClass={'danger-red'}
                      transactionNum={insightsData !== null ? insightsData.failure : '--'}
                      transactionInfo='Failed Transactions'
                      transactionImg={
                        <img
                          src={RoundBtn}
                          className='round-btn'
                          alt=''
                        />
                      }
                    />
                  </div>
                  <div className='vertical-seperator'></div>
                  <div
                    onClick={() => {
                      filterByInsights(0)
                    }}>
                    <TransactionBox
                      selectedFilter={selectedFilter}
                      clearFilter={() => { filterByInsights(0) }}
                      id={0}
                      colorClass={'average-orange'}
                      transactionNum={insightsData !== null ? insightsData.pending : '--'}
                      transactionInfo='Pending Transactions'
                      transactionImg={
                        <img
                          src={RoundBtn}
                          className='round-btn'
                          alt=''
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <Table
              pageType="billdesk"
              page={currentPage}
              totalPages={totalPages}
              onPageChange={(type) => onPagination(type)}
              onDownload={() => { downloadTransactionsList() }}
            />
          </Fragment>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};
export default Billdesk;
