import {

    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAILURE,
    GET_ALL_USERS_INIT,

    GET_USER_DETAILS,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILURE,
    GET_USER_DETAILS_INIT,

    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILURE,
    EDIT_USER_INIT,

    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    DELETE_USER_INIT,

    INVITE_USER,
    INVITE_USER_SUCCESS,
    INVITE_USER_FAILURE,
    INVITE_USER_INIT,

    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,
    GET_ROLES_INIT,

    GET_PERMISSIONS,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS_FAILURE,
    GET_PERMISSIONS_INIT

} from './actionTypes'

const initialState = {
    allUsers: [],
    userDetails: null,
    updatedUser: null,
    newUser: null,
    allRoles: [],
    allPermissions: [],
    errorMessage: '',

    getAllUsersSuccess: false,
    getAllUsersFailure: false,
    getUserDetailsSuccess: false,
    getUserDetailsFailure: false,
    editUserSuccess: false,
    editUserFailure: false,
    deleteUserSuccess: false,
    deleteUserFailure: false,
    inviteUserSuccess: false,
    inviteUserFailure: false,
    getRolesSuccess: false,
    getRolesFailure: false,
    getPermissionsSuccess: false,
    getPermissionsFailure: false,
}

const ManageAccess = (state=initialState, action) => {
    switch(action.type){

        // ==== Cases for Get All Users ====
        case GET_ALL_USERS:
            return{
                ...state,
                getAllUsersSuccess: false,
                getAllUsersFailure: false,
            }

        case GET_ALL_USERS_SUCCESS:
            return{
                ...state,
                allUsers: action.payload,
                getAllUsersSuccess: true,
                getAllUsersFailure: false,
            }

        case GET_ALL_USERS_FAILURE:
            return{
                ...state,
                getAllUsersSuccess: false,
                getAllUsersFailure: true,
                errorMessage: action.payload
            }

        case GET_ALL_USERS_INIT:
            return{
                ...state,
                allUsers: [],
                getAllUsersSuccess: false,
                getAllUsersFailure: false,
                errorMessage: ''
            }


        // ==== Cases for Get User Details  ====
        case GET_USER_DETAILS:
            return{
                ...state,
                getUserDetailsSuccess: false,
                getUserDetailsFailure: false,
            }

        case GET_USER_DETAILS_SUCCESS:
            return{
                ...state,
                userDetails: action.payload,
                getUserDetailsSuccess: true,
                getUserDetailsFailure: false,
            }

        case GET_USER_DETAILS_FAILURE:
            return{
                ...state,
                getUserDetailsSuccess: false,
                getUserDetailsFailure: true,
                errorMessage: action.payload
            }

        case GET_USER_DETAILS_INIT:
            return{
                ...state,
                userDetails: null,
                getUserDetailsSuccess: false,
                getUserDetailsFailure: false,
                errorMessage: ''
            }


        // ==== Cases for Edit User ====
        case EDIT_USER:
            return{
                ...state,
                editUserSuccess: false,
                editUserFailure: false,
            }

        case EDIT_USER_SUCCESS:
            return{
                ...state,
                updatedUser: action.payload,
                editUserSuccess: true,
                editUserFailure: false,
            }

        case EDIT_USER_FAILURE:
            return{
                ...state,
                editUserSuccess: false,
                editUserFailure: true,
                errorMessage: action.payload
            }

        case EDIT_USER_INIT:
            return{
                ...state,
                updatedUser: null,
                editUserSuccess: false,
                editUserFailure: false,
                errorMessage: ''
            }    
        

        // ==== Cases for Delete User ====
        case DELETE_USER:
            return{
                ...state,
                deleteUserSuccess: false,
                deleteUserFailure: false,
            }

        case DELETE_USER_SUCCESS:
            return{
                ...state,
                updatedUser: action.payload,
                deleteUserSuccess: true,
                deleteUserFailure: false,
            }

        case DELETE_USER_FAILURE:
            return{
                ...state,
                deleteUserSuccess: false,
                deleteUserFailure: true,
                errorMessage: action.payload
            }

        case DELETE_USER_INIT:
            return{
                ...state,
                updatedUser: null,
                deleteUserSuccess: false,
                deleteUserFailure: false,
                errorMessage: ''
            }

        // ==== Cases for Invite User ====
        case INVITE_USER:
            return{
                ...state,
                inviteUserSuccess: false,
                inviteUserFailure: false,                
            }
            
        case INVITE_USER_SUCCESS:
            return{
                ...state,
                updatedUser: action.payload,
                inviteUserSuccess: true,
                inviteUserFailure: false,                
            }

        case INVITE_USER_FAILURE:
            return{
                ...state,
                inviteUserSuccess: false,
                inviteUserFailure: true,
                errorMessage: action.payload                
            }

        case INVITE_USER_INIT:
            return{
                ...state,
                updatedUser: null,
                inviteUserSuccess: false,
                inviteUserFailure: false,    
                errorMessage: ''
            }


        // ==== Cases for Get Roles ====
        case GET_ROLES:
            return{
                ...state,
                getRolesSuccess: false,
                getRolesFailure: false,
            }

        case GET_ROLES_SUCCESS:
            return{
                ...state,
                allRoles: action.payload,
                getRolesSuccess: true,
                getRolesFailure: false,
            }

        case GET_ROLES_FAILURE:
            return{
                ...state,
                getRolesSuccess: false,
                getRolesFailure: true,
                errorMessage: action.payload
            }

        case GET_ROLES_INIT:
            return{
                ...state,
                allRoles: [],
                getRolesSuccess: false,
                getRolesFailure: false,
                errorMessage: ''
            }


        // ==== Cases on Get Permissions ====
        case GET_PERMISSIONS:
            return{
                ...state,
                getPermissionsSuccess: false,
                getPermissionsFailure: false,
            }

        case GET_PERMISSIONS_SUCCESS:
            return{
                ...state,
                allPermissions: action.payload,
                getPermissionsSuccess: true,
                getPermissionsFailure: false,
            }

        case GET_PERMISSIONS_FAILURE:
            return{
                ...state,
                getPermissionsSuccess: false,
                getPermissionsFailure: true,
                errorMessage: action.payload
            }

        case GET_PERMISSIONS_INIT:
            return{
                ...state,
                allPermissions: null,
                getPermissionsSuccess: false,
                getPermissionsFailure: false,
                errorMessage: ''
            }

        // ==== default case ====
        default:
            return{ ...state }
    }
}

export default ManageAccess;