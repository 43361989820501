import React, { Component } from 'react';
import { Container, Button, Modal, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import InputBox from '../../components/Common/input-box';
import DropdownList from 'react-widgets/lib/DropdownList';
import Breadcrumbs from '../../components/Common/breadcrumb';

import * as ProductCategoryActions from '../../store/productCategory/actions'
import * as LayoutActions from '../../store/layout/actions'
import * as VendorActions from '../../store/vendor/actions'
import { sortIntegerTypeData, sortStringTypeData } from '../../helpers/sortHelper';

// Store
import { connect } from 'react-redux';
import Layout from '../../store/layout/reducer';


class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateModal: false,
            modalType: 'add',
            searchValue: '',
            vendorId: '',
            categoryName: '',
            subCategoryName: '',
            categoryID: '',
            vendorIdError: false,
            categoryNameError: false,
            subCategoryNameError: false,
            searchValueError: false,
            categoryList: [],
            vendorList: [],
            deleteConfirmPopUp: false,
            deleteConfirmPopupId: null,
            direction: 1,
            deleteIndex : null,
            editIndex : null,
            emptySearchResult : ''
        }
    }

    componentDidMount() {
        this.props.getVendors();
        this.props.changePreloader(true);
    }

    modalToggle = () => {
        let { showCreateModal } = this.state;
        this.setState({ vendorId: '', categoryName: '', subCategoryName: '', modalType: 'add', showCreateModal: !showCreateModal, vendorIdError: false, categoryNameError: false });
    }

    addClickHandler = (type, category) => {
        let { vendorId, categoryName, subCategoryName, categoryID } = this.state;
        let trimmedCategoryName = categoryName.trim()
        if ((vendorId !== '') && (trimmedCategoryName !== '')) {
            let data = {
                vendorId: vendorId,
                productCategoryName: trimmedCategoryName,
                subProductCategoryName: subCategoryName
            };
            if (type === 'add') {
                this.props.createProductCategory(data)
                this.props.changePreloader(true)
            } else if (type === 'edit') {
                //call edit api
                let newData = {
                    vendorId: vendorId,
                    categoryId: categoryID,
                    productCategoryName: trimmedCategoryName,
                    subProductCategoryName: subCategoryName,
                };
                this.props.editProductCategory(newData)
                this.props.changePreloader(true)
            }

        }

        if (vendorId === '') {
            this.setState({ vendorIdError: true });
        } else {
            this.setState({ vendorIdError: false });
        }
        if (trimmedCategoryName === '') {
            this.setState({ categoryNameError: true });
        } else {
            this.setState({ categoryNameError: false });
        }
    }

    onSearch = (e) => {
        if (e.keyCode === 13 || e.target.name === 'searchButton') {
            let { searchValue } = this.state

            // this.setState({ categoryList : [], emptySearchResult : ''})
            let trimmedSearchValue = searchValue.toString().trim()

            if (trimmedSearchValue !== '') {                                
                let data = {
                    search : trimmedSearchValue
                }
                this.setState({ searchValue : trimmedSearchValue, categoryList : [], emptySearchResult : '' }, ()=>{
                    this.props.getProductCategoryByName(data)
                    this.props.changePreloader(true);
                })

            } else {
                this.setState({ searchValueError: true })

            }
        }
    }

    onClearProductCategories = () => {
        this.setState({ searchValue : '', searchValueError : false })
        this.props.getProductCategoryByName()
        this.props.changePreloader(true)
    }

    inputHandler = (e) => {

        if (e.target.name === "categoryName") {
            this.setState({ categoryName: e.target.value, categoryNameError: false });
        }
        else if (e.target.name === "subCategoryName") {
            this.setState({ subCategoryName: e.target.value });
        }
        else {
            this.setState({ searchValue: e.target.value, searchValueError: false })
        }
    }

    editVendorHandler = (category, index) => {
        this.setState({ modalType: 'edit', showCreateModal: true, categoryName: category.productCategoryName, subCategoryName: category.subProductCategoryName, categoryID: category.productCategoryId, vendorId: category.vendorId, editIndex : index });
    }

    deleteHandler = (vendorId, categoryId, index) => {
        let data = {
            vendorId: vendorId,
            categoryId: categoryId
        }
        this.props.deleteProductCategory(data)
        this.props.changePreloader(true);
        this.setState({ deleteConfirmPopUp: false, deleteConfirmPopupId: '', deleteIndex : index});
    }

    deleteFromCategoryList = () => {
        let {categoryList, deleteIndex} = this.state
        categoryList.splice(deleteIndex, 1)
        this.setState({categoryList : categoryList, deleteIndex : null})
    }

    editOnCategoryList = (data) => {
        let { categoryList, editIndex } = this.state
        categoryList[editIndex] = data
        this.setState({ categoryList : categoryList, editIndex : null})
    }

    toggletop = (id) => { this.setState({ deleteConfirmPopUp: !this.state.deleteConfirmPopUp, deleteConfirmPopupId: id }); }

    // ==== Checking methods added below !!!! ====
    checkGetVendor() {
        let { vendorList } = this.state
        if (this.props.layout.isPreloader) {
            if (this.props.vendor.getVendorsSuccess === true) {
                this.setState({ vendorList: [...vendorList, ...this.props.vendor.vendors] });
                this.props.getVendorsInit();
                this.props.getProductCategoryByName()
            } else if (this.props.vendor.getVendorsFailure === true) {
                this.props.changePreloader(false);
                toast.error(this.props.vendor.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getVendorsInit();
            }
        }
    }

    checkGetProductCategory() {
        let { categoryList } = this.state
        if (this.props.layout.isPreloader) {
            if (this.props.productCategory.getProductCategoryByNameSuccess === true) {
                console.log("running check get")//...categoryList, 
                this.setState({ categoryList: [...this.props.productCategory.productCategoriesList] })
                this.props.getProductCategoryByNameInit()
                this.props.changePreloader(false)
            } else if (this.props.productCategory.getProductCategoryByNameFailure === true) {
                this.setState({ emptySearchResult : 'No Product Categories Found.'})
                this.props.changePreloader(false)
                this.props.getProductCategoryByNameInit()
            }
        }
    }

    checkCreateProductCategory() {
        if (this.props.layout.isPreloader) {
            if (this.props.productCategory.createProductCategorySuccess === true) {
                console.log("running check create")
                this.setState({ showCreateModal: false, categoryList: [] })
                toast.success("Product Category Created Successfully")
                this.props.createProductCategoryInit()
                // this.props.getProductCategoryByName()
                this.onClearProductCategories()
            } else if (this.props.productCategory.createProductCategoryFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.productCategory.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.createProductCategoryInit()
            }
        }
    }

    checkDeleteProductCategory() {
        if (this.props.layout.isPreloader) {
            if (this.props.productCategory.deleteProductCategorySuccess === true) {
                this.props.deleteProductCategoryInit()
                // this.setState({ categoryList: [] })
                // this.props.getProductCategory()
                this.deleteFromCategoryList()
                this.props.changePreloader(false)
                toast.success("Category Deleted Successfully")
            } else if (this.props.productCategory.deleteProductCategoryFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.productCategory.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.deleteProductCategoryInit()
            }
        }
    }

    checkEditProductCategory() {
        if (this.props.layout.isPreloader) {
            if (this.props.productCategory.editProductCategorySuccess === true) {
                this.setState({ showCreateModal: false, categoryList: [] })
                let data = this.props.productCategory.editProductCategoryDetails
                this.editOnCategoryList(data)
                this.props.editProductCategoryInit()
                this.props.changePreloader(false)
                toast.success("Category Updated Successfully")
                // this.props.getProductCategory()
                
            } else if (this.props.productCategory.editProductCategoryFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.productCategory.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.editProductCategoryInit()
            }
        }
    }

    sort = (sortBy, sortType) => {
        let order = this.state.direction === -1 ? 'desc' : 'asc';
        if (sortType === 'string') {
            let sortedData = sortStringTypeData(this.state.categoryList, sortBy, order);
            this.setState({ categoryList: sortedData, direction: this.state.direction === -1 ? 1 : -1 });
        } else {
            let sortedData = sortIntegerTypeData(this.state.categoryList, sortBy, order);
            this.setState({ categoryList: sortedData, direction: this.state.direction === -1 ? 1 : -1 })
        }
    }

    render() {
        let { searchValue, vendorId, vendorIdError, categoryNameError, modalType, categoryName, subCategoryName, searchValueError, categoryList, vendorList, deleteConfirmPopUp, deleteConfirmPopupId } = this.state;
        this.checkGetProductCategory()
        this.checkGetVendor()
        this.checkCreateProductCategory()
        this.checkDeleteProductCategory()
        this.checkEditProductCategory()
        return (
            <React.Fragment>
                <div className="page-content category-page">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        {/* ==== Uncomment Below to show bread crumbs ==== */}
                        {/* <Breadcrumbs title="Admin" breadcrumbItem="Manage Product Categories" /> */}
                    </Container>
                    <div className="actions-section">

                        <div className="actions-grid">
                            <InputBox type="text" label="Search Categories" key={`category ${1}`} name="SearchInputTag" value={searchValue} onChange={(e) => { this.inputHandler(e) }} onKeyDown={(e) => this.onSearch(e)} hasError={searchValueError} />
                            <Button name="searchButton" onClick={(e) => { this.onSearch(e) }}>Search</Button>
                            <div className="clear-btn" onClick = {() => {this.onClearProductCategories()}}>
                                <span>Clear</span>
                            </div>
                            <span className="divider"></span>
                            <Button onClick={this.modalToggle}>Add Category</Button>
                        </div>

                    </div>

                    <div className="table-section">
                        <div>
                            <div className="custom-table-head">
                                {/* <div>Product Category ID <i className="fas fa-sort"></i></div> */}
                                <div>Product Category Name <i className="fas fa-sort select-hover" onClick={() => { this.sort('productCategoryName', 'string') }}></i></div>
                                <div>Sub Category <i className="fas fa-sort select-hover" onClick={() => { this.sort('subProductCategoryName', 'string') }}></i></div>
                                <div>Vendor Name <i className="fas fa-sort select-hover" onClick={() => { this.sort('vendorName', 'string') }}></i></div>
                                <div>Actions </div>
                            </div>
                            {/* 
                            {this.props.productCategories.productCategories.map((val) => { */}
                            {categoryList.length !== 0 ? categoryList.map((val, index) => {
                                // console.log(val);
                                return (
                                    <div className="table-row-section">
                                        <div className="custom-table-body table-data-style">
                                            {/* <div className="has-edit-icon">{val.productCategoryId} </div> */}
                                            <div className="name-fieldbox">
                                                {val.productCategoryName}
                                            </div>
                                            <div className="has-edit-icon">{(val.subProductCategoryName !== undefined && val.subProductCategoryName.length !== 0) ? val.subProductCategoryName : '-'} </div>
                                            <div className="name-fieldbox">
                                                {val.vendorName}
                                            </div>
                                            <div className="p-10">
                                                <i className="fas fa-pencil-alt font-size-18 golden-color select-hover" onClick={() => { this.editVendorHandler(val, index) }} ></i>
                                                <i id={`pop${val.productCategoryId}`} className="fa fa-trash font-size-18 m-l-10 color-red select-hover" aria-hidden="true"></i>
                                                <Popover placement="top" isOpen={(deleteConfirmPopUp && deleteConfirmPopupId === val.productCategoryId) ? true : false} target={`pop${val.productCategoryId}`} toggle={() => { this.toggletop(val.productCategoryId) }}>
                                                    <PopoverHeader>Are you sure?</PopoverHeader>
                                                    <PopoverBody>
                                                        <div className="popconfirm-body">
                                                            <span onClick={() => { this.deleteHandler(val.vendorId, val.productCategoryId, index) }}>Yes</span>
                                                            <span onClick={() => { this.toggletop('') }}>No</span>
                                                        </div>
                                                    </PopoverBody>
                                                </Popover>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }):
                            <div>
                                <br></br>
                                <center><b>{this.state.emptySearchResult}</b></center>
                            </div>
                            }

                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.showCreateModal}
                    toggle={this.modalToggle}
                    className="modal-md m-t-15"
                >
                    <p className="modal-body-title">{modalType === 'add' ? 'Add' : 'Update'} Product Category</p>
                    <div className="modal-body custom-body-style">
                        <div className="form-section">
                            <DropdownList
                                className={`m-b-six  ${(vendorIdError) ? ' select-error' : ''}`}
                                name='vendorDropdown'
                                placeholder="Select Vendor"
                                data={vendorList}
                                defaultValue={vendorId}
                                textField="vendorName"
                                valueField="vendorId"
                                onChange={(e) => {
                                    this.setState({ vendorId: e.vendorId , vendorIdError:false });
                                }}
                                disabled={modalType === 'edit'}
                            />

                            <InputBox type="text" className="m-b-six" label="Category Name" name="categoryName" key={200} value={categoryName} onChange={(e) => { this.inputHandler(e) }} hasError={categoryNameError} />


                            <InputBox type="text" label="Subcategory (if any)" name="subCategoryName" value={subCategoryName} onChange={(e) => { this.inputHandler(e) }} hasError={false} />

                            <div className="footer-btns">
                                {modalType === 'add' ? (<Button onClick={() => { this.addClickHandler('add') }}>Add</Button>) : (<Button onClick={() => { this.addClickHandler('edit') }}>Update</Button>)}
                                <span onClick={() => { this.modalToggle()}} >Cancel</span>
                            </div>
                        </div>
                    </div>
                </Modal>
                <ToastContainer />
            </React.Fragment>
        )
    }
}
// const mapStatetoProps = state => {
//     const { countries, vendors, vendorResponse, productCategories } = state.Database;
//     return { countries, vendors, vendorResponse, productCategories };
// }

// export default connect(mapStatetoProps, {
//     getCountriesList, getVendorList, successCreateProductCategory, saveVendorList, deleteVendor,
//     saveProductCategories, deleteCategory, editVendorSuccess, editCategorySuccess
// })(Category)

const mapStateToProps = state => ({
    productCategory: state.ProductCategory,
    layout: state.Layout,
    vendor: state.Vendor
})

const mapDispatchToProps = dispatch => ({
    getProductCategory: (data) => dispatch(ProductCategoryActions.getProductCategory(data)),
    getProductCategoryInit: () => dispatch(ProductCategoryActions.getProductCategoryInit()),

    getProductCategoryByName : (data) => dispatch(ProductCategoryActions.getProductCategoryByName(data)),
    getProductCategoryByNameInit : () => dispatch(ProductCategoryActions.getProductCategoryByNameInit()),

    createProductCategory: (data) => dispatch(ProductCategoryActions.createProductCategory(data)),
    createProductCategoryInit: () => dispatch(ProductCategoryActions.createProductCategoryInit()),

    editProductCategory: (data) => dispatch(ProductCategoryActions.editProductCategory(data)),
    editProductCategoryInit: () => dispatch(ProductCategoryActions.editProductCategoryInit()),

    deleteProductCategory: (data) => dispatch(ProductCategoryActions.deleteProductCategory(data)),
    deleteProductCategoryInit: () => dispatch(ProductCategoryActions.deleteProductCategoryInit()),
    changePreloader: (flag) => dispatch(LayoutActions.changePreloader(flag)),

    getVendors: () => dispatch(VendorActions.getVendors()),
    getVendorsInit: () => dispatch(VendorActions.getVendorsInit())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Category)
