import moment from 'moment';
//Input date format: "31-08-2021 18:02:35"
//Output date format  "2021-08-31 18:32:36.796"
export function formatDate(dateString) {
    let dateArr = dateString.split('-');
    // console.log(dateArr, dateString);
    let dateOfMonth = dateArr[0];
    let month = dateArr[1];
    let year = dateArr[2].split(' ')[0];
    let time = dateArr[2].split(' ')[1];
    return `${year}-${month}-${dateOfMonth} ${time}.`;
}


export function convertDateStringToUTCDateTimeString(dateString) {
    let UTCDate = moment(dateString).subtract(1, 'day');
    let formattedDate = moment(UTCDate).format('YYYY/MM/DD');
    return formattedDate + ' 18:30:00';
}