import React, { Component } from "react";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, DropdownItem, DropdownToggle, Modal, ModalBody, DropdownMenu, Media, Dropdown, Popover, PopoverBody, UncontrolledPopover } from "reactstrap";
import { Link } from "react-router-dom";
import DeviceCard from "./deviceCard";
import ActivityComp from "../Dashboard/activityComp";
import { connect } from 'react-redux';
import DropdownList from 'react-widgets/lib/DropdownList';
import InputBox from '../../components/Common/input-box';
import TextBox from '../../components/Common/text-box';
import img from '../../assets/images/product/product.png';
import { ToastContainer, toast } from 'react-toastify';
import { Calendar, DateRange } from 'react-date-range'
import {Tabs, Tab, TabPanel, TabList} from 'react-web-tabs'
import * as jwt_decode from 'jwt-decode'
import moment from 'moment';
import { createAndDownloadBlobFile } from '../../helpers/downloadHelper';

// ==== Actions ====
import * as CustomerActions from '../../store/customer/actions'
import * as LayoutActions from '../../store/layout/actions'
import * as LoginActions from '../../store/auth/login/actions'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/breadcrumb';

// Images 
import detailBack from '../../assets/images/detail-back.png'

class Customers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentView: 'config',
            showDetails: false,
            inputPlaceholder: 'Type your search...',
            searchValidation : false,
            searchType: '',
            searchTypeError : false,
            filter: 'Exact', // uncomment to '' | OPTIONS : ['Contains', 'Starts With']
            searchString: '',
            filterTypes: [{ filterName: 'Mobile No' }, { filterName: 'Email ID' }, { filterName: 'Serial No' }],
            filterConditions: [{ filterName: 'Start with' }, { filterName: 'Contains' }, { filterName: 'Exact' }],
            customerList : [],
            customerDetails : null,
            searchParams : {
                pageCount : 1,
                pageSize : 10
            },
            configDetails : {
                action: "",
                adminRemarks: "",
                date: (new Date).toISOString().split("T")[0],
                raisedBy: "",
                recommendedAction: "",
                serialNo: "",
                serviceRequestNumber: "",
                ticketDetail: ""
            },
            configDetailsValidation : {
                action: false,
                adminRemarks: false,
                date: false,
                raisedBy: false,
                recommendedAction: false,
                serialNo: false,
                serviceRequestNumber: false,
                ticketDetail: false
            },
            actionChecks : {
                unbind : false,
                unblock : false,
                blockUnbind : false
            },
            selectedAction : null,
            userAllFilters : [],
            userFiltersSelected : {},
            userFiltersDeselected : {},
            adminAllFilters : [],
            adminFiltersSelected : {},
            adminFiltersDeselected : {},
            selectedLogTab : "user",
            userLogs : [],
            portalLogs : [],
            logKey : 1,
            selectionRange : {
                startDate : new Date(),
                endDate : new Date(),
                key : "selection"
            },
            filterPopperOpen : false,
            datePopperOpen : false,
            userId : null,
            userLastSortKeyContainer : 0,
            portalLastSortKeyContainer : 0,
            selectedUserId : null,
            selectedUserSerialNo : null,
            paginationState : false,
            otpValidation : false,
            otpValue : '',
            showOtpModal : false,

            selectedFilterObj : null,

        };
        this.dropDownRef = React.createRef()
    }

    componentDidMount(){
        this.setDates()
        let data = {
            userType : "user"
        }
        this.props.getUserLogFilters(data)
        this.extractUserId()
        this.props.changePreloader(true)
    }

    setDates() {
        let { selectionRange } = this.state
        let oldDate = new Date()
        oldDate.setDate( oldDate.getDate() - 80)
        let tempOldDate = new Date(oldDate)
        selectionRange.startDate = tempOldDate
        this.setState({ selectionRange : selectionRange})
    }
    
    extractUserId = () => {
        console.log("GETTING IN USER ID")
        let accessToken = this.props.login.access_token
        let decodedToken = jwt_decode(accessToken)
        console.log("***** DECODED TOKEN *****")
        console.log(decodedToken.user_id)
        this.setState({ userId : decodedToken.user_id })
    }


    searchTypeHandler = (val) => {
        this.setState({ searchType: val, inputPlaceholder: 'Enter ' + val });
    }

    filterHandler = (val) => {
        this.setState({ filter: val });
    }

    searchInputHandler = (val) => {
        this.setState({ searchString: val });
        console.log(val);
    }

    changeActionCheck = (newValue) => {
        let sampleActions = {
            unbind : false,
            unblock : false,
            blockUnbind : false
        }
        sampleActions[newValue] = true
        this.setState({ actionChecks : sampleActions, selectedAction : newValue, configDetailsValidation : {...this.state.configDetailsValidation, action : false}})
    }

    searchButtonHandler = (e) => {
    
        if(e.keyCode === 13 || e.target.name === "searchButton"){
            // console.log(this.props.customer.searchCustomerSuccess)
            // return
            let {searchType, filter, searchString} = this.state
            let validationCheck = false
            let trimmedSearchString = searchString.trim()
            if(searchString === ''){
                this.setState({ searchValidation : true })
                validationCheck = true
            }
            if(searchType === ''){
                this.setState({ searchTypeError : true })
                validationCheck = true
            }else if(searchType === "Email ID"){
                let regexEmail = new RegExp(/@+\S+\./)
                if(!regexEmail.test(searchString)){
                    this.setState({ searchValidation : true })
                    validationCheck = true
                }
            }

            if(validationCheck){
                return
            }

            if(searchType === "Mobile No"){
                let regexNumber = /^\+91/
                console.log("Inside Mobile  No")
                if(!regexNumber.test(trimmedSearchString)){
                    trimmedSearchString = "+91" + trimmedSearchString
                    this.setState({ searchString : trimmedSearchString })
                }
            }
            let params = {
                search: searchType,
                filter: filter,
                value: trimmedSearchString,
                pageCount: 1,
                pageSize: 10
            }
            this.setState({ showDetails: false, customerDetails: null, customerList : [], searchString : trimmedSearchString });
            this.resetSystemConfigDetails("search")
            this.props.searchCustomers(params)
            this.props.changePreloader(true)
        }

    }

    showDetails = (serialNumber, userId) => {
        let data =  {
            serialNo : serialNumber
        }
        this.setState({ selectedUserId : userId, selectedUserSerialNo : serialNumber}, ()=>{
            // ==== Calling API after setting the user and it's serial number for Logs ====
            this.props.getCustomerDetails(data)
            this.props.changePreloader(true)
            this.setState({ showDetails: true });
        })

    }

    inputHandler = (e) => {

        if(e.target.name === "searchValue"){
            let reg = new RegExp(/^([0-9]|\+)*$/)
            if(this.state.searchType === "Mobile No"){
                if(!reg.test(e.target.value) || (e.target.value).length > 13){
                    return
                }
            }
            this.setState({
                searchString : e.target.value,
                searchValidation : false
            })
        }

        if(e.target.name === "serviceRequestNumber"){
            this.setState({ 
                configDetails : {...this.state.configDetails, serviceRequestNumber : e.target.value},
                configDetailsValidation : {...this.state.configDetailsValidation, serviceRequestNumber : false}
            })
        }
        else if(e.target.name === "ticketRaisedBy"){
            this.setState({ 
                configDetails : {...this.state.configDetails, raisedBy : e.target.value},
                configDetailsValidation : {...this.state.configDetailsValidation, raisedBy : false}
            })
        }
        else if(e.target.name === "ticketDate"){
            console.log(e.target)
            console.log(e.target.value)
            this.setState({ 
                configDetails : {...this.state.configDetails, date : e.target.value},
                configDetailsValidation : {...this.state.configDetailsValidation, date : false}
            })
        }
        else if(e.target.name === "ticketDetails"){
            this.setState({ 
                configDetails : {...this.state.configDetails, ticketDetail : e.target.value},
                configDetailsValidation : {...this.state.configDetailsValidation, ticketDetail : false}
            })
        }
        else if(e.target.name === "adminRemarks"){
            this.setState({ 
                configDetails : {...this.state.configDetails, adminRemarks : e.target.value},
                configDetailsValidation : {...this.state.configDetailsValidation, adminRemarks : false}
            })
        }
        else if(e.target.name === "recommendedAction"){
            this.setState({ 
                configDetails : {...this.state.configDetails, recommendedAction : e.target.value },
                configDetailsValidation : {...this.state.configDetailsValidation, recommendedAction : false}
            })
        }
    }

    submitChangeConfigDetails = (sendType, otpAddedValue, configPayload) => {

        configPayload.type = sendType
        configPayload.otp = otpAddedValue
        console.log(configPayload)
        // ==== Uncomment Below values when issue is fixed ====
        this.props.changeConfig(configPayload)
        this.props.changePreloader(true)
        // this.resetSystemConfigDetails()
        // toast.success("Configuration Changed Successfully")
    }


    implementChangeConfig(submitCase){    
        let { configDetails, customerDetails, selectedAction, configDetailsValidation} = this.state

        // ==== State validation for the form ====
        let configPayload = {
            ...configDetails,
            serialNo : customerDetails.serialNumber,
            action : selectedAction
        }
        let validationFailed = false
        let configDetailKeys = Object.keys(configPayload)
        for(let detailKey of configDetailKeys){
            if(configPayload[detailKey] === ''){
                configDetailsValidation[detailKey] = true
                validationFailed = true
            }
        }
        if(selectedAction === null){
            configDetailsValidation["action"] = true
            validationFailed = true
        }
        this.setState({ configDetailsValidation : configDetailsValidation})
        console.log(configDetailsValidation)
        console.log(configPayload)
        if(validationFailed){
            return
        }
        
        // ==== Validation for Form cleared and proceeded with OTP / Verify ====
        if(submitCase === "verify"){
            let { otpValue } = this.state
            if(otpValue === ''){
                this.setState({otpValidation : true})
                return
            }else{
                this.submitChangeConfigDetails("changeConfig", parseInt(otpValue), configPayload)
            }
        }else if(submitCase === "sendOtp"){
            this.toggleModal()
            this.submitChangeConfigDetails("send", 0, configPayload)

        }
        else if(submitCase === "reSendOtp"){
            this.setState({ otpValue : ''}, ()=>{
                this.submitChangeConfigDetails("reSend", 0, configPayload)
            })
        }else{
            this.submitChangeConfigDetails("changeConfig", 0, configPayload)
        }
    }

    toggleModal = () => {
        let { showOtpModal } = this.state
        this.setState({ showOtpModal : !showOtpModal })

    }


    resetSystemConfigDetails = (callFrom=null) => {
        let { otpValue, selectedAction } = this.state
        if((selectedAction === "blockUnbind" || selectedAction === "unbind") && otpValue === ''){
            toast.success("OTP Sent Successfully")
            return

        }
        this.setState({
            configDetails : {
                action: "",
                adminRemarks: "",
                date: (new Date).toISOString().split("T")[0],
                raisedBy: "",
                recommendedAction: "",
                serialNo: "",
                serviceRequestNumber: "",
                ticketDetail: ""
            },
            actionChecks : {
                unbind : false,
                unblock : false,
                blockUnbind : false
            },
            selectedAction : null,
            otpValidation : false,
            otpValue : '',
            showOtpModal : false
        }, ()=>{
            if(callFrom === null){
                toast.success("Configuration Changed Successfully.")
            }
        })
    }

    setInitialFilter = (userType) => {
        let {userAllFilters, userFiltersSelected, adminAllFilters, adminFiltersSelected } = this.state
        console.log("#### start filter process : ")
        console.log(this.state.userAllFilters)
        if(userType === "user"){
            userAllFilters.map((filter)=> {
                filter.isChecked = true
                userFiltersSelected[filter.permissionName] = filter.permissionName
            })
            console.log("#### Mid filter process : ")
            console.log(userAllFilters)
            this.setState({
                userAllFilters : userAllFilters,
                userFiltersSelected : userFiltersSelected
            },
            () => {
                console.log("#### Filters : ")
                console.log(this.state.userFiltersSelected)
                console.log(this.state.userAllFilters)
            })
        }
        else if(userType === "admin"){
            adminAllFilters.map((filter)=> {
                filter.isChecked = true
                adminFiltersSelected[filter.permissionName] = filter.permissionName
            })
            console.log("#### Mid filter process : ")
            console.log(adminAllFilters)
            this.setState({
                adminAllFilters : adminAllFilters,
                adminFiltersSelected : adminFiltersSelected
            },
            () => {
                console.log("#### Filters : ")
                console.log(this.state.adminFiltersSelected)
                console.log(this.state.adminAllFilters)
                // ==== UNCOMMENT THIS ONCE LOGS ARE FIXED ====
                // this.getLogs("user")
            })
        }

    }

    setSelectedDate(selectedDateRange) {
        console.log(selectedDateRange)
        // return
        this.setState({ selectionRange : selectedDateRange.selection})
    }

    switchToCardView(){
        this.setState({
            // currentView : "overview", // Uncomment this when overview is ready
            customerDetails : null,
            showDetails : false,
            selectedUserId : null,
            selectedUserSerialNo : null,

            // ==== clearing details ====
            configDetailsValidation : {
                action: false,
                adminRemarks: false,
                date: false,
                raisedBy: false,
                recommendedAction: false,
                serialNo: false,
                serviceRequestNumber: false,
                ticketDetail: false
            },
            configDetails : {
                action: "",
                adminRemarks: "",
                date: (new Date).toISOString().split("T")[0],
                raisedBy: "",
                recommendedAction: "",
                serialNo: "",
                serviceRequestNumber: "",
                ticketDetail: ""
            },
            actionChecks : {
                unbind : false,
                unblock : false,
                blockUnbind : false
            },
            selectedAction : null,
        })
    }

    changeFilter(filterValue, index){
        let { selectedLogTab, adminAllFilters, userAllFilters } = this.state
        if(selectedLogTab === "user"){
            let checkValue = userAllFilters[index].isChecked
            checkValue ? userAllFilters[index].isChecked = false : userAllFilters[index].isChecked = true
            this.setState({
                userAllFilters : userAllFilters
            })
        }
        else{
            let checkValue = adminAllFilters[index].isChecked
            checkValue ? adminAllFilters[index].isChecked = false : adminAllFilters[index].isChecked = true
            this.setState({
                adminAllFilters : adminAllFilters
            })
        }
    }

    toggleLogsPopover(type){
        let {filterPopperOpen, datePopperOpen } = this.state
        if(type === "date"){
            if(datePopperOpen){
                this.setState({ datePopperOpen : false, paginationState : false, userLastSortKeyContainer : 0, portalLastSortKeyContainer : 0 }, ()=>{
                    console.log("Popper closed!!!!")
                    // ==== UNCOMMENT THIS ONCE LOGS ARE FIXED ====
                    this.getLogs(null)
                }) 

            }else {
                this.setState({ datePopperOpen : true }, ()=>{console.log("Popper Open!!!!")} )
            }
        }
        else if(type === "filter"){
            if(filterPopperOpen){
                this.setState({ filterPopperOpen : false, paginationState : false, userLastSortKeyContainer : 0, portalLastSortKeyContainer : 0 }, ()=>{
                    console.log("Popper closed!!!!")
                    // ==== UNCOMMENT THIS ONCE LOGS ARE FIXED ====
                    this.getLogs(null)
                }) 

            }else {
                this.setState({ filterPopperOpen : true }, ()=>{console.log("Popper Open!!!!")} )
            }
        }
    }

    downloadLogsCaller(){
        let {selectionRange, userFiltersSelected, adminFiltersSelected, selectedLogTab, selectedUserId, selectedUserSerialNo  } = this.state
        console.log("##### Portal Log data!")
        let userEventArr = Object.keys(userFiltersSelected)
        let adminEventArr = Object.keys(adminFiltersSelected)
        let data = {
            activityLogs : "userActivity",
            eventType : userEventArr,
            fromDate : selectionRange.startDate,
            toDate : selectionRange.endDate
        }
        console.log(data)
        let paramData = {}        
        console.log(JSON.stringify(paramData))

        if(selectedLogTab === "user"){
            paramData = {
                inputActivityLogsDto : data,
                userId : selectedUserId,
                serialNumber : null
            }
            this.props.downloadLogs(paramData)
        }
        else if(selectedLogTab === "admin"){
            data.activityLogs = "devicePortal"
            data.eventType = adminEventArr
            console.log(data)
            paramData = {
                inputActivityLogsDto : data,
                userId : 0,
                serialNumber : selectedUserSerialNo,
            }

            this.props.downloadLogs(paramData)
        }
    }

    getLogs(userValue) {
        let {selectionRange, userFiltersSelected, adminFiltersSelected, userId, selectedLogTab, selectedUserId, selectedUserSerialNo,
            userLastSortKeyContainer, portalLastSortKeyContainer } = this.state
        console.log("##### Portal Log data!")
        let userEventArr = Object.keys(userFiltersSelected)
        let adminEventArr = Object.keys(adminFiltersSelected)
        
        if(userValue === null){
            userValue = selectedLogTab
        }

        // ==== Common Log data ====
        let paramData = {}
        let data = {
            fromDate : selectionRange.startDate,
            toDate : selectionRange.endDate
        }

        // ==== Getting user logs ====
        if(userValue === "user"){
            data.eventType = userEventArr
            // data.eventType = []
            data.activityLogs = "userActivity"
            paramData = {
                inputActivityLogsDto : data,
                userId : selectedUserId,
                serialNumber : null,
                pageLimit : 15,
                lastSortKey : userLastSortKeyContainer
            }
    
            this.props.getUserLogs(paramData)
        }

        // ==== Getting admin Logs ====
        else if(userValue === "admin"){
            data.eventType = adminEventArr
            data.activityLogs = "devicePortal"
            paramData = {
                inputActivityLogsDto : data,
                userId : 0,
                serialNumber : selectedUserSerialNo,
                pageLimit : 15,
                lastSortKey : portalLastSortKeyContainer
            }
    
            this.props.getPortalLogs(paramData)
        }
    }

    failedLogs = (userType) => {
        let {paginationState} = this.state
        let customChange = {}
        userType === "admin" ? customChange = {portalLogs : []} : customChange = {userLogs : []}
        if(paginationState){
            this.setState({
                paginationState : false
            })
        }else{
            this.setState({
                paginationState: false,
                ...customChange,
            })
        }
    }


    updateLogList() {
        console.log("Inside Update List")
        let {paginationState} = this.state
        if(paginationState === false){
            this.setState({
                paginationState : true
            }, ()=> {this.getLogs(null)}
            )
        }
    }

    clearSearchDetails() {
        console.log("##### Logs : ")
        console.log(this.state.userLogs)
        console.log(this.state.portalLogs)
        this.setState({
            configDetailsValidation : {
                action: false,
                adminRemarks: false,
                date: false,
                raisedBy: false,
                recommendedAction: false,
                serialNo: false,
                serviceRequestNumber: false,
                ticketDetail: false
            },
            customerList : [],
            customerDetails : null,
            configDetails : {
                action: "",
                adminRemarks: "",
                date: (new Date).toISOString().split("T")[0],
                raisedBy: "",
                recommendedAction: "",
                serialNo: "",
                serviceRequestNumber: "",
                ticketDetail: ""
            },
            actionChecks : {
                unbind : false,
                unblock : false,
                blockUnbind : false
            },
            selectedAction : null,
            searchString : "",
            searchValidation : false,
            searchTypeError : false,
            selectedFilterObj : null,
            searchType : '',
        })
    }

    filterAllLogs(logDetails, userType){
        let { paginationState } = this.state
        let lastSortKeyDetails = 0
        logDetails.filter((logValue) => {
            logValue.displayDate = moment(logValue.eventDate).format("MMM Do YYYY")            
        })

        console.log("#### Log details filtered :")
        console.log(logDetails)
        
        lastSortKeyDetails = logDetails[logDetails.length - 1].sortKey
        console.log(lastSortKeyDetails)

        if(userType === "admin"){

            if(paginationState){
                this.setState({ portalLogs : [...this.state.portalLogs, ...logDetails], portalLastSortKeyContainer : lastSortKeyDetails, paginationState : false})
            }else{
                this.setState({ portalLogs : logDetails, logKey : Math.random().toFixed(2)*100, paginationState : false })
            }
                  
        }
        else if(userType === "user"){
            let currentPaginationState = paginationState
            if(paginationState){
                this.setState({ userLogs : [...this.state.userLogs, ...logDetails], userLastSortKeyContainer : lastSortKeyDetails, paginationState : false}, ()=>{
                    // ==== UNCOMMENT THIS ONCE LOGS ARE FIXED ====
                    if(currentPaginationState === false){this.getLogs("admin")}
                })
            }else{
                this.setState({ userLogs : logDetails, logKey : Math.random().toFixed(2)*100, paginationState : false }, ()=>{
                    // ==== UNCOMMENT THIS ONCE LOGS ARE FIXED ====
                    if(currentPaginationState === false){this.getLogs("admin")}
                })
            }
        }
    }

    switchTabs(tabId) {
        this.setState({
            selectedLogTab : tabId,
            logKey : Math.random().toFixed(2)*100
        })
    }

    // ==== CHECK METHODS START HERE!! ====
    checkSearchCustomer() {
        if(this.props.layout.isPreloader){
            console.log("#####  Inside Search preloader")
            console.log(this.props.customer.searchCustomerSuccess)
            if(this.props.customer.searchCustomerSuccess === true){
                console.log("#####  Inside Search Success")
                this.setState({ customerList : this.props.customer.customerList, showDetails : false})
                this.props.searchCustomersInit()
                this.props.changePreloader(false)
            }
            else if(this.props.customer.searchCustomerFailure === true){
                console.log("#####  Inside Search Failure")
                this.props.changePreloader(false)
                console.log("##### Preloader Stopped from Failure!!")
                toast.error(this.props.customer.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.log("##### Ending search. Initing surface")
                this.props.searchCustomersInit()
            }
        }
    }

    checkCustomerDetails(){
        if(this.props.layout.isPreloader){
            if(this.props.customer.getCustomerDetailsSuccess === true){
                this.setState({ customerDetails : this.props.customer.customerDetails})
                this.props.getCustomerDetailsInit()
                this.props.changePreloader(false)
                // this.getLogs("admin")
                this.getLogs("user")
            }
            else if(this.props.customer.getCustomerDetailsFailure === true){
                this.props.changePreloader(false)
                toast.error(this.props.customer.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getCustomerDetailsInit()
            }
        }
    }

    checkChangeConfig(){
        if(this.props.layout.isPreloader){
            if(this.props.customer.changeConfigSuccess === true){
                this.resetSystemConfigDetails()
                this.props.changeConfigInit()
                this.props.changePreloader(false)
            }
            else if(this.props.customer.changeConfigFailure === true){
                this.props.changePreloader(false)
                toast.error(this.props.customer.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.changeConfigInit()
            }
        }
    }

    checkGetUserLogFilters(){
        if(this.props.layout.isPreloader){
            if(this.props.customer.getUserLogFiltersSuccess === true){
                this.setState({ userAllFilters : this.props.customer.logFilters}, 
                    () => {
                        this.setInitialFilter("user")
                        let data = {
                            userType : "admin"
                        }
                        this.props.getPortalLogFilters(data)
                    })
                this.props.getUserLogFiltersInit()
            }
            else if(this.props.customer.getUserLogFiltersFailure === true){
                this.props.changePreloader(false)
                toast.error(this.props.customer.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getUserLogFiltersInit()
            }
        }
    }

    checkGetPortalLogFilters(){
        if(this.props.layout.isPreloader){
            if(this.props.customer.getPortalLogFiltersSuccess === true){
                this.setState({ adminAllFilters : this.props.customer.logFilters}, 
                    () => {this.setInitialFilter("admin")})
                this.props.getPortalLogFiltersInit()
                this.props.changePreloader(false)
            }
            else if(this.props.customer.getPortalLogFiltersFailure === true){
                this.props.changePreloader(false)
                toast.error(this.props.customer.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getPortalLogFiltersInit()
            }
        }
    }

    checkGetPortalLogs(){
        if(this.props.customer.getPortalLogsSuccess === true){
            // this.setState({ portalLogs : this.props.customer.logDetails})
            let logDetails = this.props.customer.logDetails
            this.filterAllLogs(logDetails, "admin")
            this.props.getPortalLogsInit()
        }
        else if(this.props.customer.getPortalLogsFailure === true){
            this.failedLogs("admin")
            // toast.error(this.props.customer.errorMessage, {
            //     position: "bottom-right",
            //     autoClose: 2000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            this.props.getPortalLogsInit()
        }
    }

    checkGetUserLogs(){
        if(this.props.customer.getUserLogsSuccess === true){
            // this.setState({ portalLogs : this.props.customer.logDetails})
            let logDetails = this.props.customer.logDetails
            this.filterAllLogs(logDetails, "user")
            // console.log("Logs")
            this.props.getUserLogsInit()
        }
        else if(this.props.customer.getUserLogsFailure === true){
            this.failedLogs("user")
            // toast.error(this.props.customer.errorMessage, {
            //     position: "bottom-right",
            //     autoClose: 2000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            this.props.getUserLogsInit()
        }
    }

    checkDownloadLogs(){
        if(this.props.customer.downloadLogsSuccess === true){
            let arrayBuffer = this.props.customer.logFile
            createAndDownloadBlobFile(arrayBuffer, 'Log_File')
            toast.success("Log Report Downloaded Successfully")
            this.props.downloadLogsInit()
        }
        else if(this.props.customer.downloadLogsFailure === true){
            toast.error(this.props.customer.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.downloadLogsInit()
        }
    }



    render() {
        let { searchString, filterTypes, filterConditions, showDetails, currentView, customerList, customerDetails, configDetails, actionChecks,
        configDetailsValidation, userAllFilters, adminAllFilters, selectedLogTab, selectionRange, searchValidation, searchType, userLogs, portalLogs, logKey,
        searchTypeError, selectedAction, otpValidation, otpValue, showOtpModal, selectedFilterObj } = this.state;
        let testArray = [10,20,30,40,50,60,70,80,90,100,110,120,130,140,150,160,170,180,1,2,3,4,5,6,7,8,9,10,10,20,30,40,50,60,70,80,90,100,110,120,130,140,150,160,170,180,1,2,3,4,5,6,7,8,9,10]
        let filterList = []
        let activeLogList = []
        if(selectedLogTab === "user"){
            filterList=userAllFilters 
            activeLogList = userLogs
        }else{
            filterList=adminAllFilters
            activeLogList = portalLogs
        } 

         // ==== Calling refresher method ====
         this.checkSearchCustomer()
         this.checkCustomerDetails()
         this.checkChangeConfig()
         this.checkGetUserLogFilters()
         this.checkGetPortalLogFilters()
         this.checkGetPortalLogs()
         this.checkGetUserLogs()
         this.checkDownloadLogs()
         
        return (
            <React.Fragment>
                <div className="page-content customer-page">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        {/* <Breadcrumbs title="Admin" breadcrumbItem="Customers" /> */}
                    </Container>

                    <div className="search-section">
                        <h5 className="font-weight-bold no-mar">Search Users</h5>
                        <p>Search using Mobile No / Email ID / Device Serial Number</p>
                        <div className="input-grid-section">
                            <DropdownList
                                className={ "t-a-l " + ((searchTypeError) ? 'select-error' : '')}
                                placeholder="Search by"
                                data={filterTypes}
                                // defaultValue={searchType}
                                textField="filterName"
                                valueField="filterName-02"
                                value={selectedFilterObj}
                                onChange={(e) => {
                                    this.setState({ searchType: e.filterName, searchString : "", searchTypeError : false, selectedFilterObj : {"filterName" : e.filterName} });
                                }}
                                ref={this.dropDownRef}                      
                            />
                            <InputBox className={searchType === '' ? "select-hover-not-allowed" : ""} type="text" label="Search Customer" key={`searchText`} name="searchValue" value={searchString} onChange={(e)=>{this.inputHandler(e)}} onKeyDown={(e)=>{this.searchButtonHandler(e)}} hasError={searchValidation} disabled={ searchType === '' ? true : false} />
                            <Button name="searchButton" onClick={this.searchButtonHandler} >Search</Button>
                            <Button onClick={()=>{this.clearSearchDetails()}} > Clear </Button>
                        </div>
                    </div>

                    {showDetails === false ? (
                        <div className="devices-section">
                            {customerList.length !== 0 ? customerList.map((val) => {
                                return <DeviceCard data={val} clickHandler={() => {this.showDetails(val.serialNumber, val.userId)}} />
                            }) : ''}
                        </div>
                    ) : ''}
                    
                    

                    {showDetails === true && customerDetails !== null ? (
                        <div className="grid-box">
                            <div className="details-section">
                                <div className="detail-section-back ">
                                    <img className="select-hover" src={detailBack} alt="Lets go back" onClick={() => {this.switchToCardView()}} />
                                </div>
                                <div className="head-detail-section">
                                    <img src={img} alt="" />
                                    {/* <img src={customerDetails.deviceImageUrl !== null ? customerDetails.deviceImageUrl : img} alt="" /> */}
                                    <div className="equal-grid">
                                        {/* ==== Uncomment below to test ==== */}
                                        {/* <div>
                                            <p className="font-size-16">User Name : <span>John Smith</span></p>
                                            <p className="font-size-16">Mobile Number : <span>+91 8787656545</span></p>
                                            <p className="font-size-16">Email ID : <span>Johnsmith@gmail.com</span></p>                                            
                                        </div>
                                        <div>
                                            <p className="font-size-16">Serial Number : <span>8845</span></p>
                                            <p className="font-size-16">Camera Status : <span>Offline</span></p>
                                            <p className="font-size-16">Device Type : <span>Fixed</span></p>
                                        </div> */}
                                        <div>
                                            <p className="font-size-16">User Name : <span>{customerDetails.userName}</span></p>
                                            <p className="font-size-16">Mobile Number : <span>{customerDetails.mobNumber}</span></p>
                                            <p className="font-size-16">Email ID : <span>{customerDetails.emailId}</span></p>                                            
                                        </div>
                                        <div>
                                            <p className="font-size-16">Serial Number : <span>{customerDetails.serialNumber}</span></p>
                                            <p className="font-size-16">Camera Status : <span>{customerDetails.cameraStatus}</span></p>
                                            <p className="font-size-16">Device Type : <span>{customerDetails.productType}</span></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="details-section-body">
                                    <div>
                                        <span className={currentView === "overview" ? "active-menu" : "menu-btn"} /*onClick={() => { this.setState({ currentView: 'overview' }) }}*/>SD Card & Cloud</span>
                                        <span className={currentView === "config" ? "active-menu" : "menu-btn"} /*onClick={() => { this.setState({ currentView: 'config' }) }}*/>System Tools</span>
                                    </div>
                                    {currentView === 'overview' ? (
                                        <div className="display-screen">
                                            <div className="grey-border">
                                                <p>Current Plan : <span>Intelligent Pro</span></p>
                                                <p>Plan Details : </p>
                                                <p>Cloud Storage : <span>30 days of Recording</span></p>
                                                <p>Record Type : <span>24x7, Motion Based</span></p>

                                                <p><span>Video Analytic's, Face Recognition, Unknown Person Detection, Remote Monitoring,
                                                Pet Detection, Fall Detection, Emergency response team support</span></p>

                                                <div className="equal-boxes">
                                                    <Button className="round-btn mr-2">Change Plan</Button>
                                                    <Button className="round-btn" onClick={() => { this.setState({ currentView: 'config' }) }}>Change Configuration</Button>
                                                </div>
                                            </div>
                                        </div>) : ''}

                                    {currentView === 'config' ? (
                                        <div className="display-screen">
                                            <div className="grey-border">
                                                <h5 className='font-weight-bold mb-3'>Change Configuration Form : </h5>
                                                <InputBox type="text" className="m-b-three" label="Service Request Number*" name="serviceRequestNumber" value={configDetails.serviceRequestNumber} onChange={(e) => {this.inputHandler(e)}} hasError={configDetailsValidation.serviceRequestNumber} />
                                                <InputBox type="text" className="m-b-three" label="Ticket Raised By*" name="ticketRaisedBy" value={configDetails.raisedBy} onChange={(e)=>{this.inputHandler(e)}} hasError={configDetailsValidation.raisedBy} />
                                                <input disabled={false} className="form-control m-b-three" type="date" name="ticketDate" id="example-date-input" value={configDetails.date} max = {(new Date).toISOString().split("T")[0]} onChange={(e)=>{this.inputHandler(e)}}/>
                                                {/* <span>Ticket Details :</span> */}
                                                {/* <textarea rows="4" cols="50" placeholder="Ticket Details" className="text-area-element" name="ticketDetails" value={configDetails.ticketDetail} onChange={(e)=>{this.inputHandler(e)}}  hasError={configDetailsValidation.ticketDetail}/> */}
                                                <TextBox name="ticketDetails" label="Ticket Details*" value={configDetails.ticketDetail} onChange={(e)=>{this.inputHandler(e)}} hasError={configDetailsValidation.ticketDetail} rows="4" cols="50" className="m-b-three height-auto"/>
                                                <InputBox type="text" className="m-b-three" label="Recommended Action*" name="recommendedAction" value={configDetails.recommendedAction} onChange={(e)=>{this.inputHandler(e)}} hasError={configDetailsValidation.recommendedAction} />

                                                <p className="font-weight-bold mt-2 font-fourteen">Select Action* :</p>
                                                {
                                                    configDetailsValidation.action ? <p className="action-validation-text"> Please Select an Action. </p> : ''
                                                }
                                                {/* <p className="action-validation-text"> Please Select an Action. </p> */}
                                                <div className="font-fourteen m-b-three p-l-10" hasError={configDetailsValidation.action}>
                                                    <input type="radio" className="filter-checkbox" name="isVendor" value={actionChecks.blockUnbind ? 1 : 0} checked={actionChecks.blockUnbind} onClick={() => {this.changeActionCheck("blockUnbind")}}/>&nbsp;
                                                    <label>Block and Unbind Device</label><br></br>
                                                    <input type="radio" className="filter-checkbox" name="isVendor" value={actionChecks.unbind ? 1 : 0} checked={actionChecks.unbind} onClick={() => {this.changeActionCheck("unbind")}} />&nbsp;
                                                    <label>Unbind Device</label><br></br>
                                                    <input type="radio" className="filter-checkbox" name="isVendor" value={actionChecks.unblock ? 1 : 0} checked={actionChecks.unblock} onClick={() => {this.changeActionCheck("unblock")}} />&nbsp;
                                                    <label>Unblock Device</label>
                                                </div>                                                

                                                <TextBox className="m-b-three height-auto" label="Admin Remarks*" rows="4" cols="50" name="adminRemarks" value={configDetails.adminRemarks} onChange={(e)=>{this.inputHandler(e)}} hasError={configDetailsValidation.adminRemarks}/>
                                                <Button className="btn btn-secondary m-v-2 " onClick={selectedAction === "blockUnbind" || selectedAction === "unbind" ? ()=>{this.implementChangeConfig("sendOtp")} : ()=>{this.implementChangeConfig(null)} }>{selectedAction === "blockUnbind" || selectedAction === "unbind" ? "Send OTP" : "Confirm"}</Button>
                                            </div>
                                        </div>) : ''}
                                </div>
                            </div>
                            <div>
                                <div className="head-section">
                                    <div className="title-logo-div">
                                        {/* <i className="fas fa-wave-square icon-sizing"></i> */}
                                        {/* <p className="large-header-text text-muted ml-20">User Activity Log</p> */}
                                        <div>
                                        <Tabs
                                            defaultTab = "user"
                                            onChange={(tabId)=>{this.switchTabs(tabId)}}>
                                            <TabList className="custom-tab-button">
                                                <Tab tabFor="user">User Activity</Tab>
                                                <Tab tabFor="admin">Portal Activity</Tab>
                                            </TabList>
                                        </Tabs>
                                        </div>

                                    </div>
                                    <div className="setting-icons-div">
                                        <div className="fas fa-file-download select-hover" onClick={()=>{this.downloadLogsCaller()}}></div>
                                        <div id="filtersDiv" className="fas fa-filter select-hover"></div>
                                        <div id="datePickerDiv" className="fas fa-calendar-alt select-hover"></div>
                                        <UncontrolledPopover trigger="legacy" placement="bottom-end" target="datePickerDiv" isOpen={this.state.datePopperOpen} toggle={()=>{this.toggleLogsPopover("date")}}>
                                            <PopoverBody className="popper-width-inc">
                                                <DateRange
                                                    date={new Date()}
                                                    rangeColors="#3d91ff"
                                                    onChange={(val)=>{this.setSelectedDate(val)}}
                                                    ranges={[selectionRange]}
                                                    maxDate={new Date()}
                                                />
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                        <UncontrolledPopover trigger="legacy" placement="bottom-end" target="filtersDiv" isOpen={this.state.filterPopperOpen} toggle={()=>{this.toggleLogsPopover("filter")}}>
                                            <PopoverBody className="popper-width-inc">
                                                <div className="font-fourteen">
                                                    {
                                                        filterList.map((filter, index) => {
                                                            return(
                                                                <div>
                                                                    <input className="filter-checkbox" type="checkbox" onChange={()=>{this.changeFilter(filter, index)}} value={filter.isChecked ? 1 : 0} checked={filter.isChecked}/>&nbsp;
                                                                    <label>{filter.permissionDesc}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </div>
                                </div>
                                <ActivityComp key={logKey} logList={activeLogList} updateList={()=>{this.updateLogList()}}/>
                            </div>
                        </div>) : 
                    ''}

                    <Modal
                        isOpen={showOtpModal}
                        toggle={this.toggleModal}
                        className="modal-md customer-page"
                    >
                        <div className="modal-internal-body">
                            <div className="main-modal-body">
                                <div className="font-eighteen m-b-three"><span><b>{selectedAction === "blockUnbind" ? "Block and Unbind Device" : "Unbind Device"}</b></span></div>
                                <div className="font-fourteen"><span>The Camera owner will receive an OTP on the registered mobile number and Email address</span></div>
                                <br></br>
                                <InputBox type="text" label="Enter OTP" key={`otp`} name="otpValue" value={otpValue} onChange={(e) => { this.setState({ otpValue: e.target.value, otpValidation :false }) }}  hasError={otpValidation} />
                                <div className="otp-based-grid m-t-5">
                                    <Button name="submitOTP" onClick={()=>{this.implementChangeConfig("verify")}}>Verify & Continue</Button>
                                    <div className="resend-otp-section" onClick={()=>{this.implementChangeConfig("reSendOtp")}}><span><u>Resend OTP</u></span></div>
                                </div>
                            </div>
                        </div>

                    </Modal>
                </div>
                <ToastContainer />
            </React.Fragment>
        );
    }
}



const mapStateToProps = state => ({
    customer : state.Customer,
    layout : state.Layout,
    login : state.Login
})

const mapDispatchToProps = dispatch => ({

    searchCustomers : (data) => dispatch(CustomerActions.searchCustomers(data)),
    searchCustomersInit : () => dispatch(CustomerActions.searchCustomersInit()),

    getCustomerDetails : (data) => dispatch(CustomerActions.getCustomerDetails(data)),
    getCustomerDetailsInit : () => dispatch(CustomerActions.getCustomerDetailsInit()),

    changeConfig : (data) => dispatch(CustomerActions.changeConfig(data)),
    changeConfigInit : () => dispatch(CustomerActions.changeConfigInit()),

    getUserLogFilters : (data) => dispatch(CustomerActions.getUserLogFilters(data)),
    getUserLogFiltersInit : () => dispatch(CustomerActions.getUserLogFiltersInit()),

    getPortalLogFilters : (data) => dispatch(CustomerActions.getPortalLogFilters(data)),
    getPortalLogFiltersInit : () => dispatch(CustomerActions.getPortalLogFiltersInit()),

    getUserLogs : (data) => dispatch(CustomerActions.getUserLogs(data)),
    getUserLogsInit : () => dispatch(CustomerActions.getUserLogsInit()),

    getPortalLogs : (data) => dispatch(CustomerActions.getPortalLogs(data)),
    getPortalLogsInit : () => dispatch(CustomerActions.getPortalLogsInit()),

    downloadLogs : (data) => dispatch(CustomerActions.downloadLogs(data)),
    downloadLogsInit : () => dispatch(CustomerActions.downloadLogsInit()),

    changePreloader: (flag) => dispatch(LayoutActions.changePreloader(flag)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Customers)