import { GET_COUNTRIES_LIST , SAVE_COUNTRIES_LIST , SAVE_VENDORS_LIST, GET_VENDORS_LIST , GET_PRODUCT_TYPES , SAVE_PRODUCT_TYPES , GET_PRODUCT_CATEGORIES , SAVE_PRODUCT_CATEGORIES,
         GET_PRODUCTS , SAVE_PRODUCTS , GET_BATCHES , SAVE_BATCHES  } from '../actionTypes';

export const getCountriesList = () => {
    return {
        type: GET_COUNTRIES_LIST
    }
}

export const saveCountriesList = (data) => {
    return {
        type: SAVE_COUNTRIES_LIST,
        payload:data
    }
}

export const getVendorList = () => {
    return {
        type: GET_VENDORS_LIST
    }
}

export const saveVendorList = (data) => {
    return {
        type: SAVE_VENDORS_LIST,
        payload:data
    }
}

export const getProductTypes = () => {
    return {
        type: GET_PRODUCT_TYPES
    }
}

export const saveProductTypes = (data) => {
    return {
        type: SAVE_PRODUCT_TYPES,
        payload:data
    }
}

export const getProductCategories = () => {
    return {
        type: GET_PRODUCT_CATEGORIES
    }
}


export const saveProductCategories = (data) => {
    return {
        type: SAVE_PRODUCT_CATEGORIES,
        payload:data
    }
}

export const getProducts = () => {
    return {
        type: GET_PRODUCTS
    }
}

export const saveProducts = (data) => {
    return {
        type: SAVE_PRODUCTS,
        payload:data
    }
}

export const getBatches = () => {
    return {
        type: GET_BATCHES
    }
}

export const saveBatches = (data) => {
    return {
        type: SAVE_BATCHES,
        payload:data
    }
}