import API from './interceptor';


export function fetchActivationNumber(params) {
    return API({
        method: 'GET',
        url: 'dashboard/activations',
        params
    });
}


export function fetchOnlineUsers() {
    return API({
        method: 'GET',
        url: 'dashboard/online/devices',
    });
}