export const SEARCH_CUSTOMERS = "SEARCH_CUSTOMERS"
export const SEARCH_CUSTOMERS_SUCCESS = "SEARCH_CUSTOMERS_SUCCESS"
export const SEARCH_CUSTOMERS_FAILURE = "SEARCH_CUSTOMERS_FAILURE"
export const SEARCH_CUSTOMERS_INIT = "SEARCH_CUSTOMERS_INIT"

export const GET_CUSTOMER_DETAILS = "GET_CUSTOMER_DETAILS"
export const GET_CUSTOMER_DETAILS_SUCCESS = "GET_CUSTOMER_DETAILS_SUCCESS"
export const GET_CUSTOMER_DETAILS_FAILURE = "GET_CUSTOMER_DETAILS_FAILURE"
export const GET_CUSTOMER_DETAILS_INIT = "GET_CUSTOMER_DETAILS_INIT"

export const CHANGE_CONFIG = "CHANGE_CONFIG"
export const CHANGE_CONFIG_SUCCESS = "CHANGE_CONFIG_SUCCESS"
export const CHANGE_CONFIG_FAILURE = "CHANGE_CONFIG_FAILURE"
export const CHANGE_CONFIG_INIT = "CHANGE_CONFIG_INIT"

export const GET_USER_LOGS = "GET_USER_LOGS"
export const GET_USER_LOGS_SUCCESS = "GET_USER_LOGS_SUCCESS"
export const GET_USER_LOGS_FAILURE = "GET_USER_LOGS_FAILURE"
export const GET_USER_LOGS_INIT = "GET_USER_LOGS_INIT"

export const GET_PORTAL_LOGS = "GET_PORTAL_LOGS"
export const GET_PORTAL_LOGS_SUCCESS = "GET_PORTAL_LOGS_SUCCESS"
export const GET_PORTAL_LOGS_FAILURE = "GET_PORTAL_LOGS_FAILURE"
export const GET_PORTAL_LOGS_INIT = "GET_PORTAL_LOGS_INIT"

export const GET_USER_LOG_FILTERS = "GET_USER_LOG_FILTERS"
export const GET_USER_LOG_FILTERS_SUCCESS = "GET_USER_LOG_FILTERS_SUCCESS"
export const GET_USER_LOG_FILTERS_FAILURE = "GET_USER_LOG_FILTERS_FAILURE"
export const GET_USER_LOG_FILTERS_INIT = "GET_USER_LOG_FILTERS_INIT"

export const GET_PORTAL_LOG_FILTERS = "GET_PORTAL_LOG_FILTERS"
export const GET_PORTAL_LOG_FILTERS_SUCCESS = "GET_PORTAL_LOG_FILTERS_SUCCESS"
export const GET_PORTAL_LOG_FILTERS_FAILURE = "GET_PORTAL_LOG_FILTERS_FAILURE"
export const GET_PORTAL_LOG_FILTERS_INIT = "GET_PORTAL_LOG_FILTERS_INIT"

export const DOWNLOAD_LOGS = "DOWNLOAD_LOGS"
export const DOWNLOAD_LOGS_SUCCESS = "DOWNLOAD_LOGS_SUCCESS"
export const DOWNLOAD_LOGS_FAILURE = "DOWNLOAD_LOGS_FAILURE"
export const DOWNLOAD_LOGS_INIT = "DOWNLOAD_LOGS_INIT"

// ==== Action Types for Group Management ====

export const SEARCH_CUSTOMERS_GROUPS = "SEARCH_CUSTOMERS_GROUPS"
export const SEARCH_CUSTOMERS_GROUPS_SUCCESS = "SEARCH_CUSTOMERS_GROUPS_SUCCESS"
export const SEARCH_CUSTOMERS_GROUPS_FAILURE = "SEARCH_CUSTOMERS_GROUPS_FAILURE"
export const SEARCH_CUSTOMERS_GROUPS_INIT = "SEARCH_CUSTOMERS_GROUPS_INIT"

export const ASSIGN_CUSTOMER_GROUP = "ASSIGN_CUSTOMER_GROUP"
export const ASSIGN_CUSTOMER_GROUP_SUCCESS = "ASSIGN_CUSTOMER_GROUP_SUCCESS"
export const ASSIGN_CUSTOMER_GROUP_FAILURE = "ASSIGN_CUSTOMER_GROUP_FAILURE"
export const ASSIGN_CUSTOMER_GROUP_INIT = "ASSIGN_CUSTOMER_GROUP_INIT"

// ==== User Specific Actions ====
export const SEARCH_USER = "SEARCH_USER"
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS"
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE"
export const SEARCH_USER_INIT = "SEARCH_USER_INIT"

export const SEARCH_DEVICES = "SEARCH_DEVICES" 
export const SEARCH_DEVICES_SUCCESS = "SEARCH_DEVICES_SUCCESS" 
export const SEARCH_DEVICES_FAILURE = "SEARCH_DEVICES_FAILURE" 
export const SEARCH_DEVICES_INIT = "SEARCH_DEVICES_INIT" 


