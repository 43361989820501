import {
    CREATE_BATCH,
    CREATE_BATCH_SUCCESS,
    CREATE_BATCH_FAILURE,
    CREATE_BATCH_INIT,

    EDIT_BATCH,
    EDIT_BATCH_SUCCESS,
    EDIT_BATCH_FAILURE,
    EDIT_BATCH_INIT,

    DELETE_BATCH,
    DELETE_BATCH_SUCCESS,
    DELETE_BATCH_FAILURE,
    DELETE_BATCH_INIT,

    GET_BATCH,
    GET_BATCH_SUCCESS,
    GET_BATCH_FAILURE,
    GET_BATCH_INIT,

    DOWNLOAD_BATCH_FILE,
    DOWNLOAD_BATCH_FILE_SUCCESS,
    DOWNLOAD_BATCH_FILE_FAILURE,
    DOWNLOAD_BATCH_FILE_INIT,

    UPLOAD_BATCH_FILE,
    UPLOAD_BATCH_FILE_SUCCESS,
    UPLOAD_BATCH_FILE_FAILURE,
    UPLOAD_BATCH_FILE_INIT
} from './actionTypes'

// ==== Action Methods ====

// Actions for Create batch ====
export const createBatch = (data) => {
    return {
        type : CREATE_BATCH,
        payload : data
    }
}
export const createBatchSuccess = (data) => {
    return {
        type : CREATE_BATCH_SUCCESS,
        payload : data
    }
}
export const createBatchFailure = (data) => {
    return {
        type : CREATE_BATCH_FAILURE,
        payload : data
    }
}
export const createBatchInit = (data) => {
    return {
        type : CREATE_BATCH_INIT,
        payload : data
    }
}

// ==== Actions for Edit Batch ====
export const editBatch = (data) => {
    return {
        type : EDIT_BATCH,
        payload : data
    }
}
export const editBatchSuccess = (data) => {
    return {
        type : EDIT_BATCH_SUCCESS,
        payload : data
    }
}
export const editBatchFailure = (data) => {
    return {
        type : EDIT_BATCH_FAILURE,
        payload : data
    }
}
export const editBatchInit = (data) => {
    return {
        type : EDIT_BATCH_INIT,
        payload : data
    }
}

// ==== Actions for Delete batch ====
export const deleteBatch = (data) => {
    return {
        type : DELETE_BATCH,
        payload : data
    }
}
export const deleteBatchSuccess = (data) => {
    return {
        type : DELETE_BATCH_SUCCESS,
        payload : data
    }
}
export const deleteBatchFailure = (data) => {
    return {
        type : DELETE_BATCH_FAILURE,
        payload : data
    }
}
export const deleteBatchInit = (data) => {
    return {
        type : DELETE_BATCH_INIT,
        payload : data
    }
}

// ==== Actions for Get batch ==== 
export const getBatch = (data) => {
    return {
        type : GET_BATCH,
        payload : data
    }
}
export const getBatchSuccess = (data) => {
    return {
        type : GET_BATCH_SUCCESS,
        payload : data
    }
}
export const getBatchFailure = (data) => {
    return {
        type : GET_BATCH_FAILURE,
        payload : data
    }
}
export const getBatchInit = (data) => {
    return {
        type : GET_BATCH_INIT,
        payload : data
    }
}

// ==== Actions for Download batch file ====
export const downloadBatchFile = (data) => {
    return {
        type : DOWNLOAD_BATCH_FILE,
        payload : data
    }
}

export const downloadBatchFileSuccess = (data) => {
    return {
        type : DOWNLOAD_BATCH_FILE_SUCCESS,
        payload : data
    }
}

export const downloadBatchFileFailure = (data) => {
    return {
        type : DOWNLOAD_BATCH_FILE_FAILURE,
        payload : data
    }
}

export const downloadBatchFileInit = (data) => {
    return {
        type : DOWNLOAD_BATCH_FILE_INIT,
        payload : data
    }
}

// ==== Actions for Upload batch file ====
export const uploadBatchFile = (data) => {
    return {
        type : UPLOAD_BATCH_FILE,
        payload : data
    }
}

export const uploadBatchFileSuccess = (data) => {
    return {
        type : UPLOAD_BATCH_FILE_SUCCESS,
        payload : data
    }
}

export const uploadBatchFileFailure = (data) => {
    return {
        type : UPLOAD_BATCH_FILE_FAILURE,
        payload : data
    }
}

export const uploadBatchFileInit = (data) => {
    return {
        type : UPLOAD_BATCH_FILE_INIT,
        payload : data
    }
}
