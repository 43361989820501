import React, { Component } from "react";
import { Container, Card, CardBody, CardTitle, PopoverBody, UncontrolledPopover } from "reactstrap";
import "./dashboard.scss";
import BarApexChart from "../AllCharts/apex/barchart";
import Doughnut from "../AllCharts/echart/doughnutchart";
import ActivityComp from "./activityComp";
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Calendar, DateRange } from 'react-date-range'
import { changePreloader  } from '../../store/layout/actions';
import moment from 'moment';
import DropdownList from 'react-widgets/lib/DropdownList';
import { createAndDownloadBlobFile } from '../../helpers/downloadHelper';
// import * as jose from 'jose'
import * as jwt_decode from 'jwt-decode'

// Actions and Redux components 
import * as LayoutActions from '../../store/layout/actions'
import * as DeviceActions from '../../store/devices/actions'
import * as CustomerActions from '../../store/customer/actions'
import * as LoginActions from '../../store/auth/login/actions'

// Images 
import Download_icon from '../../assets/images/download.png'

import { getActivationsSuccess, getActivations, getOnlineDevices } from '../../store/dashboard/actions';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/breadcrumb';
// const jose = require('jose')

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            daysListObj : [
                {dayLabel : "Last 30 Days", dayVal : 30},
                {dayLabel : "Last 10 Days", dayVal : 10},
                {dayLabel : "Last 7 Days", dayVal : 7},
            ],
            newActivations : 0,
            onlineDevices : 0,
            newActivationsDays : {dayLabel : "Last 30 Days", dayVal : 30},
            trendGraphDays : {dayLabel : "Last 30 Days", dayVal : 30},
            allFilters : [],
            filtersSelected : {},
            filtersDeselected : {},
            selectionRange : {
                startDate : new Date(),
                endDate : new Date(),
                key : "selection"
            },
            filterPopperOpen : false,
            datePopperOpen : false,
            trendGraphDetails : [],
            trendGraphObject : {
                newActivations : [],
                onlineDevices : [],
                dateArr : []
            },
            updateGraph : false,
            chartKeyBar : 1,
            chartKeyPie : 1,
            logKey : 1,
            deviceInfo : {
                "totalDevices": 0,
                "bindedDevices": 0,
                "unbindedDevices": 0,
                "blockedDevices": 0
            },
            userId : null,
            portalLogs : [],
            lastSortKeyContainer : 0,
            paginationState : false
        };

    }

    componentDidMount() {
        this.setDates()
        let {daysListObj, trendGraphDays, newActivationsDays} = this.state
        let data = {
            days : newActivationsDays.dayVal
        }
        let logData = {
            userType : "admin"
        }
        let graphDays = {
            days : trendGraphDays.dayVal
        }
        this.props.getPortalLogFilters(logData)
        this.props.getNewActivations(data)
        this.props.getOnlineDevices()
        this.props.getTrendGraph(graphDays)
        this.props.getDeviceInfo()
        this.extractUserId()
        // this.props.setIsLoading(false);
        this.props.changePreloader(true)
    }

    setDates() {
        let { selectionRange } = this.state
        let oldDate = new Date()
        oldDate.setDate( oldDate.getDate() - 80)
        let tempOldDate = new Date(oldDate)
        selectionRange.startDate = tempOldDate
        this.setState({ selectionRange : selectionRange})
    }

    extractUserId = () => {
        console.log("GETTING IN USER ID")
        let accessToken = this.props.login.access_token
        let decodedToken = jwt_decode(accessToken)
        console.log("***** DECODED TOKEN *****")
        console.log(decodedToken.user_id)
        this.setState({ userId : decodedToken.user_id })
    }

    activationsFilterHandler = (val) => {
        let data = {
            days : val
        }
        this.props.getNewActivations(data)
    }

    onlineDevicesRefreshHandler = () => {
        console.log(this.props.login.access_token)
        this.props.getOnlineDevices()
    }

    newActivationsRefreshHandler = () => {
        let data = {
            days : this.state.newActivationsDays.dayVal
        }
        this.props.getNewActivations(data)
    }

    trendGraphRefreshHandler = () => {
        let data = {
            days : this.state.trendGraphDays.dayVal
        }
        this.props.getTrendGraph(data)
    }

    deviceInfoRefreshHandler = () => {
        this.props.getDeviceInfo()
    }

    setInitialFilter = (userType) => {
        let {allFilters, filtersSelected} = this.state
        console.log("#### start filter process : ")
        allFilters.map((filter)=> {
            filter.isChecked = true
            filtersSelected[filter.permissionName] = filter.permissionName
        })
        console.log("#### Mid filter process : ")
        console.log(allFilters)
        this.setState({
            allFilters : allFilters,
            filtersSelected : filtersSelected
        },
        () => {
            console.log("#### Filters : ")
            console.log(this.state.filtersSelected)
            console.log(this.state.allFilters)
            // ==== UNCOMMENT THIS ONCE LOGS ARE FIXED ====
            this.getLogs()
        })
    }

    setSelectedDate(selectedDateRange) {
        console.log(selectedDateRange)
        // return
        this.setState({ selectionRange : selectedDateRange.selection})
    }

    changeFilter(filterValue, index){
        let { allFilters, filtersSelected } = this.state
            let checkValue = allFilters[index].isChecked
            let permissionName = allFilters[index].permissionName
            if(checkValue){
                allFilters[index].isChecked = false
                delete filtersSelected[permissionName]
            }else{
                allFilters[index].isChecked = true
                filtersSelected[permissionName] = permissionName
            } 
            this.setState({
                allFilters : allFilters, filtersSelected : filtersSelected
            })        
    }

    toggleLogsPopover(type){
        let {filterPopperOpen, datePopperOpen } = this.state
        if(type === "date"){
            if(datePopperOpen){
                this.setState({ datePopperOpen : false, lastSortKeyContainer : 0 , paginationState : false }, ()=>{
                    console.log("Popper closed!!!!")
                    // ==== UNCOMMENT THIS ONCE LOGS ARE FIXED ====
                    this.getLogs()
                })                 
                
            }else {
                this.setState({ datePopperOpen : true }, ()=>{console.log("Popper Open!!!!")} )
            }
        }
        else if(type === "filter"){
            if(filterPopperOpen){
                this.setState({ filterPopperOpen : false, lastSortKeyContainer : 0, paginationState : false }, ()=>{
                    console.log("Popper closed!!!!")                    
                    // ==== UNCOMMENT THIS ONCE LOGS ARE FIXED ====
                    this.getLogs()
                }) 
            }else {
                this.setState({ filterPopperOpen : true }, ()=>{console.log("Popper Open!!!!")} )
            }
        }

    }

    getLogs() {
        let {selectionRange, filtersSelected, userId, lastSortKeyContainer } = this.state
        console.log("##### Portal Log data!")
        let eventArr = Object.keys(filtersSelected)
        let data = {
            activityLogs : "dashboardPortal",
            eventType : eventArr,
            // eventType : [],
            fromDate : selectionRange.startDate,
            toDate : selectionRange.endDate
        }
        console.log(data)
        let paramData = {
            inputActivityLogsDto : data,
            userId : 0,
            serialNumber : null,
            pageLimit : 15,
            lastSortKey : lastSortKeyContainer
        }
        this.props.getPortalLogs(paramData)
    }

    updateLogList() {
        let {paginationState} = this.state
        if(paginationState === false){
            this.setState({
                paginationState : true
            }, ()=> {this.getLogs()}
            )
        }
    }

    downloadLogs(){
        let {selectionRange, filtersSelected, userId } = this.state
        console.log("##### Portal Log data!")
        let eventArr = Object.keys(filtersSelected)
        let data = {            
            activityLogs : "dashboardPortal",
            eventType : eventArr,
            fromDate : selectionRange.startDate,
            toDate : selectionRange.endDate
        }
        console.log(data)
        let paramData = {
            inputActivityLogsDto : data,
            userId : 0,
            serialNumber : null,
        }
        console.log(JSON.stringify(paramData))
        this.props.downloadLogs(paramData)
    }

    filterTrendGraphDetails() { 
        let {trendGraphDetails, trendGraphObject, graphRefreshIndex} = this.state
        let tempNewActivations = []
        let tempOnlineDevices = []
        let tempDataObj = []

        trendGraphDetails.filter((graphObj) => {
            tempNewActivations.push(graphObj.newActivations)
            tempOnlineDevices.push(graphObj.onlineDevices)
            tempDataObj.push(moment(graphObj.onDate).format("MMM Do"))
        })

        this.setState({
            trendGraphObject : {
                newActivations : tempNewActivations,
                onlineDevices : tempOnlineDevices,
                dateArr : tempDataObj,
            },
            updateGraph : true,
            chartKeyBar : this.state.chartKeyBar + 1
        })
    }

    toggleUpdateGraph() {
        console.log("#### UPDATING GRAPH")
        this.setState({ updateGraph : false})
    }

    updateDetailHistory(changeParam, e){
        let {newActivationsDays, trendGraphDays, daysListObj} = this.state
        
        if(changeParam === "newActivations"){
            this.setState({newActivationsDays : e})
            let activationData = {
                days : e.dayVal
            }
            this.props.getNewActivations(activationData)

        }else if(changeParam = "trendGraph"){
            this.setState({trendGraphDays : e})
            let graphData = {
                days : e.dayVal
            }
            this.props.getTrendGraph(graphData)
        }
    }

    downloadDeviceInfoReport(){
        this.props.downloadDeviceInfoReport()
    }

    filterPortalLogs = (logDetails) => {
        let { paginationState } = this.state
        console.log("##### LOG Details : ")
        console.log(logDetails)
        logDetails.filter((logValue) => {
            logValue.displayDate = moment(logValue.eventDate).format("MMM Do YYYY")            
        })

        console.log("#### Log details filtered :")
        console.log(logDetails)
        let lastSortKeyDetails = logDetails[logDetails.length - 1].sortKey
        console.log(lastSortKeyDetails)

        if(paginationState){
            this.setState({ portalLogs : [...this.state.portalLogs, ...logDetails], lastSortKeyContainer : lastSortKeyDetails, paginationState : false})
        }else{
            this.setState({ portalLogs : logDetails, logKey : Math.random().toFixed(2)*100, lastSortKeyContainer : lastSortKeyDetails })
        }
        
    }

    failedLogs = () => {
        let {paginationState} = this.state
        if(paginationState){
            this.setState({
                paginationState : false
            })
        }else{
            this.setState({
                paginationState: false,
                portalLogs : []
            })
        }
    }



    // ==== CHECK METHODS ====
    checkGetNewActivations() {
        // if(this.props.layout.isPreloader){
            if(this.props.devices.getNewActivationsSuccess === true){
                console.log(this.props.devices.totalNewActivations)
                this.setState({ newActivations : this.props.devices.totalNewActivations.newActivations})
                this.props.getNewActivationsInit()

            }
            else if(this.props.devices.getNewActivationsFailure === true){
                // this.props.changePreloader(false)
                console.log("#####  NO Activations found!!!")
                this.props.getNewActivationsInit()
            }
        // }
    }

    checkGetOnlineDevices() {
        // if(this.props.layout.isPreloader){
            if(this.props.devices.getOnlineDevicesSuccess === true){
                this.setState({ onlineDevices : this.props.devices.totalOnlineDevices.onlineDevices})
                this.props.getOnlineDevicesInit()

            }
            else if(this.props.devices.getOnlineDevicesFailure === true){
                // this.props.changePreloader(false)
                console.log("#####  NO Online devices found!!!")
                this.props.getOnlineDevicesInit()
            }
        // }
    }


    checkGetPortalLogFilters(){
        if(this.props.layout.isPreloader){
            if(this.props.customer.getPortalLogFiltersSuccess === true){
                this.setState({ allFilters : this.props.customer.logFilters}, 
                    () => {this.setInitialFilter("admin")})
                this.props.getPortalLogFiltersInit()
                this.props.changePreloader(false)
            }
            else if(this.props.customer.getPortalLogFiltersFailure === true){
                this.props.changePreloader(false)
                toast.error(this.props.customer.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getPortalLogFiltersInit()
            }
        }
    }

    checkGetTrendGraph(){
        if(this.props.devices.getTrendGraphSuccess === true){
            this.setState({ trendGraphDetails : this.props.devices.trendGraphDetails}, ()=>{this.filterTrendGraphDetails()})
            this.props.getTrendGraphInit()
        }
        else if(this.props.devices.getTrendGraphFailure === true){
            toast.error(this.props.devices.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getTrendGraphInit()
        }
    }

    checkGetDeviceInfo(){
        if(this.props.devices.getDeviceInfoSuccess === true){
            this.setState({ deviceInfo : this.props.devices.deviceInfo, chartKeyPie : Math.random().toFixed(2)*100 })
            this.props.getDeviceInfoInit()
        }
        else if(this.props.devices.getDeviceInfoFailure === true){
            toast.error(this.props.devices.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getDeviceInfoInit()
        }
    }

    checkDownloadDeviceInfoReport(){
        if(this.props.devices.downloadDeviceInfoReportSuccess === true){
            console.log("#####  INSIDE DOWNLOAD REPORT !!!! ")
            let arrayBuffer = this.props.devices.deviceInfoReport
            createAndDownloadBlobFile(arrayBuffer, 'DeviceReport')
            toast.success("Report Downloaded Successfully")
            this.props.downloadDeviceInfoReportInit()
            console.log("#####  FINISH DOWNLOAD REPORT ")
        }
        else if(this.props.devices.downloadDeviceInfoReportFailure === true){
            toast.error(this.props.devices.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.downloadDeviceInfoReportInit()
        }
    }

    checkGetPortalLogs(){
        if(this.props.customer.getPortalLogsSuccess === true){
            // this.setState({ portalLogs : this.props.customer.logDetails})
            let logDetails = this.props.customer.logDetails
            this.filterPortalLogs(logDetails)
            // console.log("Logs")
            this.props.getPortalLogsInit()
        }
        else if(this.props.customer.getPortalLogsFailure === true){
            this.failedLogs()
            // toast.error(this.props.customer.errorMessage, {
            //     position: "bottom-right",
            //     autoClose: 2000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            this.props.getPortalLogsInit()
        }
    }

    checkDownloadLogs(){
        if(this.props.customer.downloadLogsSuccess === true){
            let arrayBuffer = this.props.customer.logFile
            createAndDownloadBlobFile(arrayBuffer, 'Log_File')
            toast.success("Log Report Downloaded Successfully")
            this.props.downloadLogsInit()
        }
        else if(this.props.customer.downloadLogsFailure === true){
            toast.error(this.props.customer.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.downloadLogsInit()
        }
    }


    

    render() {
        let { newActivations, onlineDevices, allFilters, selectionRange, trendGraphObject, updateGraph, daysListObj, newActivationsDays, trendGraphDays, 
            deviceInfo, portalLogs, logKey } = this.state
        this.checkGetNewActivations()
        this.checkGetOnlineDevices()
        this.checkGetPortalLogFilters()
        this.checkGetTrendGraph()
        this.checkGetDeviceInfo()
        this.checkDownloadDeviceInfoReport()
        this.checkGetPortalLogs()
        this.checkDownloadLogs()

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        {/* <Breadcrumbs title="Admin" breadcrumbItem="Dashboard" /> */}
                    </Container>

                    <div className="container-grid">
                        <div className="first-child">
                            <div className="card-holder-div">
                                <Card className="card-common-padding">
                                    <div className="inner-content-topgrid">
                                        <h5 className="text-muted font-weight-bold no-mar">New Activations</h5>
                                        <h5 className="no-mar refresh-icon-layout"><i className="fas fa-redo-alt refresh-icon select-hover" onClick={this.newActivationsRefreshHandler}></i></h5>
                                        <DropdownList
                                            className=" t-a-l"
                                            placeholder="Search by"
                                            data={daysListObj}
                                            defaultValue={newActivationsDays}
                                            textField="dayLabel"
                                            valueField="dayVal"
                                            onChange={(e) => {
                                                this.updateDetailHistory("newActivations", e)
                                            }}                                
                                        />
                                    </div>

                                    <div className="inner-content-bottomgrid">
                                        <h2 className="font-weight-bold no-mar">{newActivations}</h2>
                                        <i className="fab fa-hubspot text-muted"></i>
                                    </div>
                                </Card>
                                <Card className="card-common-padding">
                                    <div className="inner-content-topgrid">
                                        <h5 className="text-muted font-weight-bold no-mar">Current Online Devices</h5>
                                        <div></div>
                                        <h5 className="no-mar refresh-icon-layout"><i className="fas fa-redo-alt refresh-icon select-hover" onClick={this.onlineDevicesRefreshHandler}></i></h5>
                                    </div>

                                    <div className="inner-content-bottomgrid">
                                        <h2 className="font-weight-bold no-mar">{onlineDevices} </h2>
                                        <i className="fab fa-audible text-muted"></i>
                                    </div>
                                </Card>
                            </div>

                            <Card className="card-common-padding">
                                {/* <CardBody> */}
                                <div className="inner-content-topgrid">
                                    <CardTitle className="text-muted mb-4">Trends</CardTitle>                                        
                                    <h5 className="no-mar refresh-icon-layout"><i className="fas fa-redo-alt refresh-icon select-hover" onClick={this.trendGraphRefreshHandler}></i></h5>
                                    <DropdownList
                                        className=" t-a-l"
                                        placeholder="Search by"
                                        data={daysListObj}
                                        defaultValue={trendGraphDays}
                                        textField="dayLabel"
                                        valueField="dayVal"
                                        onChange={(e) => {
                                            this.updateDetailHistory("trendGraph", e)
                                        }}                                
                                    />
                                    

                                </div>
                                <BarApexChart key={this.state.chartKeyBar} graphDetails={trendGraphObject} toggleUpdate={()=>{this.toggleUpdateGraph()}} updateStatus={updateGraph}/>
                                {/* </CardBody> */}
                            </Card>

                            <div className="card-holder-div">
                                <Card className="card-common-padding">
                                    <div className="inner-content-topgrid-dual-icon m-b-ten">
                                        <h5 className="text-muted font-weight-bold no-mar">Cloud / SD Card Status</h5>
                                        <h5 className="no-mar refresh-icon-layout"><i className="fas fa-redo-alt refresh-icon select-hover" onClick={this.onlineDevicesRefreshHandler}></i></h5>
                                        <div className="justify-self-end select-hover" onClick={()=>{this.downloadDeviceInfoReport()}}><img src={Download_icon} className="download-icon-style"/></div>
                                        
                                    </div>

                                    <div>
                                        <div className="internal-text-content"> 
                                            <span className="font-size-17">Active Cloud Subscriptions</span>
                                            <span className="value-bold-text">2000</span>
                                        </div>
                                        <div className="internal-text-content"> 
                                            <span className="font-size-17">Active in device SD cards</span>
                                            <span className="value-bold-text">2000</span>
                                        </div>
                                    </div>
                                </Card>

                                <Card className="card-common-padding">
                                    {/* <CardBody> */}
                                    <div className="inner-content-topgrid-dual-icon m-b-five">
                                        <h5 className="text-muted font-weight-bold no-mar">Devices Info</h5>
                                        <h5 className="no-mar refresh-icon-layout"><i className="fas fa-redo-alt refresh-icon select-hover" onClick={this.deviceInfoRefreshHandler}></i></h5>
                                        <div className="justify-self-end select-hover" onClick={()=>{this.downloadDeviceInfoReport()}}><img src={Download_icon} className="download-icon-style"/></div>
                                        
                                    </div>                                                       
                                    <div id="doughnut-chart" className="pie-chart-grid">
                                        <Doughnut key={this.state.chartKeyPie} deviceDetails={deviceInfo}/>
                                        <div className="align-self-center">
                                            <div className="device-info-legend">
                                                <div className="device-info-tag"></div>
                                                <span className="justify-self-center">Total Devices</span>
                                                <span className="justify-self-end"> {deviceInfo.totalDevices} </span> 
                                            </div>

                                            <div className="device-info-legend">
                                                <div className="device-info-tag device-info-tag-binded"></div>
                                                <span className="justify-self-center">Binded Devices</span>
                                                <span className="justify-self-end"> {deviceInfo.bindedDevices} </span> 
                                            </div>

                                            <div className="device-info-legend">
                                                <div className="device-info-tag device-info-tag-unbinded"></div>
                                                <span className="justify-self-center">Unbinded Devices</span>
                                                <span className="justify-self-end"> {deviceInfo.unbindedDevices} </span> 
                                            </div>

                                            <div className="device-info-legend">
                                                <div className="device-info-tag device-info-tag-blocked"></div>
                                                <span className="justify-self-center">Blocked Devices</span>
                                                <span className="justify-self-end"> {deviceInfo.blockedDevices} </span> 
                                            </div>
                                        </div>
                                    </div>
                                    {/* </CardBody> */}
                                </Card>
                            </div>
                        </div>

                        <div className="second-child">
                            <div className="head-section">
                                <div className="title-logo-div">
                                    <i className="fas fa-wave-square icon-sizing"></i>
                                    <p className="large-header-text text-muted ml-20">Portal Activity Log</p>
                                </div>
                                <div className="setting-icons-div">
                                    <div className="fas fa-file-download select-hover" onClick={()=>{this.downloadLogs()}}></div>
                                    <div id="filterDiv" className="fas fa-filter select-hover"></div>
                                    <div id="datePickerDiv" className="fas fa-calendar-alt select-hover"></div>

                                    <UncontrolledPopover trigger="legacy" placement="bottom-end" target="datePickerDiv" isOpen={this.state.datePopperOpen} toggle={()=>{this.toggleLogsPopover("date")}}>
                                            <PopoverBody className="popper-width-inc">
                                                <DateRange
                                                    date={new Date()}
                                                    rangeColors="#3d91ff"
                                                    onChange={(val)=>{this.setSelectedDate(val)}}
                                                    ranges={[selectionRange]}
                                                    maxDate={new Date()}
                                                />
                                            </PopoverBody>
                                    </UncontrolledPopover>

                                    <UncontrolledPopover trigger="legacy" placement="bottom-end" target="filterDiv" isOpen={this.state.filterPopperOpen} toggle={()=>{this.toggleLogsPopover("filter")}}>
                                            <PopoverBody className="popper-width-inc">
                                                <div className="font-fourteen">
                                                    {
                                                        allFilters.map((filter, index) => {
                                                            return(
                                                                <div>
                                                                    <input className="filter-checkbox" type="checkbox" onChange={()=>{this.changeFilter(filter, index)}} value={filter.isChecked ? 1 : 0} checked={filter.isChecked}/>&nbsp;
                                                                    <label>{filter.permissionDesc}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </PopoverBody>
                                    </UncontrolledPopover>
                                </div>
                            </div>
                            <ActivityComp key={logKey} logList={portalLogs} updateList={()=>{this.updateLogList()}} />
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </React.Fragment>
        );
    }
}

// const mapStatetoProps = state => {
//     const { activations, onlineDevices } = state.Dashboard;
//     return { activations, onlineDevices };
// }

// // export default Dashboard;
// export default connect(mapStatetoProps, { getActivations, getActivationsSuccess, getOnlineDevices , changePreloader})(Dashboard);
const mapStateToProps = state => ({
    customer : state.Customer,
    devices : state.Devices,
    layout : state.Layout,
    login : state.Login
})

const mapDispatchToProps = dispatch => ({

    getNewActivations : (data) => dispatch(DeviceActions.getNewActivations(data)),
    getNewActivationsInit : () => dispatch(DeviceActions.getNewActivationsInit()),

    getOnlineDevices : () => dispatch(DeviceActions.getOnlineDevices()),
    getOnlineDevicesInit : () => dispatch(DeviceActions.getOnlineDevicesInit()),

    getTrendGraph : (data) => dispatch(DeviceActions.getTrendGraph(data)),
    getTrendGraphInit : () => dispatch(DeviceActions.getTrendGraphInit()),

    getPortalLogFilters : (data) => dispatch(CustomerActions.getPortalLogFilters(data)),
    getPortalLogFiltersInit : () => dispatch(CustomerActions.getPortalLogFiltersInit()),

    getPortalLogs : (data) => dispatch(CustomerActions.getPortalLogs(data)),
    getPortalLogsInit : () => dispatch(CustomerActions.getPortalLogsInit()),

    getDeviceInfo : () => dispatch(DeviceActions.getDevicesInfo()),
    getDeviceInfoInit : () => dispatch(DeviceActions.getDevicesInfoInit()),

    downloadDeviceInfoReport : () => dispatch(DeviceActions.downloadDeviceInfoReport()),
    downloadDeviceInfoReportInit : () => dispatch(DeviceActions.downloadDeviceInfoReportInit()),

    downloadLogs : (data) => dispatch(CustomerActions.downloadLogs(data)),
    downloadLogsInit : () => dispatch(CustomerActions.downloadLogsInit()),

    loginSuccess : (user) => dispatch(LoginActions.loginSuccess(user)),

    changePreloader: (flag) => dispatch(LayoutActions.changePreloader(flag)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)