import { LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS, API_ERROR, SET_ACCESS_TOKEN } from './actionTypes';
import { loadUser } from '../../../helpers/authUtils'
import * as jwt_decode from 'jwt-decode';


const initialState = {
    error: null,
    loading: false,
    access_token : '',
    permissions: '',
}


const login = (state = initialState, action) => {
    let cachedState = loadUser();

    if (cachedState !== undefined && cachedState !== null) {
        state = {...cachedState.Login }
    }
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                access_token: action.payload.signInUserSession.idToken.jwtToken,
                permissions: jwt_decode(action.payload.signInUserSession.idToken.jwtToken).permissions,
                role: action.payload.attributes,
            }
        case LOGOUT_USER:
            return {
                ...state,
                access_token: ''
            }
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                access_token: action.payload
            }
        case LOGOUT_USER_SUCCESS:
            return {
                ...state
            }
        case API_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default login;