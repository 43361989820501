import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// const unsubscribe = store.subscribe(handleChange)
const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	isLoggedIn,
	...rest
}) => {
	const currentState = useSelector(state => state.Dashboard);
	return (
		<Route
			{...rest}
			render={props => {
				if (isAuthProtected && isLoggedIn === false) {
					return (
						<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
					);
				} else if (isAuthProtected === false && isLoggedIn === true) {
					return (
						<Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
					);
				}
		
					return (
						<React.Fragment>
							<div className={`spinner-container ${currentState.isLoading === true ? '' : 'disp-none'}`}>
								<div className={`spinner-grow p-color m-1`} role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
							<Layout >
								<Component {...props}/>
							</Layout>
						</React.Fragment>
					);
				}
			}
		/>
	)
}


export default AppRoute;

