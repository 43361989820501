//AUTH related methods
import { Auth } from 'aws-amplify';



export function loginAPI(values) {
    return new Promise(
        function (resolve, reject) {
            Auth.signIn(values.email, values.password)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => { reject(err); });
        }
    )
}