import {
    CREATE_PRODUCT_CATEGORY,
    CREATE_PRODUCT_CATEGORY_SUCCESS,
    CREATE_PRODUCT_CATEGORY_FAILURE,
    CREATE_PRODUCT_CATEGORY_INIT,

    EDIT_PRODUCT_CATEGORY,
    EDIT_PRODUCT_CATEGORY_SUCCESS,
    EDIT_PRODUCT_CATEGORY_FAILURE,
    EDIT_PRODUCT_CATEGORY_INIT,

    DELETE_PRODUCT_CATEGORY,
    DELETE_PRODUCT_CATEGORY_SUCCESS,
    DELETE_PRODUCT_CATEGORY_FAILURE,
    DELETE_PRODUCT_CATEGORY_INIT,

    GET_PRODUCT_CATEGORY,
    GET_PRODUCT_CATEGORY_SUCCESS,
    GET_PRODUCT_CATEGORY_FAILURE,
    GET_PRODUCT_CATEGORY_INIT, 

    GET_PRODUCT_CATEGORY_BY_NAME,
    GET_PRODUCT_CATEGORY_BY_NAME_SUCCESS,
    GET_PRODUCT_CATEGORY_BY_NAME_FAILURE,
    GET_PRODUCT_CATEGORY_BY_NAME_INIT
} from './actionTypes'

const initialState = {
    errorMessage : '',
    productCategoriesList : [],
    editProductCategoryDetails : null,
    getProductCategorySuccess : false,
    getProductCategoryFailure : false,
    createProductCategorySuccess : false,
    createProductCategoryFailure : false,
    editProductCategorySuccess : false,
    editProductCategoryFailure : false,
    deleteProductCategorySuccess : false,
    deleteProductCategoryFailure : false,
    getProductCategoryByNameSuccess : false,
    getProductCategoryByNameFailure : false
}


const ProductCategory = (state = initialState, action) => {
    switch (action.type){
        
        // ==== Create Product Category cases ====
        case CREATE_PRODUCT_CATEGORY : 
        return {
            ...state,
            data : action.payload,
            createProductCategorySuccess : false,
            createProductCategoryFailure : false
        }

        case CREATE_PRODUCT_CATEGORY_SUCCESS : 
        return {
            ...state,
            createProductCategorySuccess : true,
            createProductCategoryFailure : false
        }

        case CREATE_PRODUCT_CATEGORY_FAILURE : 
        return {
            ...state,
            createProductCategorySuccess : false,
            createProductCategoryFailure : true,
            errorMessage : action.payload
        }

        case CREATE_PRODUCT_CATEGORY_INIT : 
        return {
            ...state,
            createProductCategorySuccess : false,
            createProductCategoryFailure : false,
            errorMessage : ''
        }

        // ==== Edit Product Category Cases ====
        case EDIT_PRODUCT_CATEGORY : 
        return {
            ...state,
            data : action.payload,
            editProductCategorySuccess : false,
            editProductCategoryFailure : false,
        }

        case EDIT_PRODUCT_CATEGORY_SUCCESS : 
        return {
            ...state,
            editProductCategoryDetails : action.payload,
            editProductCategorySuccess : true,
            editProductCategoryFailure : false
        }

        case EDIT_PRODUCT_CATEGORY_FAILURE : 
        return {
            ...state,
            editProductCategorySuccess : false,
            editProductCategoryFailure : true,
            errorMessage : action.payload
        }

        case EDIT_PRODUCT_CATEGORY_INIT : 
        return {
            ...state,
            editProductCategoryDetails : null,
            editProductCategorySuccess : false,
            editProductCategoryFailure : false,
            errorMessage : ''
        }

        // ==== Delete product Category cases ===
        case DELETE_PRODUCT_CATEGORY : 
        return {
            ...state,
            data : action.payload,
            deleteProductCategorySuccess : false,
            deleteProductCategoryFailure : false
        }

        case DELETE_PRODUCT_CATEGORY_SUCCESS : 
        return {
            ...state,
            deleteProductCategorySuccess : true,
            deleteProductCategoryFailure : false,
        }

        case DELETE_PRODUCT_CATEGORY_FAILURE : 
        return {
            ...state,
            deleteProductCategorySuccess : false,
            deleteProductCategoryFailure : true,
            errorMessage : action.payload
        }

        case DELETE_PRODUCT_CATEGORY_INIT : 
        return {
            ...state,
            deleteProductCategorySuccess : false,
            deleteProductCategoryFailure : false,
            errorMessage : ''
        }

        // ==== Get Product category case ====
        case GET_PRODUCT_CATEGORY :
            console.log("#####  inside reducer get!!")
            return {
                ...state,
                getProductCategorySuccess : false,
                getProductCategoryFailure : false
            }

        case GET_PRODUCT_CATEGORY_SUCCESS :
            return {
                ...state,
                productCategoriesList : action.payload,
                getProductCategorySuccess : true,
                getProductCategoryFailure : false,
            }

        case GET_PRODUCT_CATEGORY_FAILURE :
            return {
                ...state,
                getProductCategorySuccess : false,
                getProductCategoryFailure : true,
                errorMessage : action.payload
            }

        case GET_PRODUCT_CATEGORY_INIT :
            return {
                ...state,
                productCategoriesList : [],
                getProductCategorySuccess : false,
                getProductCategoryFailure : false,
                errorMessage : ''
            }

        // ==== cases for get product category by name ====
        case GET_PRODUCT_CATEGORY_BY_NAME : 
            return {
                ...state,
                data : action.payload,
                getProductCategoryByNameSuccess :false,
                getProductCategoryByNameFailure : false
            }

        case GET_PRODUCT_CATEGORY_BY_NAME_SUCCESS : 
            return {
                ...state,
                productCategoriesList : action.payload,
                getProductCategoryByNameSuccess : true,
                getProductCategoryByNameFailure : false
            }

        case GET_PRODUCT_CATEGORY_BY_NAME_FAILURE : 
            return {
                ...state,
                getProductCategoryByNameSuccess :false,
                getProductCategoryByNameFailure : true,
                errorMessage : action.payload
            }

        case GET_PRODUCT_CATEGORY_BY_NAME_INIT : 
            return {
                ...state,
                data : action.payload,
                productCategoriesList : [],
                getProductCategoryByNameSuccess :false,
                getProductCategoryByNameFailure : false,
                errorMessage : ''
            }

        default :
        return state

    }
}

export default ProductCategory;