import React from 'react';
import { Redirect } from 'react-router-dom';
// Authentication related pages
import Login from '../pages/Authentication/login';
// Dashboard
import Dashboard from '../pages/Dashboard/index';
//Customers
import Customers from '../pages/Customers/index';
//Database
import Vendors from '../pages/Vendors';
import Category from '../pages/ProductCategory';
import Product from '../pages/Product';
import Batch from '../pages/Batches';
//System
import System from '../pages/System/index';
import Overview from '../pages/System/overview';
import Firmware from '../pages/System/firmwareUpload';
import Group from '../pages/System/group';
import Subscription from '../pages/Subscription/index';
//Access Management
import ManageAccess from '../pages/ManageAccess/index';
import LnReport from '../pages/LnReport/index';

const authProtectedRoutes = [
    // this route should be at the end of all other routes
    { path: '/dashboard', component: Dashboard },
    { path: '/customers', component: Customers },
    { path: '/vendors', component: Vendors },
    { path: '/categories', component: Category },
    { path: '/products', component: Product },
    { path: '/batches', component: Batch },
    { path: '/system', component: System },
    { path: '/overview', component: Overview },
    { path: '/firmware', component: Firmware },
    { path: '/group', component: Group },
    { path: '/subscription', component: Subscription },
    { path: '/manage-access', component: ManageAccess },
    { path: '/ln-report', component: LnReport },

    {
        path: '/',
        exact: true,
        component: () => < Redirect to = '/dashboard' / > ,
    },
];

const publicRoutes = [{ path: '/login', component: Login }];

export { authProtectedRoutes, publicRoutes };