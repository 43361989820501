import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import {
    GET_ALL_USERS, GET_USER_DETAILS,
    EDIT_USER, DELETE_USER,
    INVITE_USER, 
    GET_ROLES, GET_PERMISSIONS
} from './actionTypes'

import {
    getAllUserSuccess, getAllUserFailure,
    getUserDetailsSuccess, getUserDetailsFailure,
    editUserSuccess, editUserFailure,
    deleteUserSuccess, deleteUserFailure,
    inviteUserSuccess, inviteUserFailure,
    getRolesSuccess, getRolesFailure,
    getPermissionsSuccess, getPermissionsFailure
} from './actions'
import { getRolesService, getAllUserService, getUserDetailService, editUserService, inviteUserService, deleteUserService, getPermissionsService } from '../../services/manageAccessService'
import { checkError } from '../../helpers/errorHelper'
import ManageAccess from './reducer';

// ==== Functions start here ====

// ==== Function to Get All users ====
function* fetchAllUsers(action){
    try{
        const response = yield call(getAllUserService)
        if(response.data.status === true){
            yield put(getAllUserSuccess(response.data.data))
        }
        else{
            yield put(getAllUserFailure(response.data.message))
        }
    }catch(error){
        yield put(getAllUserFailure(checkError(error)))
    }
}

// ==== Function to Get User Details ====
function* fetchUserDetails(action){
    try{
        let userId = action.payload.userId
        const response = yield call(getUserDetailService, userId)
        if(response.data.status === true){
            yield put(getUserDetailsSuccess(response.data.data))
        }
        else{
            yield put(getUserDetailsFailure(response.data.message))
        }
    }catch(error){
        yield put(getUserDetailsFailure(checkError(error)))
    }
}

// ==== Function to Edit user ====
function* updateUser(action){
    try{
        const response = yield call(editUserService, action.payload)
        if(response.data.status === true){
            yield put(editUserSuccess(response.data.data))
        }
        else{
            yield put(editUserFailure(response.data.message))
        }
    }catch(error){
        yield put(editUserFailure(checkError(error)))
    }
}

// ==== Function to Delete User ====
function* removeUser(action){
    try{
        let userId = action.payload.userId
        const response = yield call(deleteUserService, userId)
        if(response.data.status === true){
            yield put(deleteUserSuccess(response.data.data))
        }
        else{
            yield put(deleteUserFailure(response.data.message))
        }
    }catch(error){
        yield put(deleteUserFailure(checkError(error)))
    }
}

// ==== Function to Invite User ====
function* addUser(action){
    try{
        const response = yield call(inviteUserService, action.payload)
        if(response.data.status === true){
            yield put(inviteUserSuccess(response.data.data))
        }
        else{
            yield put(inviteUserFailure(response.data.message))
        }
    }catch(error){
        yield put(inviteUserFailure(checkError(error)))
    }
}


// ==== Funtion to Get Roles ====
function* fetchRoles(action){
    try{
        const response = yield call(getRolesService)
        if(response.data.status === true){
            yield put(getRolesSuccess(response.data.data))
        }
        else{
            yield put(getRolesFailure(response.data.message))
        }
    }catch(error){
        yield put(getRolesFailure(checkError(error)))
    }
}

// ==== Function to Get Permissions ====
function* fetchPermissions(action){
    try{
        let roleId = action.payload.roleId
        const response = yield call(getPermissionsService, roleId)
        if(response.data.status === true){
            yield put(getPermissionsSuccess(response.data.data))
        }
        else{
            yield put(getPermissionsFailure(response.data.message))
        }
    }catch(error){
        yield put(getPermissionsFailure(checkError(error)))
    }
}


// ==== Watcher Functions start here. ====
export function* watchFetchAllUsers() {
    yield takeEvery(GET_ALL_USERS, fetchAllUsers)
}
export function* watchFetchUserDetails() {
    yield takeEvery(GET_USER_DETAILS, fetchUserDetails)
}
export function* watchUpdateUsers() {
    yield takeEvery(EDIT_USER, updateUser)
}
export function* watchRemoveUser() {
    yield takeEvery(DELETE_USER, removeUser)
}
export function* watchAddUser() {
    yield takeEvery(INVITE_USER, addUser)
}
export function* watchFetchRoles() {
    yield takeEvery(GET_ROLES, fetchRoles)
}
export function* watchFetchPermissions() {
    yield takeEvery(GET_PERMISSIONS, fetchPermissions)
}

// ==== Main exporter function starts here ====

function* ManageAccessSaga(){
    yield all([
        fork(watchFetchAllUsers),
        fork(watchFetchUserDetails),
        fork(watchUpdateUsers),
        fork(watchRemoveUser),
        fork(watchAddUser),
        fork(watchFetchRoles),
        fork(watchFetchPermissions)
    ])
}

export default ManageAccessSaga;
