import API from './interceptor';
import constants from '../common/constants'
const urlDevicePrefix = constants.urlDevicePrefix
const urlPrefix = constants.urlPrefix

export function getOverviewService(params) {
    return API({
        method : 'GET',
        url : urlDevicePrefix + "systems/overview",
        params
    })
}

export function getFirmwareDetailsService(params){
    let productId = params.productId
    return API({
        method : 'GET',
        url : urlDevicePrefix + `systems/history/${productId}/build`
    })
}

export function rollbackFirmwareService(param){
    let firmwareId = param.firmwareId
    return API({
        method : 'PUT',
        url : urlDevicePrefix + `systems/firmware/${firmwareId}/rollback`
    })
}

export function stopFirmwareTestService(param){
    let firmwareId = param.firmwareId
    return API({
        method : 'PUT',
        url : urlDevicePrefix + `systems/firmware/${firmwareId}/stop`
    })
}

// ==== APIs for Firmware Upload ====

export function searchFirmwareService(params){
    let productId = params.productId
    return API({
        method : 'GET',
        url : urlDevicePrefix + `systems/firmwares/${productId}/details`
    })
}

export function getBuildTypesService(params){
    return API({
        method : 'GET',
        url : urlDevicePrefix + `systems/groups`,
        params
    })
}

export function updateFirmwareBuildService(params, productId){
    return API({
        method : 'POST',
        url : urlDevicePrefix + `systems/firmware/${productId}`,
        data : params,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}