import {
    GET_COUNTRIES,
    GET_COUNTRIES_SUCCESS,
    GET_COUNTRIES_FAILURE,
    GET_COUNTRIES_INIT
} from './actionTypes'

const initialState = {
    countries : [],
    errorMessage : '',
    getCountriesSuccess : false,
    getCountriesFailure : false,
}

const Master = (state = initialState, action) => {
    switch(action.type) {
        case GET_COUNTRIES :
            console.log("#####  Inside reducer for Countries!")
            return {
                ...state,
                getCountriesSuccess : false,
                getCountriesFailure : false
            }

        case GET_COUNTRIES_SUCCESS : 
            console.log("#####  Returning success!!")
            return {
                ...state,
                getCountriesSuccess : true,
                getCountriesFailure : false,
                countries : action.payload
            }

        case GET_COUNTRIES_FAILURE : 
            return {
                ...state,
                getCountriesSuccess : false,
                getCountriesFailure : true,
                errorMessage : action.payload
            }
        
        case GET_COUNTRIES_INIT : 
            return {
                ...state,
                getCountriesSuccess : false,
                getCountriesFailure : false,
                countries : []
            }

        default : 
            return state
    }
} 


export default Master;