import React, { useState, useEffect } from 'react';

const InputBox = (props) => {
    const [focus, setFocus] = useState(false);
    const [valid, setValid] = useState(false);
    const [invalid, setInvalid] = useState(null);

    const validateInput = () => {
        if (props.value === "") {
            setFocus(false);
            setValid(null);
            setInvalid(null);
        } else {
            setFocus(true);
        }
    }

    useEffect(() => {
        if (props.value !== "") {
            setFocus(true);
        } else if (focus == true && props.value === '') {
            setFocus(true);
        } else if (props.value === '') {
            setFocus(false);
        }

        if (props.hasError === false) {
            setValid(true);
            setInvalid(false);
        } else {
            console.log('has error is true')
            setInvalid(true);
            setValid(false);
        }
    }, [props.hasError, props.value]);

    return (<div className={"float-container " + ((focus) ? ' active ' : '') + ((invalid) ? ' error ' : '') + props.className}>
        <label> {props.label} </label>
        <input {...props}
            onBlur={validateInput}
            autoComplete="off"
            name={props.name}
            onFocus={
                () => { setFocus(true) }}
            placeholder={
                (focus) ? '' : props.label}
        />
    </div>
    )
}

export default InputBox;