import React from 'react';
import offlineLogo from '../../assets/images/offline-logo.png'
import onlineLogo from '../../assets/images/online-logo.png'



function DeviceCard(props) {
  let { productImage, userId, userName, mobNumber, emailId, deviceStatus, deviceType, product, productCategory, serialNumber, productType } = props.data;

  return (
    <div className="device-card-container" onClick={() => { props.clickHandler() }}>
      <div className="status-indicator">
        {/* <span className="status-tag">{deviceStatus === 0 ? "Offline" : 'Online'}</span> */}
        {
          deviceStatus === 0 ? <img src={offlineLogo} className="status-tag-img"></img> : <img src={onlineLogo} className="status-tag-img"></img>
        }
      </div>
      <div className="detail-grid">
        <div>
          <div className="image-holder">
            <img src={productImage} alt="Camera" />
          </div>
          <div className="icon-btns">
            <i className="fas fa-rupee-sign"></i>
            <i className="fas fa-cloud"></i>
            <i className="fas fa-sim-card"></i>
          </div>
        </div>

        <div>
          <p className="no-mar info-text">User Name : <span className="text-value">{userName}</span></p>
          <p className="no-mar info-text">Mobile No : <span className="text-value">{mobNumber}</span></p>
          <p className=" info-text">Email ID : <span className="text-value">{emailId}</span></p>

          <p className="no-mar info-text">Device Type : </p>
          <p className="text-value info-text">{productType !== null ? productType : '--'}</p>
          <p className="no-mar info-text">Serial No : </p>
          <p className="text-value info-text">{serialNumber}</p>
        </div>
      </div>
    </div>
  )
}

export default DeviceCard;