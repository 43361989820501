// import {  } from './actionTypes';

import {
    SAVE_COUNTRIES_LIST,
    SAVE_VENDORS_LIST,
    SAVE_PRODUCT_TYPES,
    SAVE_PRODUCT_CATEGORIES,
    SAVE_PRODUCTS,
    SUCCESS_CREATE_VENDOR,
    CHANGE_VENDOR_TOAST,
    SUCCESS_DELETE_VENDOR,
    SUCCESS_CREATE_PRODUCT_CATEGORY,
    SUCCESS_DELETE_CATEGORY,
    SUCCESS_EDIT_CATEGORY,
    SUCCESS_EDIT_VENDOR,
    CREATE_PRODUCT,
    SAVE_BATCHES,
    SUCCESS_DELETE_BATCH,
    SUCCESS_CREATE_BATCH,
    SUCCESS_EDIT_BATCH,
    SUCCESS_EDIT_PRODUCT
} from "./actionTypes";

const initialState = {
    countries: [],
    vendors: [],
    productTypes: [],
    productCategories: [],
    products: [],
    batches: [],
    vendorResponse: {
        status: null,
        message: '',
        valid: false
    }
}



const Database = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_COUNTRIES_LIST:
            return {
                ...state,
                countries: action.payload
            }

        case SAVE_VENDORS_LIST:
            return {
                ...state,
                vendors: action.payload
            }

        case SAVE_PRODUCT_TYPES:
            return {
                ...state,
                productTypes: action.payload
            }

        case SAVE_PRODUCT_CATEGORIES:
            return {
                ...state,
                productCategories: action.payload
            }

        case SAVE_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }

        case SAVE_BATCHES:
            return {
                ...state,
                batches: action.payload
            }

        case SUCCESS_CREATE_VENDOR:
            return {
                ...state,
                vendors: [...state.vendors, action.payload]
            }

        case SUCCESS_EDIT_VENDOR:
            let editedVendorList = state.vendors.map((val) => {
                if (val.vendorId === action.payload.vendorId) {
                    return action.payload
                } else {
                    return val
                }
            });
            return {
                ...state,
                vendors: [...editedVendorList]
            }

        case CREATE_PRODUCT:
            return {
                ...state,
                products: [action.payload]
            }

        case CHANGE_VENDOR_TOAST:
            let newResponse = {
                status: state.vendorResponse.status,
                message: state.vendorResponse.message,
                valid: false
            }
            return {
                ...state,
                vendorResponse: {...newResponse }
            }

        case SUCCESS_DELETE_VENDOR:
            let newVendorList = state.vendors.filter((val) => {
                return val.vendorId !== action.payload
            });
            console.log(newVendorList);
            return {
                ...state,
                vendors: [...newVendorList]
            }
        case SUCCESS_CREATE_PRODUCT_CATEGORY:
            return {
                ...state,
                productCategories: [action.payload]
            }

        case SUCCESS_DELETE_CATEGORY:
            let newlist = state.productCategories.filter((val) => { return val.productCategoryId !== action.payload })
            return {
                ...state,
                productCategories: [...newlist]
            }

        case SUCCESS_DELETE_BATCH:
            let newBatches = state.batches.filter((val) => { return val.batchId !== action.payload })
            return {
                ...state,
                batches: [...newBatches]
            }

        case SUCCESS_CREATE_BATCH:
            // let newBatches = state.batches.filter((val) => { return val.batchId !== action.payload })
            return {
                ...state,
                batches: [...state.batches, action.payload]
            }
        case SUCCESS_EDIT_BATCH:
            let editedBatch = state.batches.map((val) => {
                if (val.batchId === action.payload.batchId) {
                    return action.payload;
                } else {

                    return val;
                }
            });
            return {
                ...state,
                batches: [...editedBatch]
            }
        case SUCCESS_EDIT_CATEGORY:
            let editedList = state.productCategories.map((val) => {
                if (val.productCategoryId === action.payload.productCategoryId) {
                    return action.payload;
                } else {

                    return val;
                }
            });
            return {
                ...state,
                productCategories: [...editedList]
            }

        case SUCCESS_EDIT_PRODUCT:
            let editedProductList = state.products.map((val) => {
                if (val.productId === action.payload.productId) {
                    return action.payload;
                } else {
                    return val;
                }
            });
            return {
                ...state,
                products: [...editedProductList]
            }


        default:
            return state;
    }
}

export default Database;