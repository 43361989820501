import React, { Component } from 'react';
import { Container, Button, Modal, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import * as LayoutActions from '../../store/layout/actions';

import * as VendorActions from '../../store/vendor/actions';
import * as MasterActions from '../../store/master/actions'

import { ToastContainer, toast } from 'react-toastify';
import InputBox from '../../components/Common/input-box'
import { connect } from 'react-redux';
import DropdownList from 'react-widgets/lib/DropdownList';
import Breadcrumbs from '../../components/Common/breadcrumb';
import { sortIntegerTypeData, sortStringTypeData } from '../../helpers/sortHelper';

class Vendors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateModal: false,
            modalType: 'add',
            vendorsList: [],
            countryList: [],
            searchValue: '',
            vendorName: '',
            vendorId: '',
            originCountryId: null,
            vendorNameError: false,
            countryIdError: false,
            searchValueError: false,
            deleteConfirmPopUp: false,
            deleteConfirmPopupId: null,
            direction: 1,
            deleteIndex : null,
            editIndex : null,
            originCountryName : null,
            emptySearchResult : ''
        }
    }

    autogenValue = () => {
        return 'GSK ' + Math.random();
    }

    componentDidMount() {
        this.props.getCountries();
        this.props.changePreloader(true);
    }

    modalToggle = () => {
        let { showCreateModal } = this.state;
        this.setState({ modalType: 'add', showCreateModal: !showCreateModal, vendorName: '', originCountryId: null, vendorNameError: false, countryIdError: false });
    }


    addClickHandler = (type, data) => {
        let { vendorId, originCountryId, vendorName, originCountryName } = this.state;
        let trimmedVendorName = vendorName.trim()
        if (originCountryId !== null && trimmedVendorName !== '') {
            let data = {
                originCountryId,
                vendorName: trimmedVendorName,
                vendorId: 34                //Unnecessary use of vendorId property in create vendor API.
            };
            if (type === 'add') {
                this.props.createVendor(data)
                this.props.changePreloader(true)

            } else if (type === 'edit') {
                //call edit api
                let country = this.state.countryList.find(value => value.countryId === originCountryId)

                let newData = {
                    originCountryId: originCountryId,
                    vendorName: trimmedVendorName,
                    vendorId: vendorId,
                    countryName : country.countryName
                };
                this.props.editVendor(newData)
                this.props.changePreloader(true)

            }
        }
        if (originCountryId === null) {
            this.setState({ countryIdError: true });
        }
        if (originCountryId !== null) {
            this.setState({ countryIdError: false });
        }
        if (vendorName !== '') {
            this.setState({ vendorNameError: false });
        }
        if (trimmedVendorName === '') {
            this.setState({ vendorNameError: true });
        }
    }

    onSearch = (e) => {
        if (e.keyCode === 13 || e.target.name === 'searchButton') {
            let { searchValue } = this.state

            // this.setState({ vendorsList : [], emptySearchResult : ''})
            let trimmedSearchValue = searchValue.toString().trim()

            if (trimmedSearchValue !== '') {                                
                let data = { search : trimmedSearchValue }
                this.setState({ searchValueError: false, searchValue : trimmedSearchValue, vendorsList : [], emptySearchResult : '' }, () => {
                    this.props.getVendors(data)
                    this.props.changePreloader(true)
                });
                // this.setState ({ searchValue : '' })
            } else {
                this.setState({
                    searchValueError: true
                })
            }
        }
    }

    onClearVendors = () => {
        this.setState({ searchValue : '', searchValueError : false})
        this.props.getVendors()
        this.props.changePreloader(true)
    }

    inputHandler = (e) => {
        let { vendorForm, productCategoryForm, productForm, batchForm } = this.state;
        if (e.target.name === 'vendorNameField') {
            this.setState({ vendorName: e.target.value, vendorNameError: false });
        } else {
            this.setState({ searchValue: e.target.value, searchValueError: false });
        }
    }

    editVendorHandler = (vendor, index) => {
        this.setState({ vendorId: vendor.vendorId, originCountryId: vendor.countryId, vendorName: vendor.vendorName, modalType: 'edit', showCreateModal: true, countryIdError: false, vendorNameError: false, editIndex : index, originCountryName : vendor.countryName });
    }

    deleteVendorHandler = (vendorId, index) => {
        let data = {
            vendorId: vendorId
        }
        this.props.deleteVendor(data)
        this.props.changePreloader(true);
        this.setState({ deleteConfirmPopUp: false, deleteConfirmPopupId: '', deleteIndex : index });
    }

    deleteVendorFromList = () => {
        let { deleteIndex, vendorsList } = this.state
        vendorsList.splice(deleteIndex, 1)
        this.setState({ vendorsList : vendorsList, deleteIndex : null})
    }

    editVendorOnList = (data) => {
        console.log(`#### DATA MSG : ${data}`)
        
        let { editIndex, vendorsList } = this.state
        vendorsList[editIndex] = data
        this.setState({ vendorsList : vendorsList, editIndex : null })
    }

    toggletop = (id) => { this.setState({ deleteConfirmPopUp: !this.state.deleteConfirmPopUp, deleteConfirmPopupId: id }); }

    checkGetVendors = () => {
        let { vendorsList } = this.state;
        if (this.props.layout.isPreloader) {
            if (this.props.vendor.getVendorsSuccess === true) {
                console.log("#####  VENDOR GET SUCCESS")
                this.props.changePreloader(false);
                // this.setState({ vendorsList: [...vendorsList, ...this.props.vendor.vendors] });
                this.setState({ vendorsList: this.props.vendor.vendors });
                this.props.getVendorsInit();

            } else if (this.props.vendor.getVendorsFailure === true) {
                console.log("#####  VENDOR GET FAILURE")
                this.setState({ emptySearchResult : 'No Vendors Found'})
                this.props.changePreloader(false);
                this.props.getVendorsInit();
            }
        }
    }

    checkGetCountries = () => {
        let { countryList } = this.state
        if (this.props.layout.isPreloader) {
            if (this.props.master.getCountriesSuccess === true) {
                this.setState({ countryList: [...countryList, ...this.props.master.countries] })
                console.log("#####  Country list populated in local state")
                // console.log(`#####  List : ${this.state.countryList}`)
                this.props.getCountriesInit()
                this.props.getVendors()
            } else if (this.props.master.getCountriesFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.master.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getCountriesInit()
            }
        }
    }

    checkCreateVendor = () => {
        // if(this.props.layout.isPreloader){
        if (this.props.vendor.createVendorSuccess === true) {
            this.setState({ showCreateModal: false })
            toast.success("Vendor Created Successfully.")
            this.props.createVendorInit()
            this.setState({ vendorsList: [] })
            this.onClearVendors()
        } else if (this.props.vendor.createVendorFailure === true) {
            this.props.changePreloader(false)
            toast.error(this.props.vendor.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.createVendorInit(); // ==== Why was this imp ??? ====

        }
        // }
    }

    checkEditVendor = () => {
        // if(this.props.layout.isPreloader){
        if (this.props.vendor.editVendorSuccess === true) {
            this.setState({ showCreateModal: false })
            let data = this.props.vendor.editVendorDetails
            this.editVendorOnList(data)
            this.props.editVendorInit()
            this.props.changePreloader(false)
            toast.success("Vendor Updated Successfully.")
            
            // this.props.editVendorInit()
            // this.setState({ vendorsList: [] })
            // this.props.getVendors()
        } else if (this.props.vendor.editVendorFailure === true) {
            this.props.changePreloader(false)
            toast.error(this.props.vendor.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.editVendorInit(); // ==== Why was this imp ??? ====

        }
        // }
    }

    checkDeleteVendor = () => {
        if (this.props.vendor.deleteVendorSuccess === true) {
            this.props.deleteVendorInit()
            // this.setState({ vendorsList: [] })
            // this.props.getVendors()
            this.deleteVendorFromList()
            this.props.changePreloader(false)
            toast.success("Vendor Deleted Successfully")
        } else if (this.props.vendor.deleteVendorFailure === true) {
            this.props.changePreloader(false)
            toast.error(this.props.vendor.errorMessage, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.deleteVendorInit(); // ==== Why was this imp ??? ====

        }
    }

    sort = (sortBy, sortType) => {
        let order = this.state.direction === -1 ? 'desc' : 'asc';
        if (sortType === 'string') {
            let sortedData = sortStringTypeData(this.state.vendorsList, sortBy, order);
            this.setState({ vendorsList: sortedData, direction: this.state.direction === -1 ? 1 : -1 });
        } else {
            let sortedData = sortIntegerTypeData(this.state.vendorsList, sortBy, order);
            this.setState({ vendorsList: sortedData, direction: this.state.direction === -1 ? 1 : -1 })
        }
    }

    render() {
        let { vendorForm, searchValue, vendorName, vendorNameError, countryIdError, originCountryId, autogeneratedValue, modalType, searchValueError, countryList, deleteConfirmPopupId, deleteConfirmPopUp, vendorsList } = this.state;

        // ==== Below are the methods to be called on every state change ====
        this.checkGetVendors();
        this.checkGetCountries();
        this.checkCreateVendor();
        this.checkEditVendor();
        this.checkDeleteVendor();
        return (
            <React.Fragment>
                <div className="page-content vendor-page">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        {/* ==== Uncomment Below to show bread crumbs ==== */}
                        {/* <Breadcrumbs title="Admin" breadcrumbItem="Manage Vendors" /> */}
                    </Container>
                    <div className="actions-section">
                        <div className="actions-grid">
                            <InputBox type="text" label="Search Vendors" key={98} name="SearchInputTag" value={searchValue} onChange={(e) => { this.inputHandler(e) }} onKeyDown={(e) => this.onSearch(e)} hasError={searchValueError} />
                            <Button name="searchButton" onClick={(e) => { this.onSearch(e) }}>Search</Button>
                            <div className="clear-btn" onClick = {() => {this.onClearVendors()}}>
                                <span>Clear</span>
                            </div>
                            <span className="divider"></span>
                            <Button onClick={this.modalToggle}>Add Vendor</Button>
                        </div>
                    </div>

                    <div className="table-section">
                        <div>
                            <div className="custom-table-head">
                                <div>Vendor ID <i className="fas fa-sort cur-pointer" onClick={() => { this.sort('vendorId', 'integer') }}></i></div>
                                <div>Vendor Name <i className="fas fa-sort cur-pointer" onClick={() => { this.sort('vendorName', 'string') }}></i></div>
                                <div>Country <i className="fas fa-sort cur-pointer" onClick={() => { this.sort('countryName', 'string') }}></i></div>
                                <div>Actions </div>
                            </div>


                            { 
                                vendorsList.length !== 0 ? vendorsList.map((val, index) => {
                                return (
                                    <div className="table-row-section">
                                        <div className="custom-table-body table-data-style">
                                            <div className="has-edit-icon">{val.vendorId} </div>
                                            <div className="name-fieldbox">
                                                {val.vendorName}
                                            </div>
                                            <div className="has-edit-icon">{val.countryName} </div>
                                            <div className="p-10">
                                                <i className="fas fa-pencil-alt font-size-18 golden-color select-hover" onClick={() => { this.editVendorHandler(val, index) }} ></i>
                                                <i id={`pop${val.vendorId}`} className="fa fa-trash font-size-18 m-l-10 color-red select-hover" aria-hidden="true"></i>
                                                <Popover placement="top" isOpen={(deleteConfirmPopUp === true && deleteConfirmPopupId === val.vendorId) ? true : false} target={`pop${val.vendorId}`} toggle={() => { this.toggletop(val.vendorId) }}>
                                                    <PopoverHeader>Are you sure?</PopoverHeader>
                                                    <PopoverBody>
                                                        <div className="popconfirm-body">
                                                            <span onClick={() => { this.deleteVendorHandler(val.vendorId, index) }}>Yes</span>
                                                            <span onClick={() => { this.toggletop('') }}>No</span>
                                                        </div>
                                                    </PopoverBody>
                                                </Popover>

                                            </div>
                                        </div>
                                    </div>
                                )
                            }):
                            <div>
                                    <br></br>
                                    <center><b>{this.state.emptySearchResult}</b></center>
                            </div>}

                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.showCreateModal}
                    toggle={this.modalToggle}
                    className="modal-md m-t-15"
                >
                    <p className="modal-body-title">{modalType === 'add' ? 'Add' : 'Update'} Vendor</p>
                    <div className="modal-body custom-body-style">
                        <div className="form-section">
                            <InputBox type="text" className="m-b-seven" label="Vendor Name" name="vendorNameField" key={200} value={vendorName} onChange={(e) => { this.inputHandler(e) }} hasError={vendorNameError} />
                            <DropdownList
                                className={"m-b-eight " + ((countryIdError) ? 'select-error' : '')}
                                name='countryDropdown'
                                placeholder="Select Country"
                                data={countryList}
                                defaultValue={originCountryId}
                                textField="countryName"
                                valueField="countryId"
                                onChange={(e) => {
                                    this.setState({ originCountryId: e.countryId, countryIdError: false });
                                }}
                            />

                            <div className="footer-btns">
                                {modalType === 'add' ? (<Button onClick={() => { this.addClickHandler('add') }}>Add</Button>) : (<Button onClick={() => { this.addClickHandler('edit') }}>Update</Button>)}
                                <span onClick={() => { this.modalToggle() }} >Cancel</span>
                            </div>
                        </div>


                    </div>
                </Modal>
                <ToastContainer />

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    vendor: state.Vendor,
    layout: state.Layout,
    master: state.Master
});

const mapDispatchToProps = dispatch => ({
    createVendorInit: () => dispatch(VendorActions.createVendorInit()),
    createVendor: (data) => dispatch(VendorActions.createVendor(data)),
    getVendors: (data) => dispatch(VendorActions.getVendors(data)),
    changePreloader: (flag) => dispatch(LayoutActions.changePreloader(flag)),
    getVendorsInit: () => dispatch(VendorActions.getVendorsInit()),

    editVendor: (data) => dispatch(VendorActions.editVendor(data)),
    editVendorInit: () => dispatch(VendorActions.editVendorInit()),

    deleteVendor: (data) => dispatch(VendorActions.deleteVendor(data)),
    deleteVendorInit: () => dispatch(VendorActions.deleteVendorInit()),

    getCountries: () => dispatch(MasterActions.getCountries()),
    getCountriesSuccess: () => dispatch(MasterActions.getCountriesSuccess()),
    getCountriesFailure: () => dispatch(MasterActions.getCountriesFailure()),
    getCountriesInit: () => dispatch(MasterActions.getCountriesInit())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Vendors);