import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";

// Dashboard
import Dashboard from "./dashboard/reducer";

import Database from "./database/reducer";

import Vendor from "./vendor/reducer";

import Master from "./master/reducer"

import ProductCategory from './productCategory/reducer'

import Product from './product/reducer'

import Batch from './batch/reducer'

import Customer from './customer/reducer'

import Devices from './devices/reducer'

import Firmware from './firmware/reducer'

import Subscription from './subscription/reducer'

import ManageAccess from './manageAccess/reducer'

import LNDashboard from './lnDashboard/reducer'


const rootReducer = combineReducers({
    // public
    Layout,
    Login,
    Dashboard,
    Database,
    Vendor,
    Master,
    ProductCategory,
    Product,
    Batch,
    Customer,
    Devices,
    Firmware,
    Subscription,
    ManageAccess,
    LNDashboard
});

export default rootReducer;