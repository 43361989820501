export const GET_COUNTRIES_LIST = 'GET_COUNTRIES_LIST';
export const SAVE_COUNTRIES_LIST = 'SAVE_COUNTRIES_LIST';

export const GET_VENDORS_LIST = 'GET_VENDORS_LIST';
export const SAVE_VENDORS_LIST = 'SAVE_VENDORS_LIST';

export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES';
export const SAVE_PRODUCT_TYPES = 'SAVE_PRODUCT_TYPES';

export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const SAVE_PRODUCT_CATEGORIES = 'SAVE_PRODUCT_CATEGORIES';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SAVE_PRODUCTS = 'SAVE_PRODUCTS';

export const CREATE_VENDOR = 'CREATE_VENDOR';
export const SUCCESS_CREATE_VENDOR = 'SUCCESS_CREATE_VENDOR';

export const CREATE_PRODUCT_CATEGORY = 'CREATE_PRODUCT_CATEGORY';
export const SUCCESS_CREATE_PRODUCT_CATEGORY = 'SUCCESS_CREATE_PRODUCT_CATEGORY';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const SUCCESS_CREATE_PRODUCT = 'SUCCESS_CREATE_PRODUCT';

export const CHANGE_VENDOR_TOAST = 'CHANGE_VENDOR_TOAST';

export const SUCCESS_DELETE_VENDOR = 'SUCCESS_DELETE_VENDOR';
export const SUCCESS_DELETE_CATEGORY = 'SUCCESS_DELETE_CATEGORY';

export const SUCCESS_EDIT_CATEGORY = 'SUCCESS_EDIT_CATEGORY';
export const SUCCESS_EDIT_VENDOR = 'SUCCESS_EDIT_VENDOR';
export const SUCCESS_EDIT_PRODUCT = 'SUCCESS_EDIT_PRODUCT';

export const GET_BATCHES = 'GET_BATCHES';
export const SAVE_BATCHES = 'SAVE_BATCHES';

export const SUCCESS_DELETE_BATCH = 'SUCCESS_DELETE_BATCH';
export const SUCCESS_CREATE_BATCH = 'SUCCESS_CREATE_BATCH';
export const SUCCESS_EDIT_BATCH = 'SUCCESS_EDIT_BATCH';