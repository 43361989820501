import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Const from '../../common/constants';
import TransactionBox from '../../components/Common/transactionBox';
import Table from '../../components/Common/ln-table';
import Search from '../../assets/images/search-solid.svg';
import RoundBtn from '../../assets/images/circle-solid.svg';
import TransactionDetails from '../../components/Common/transaction-details';
import AdvancedSearch from '../../components/Common/advanced-search';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';

import { convertDateStringToUTCDateTimeString } from '../../helpers/formatDateHelper';

import { useErrorHandlingService } from '../../services/errorHandlingService';
import { useCheckDownloadStatus } from '../../services/downloadExcelService';

// Actions
import { changePreloader } from '../../store/layout/actions';
import { getPendingTransactions, clearPendingTransactions, getPendingTransactionsExcel } from '../../store/lnDashboard/actions';


const Overview = () => {
  const dispatch = useDispatch();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectSearch, setSelectSearch] = useState(false);
  const [selectedFilter, setFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useState(null);
  const insightsData = useSelector(state => state.LNDashboard.insightsData);
  const [totalPages, setTotalPages] = useState(0);
  const recordCount = useSelector(state => state.LNDashboard.recordCount);

  const downloadStatus = useCheckDownloadStatus();
  const errorMessageService = useErrorHandlingService();

  // Similar to componentDidMount:
  useEffect(() => {
    loadPendingTransactionsList();
  }, []);

  useEffect(() => {
    calculatePagesFromRecordCount();
  }, [recordCount]);

  const calculatePagesFromRecordCount = () => {
    if (recordCount !== 0) {
      let totalPages = parseInt(recordCount / 10);
      let lastPage = recordCount % 10 === 0 ? false : true;
      if (recordCount <= 10) {
        setTotalPages(1);
      } else {
        if (lastPage == true) {
          setTotalPages(totalPages + 1);
        } else {
          setTotalPages(totalPages);
        }
      }
    }
  }

  const loadPendingTransactionsList = () => {
    dispatch(changePreloader(true));
    let params = {
      TransactionType: Const.transactionTypes.pending,
      pageCount: currentPage,
      pageSize: Const.pageSize
    }
    setSearchParams(params);
    dispatch(getPendingTransactions(params));
  }

  const downloadPendingTransactionsList = () => {
    let params;
    if (selectedFilter == null) {
      params = {
        TransactionType: Const.transactionTypes.pending,
      }
    } else {
      switch (selectedFilter) {
        case 1:
          params = {
            TransactionType: Const.transactionTypes.pending,
            fromDate: moment().format('YYYY/MM/DD'),
            toDate: moment().format('YYYY/MM/DD'),
          }
          break;
        case 2:
          params = {
            TransactionType: Const.transactionTypes.pending,
            fromDate: moment().subtract(2, 'days').format('YYYY/MM/DD'),
            toDate: moment().subtract(1, 'days').format('YYYY/MM/DD')
          }
          break;
        case 3:
          params = {
            TransactionType: Const.transactionTypes.pending,
            fromDate: moment('01/01/2020').format('YYYY/MM/DD'),
            toDate: moment().subtract(2, 'days').format('YYYY/MM/DD')
          }
          break;
      }
    }

    dispatch(getPendingTransactionsExcel(params));
  }

  const toggleAdvanceSearch = () => {
    if (selectSearch) {
      dispatch(clearPendingTransactions());
      loadPendingTransactionsList();
      setFilter(null);
      setSelectSearch(false);
    } else {
      dispatch(clearPendingTransactions());
      setSelectSearch(true);
    }
  }

  const filterByInsights = (type) => {
    if (selectedFilter == type) {
      setFilter(null);
      setCurrentPage(1);
      dispatch(changePreloader(true));
      let params = {
        TransactionType: Const.transactionTypes.pending,
        pageCount: 1,
        pageSize: Const.pageSize
      }
      setSearchParams(params);
      dispatch(getPendingTransactions(params));
      return
    }
    let params;
    switch (type) {
      case 1:
        params = {
          TransactionType: Const.transactionTypes.pending,
          fromDate: convertDateStringToUTCDateTimeString(moment().format('YYYY/MM/DD')),
          toDate: convertDateStringToUTCDateTimeString(moment().format('YYYY/MM/DD')),
          pageCount: currentPage,
          pageSize: Const.pageSize
        }
        break;
      case 2:
        params = {
          TransactionType: Const.transactionTypes.pending,
          fromDate: convertDateStringToUTCDateTimeString(moment().subtract(2, 'days').format('YYYY/MM/DD')),
          toDate: convertDateStringToUTCDateTimeString(moment().subtract(1, 'days').format('YYYY/MM/DD')),
          pageCount: currentPage,
          pageSize: Const.pageSize
        }
        break;
      case 3:
        params = {
          TransactionType: Const.transactionTypes.pending,
          fromDate: convertDateStringToUTCDateTimeString(moment('01/01/2020').format('YYYY/MM/DD')),
          toDate: convertDateStringToUTCDateTimeString(moment().subtract(2, 'days').format('YYYY/MM/DD')),
          pageCount: currentPage,
          pageSize: Const.pageSize
        }
        break;
    }

    setSearchParams(params);
    setFilter(type);
    setCurrentPage(1);
    dispatch(clearPendingTransactions());
    dispatch(changePreloader(true));
    dispatch(getPendingTransactions(params));
  }

  const onPagination = (type) => {
    let newPage;
    switch (type) {
      case 'prev':
        if (currentPage == 1) return
        newPage = currentPage - 1;
        setCurrentPage(newPage);
        // console.log(lastPage);
        break;
      case 'next':
        if (currentPage == totalPages) return
        newPage = currentPage + 1;
        setCurrentPage(newPage);
        console.log(currentPage);
        break;
    }

    dispatch(changePreloader(true));
    let params = { ...searchParams };
    params.pageCount = newPage;

    dispatch(getPendingTransactions(params));
  }

  return (
    <div className='ln-report'>
      <div className='overview'>
        {selectedTransaction !== undefined && selectedTransaction !== null ? (
          <TransactionDetails
            onResetSelectedTransaction={
              setSelectedTransaction
            }
            selectedTransactionId={selectedTransaction}
          />
        ) : selectSearch ? (
          <AdvancedSearch
            onBack={() => { toggleAdvanceSearch() }}
            defaultTransactionType={0}
            pageType='ln'
          />
        ) : (
          <Fragment>
            <div className='upper-section'>
              <div className='title-section'>
                <div className='title'>
                  Pending Transactions
                </div>
                <div
                  className='search'
                  onClick={setSelectSearch}>
                  <img src={Search} />
                  Advanced Search
                </div>
              </div>
              <div className='transaction-section'>
                <div className='transaction-visual'>
                  <div
                    onClick={() => { filterByInsights(3) }}>
                    <TransactionBox
                      selectedFilter={selectedFilter}
                      clearFilter={() => { filterByInsights(3) }}
                      id={3}
                      colorClass={'danger-red'}
                      transactionNum={insightsData !== null ? insightsData.moreThanThree : '--'}
                      transactionInfo='More than 3 days'
                      transactionImg={
                        <img
                          src={RoundBtn}
                          className='round-btn'
                          alt=''
                        />
                      }
                    />
                  </div>

                  <div className='vertical-seperator'></div>
                  <div onClick={() => filterByInsights(2)}>
                    <TransactionBox
                      selectedFilter={selectedFilter}
                      id={2}
                      colorClass={'average-orange'}
                      clearFilter={() => { filterByInsights(2) }}
                      transactionNum={insightsData !== null ? insightsData.twoToThree : '--'}
                      transactionInfo='2 to 3 days'
                      transactionImg={
                        <img
                          src={RoundBtn}
                          className='round-btn'
                        />
                      }
                    />
                  </div>

                  <div className='vertical-seperator'></div>
                  <div onClick={() => filterByInsights(1)}>
                    <TransactionBox
                      selectedFilter={selectedFilter}
                      id={1}
                      colorClass={'free-green'}
                      clearFilter={() => { filterByInsights(1) }}
                      transactionNum={insightsData !== null ? insightsData.today : '--'}
                      transactionInfo='Today'
                      transactionImg={
                        <img
                          src={RoundBtn}
                          className='round-btn'
                        />
                      }
                    />
                  </div>
                </div>
                <div className='data'></div>
              </div>
            </div>

            <Table
              pageType="ln"
              page={currentPage}
              totalPages={totalPages}
              onPageChange={(type) => onPagination(type)}
              onSelectTransaction={(data) => { setSelectedTransaction(data) }}
              onDownload={() => { downloadPendingTransactionsList() }}

            />
          </Fragment>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};
export default Overview;
