import React, { Fragment } from 'react';
import closeBtn from '../../assets/images/close.svg';

const TransactionBox = (props) => {
  return (
    <Fragment>
      <div className='pending-section'>
        <div
          className={
            `${props.colorClass} ${props.selectedFilter == props.id
              ? 'square-data selected-square'
              : 'square-data'}`
          }>
          {props.selectedFilter == props.id ? (
            <div>
              <img
                className='close-icx'
                src={closeBtn}
                alt=''
              />
            </div>
          ) : null}
          <p className='transaction-num'>
            {props.transactionNum}
          </p>
          <p className='meta-info'>Transactions</p>
        </div>
        <div className='transaction-info'>
          <p>
            {/* {props.transactionImg} */}
            {props.transactionInfo}

            <span>{props.transactionStatus}</span>
          </p>
        </div>
      </div>
    </Fragment>
  );
};
export default TransactionBox;
