import {
    CREATE_VENDOR,
    CREATE_VENDOR_SUCCESS,
    CREATE_VENDOR_FAILURE,
    CREATE_VENDOR_INIT,
    EDIT_VENDOR,
    EDIT_VENDOR_SUCCESS,
    EDIT_VENDOR_FAILURE,
    EDIT_VENDOR_INIT,
    DELETE_VENDOR,
    DELETE_VENDOR_SUCCESS,
    DELETE_VENDOR_FAILURE,
    DELETE_VENDOR_INIT,
    FETCH_VENDORS,
    FETCH_VENDORS_SUCCESS,
    FETCH_VENDORS_FAILURE,
    FETCH_VENDORS_INIT
} from './actionTypes';


export const createVendor = (data) => {
    return {
        type: CREATE_VENDOR,
        payload: data
    }
}

export const createVendorSuccess = (data) => {
    return {
        type: CREATE_VENDOR_SUCCESS,
        payload: data
    }
}

export const createVendorFailure = (data) => {
    return {
        type: CREATE_VENDOR_FAILURE,
        payload: data
    }
}

export const createVendorInit = (data) => {
    return {
        type: CREATE_VENDOR_INIT,
        payload: data
    }
}

//edit
export const editVendor = (data) => {
    console.log("#####  Edit vendor action!")
    return {
        type: EDIT_VENDOR,
        payload: data
    }
}

export const editVendorSuccess = (data) => {
    return {
        type: EDIT_VENDOR_SUCCESS,
        payload: data
    }
}

export const editVendorFailure = (data) => {
    return {
        type: EDIT_VENDOR_FAILURE,
        payload: data
    }
}

export const editVendorInit = (data) => {
    return {
        type: EDIT_VENDOR_INIT,
        payload: data
    }
}


//delete
export const deleteVendor = (data) => {
    return {
        type: DELETE_VENDOR,
        payload: data
    }
}

export const deleteVendorSuccess = (data) => {
    return {
        type: DELETE_VENDOR_SUCCESS,
        payload: data
    }
}

export const deleteVendorFailure = (data) => {
    return {
        type: DELETE_VENDOR_FAILURE,
        payload: data
    }
}

export const deleteVendorInit = (data) => {
    return {
        type: DELETE_VENDOR_INIT,
        payload: data
    }
}


//get
export const getVendors = (data) => {
    console.log("#####  inside get vendors")
    return {
        type: FETCH_VENDORS,
        payload: data
    }
}

export const getVendorsSuccess = (data) => {
    return {
        type: FETCH_VENDORS_SUCCESS,
        payload: data
    }
}

export const getVendorsFailure = (data) => {
    return {
        type: FETCH_VENDORS_FAILURE,
        payload: data
    }
}

export const getVendorsInit = (data) => {
    return {
        type: FETCH_VENDORS_INIT,
        payload: data
    }
}