import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { CREATE_BATCH, EDIT_BATCH, DELETE_BATCH, GET_BATCH, DOWNLOAD_BATCH_FILE, UPLOAD_BATCH_FILE } from './actionTypes';
import { 
    createBatchSuccess, createBatchFailure,
    editBatchSuccess, editBatchFailure,
    deleteBatchSuccess, deleteBatchFailure,
    getBatchSuccess, getBatchFailure,
    downloadBatchFileSuccess, downloadBatchFileFailure,
    uploadBatchFileSuccess, uploadBatchFileFailure
 } from './actions';
import { fetchBatches, searchBatches, addNewBatch, editBatch, deleteBatch, downloadExcel, uploadExcel } from "../../services/batchService";
import constants from '../../common/constants'
import { act } from 'react-dom/test-utils';
import { checkError } from '../../helpers/errorHelper'

// ==== Functions for Saga ====

// ==== Function for create batch ====
function* insertBatch (action) {
    try{
        const response = yield call (
            addNewBatch,
            action.payload
        )
        if(response.data.status === true){
            yield put(createBatchSuccess(response.data.data))
        }else{
            yield put(createBatchFailure(response.data.message))
        }

    }catch(error){
        yield put(createBatchFailure(checkError(error)))
    }
}

// ==== Function for edit batch ====
function* updateBatch(action){
    try{
        const response = yield call (
            editBatch,
            action.payload.batchId,
            action.payload.newData
        )
        if(response.data.status === true){
            yield put(editBatchSuccess(response.data.data))
        }else{
            yield put(editBatchFailure(response.data.message))
        }

    }catch(error){
        yield put(editBatchFailure(checkError(error)))
    }
}


// ==== Function for Delete batch ====
function* removeBatch(action) {
    try{
        const response = yield call (
            deleteBatch, 
            action.payload.batchId
        )
        if(response.data.status === true){
            yield put(deleteBatchSuccess(response.data.data))
        }else{
            yield put(deleteBatchFailure(response.data.message))
        }

    }catch(error){
        // yield put(deleteBatchFailure(error.data.message))
        // yield put(deleteBatchFailure(checkError(error)))
        yield put(deleteBatchFailure(checkError(error)))
    }
}

// ==== Function for get batch ====
function* fetchBatch(action){
    try{
        // action.payload.search ? response = yield call(searchBatches, action.payload) : response = yield call(fetchBatches)
        const response = yield call (
            searchBatches,
            action.payload
        )
        if(response.data.status === true){
            yield put(getBatchSuccess(response.data.data))
        }else{
            yield put(getBatchFailure(response.data.message))
        }
    }catch(error){
        yield put(getBatchFailure(checkError(error)))
    }
}

// ==== Function to download the batch file ====
function* downloadBatchFile(action) {
    try{
        const response = yield call (
            downloadExcel,
            action.payload.id
        )
        if(true){
            yield put(downloadBatchFileSuccess(response.data)) // ==== This response is always res.data ====
        }else{
            yield put(downloadBatchFileFailure(response.data.message))
        }

    }catch(error){
        yield put(downloadBatchFileFailure(checkError(error)))
    }
}

// ==== Function to  upload the batch file ====
function* uploadBatchFile(action) {
    try{
        const response = yield call (
            uploadExcel,
            action.payload.id,
            action.payload.formBox
        )
        if(response.data.status === true){
            yield put(uploadBatchFileSuccess(response.data.data))
        }else{
            yield put(uploadBatchFileFailure(response.data.message))
        }

    }catch(error){
        yield put(uploadBatchFileFailure(checkError(error)))
    }
}


// ==== Watcher Function ====
export function* watchCreateBatch(){
    yield takeEvery(CREATE_BATCH, insertBatch)
}
export function* watchUpdateBatch(){
    yield takeEvery(EDIT_BATCH, updateBatch)
}
export function* watchRemoveBatch(){
    yield takeEvery(DELETE_BATCH, removeBatch)
}
export function* watchGetBatch(){
    yield takeEvery(GET_BATCH, fetchBatch)
}
export function* watchDownloadBatchFile(){
    yield takeEvery(DOWNLOAD_BATCH_FILE, downloadBatchFile)
}
export function* watchUploadBatchFile(){
    yield takeEvery(UPLOAD_BATCH_FILE, uploadBatchFile)
}


// ==== Exporter function ====
function* BatchSaga() {
    yield all([
        fork(watchCreateBatch),
        fork(watchUpdateBatch),
        fork(watchRemoveBatch),
        fork(watchGetBatch),
        fork(watchDownloadBatchFile),
        fork(watchUploadBatchFile)
    ])
}

export default BatchSaga;