import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class barchart extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                    type : 'line'
                },
                // colors: ['#3c4ccf', '#02a499'],
                colors : ['#d8e8c5', '#2895d3'],
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        endingShape: 'rounded',
                        dataLabels: {
                            show: false
                        }
                    }
                },
                // legend: {
                //     show: false
                // },
                dataLabels: {
                    enabled: false, // ==== Turn this "true" to enable Data Labels ====
                    enableOnSeries : [1]
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    }
                },
                stroke: {
                    show: true,
                    // width: 1.5,
                    width : [0, 4],
                    colors: ['#2895d3'],
                    curve : 'smooth'
                },
                xaxis: {
                    // categories: [2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020],
                    categories : this.props.graphDetails.dateArr,
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    labels : {
                        rotate : -90
                    }
                },
                legend : {
                    position : 'top',
                    horizontalAlign : 'left'
                },
                zoom : {
                    enabled : false
                }
            },
            series: [{
                name: 'New Activations',
                data: this.props.graphDetails.newActivations,
                // data: [100, 75, 50, 75, 50, 75, 100, 90, 60, 80],
                type : 'column'
            }, {
                name: 'Online Devices',
                data: this.props.graphDetails.onlineDevices,
                // data: [90, 65, 40, 65, 40, 65, 90, 75, 20, 40],
                type : 'line'
            }],
            refreshIndex : 0,
        }
    }

    updatePropsStatus(){
        let { options, series, refreshIndex } = this.state
        console.log("@@@@@@@ Inside method to update!!! @@@@@@@@")
        options.xaxis.categories = this.props.graphDetails.dateArr
        series[0].data = this.props.graphDetails.newActivations
        series[1].data = this.props.graphDetails.onlineDevices

        this.setState({ options : options, series : series, refreshIndex : refreshIndex }, ()=>{
            this.props.toggleUpdate()
            console.log(this.state.series)
        })
    }

    componentDidMount(){
        let { options, series } = this.state
        console.log("@@@@@@@ Inside method to update!!! @@@@@@@@")
        options.xaxis.categories = this.props.graphDetails.dateArr
        series[0].data = this.props.graphDetails.newActivations
        series[1].data = this.props.graphDetails.onlineDevices

        this.setState({ options : options, series : series})
    }

    render() {
        console.log("============= Inside Render Graph =============")
        console.log(this.props.graphDetails)
        console.log(this.props.updateStatus)
        // this.props.updateStatus ? this.updatePropsStatus() : console.log("Dont Update!")
        // this.updatePropsStatus()
        return (
            <React.Fragment>
                <ReactApexChart options={this.state.options} series={this.state.series} type="line" height="350" />
            </React.Fragment>
        );
    }
}

export default barchart;