import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { CREATE_VENDOR, CREATE_VENDOR_SUCCESS, CREATE_VENDOR_FAILURE, CREATE_VENDOR_INIT, FETCH_VENDORS, EDIT_VENDOR, DELETE_VENDOR } from './actionTypes';
import { createVendorSuccess, createVendorFailure, getVendorsSuccess, getVendorsFailure, editVendorSuccess, editVendorFailure, deleteVendorSuccess, deleteVendorFailure } from './actions';
import { addVendor, fetchVendorsList, editVendor, deleteVendor, searchVendor, searchVendorByName } from "../../services/vendorService";
import constants from '../../common/constants'

import { checkError } from '../../helpers/errorHelper'

// ==== Function to Create a Vendor ====
function* insertVendor(action) {
    try {
        console.log("#####  ACTIONS " + JSON.stringify(action))
        const response = yield call(
            addVendor,
            action.payload
        );
        if (response.data.status === true) {
            yield put(createVendorSuccess(response.data.data))
        } else {
            yield put(createVendorFailure(response.data.message))
        }
    } catch (error) {
        yield put(createVendorFailure(checkError(error)))
        console.log(error);
    }
}

function* fetchVendors(action) {
    try {
        console.log("#####  Getting into saga for vendors")
            // const response = yield call(fetchVendorsList);
        console.log(action.payload)
        let response
        action.payload ? response = yield call(searchVendorByName, action.payload) : response = yield call(searchVendorByName)
        if (response.data.status === true) {
            yield put(getVendorsSuccess(response.data.data))
        } else {
            yield put(getVendorsFailure(response.data.message));
        }
    } catch (error) {
        console.log("CAUGHT SAGA!!!!")
        yield put(getVendorsFailure(checkError(error)));
    }
}

// ==== Function to Edit a Vendor ====
function* updateVendor(action) {
    try {
        let countryName = action.payload.countryName
        delete action.payload.countryName
        const response = yield call(
            editVendor,
            action.payload.vendorId,
            action.payload
        )
        if (response.data.status === true) {
            console.log("#### Vendor In SAGA!! for data edited")
            console.log(`##### data : ${JSON.stringify(response.data.data)}`)
            yield put(editVendorSuccess({...response.data.data, countryName : countryName})) // ==== remove spread once api is fixed!! ====
        } else {
            yield put(editVendorFailure(response.data.message))
        }
    } catch (error) {
        yield put(editVendorFailure(checkError(error)))
    }
}

// ==== Function to Delete a Vendor ====
function* removeVendor(action) {
    try {
        const response = yield call(
            deleteVendor,
            action.payload.vendorId
        )
        if (response.data.status === true) {
            yield put(deleteVendorSuccess(response.data.data))
        } else {
            yield put(deleteVendorFailure(response.data.message))
        }

    } catch (error) {
        yield put(deleteVendorFailure(checkError(error)))
    }
}



// ==== Watcher Functions ====
export function* watchCreateVendor() {
    yield takeEvery(CREATE_VENDOR, insertVendor)
}
export function* watchFetchVendors() {
    yield takeEvery(FETCH_VENDORS, fetchVendors)
}
export function* watchUpdateVendor() {
    yield takeEvery(EDIT_VENDOR, updateVendor)
}
export function* watchRemoveVendor() {
    yield takeEvery(DELETE_VENDOR, removeVendor)
}

// ==== Exporter default function ====
function* VendorSaga() {
    yield all([
        fork(watchCreateVendor),
        fork(watchFetchVendors),
        fork(watchUpdateVendor),
        fork(watchRemoveVendor)
    ]);
}

export default VendorSaga;