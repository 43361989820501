import { GET_ACTIVATIONS_SUCCESS, SAVE_ONLINE_DEVICES } from './actionTypes';

const initialState = {
    // isLoading: false,
    activations: 0,
    onlineDevices: 0
}



const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_ONLINE_DEVICES:

            return {
                ...state,
                onlineDevices: action.payload
            }

        case GET_ACTIVATIONS_SUCCESS:
            return {
                ...state,
                activations: action.payload
            }



        default:
            return state;
    }
}

export default dashboard;