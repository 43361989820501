export const CREATE_VENDOR = 'CREATE_VENDOR';
export const CREATE_VENDOR_SUCCESS = 'CREATE_VENDOR_SUCCESS';
export const CREATE_VENDOR_FAILURE = 'CREATE_VENDOR_FAILURE';
export const CREATE_VENDOR_INIT = 'CREATE_VENDOR_INIT';

export const EDIT_VENDOR = 'EDIT_VENDOR';
export const EDIT_VENDOR_SUCCESS = 'EDIT_VENDOR_SUCCESS';
export const EDIT_VENDOR_FAILURE = 'EDIT_VENDOR_FAILURE';
export const EDIT_VENDOR_INIT = 'EDIT_VENDOR_INIT';

export const DELETE_VENDOR = 'DELETE_VENDOR';
export const DELETE_VENDOR_SUCCESS = 'DELETE_VENDOR_SUCCESS';
export const DELETE_VENDOR_FAILURE = 'DELETE_VENDOR_FAILURE';
export const DELETE_VENDOR_INIT = 'DELETE_VENDOR_INIT';

export const FETCH_VENDORS = 'FETCH_VENDOR';
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDOR_SUCCESS';
export const FETCH_VENDORS_FAILURE = 'FETCH_VENDOR_FAILURE';
export const FETCH_VENDORS_INIT = 'FETCH_VENDOR_INIT';