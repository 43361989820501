import {
    GET_PENDING_TRANSACTIONS_SUCCESS,
    GET_PENDING_TRANSACTIONS_FAILURE,
    GET_TRANSACTION_DETAILS_SUCCESS,
    GET_PENDING_TRANSACTIONS_EXCEL_INIT,
    GET_PENDING_TRANSACTIONS_EXCEL_SUCCESS,
    GET_PENDING_TRANSACTIONS_EXCEL_FAILURE,
    GET_PAYMENT_DETAILS_SUCCESS,
    CLEAR_PENDING_TRANSACTIONS,
    GET_BOOK_SUCCESS,
    GET_BOOK_FAILURE,
    GET_BOOK,
    DOWNLOAD_INIT,
    DOWNLOAD_BOOK_SUCCESS,
    DOWNLOAD_BOOK_FAILURE,
    DOWNLOAD_PAYMENT_DETAILS_FAILURE,
    DOWNLOAD_PAYMENT_DETAILS_SUCCESS,
    GET_BILLDESK_TRANSACTIONS,
    GET_BILLDESK_TRANSACTIONS_INIT,
    GET_BILLDESK_TRANSACTIONS_SUCCESS,
    GET_BILLDESK_TRANSACTIONS_FAILURE,
    DOWNLOAD_BILLDESK_TRANSACTIONS,
    DOWNLOAD_BILLDESK_TRANSACTIONS_SUCCESS,
    DOWNLOAD_BILLDESK_TRANSACTIONS_FAILURE,
    CLEAR_ERROR
} from './actionTypes';

const initialState = {
    // isLoading: false,
    pendingTransactions: null,
    pendingTransactionsIds: null,
    getPendingTransactionsFailure: false,
    insightsData: null,
    billdeskTransactions: null,
    billdeskTransactionsIds: null,
    billdeskInsightsData: null,
    recordCount: 0,
    book: null,
    downloadSuccessful: false,
    downloadFailed: false,
    downloadFile: null,
    error: null

}



const lnDashboard = (state = initialState, action) => {
    switch (action.type) {
        case GET_PENDING_TRANSACTIONS_SUCCESS:
            let arrayList = action.payload.pendingTransactions;
            let transactionListWithTransactionIdMappedAsKey = {};

            arrayList.forEach((transaction) => {
                transactionListWithTransactionIdMappedAsKey[transaction.transactionId] = {...transaction, transactionDetails: null, paymentDetails: null };
            });

            return {
                ...state,
                pendingTransactions: {...transactionListWithTransactionIdMappedAsKey },
                pendingTransactionsIds: Object.keys(transactionListWithTransactionIdMappedAsKey),
                insightsData: {...action.payload.insights },
                recordCount: action.payload.recordCount
            }
        case GET_PENDING_TRANSACTIONS_FAILURE:
            return {
                ...state,
                pendingTransactions: {},
                pendingTransactionsIds: [],
                error: action.payload.data
            }
        case GET_TRANSACTION_DETAILS_SUCCESS:

            let pendingTransactionList = {...state.pendingTransactions };
            pendingTransactionList[action.payload.transactionId].transactionDetails = {...action.payload.data };

            return {
                ...state,
                pendingTransactions: {...pendingTransactionList }
            }

        case GET_PAYMENT_DETAILS_SUCCESS:

            let pendingTransactions = {...state.pendingTransactions };
            pendingTransactions[action.payload.transactionId].paymentDetails = {...action.payload.data };

            return {
                ...state,
                pendingTransactions: {...pendingTransactions }
            }

        case GET_PENDING_TRANSACTIONS_EXCEL_SUCCESS:
            // console.log(action.payload.data);
            return {
                ...state,
                downloadSuccessful: true,
                downloadFailed: false,
                downloadFile: action.payload.data,
            }
        case GET_PENDING_TRANSACTIONS_EXCEL_FAILURE:
            return {
                ...state,
                downloadSuccessful: false,
                downloadFailed: true,
                downloadFile: null
            }
        case GET_PENDING_TRANSACTIONS_EXCEL_INIT:
            return {
                ...state,
                downloadSuccessful: false,
                downloadFailed: false,
                downloadFile: null
            }
        case CLEAR_PENDING_TRANSACTIONS:
            return {
                ...state,
                pendingTransactions: null,
                pendingTransactionsIds: null,
                recordCount: 0
            }
        case GET_BOOK:
            return {
                ...state,
                book: null
            }

        case GET_BOOK_SUCCESS:
            return {
                ...state,
                book: action.payload.data
            }
        case GET_BOOK_FAILURE:
            return {
                ...state,
                book: null,
                error: action.payload.data
            }
        case DOWNLOAD_INIT:
            return {
                ...state,
                downloadSuccessful: false,
                downloadFailed: false,
                downloadFile: null
            }
        case DOWNLOAD_BOOK_SUCCESS:
            return {
                ...state,
                downloadFile: action.payload.data,
                downloadSuccessful: true,
                downloadFailed: false
            }
        case DOWNLOAD_BOOK_FAILURE:
            return {
                ...state,
                downloadSuccessful: false,
                downloadFailed: true,
                downloadFile: null,
                error: action.payload.data
            }
        case DOWNLOAD_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state,
                downloadSuccessful: true,
                downloadFailed: false,
                downloadFile: action.payload.data
            }

        case DOWNLOAD_PAYMENT_DETAILS_FAILURE:
            return {
                ...state,
                downloadSuccessful: false,
                downloadFailed: true,
                downloadFile: null
            }

        case GET_BILLDESK_TRANSACTIONS_SUCCESS:
            let billdeskTransactions = action.payload.billdeskTransactions;
            let transactionsListWithTransactionIdMappedAsKey = {};

            billdeskTransactions.forEach((transaction) => {
                transactionsListWithTransactionIdMappedAsKey[transaction.transactionId] = {...transaction };
            });

            return {
                ...state,
                billdeskTransactions: {...transactionsListWithTransactionIdMappedAsKey },
                billdeskTransactionsIds: Object.keys(transactionsListWithTransactionIdMappedAsKey),
                billdeskInsightsData: {...action.payload.insights },
                recordCount: action.payload.insights.recordsCount
            }

        case GET_BILLDESK_TRANSACTIONS_FAILURE:
            return {
                ...state,
                billdeskTransactions: {},
                billdeskTransactionsIds: [],
                billdeskInsightsData: {},
                // recordCount: 0,
                error: action.payload.data
            }

        case GET_BILLDESK_TRANSACTIONS_INIT:
            return {
                ...state,
                billdeskTransactions: null,
                billdeskTransactionsIds: null,
                // billdeskInsightsData: null,
                recordCount: 0
            }

        case DOWNLOAD_BILLDESK_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                downloadSuccessful: true,
                downloadFailed: true,
                downloadFile: action.payload.data
            }

        case DOWNLOAD_BILLDESK_TRANSACTIONS_FAILURE:
            return {
                ...state,
                downloadSuccessful: false,
                downloadFailed: true,
                downloadFile: null
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export default lnDashboard;