// ==== TYPES FOR PLANS ====
export const GET_ALL_PLANS = "GET_ALL_PLANS"
export const GET_ALL_PLANS_SUCCESS = "GET_ALL_PLANS_SUCCESS"
export const GET_ALL_PLANS_FAILURE = "GET_ALL_PLANS_FAILURE"
export const GET_ALL_PLANS_INIT = "GET_ALL_PLANS_INIT"

export const GET_PLAN_DETAILS = "GET_PLAN_DETAILS"
export const GET_PLAN_DETAILS_SUCCESS = "GET_PLAN_DETAILS_SUCCESS"
export const GET_PLAN_DETAILS_FAILURE = "GET_PLAN_DETAILS_FAILURE"
export const GET_PLAN_DETAILS_INIT = "GET_PLAN_DETAILS_INIT"

export const GET_PLAN_FREQUENCY = "GET_PLAN_FREQUENCY"
export const GET_PLAN_FREQUENCY_SUCCESS = "GET_PLAN_FREQUENCY_SUCCESS"
export const GET_PLAN_FREQUENCY_FAILURE = "GET_PLAN_FREQUENCY_FAILURE"
export const GET_PLAN_FREQUENCY_INIT = "GET_PLAN_FREQUENCY_INIT"

export const CREATE_PLAN = "CREATE_PLAN"
export const CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS"
export const CREATE_PLAN_FAILURE = "CREATE_PLAN_FAILURE"
export const CREATE_PLAN_INIT = "CREATE_PLAN_INIT"

export const EDIT_PLAN = "EDIT_PLAN"
export const EDIT_PLAN_SUCCESS = "EDIT_PLAN_SUCCESS"
export const EDIT_PLAN_FAILURE = "EDIT_PLAN_FAILURE"
export const EDIT_PLAN_INIT = "EDIT_PLAN_INIT"

export const DISABLE_PLAN = "DISABLE_PLAN"
export const DISABLE_PLAN_SUCCESS = "DISABLE_PLAN_SUCCESS"
export const DISABLE_PLAN_FAILURE = "DISABLE_PLAN_FAILURE"
export const DISABLE_PLAN_INIT = "DISABLE_PLAN_INIT"

export const GET_LN_PLAN = "GET_LN_PLAN"
export const GET_LN_PLAN_SUCCESS = "GET_LN_PLAN_SUCCESS"
export const GET_LN_PLAN_FAILURE = "GET_LN_PLAN_FAILURE"
export const GET_LN_PLAN_INIT = "GET_LN_PLAN_INIT" 

// ==== TYPES FOR PROMO CODE====
export const GET_ALL_PROMO = "GET_ALL_PROMO"
export const GET_ALL_PROMO_SUCCESS = "GET_ALL_PROMO_SUCCESS"
export const GET_ALL_PROMO_FAILURE = "GET_ALL_PROMO_FAILURE"
export const GET_ALL_PROMO_INIT = "GET_ALL_PROMO_INIT"

export const GET_PROMO_DETAILS = "GET_PROMO_DETAILS"
export const GET_PROMO_DETAILS_SUCCESS = "GET_PROMO_DETAILS_SUCCESS"
export const GET_PROMO_DETAILS_FAILURE = "GET_PROMO_DETAILS_FAILURE"
export const GET_PROMO_DETAILS_INIT = "GET_PROMO_DETAILS_INIT"

export const CREATE_PROMO = "CREATE_PROMO"
export const CREATE_PROMO_SUCCESS = "CREATE_PROMO_SUCCESS"
export const CREATE_PROMO_FAILURE = "CREATE_PROMO_FAILURE"
export const CREATE_PROMO_INIT = "CREATE_PROMO_INIT"

export const EDIT_PROMO = "EDIT_PROMO"
export const EDIT_PROMO_SUCCESS = "EDIT_PROMO_SUCCESS"
export const EDIT_PROMO_FAILURE = "EDIT_PROMO_FAILURE"
export const EDIT_PROMO_INIT = "EDIT_PROMO_INIT"

export const DISABLE_PROMO = "DISABLE_PROMO"
export const DISABLE_PROMO_SUCCESS = "DISABLE_PROMO_SUCCESS"
export const DISABLE_PROMO_FAILURE = "DISABLE_PROMO_FAILURE"
export const DISABLE_PROMO_INIT = "DISABLE_PROMO_INIT"