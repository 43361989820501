import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { GET_COUNTRIES, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILURE, GET_COUNTRIES_INIT } from './actionTypes';
import { getCountries, getCountriesSuccess, getCountriesFailure, getCountriesInit } from './actions';
import { addVendor, fetchVendorsList, fetchCountriesList } from "../../services/vendorService";
import constants from '../../common/constants'

import { checkError } from '../../helpers/errorHelper'


function* fetchCountries() {
    try{
        console.log("Calling Countries!!!!")
        const response = yield call (fetchCountriesList)
        if(response.data.status === true){
            console.log("#####  Got all countries")
            yield put(getCountriesSuccess(response.data.data))
        }else{
            console.log("#####  Failed to get all countries")
            yield put(getCountriesFailure(response.data.message))
        }
    }catch (error) {
        yield put(getCountriesFailure(checkError(error)))
    }
}

// ==== Watcher functions ====
export function* watchGetCoutries() {
    yield takeEvery(GET_COUNTRIES, fetchCountries)
}

// ==== Fork functions ====
function* MasterSaga() {
    yield all([
        fork(watchGetCoutries)
    ]);
}

export default MasterSaga;