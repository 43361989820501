import {
    CREATE_PRODUCT,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE,
    CREATE_PRODUCT_INIT,
    
    EDIT_PRODUCT,
    EDIT_PRODUCT_SUCCESS,
    EDIT_PRODUCT_FAILURE,
    EDIT_PRODUCT_INIT,
    
    DELETE_PRODUCT,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAILURE,
    DELETE_PRODUCT_INIT,
    
    GET_PRODUCT,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAILURE,
    GET_PRODUCT_INIT,

    GET_PRODUCT_TYPE,
    GET_PRODUCT_TYPE_SUCCESS,
    GET_PRODUCT_TYPE_FAILURE,
    GET_PRODUCT_TYPE_INIT,

    GET_PRODUCT_BY_CATEGORY,
    GET_PRODUCT_BY_CATEGORY_SUCCESS,
    GET_PRODUCT_BY_CATEGORY_FAILURE,
    GET_PRODUCT_BY_CATEGORY_INIT

    } from './actionTypes'

const initialState = {
    productList : [],
    productTypeList : [],
    errorMessage : '',
    editProductDetails : null,
    createProductSuccess : false,
    createProductFailure : false,
    editProductSuccess : false,
    editProductFailure : false,
    deleteProductSuccess : false,
    deleteProductFailure : false, 
    getProductSuccess : false,
    getProductFailure : false,
    getProductTypeSuccess : false,
    getProductTypeFailure : false,
    getProductByCategorySuccess : false,
    getProductByCategoryFailure : false
}

const Product = (state = initialState, action) => {
    switch (action.type){

        // ==== Cases for Create product ====
        case CREATE_PRODUCT : 
            return { 
                ...state,
                createProductSuccess : false,
                createProductFailure : false
            }
        
        case CREATE_PRODUCT_SUCCESS : 
            return { 
                ...state,
                data : action.payload,
                createProductSuccess : true,
                createProductFailure : false
            }

        case CREATE_PRODUCT_FAILURE : 
            return { 
                ...state,
                createProductSuccess : false,
                createProductFailure : true,
                errorMessage : action.payload
            }

        case CREATE_PRODUCT_INIT : 
            return { 
                ...state,
                createProductSuccess : false,
                createProductFailure : false,
                errorMessage : ''
            }


        // ==== Cases for Edit product ====
        case EDIT_PRODUCT : 
            return {
                ...state,
                editProductSuccess : false,
                editProductFailure : false
            }

        case EDIT_PRODUCT_SUCCESS : 
            return {
                ...state,
                editProductDetails : action.payload,
                editProductSuccess : true,
                editProductFailure : false
            }

        case EDIT_PRODUCT_FAILURE : 
            return {
                ...state,
                editProductSuccess : false,
                editProductFailure : true,
                errorMessage : action.payload
            }

        case EDIT_PRODUCT_INIT : 
            return {
                ...state,
                editProductDetails : null,
                editProductSuccess : false,
                editProductFailure : false,
                errorMessage : ''
            }
        
        // ==== Cases for Delete Product ====
        case DELETE_PRODUCT : 
            return {
                ...state,
                deleteProductSuccess : false,
                deleteProductFailure : false
            }

        case DELETE_PRODUCT_SUCCESS : 
            return {
                ...state,
                data : action.payload,
                deleteProductSuccess : true,
                deleteProductFailure : false
            }

        case DELETE_PRODUCT_FAILURE : 
            return {
                ...state,
                deleteProductSuccess : false,
                deleteProductFailure : true,
                errorMessage : action.payload
            }

        case DELETE_PRODUCT_INIT : 
            return {
                ...state,
                deleteProductSuccess : false,
                deleteProductFailure : false,
                errorMessage : ''
            }

        // ==== Cases on Get product ====
        case GET_PRODUCT : 
            return { 
                ...state,
                getProductSuccess : false,
                getProductFailure : false
            }
            
        case GET_PRODUCT_SUCCESS : 
            return { 
                ...state,
                productList : action.payload,
                getProductSuccess : true,
                getProductFailure : false
            }
            
        case GET_PRODUCT_FAILURE : 
            return { 
                ...state,
                getProductSuccess : false,
                getProductFailure : true,
                errorMessage : action.payload
            }

        case GET_PRODUCT_INIT : 
            return { 
                ...state,
                productList : [],
                getProductSuccess : false,
                getProductFailure : false,
                errorMessage : ''
            }

        
        // ==== Cases for Get Product Type ====
        case GET_PRODUCT_TYPE : 
            return {
                ...state,
                getProductTypeSuccess : false,
                getProductTypeFailure : false
            }

        case GET_PRODUCT_TYPE_SUCCESS : 
            return {
                ...state,
                productTypeList : action.payload,
                getProductTypeSuccess : true,
                getProductTypeFailure : false
            }

        case GET_PRODUCT_TYPE_FAILURE : 
            return {
                ...state,
                getProductTypeSuccess : false,
                getProductTypeFailure : true,
                errorMessage : action.payload
            }

        case GET_PRODUCT_TYPE_INIT : 
            return {
                ...state,
                productTypeList : [],
                getProductTypeSuccess : false,
                getProductTypeFailure : false,
                errorMessage : ''
            }

        // ==== Cases for get Product by Category ====
        case GET_PRODUCT_BY_CATEGORY : 
            return{
                ...state,
                getProductByCategorySuccess : false,
                getProductByCategoryFailure : false
            }

        case GET_PRODUCT_BY_CATEGORY_SUCCESS : 
            return{
                ...state,
                productList : action.payload,
                getProductByCategorySuccess : true,
                getProductByCategoryFailure : false
            }

        case GET_PRODUCT_BY_CATEGORY_FAILURE : 
            return{
                ...state,
                getProductByCategorySuccess : false,
                getProductByCategoryFailure : true,
                errorMessage : action.payload
            }

        case GET_PRODUCT_BY_CATEGORY_INIT : 
            return{
                ...state,
                productList : [],
                getProductByCategorySuccess : false,
                getProductByCategoryFailure : false,
                errorMessage : ''
            }
        default : 
            return state
    }
}

export default Product