import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';

class Doughnut extends Component {
    constructor(props){
        super(props)


        this.state = {
            chartKey : 0,
            options : {
                toolbox: {
                    show: false,
                },
                tooltip: {
                    trigger: 'item',
                    formatter: "{a} <br/>{b}: {c} ({d}%)"
                },
                legend: {
                    show :false,
                    orient: 'vertical',
                    x: 'left',
                    data: ['Binded Devices', 'Unbinded Devices', 'Blocked Devices'],
                    textStyle: {
                        color: ['#74788d']
                    }
                },
                color: ['#d8e8c5', '#ba1f67', '#2895d3'],
                series: [
                    {
                        name: 'Device Detail : ',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                show: false,
                                textStyle: {
                                    fontSize: '30',
                                    fontWeight: 'bold'
                                }
                            }
                        },
                        labelLine: {
                            normal: {
                                show: false
                            }
                        },
                        data: [
                            { value: 335, name: 'Binded Devices' },
                            { value: 310, name: 'Unbinded Devices' },
                            { value: 234, name: 'Blocked Devices' },
                        ]
                    }
                ]
            }
        }
    }
    // getOption = () => {
    //     return {

    //         toolbox: {
    //             show: false,
    //         },
    //         tooltip: {
    //             trigger: 'item',
    //             formatter: "{a} <br/>{b}: {c} ({d}%)"
    //         },
    //         legend: {
    //             orient: 'vertical',
    //             x: 'left',
    //             data: ['Binded Devices', 'Unbinded Devices', 'Blocked Devices'],
    //             textStyle: {
    //                 color: ['#74788d']
    //             }
    //         },
    //         color: ['#d8e8c5 ', '#ba1f67 ', '#2895d3'],
    //         series: [
    //             {
    //                 name: 'Device Detail : ',
    //                 type: 'pie',
    //                 radius: ['50%', '70%'],
    //                 avoidLabelOverlap: false,
    //                 label: {
    //                     normal: {
    //                         show: false,
    //                         position: 'center'
    //                     },
    //                     emphasis: {
    //                         show: false,
    //                         textStyle: {
    //                             fontSize: '30',
    //                             fontWeight: 'bold'
    //                         }
    //                     }
    //                 },
    //                 labelLine: {
    //                     normal: {
    //                         show: false
    //                     }
    //                 },
    //                 data: [
    //                     // { value: 335, name: 'Binded Devices' },
    //                     // { value: 310, name: 'Unbinded Devices' },
    //                     // { value: 234, name: 'Blocked Devices' },
    //                     { value: this.props.deviceDetails.bindedDevices, name: 'Binded Devices' },
    //                     { value: this.props.deviceDetails.unbindedDevices, name: 'Unbinded Devices' },
    //                     { value: this.props.deviceDetails.blockedDevices, name: 'Blocked Devices' },
    //                 ]
    //             }
    //         ]
    //     };
    // };

    componentDidMount(){
        console.log("#### CLAG Device details ")
        console.log(this.props.deviceDetails)
        let { options } = this.state
        let series = options.series
        let dataValues = [
            { value: this.props.deviceDetails.bindedDevices, name: 'Binded Devices' },
            { value: this.props.deviceDetails.unbindedDevices, name: 'Unbinded Devices' },
            { value: this.props.deviceDetails.blockedDevices, name: 'Blocked Devices' },
        ]
        series[0].data = dataValues
        options.series = series
        console.log(options)
        this.setState({options : options, chartKey : Math.random().toFixed(2)*100}, ()=>{console.log(this.state.options)})
        
    }
    render() {
        console.log("RESET DOUGHNUT CHART RENDER!")
        return (
            <React.Fragment>
                <ReactEcharts key={this.state.chartKey} style={{ height: "350px" }} option={this.state.options} />
            </React.Fragment>
        );
    }
}
export default Doughnut;