export const sortStringTypeData = (data, sortValue, direction) => {
    let sortType = sortValue;
    return data.sort((a, b, sortBy = sortType, order = direction) => {
        const bandA = a[sortBy].toUpperCase();
        const bandB = b[sortBy].toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return (order === 'desc') ? (comparison * -1) : comparison
    })
}


export const sortIntegerTypeData = (data, sortValue, direction) => {
    let sortType = sortValue;
    return data.sort((a, b, sortBy = sortType, order = direction) => {
        console.log(sortBy);
        const bandA = a[sortBy];
        const bandB = b[sortBy];

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return (order === 'desc') ? (comparison * -1) : comparison
    })
}