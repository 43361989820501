import { 
    SEARCH_CUSTOMERS,
    SEARCH_CUSTOMERS_SUCCESS,
    SEARCH_CUSTOMERS_FAILURE,
    SEARCH_CUSTOMERS_INIT,

    GET_CUSTOMER_DETAILS,
    GET_CUSTOMER_DETAILS_SUCCESS,
    GET_CUSTOMER_DETAILS_FAILURE,
    GET_CUSTOMER_DETAILS_INIT,

    CHANGE_CONFIG,
    CHANGE_CONFIG_SUCCESS,
    CHANGE_CONFIG_FAILURE,
    CHANGE_CONFIG_INIT,

    GET_USER_LOGS,
    GET_USER_LOGS_SUCCESS,
    GET_USER_LOGS_FAILURE,
    GET_USER_LOGS_INIT,

    GET_PORTAL_LOGS,
    GET_PORTAL_LOGS_SUCCESS,
    GET_PORTAL_LOGS_FAILURE,
    GET_PORTAL_LOGS_INIT,

    GET_USER_LOG_FILTERS,
    GET_USER_LOG_FILTERS_SUCCESS,
    GET_USER_LOG_FILTERS_FAILURE,
    GET_USER_LOG_FILTERS_INIT,

    GET_PORTAL_LOG_FILTERS,
    GET_PORTAL_LOG_FILTERS_SUCCESS,
    GET_PORTAL_LOG_FILTERS_FAILURE,
    GET_PORTAL_LOG_FILTERS_INIT,

    DOWNLOAD_LOGS,
    DOWNLOAD_LOGS_SUCCESS,
    DOWNLOAD_LOGS_FAILURE,
    DOWNLOAD_LOGS_INIT,

    SEARCH_CUSTOMERS_GROUPS,
    SEARCH_CUSTOMERS_GROUPS_SUCCESS,
    SEARCH_CUSTOMERS_GROUPS_FAILURE,
    SEARCH_CUSTOMERS_GROUPS_INIT,

    ASSIGN_CUSTOMER_GROUP,
    ASSIGN_CUSTOMER_GROUP_SUCCESS,
    ASSIGN_CUSTOMER_GROUP_FAILURE,
    ASSIGN_CUSTOMER_GROUP_INIT,

    SEARCH_USER,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_FAILURE,
    SEARCH_USER_INIT,

    SEARCH_DEVICES,
    SEARCH_DEVICES_SUCCESS,
    SEARCH_DEVICES_FAILURE,
    SEARCH_DEVICES_INIT
    
 } from './actionTypes'

const initialState = {
   customerList : [],
   customerDetails : null,
   logDetails : null,
   logFile : null,
   logFilters : [],
   customerGroup : null,
   searchedUsers : null,
   searchedDevices : null,
   errorMessage : '',

   searchCustomerSuccess : false,
   searchCustomerFailure : false,
   getCustomerDetailsSuccess : false,
   getCustomerDetailsFailure : false,
   changeConfigSuccess : false,
   changeConfigFailure : false,
   getUserLogsSuccess : false,
   getUserLogsFailure : false,
   getPortalLogsSuccess : false,
   getPortalLogsFailure : false,
   getUserLogFiltersSuccess : false,
   getUserLogFiltersFailure : false,
   getPortalLogFiltersSuccess : false,
   getPortalLogFiltersFailure : false,
   downloadLogsSuccess: false,
   downloadLogsFailure: false,
   searchCustomersGroupsSuccess : false,
   searchCustomersGroupsFailure : false,
   assignCustomerGroupSuccess : false,
   assignCustomerGroupFailure : false,
   searchUserSuccess : false,
   searchUserFailure : false,
   searchDevicesSuccess : false,
   searchDevicesFailure : false,
}



const Customer = (state = initialState, action) => {
    console.log("#####  Enters Reducer for Customer")
    console.log(action.type)
    switch (action.type) {
        // ==== Cases for Search Customers ====
        case SEARCH_CUSTOMERS: 
            console.log(action.payload)
            return {
                ...state,
                searchCustomerSuccess : false,
                searchCustomerFailure : false
            }

        case SEARCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customerList : action.payload,
                searchCustomerSuccess : true,
                searchCustomerFailure : false
            }

        case SEARCH_CUSTOMERS_FAILURE : 
            return {
                ...state,
                searchCustomerSuccess : false,
                searchCustomerFailure : true,
                errorMessage : action.payload
            }

        case SEARCH_CUSTOMERS_INIT : 
            return {
                ...state,
                customerList : [],
                searchCustomerSuccess : false,
                searchCustomerFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Get Customer Details ====
        case GET_CUSTOMER_DETAILS :
            return {
                ...state,
                getCustomerDetailsSuccess : false,
                getCustomerDetailsFailure : false
            }
        case GET_CUSTOMER_DETAILS_SUCCESS :
            return {
                ...state,
                customerDetails : action.payload,
                getCustomerDetailsSuccess : true,
                getCustomerDetailsFailure : false
            }
        case GET_CUSTOMER_DETAILS_FAILURE :
            return {
                ...state,
                getCustomerDetailsSuccess : false,
                getCustomerDetailsFailure : true,
                errorMessage : action.payload
            }
        case GET_CUSTOMER_DETAILS_INIT :
            return {
                ...state,
                customerDetails : null,
                getCustomerDetailsSuccess : false,
                getCustomerDetailsFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Change Config ====
        case CHANGE_CONFIG :
            return {
                ...state,
                changeConfigSuccess : false,
                changeConfigFailure : false
            }
        case CHANGE_CONFIG_SUCCESS :
            return {
                ...state,
                changeConfigSuccess : true,
                changeConfigFailure : false
            }
        case CHANGE_CONFIG_FAILURE :
            return {
                ...state,
                changeConfigSuccess : false,
                changeConfigFailure : true,
                errorMessage : action.payload
            }
        case CHANGE_CONFIG_INIT :
            return {
                ...state,
                changeConfigSuccess : false,
                changeConfigFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Get User Logs ====
        case GET_USER_LOGS :
            return {
                ...state,
                getUserLogsSuccess : false,
                getUserLogsFailure : false
            }
        case GET_USER_LOGS_SUCCESS :
            return {
                ...state,
                logDetails : action.payload,
                getUserLogsSuccess : true,
                getUserLogsFailure : false
            }
        case GET_USER_LOGS_FAILURE :
            return {
                ...state,
                getUserLogsSuccess : false,
                getUserLogsFailure : true,
                errorMessage : action.payload
            }
        case GET_USER_LOGS_INIT :
            return {
                ...state,
                logDetails : null,
                getUserLogsSuccess : false,
                getUserLogsFailure : false,
                errorMessage : ''
            }
            
        // ==== Cases for Get Portal Logs ====
        case GET_PORTAL_LOGS :
            return {
                ...state,
                getPortalLogsSuccess : false,
                getPortalLogsFailure : false
            }
        case GET_PORTAL_LOGS_SUCCESS :
            return {
                ...state,
                logDetails : action.payload,
                getPortalLogsSuccess : true,
                getPortalLogsFailure : false
            }
        case GET_PORTAL_LOGS_FAILURE :
            return {
                ...state,
                getPortalLogsSuccess : false,
                getPortalLogsFailure : true,
                errorMessage : action.payload
            }
        case GET_PORTAL_LOGS_INIT :
            return {
                ...state,
                logDetails : null,
                getPortalLogsSuccess : false,
                getPortalLogsFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Getting User Log Filters ====
        case GET_USER_LOG_FILTERS :
            return {
                ...state,
                getUserLogFiltersSuccess : false,
                getUserLogFiltersFailure : false
            }
        case GET_USER_LOG_FILTERS_SUCCESS :
            return {
                ...state,
                logFilters : action.payload,
                getUserLogFiltersSuccess : true,
                getUserLogFiltersFailure : false
            }        
        case GET_USER_LOG_FILTERS_FAILURE :
            return {
                ...state,
                getUserLogFiltersSuccess : false,
                getUserLogFiltersFailure : true,
                errorMessage : action.payload
            }      
        case GET_USER_LOG_FILTERS_INIT :
            return {
                ...state,
                logFilters : [],
                getUserLogFiltersSuccess : false,
                getUserLogFiltersFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Getting Portal Log Filters ====
        case GET_PORTAL_LOG_FILTERS :
            return {
                ...state,
                getPortalLogFiltersSuccess : false,
                getPortalLogFiltersFailure : false
            }
        case GET_PORTAL_LOG_FILTERS_SUCCESS :
            return {
                ...state,
                logFilters : action.payload,
                getPortalLogFiltersSuccess : true,
                getPortalLogFiltersFailure : false
            }
        case GET_PORTAL_LOG_FILTERS_FAILURE:
            return {
                ...state,
                getPortalLogFiltersSuccess : false,
                getPortalLogFiltersFailure : true,
                errorMessage : action.payload
            }
        case GET_PORTAL_LOG_FILTERS_INIT :
            return {
                ...state,
                logFilters : [],
                getPortalLogFiltersSuccess : false,
                getPortalLogFiltersFailure : false,
                errorMessage : ''
            }


        // ==== Cases for Download Logs ====
        case DOWNLOAD_LOGS :
            return {
                ...state,
                downloadLogsSuccess : false,
                downloadLogsFailure : false
            }
        case DOWNLOAD_LOGS_SUCCESS :
            return {
                ...state,
                logFile : action.payload,
                downloadLogsSuccess : true,
                downloadLogsFailure : false
            }
        case DOWNLOAD_LOGS_FAILURE :
            return {
                ...state,
                downloadLogsSuccess : false,
                downloadLogsFailure : true,
                errorMessage : action.payload
            }
        case DOWNLOAD_LOGS_INIT :
            return {
                ...state,
                logFile : null,
                downloadLogsSuccess : false,
                downloadLogsFailure : false,
                errorMessage : ''
            }

        
        // ==== Cases for Search Customers Groups ====
        case SEARCH_CUSTOMERS_GROUPS : 
            return {
                ...state,
                searchCustomersGroupsSuccess : false,
                searchCustomersGroupsFailure : false,
            }
        case SEARCH_CUSTOMERS_GROUPS_SUCCESS : 
            return {
                ...state,
                customerGroup : action.payload[0],
                searchCustomersGroupsSuccess : true,
                searchCustomersGroupsFailure : false,
            }
        case SEARCH_CUSTOMERS_GROUPS_FAILURE : 
            return {
                ...state,
                searchCustomersGroupsSuccess : false,
                searchCustomersGroupsFailure : true,
                errorMessage : action.payload
            }
        case SEARCH_CUSTOMERS_GROUPS_INIT : 
        console.log("Inside Customer Group Init reducer!!")
            return {
                ...state,
                customerGroup : null,
                searchCustomersGroupsSuccess : false,
                searchCustomersGroupsFailure : false,
                errorMessage : ''
            }


        // ==== Cases for Assign Customer Groups ====
        case ASSIGN_CUSTOMER_GROUP : 
            return {
                ...state,
                assignCustomerGroupSuccess : false,
                assignCustomerGroupFailure : false,
            }
        case ASSIGN_CUSTOMER_GROUP_SUCCESS : 
            return {
                ...state,
                assignCustomerGroupSuccess : true,
                assignCustomerGroupFailure : false,
            }
        case ASSIGN_CUSTOMER_GROUP_FAILURE : 
            return {
                ...state,
                assignCustomerGroupSuccess : false,
                assignCustomerGroupFailure : true,
                errorMessage : action.payload
            }
        case ASSIGN_CUSTOMER_GROUP_INIT : 
            return {
                ...state,
                assignCustomerGroupSuccess : false,
                assignCustomerGroupFailure : false,
                errorMessage : ''
            }


        // ==== Cases for Search User ====
        case SEARCH_USER:
            return {
                ...state,
                searchUserSuccess : false,
                searchUserFailure : false,
            }
        case SEARCH_USER_SUCCESS:
            console.log("Running!!")
            return {
                ...state,
                searchedUsers : action.payload,
                searchUserSuccess : true,
                searchUserFailure : false,
            }
        case SEARCH_USER_FAILURE:
            return {
                ...state,
                searchUserSuccess : false,
                searchUserFailure : true,
                errorMessage : action.payload,
            }
        case SEARCH_USER_INIT:
            return {
                ...state,
                searchedUsers : null,
                searchUserSuccess : false,
                searchUserFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Search Devices ====
        case SEARCH_DEVICES:
            return {
                ...state,
                searchDevicesSuccess : false,
                searchDevicesFailure : false,
            }
        case SEARCH_DEVICES_SUCCESS:
            return {
                ...state,
                searchedDevices : action.payload,
                searchDevicesSuccess : true,
                searchDevicesFailure : false,
            }
        case SEARCH_DEVICES_FAILURE:
            return {
                ...state,
                searchDevicesSuccess : false,
                searchDevicesFailure : true,
                errorMessage : action.payload
            }
        case SEARCH_DEVICES_INIT:
            return {
                ...state,
                searchedUsers : null,
                searchDevicesSuccess : false,
                searchDevicesFailure : false,
                errorMessage : ''
            }


         // ==== Default case ====   
        default:
            console.log("#### customer reducer default case")
            return state;
    }
}

export default Customer;