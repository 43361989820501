import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { 
    SEARCH_CUSTOMERS, GET_CUSTOMER_DETAILS,
    CHANGE_CONFIG, GET_USER_LOGS,
    GET_PORTAL_LOGS, GET_USER_LOG_FILTERS,
    GET_PORTAL_LOG_FILTERS, DOWNLOAD_LOGS,
    SEARCH_CUSTOMERS_GROUPS, ASSIGN_CUSTOMER_GROUP,
    SEARCH_USER, SEARCH_DEVICES
    } from './actionTypes';

import { 
    searchCustomersSuccess, searchCustomersFailure,
    getCustomerDetailsSuccess, getCustomerDetailsFailure,
    changeConfigSuccess, changeConfigFailure,
    getUserLogsSuccess, getUserLogsFailure,
    getPortalLogsSuccess, getPortalLogsFailure,
    getUserLogFiltersSuccess, getUserLogFiltersFailure,
    getPortalLogFiltersSuccess, getPortalLogFiltersFailure,
    downloadLogsSuccess, downloadLogsFailure,
    searchCustomersGroupsSuccess, searchCustomersGroupsFailure,
    assignCustomerGroupsSuccess, assignCustomerGroupsFailure,
    searchUserSuccess, searchUserFailure,
    searchDevicesSuccess, searchDevicesFailure
 } from './actions';

import { getCustomer, getCustomerDetails, changeCustomerConfig, getLogs, downloadLogsService, getLogFilters, searchCustomersGroupsService, assignCustomerGroupsService, searchUserService, searchDevicesService } from "../../services/customerService";
import { checkError } from '../../helpers/errorHelper'
import { act } from 'react-dom/test-utils';
import { actionIcon } from 'aws-amplify';

// ==== Functions for Saga start here ====

// ==== Function for Search Customer ====
function* fetchCustomers(action) {
    try{
        console.log("#####  Starts saga")
        const response = yield call(getCustomer, action.payload)
        if(response.data.status === true){
            console.log("In saga success")
            console.log(response)
            yield put(searchCustomersSuccess(response.data.data))
            console.log("####   In Saga success Post fire")
        }else{
            console.log("In saga failure")
            yield put(searchCustomersFailure(response.data.message))
        }
    }catch(error){
        console.log("In saga failure")
        yield put(searchCustomersFailure(checkError(error)))
    }
}

// ==== Function to Get customer details ====
function* fetchCustomerDetails(action) {
    try{
        console.log("####   Fetch Details")
        const response = yield call(getCustomerDetails, action.payload.serialNo)
        if(response.data.status === true){
            yield put(getCustomerDetailsSuccess(response.data.data[0]))
        }else{
            yield put(getCustomerDetailsFailure(response.data.message))
        }
    }catch(error){
        yield put(getCustomerDetailsFailure(checkError(error)))
    }
}

// ==== Function to Change Config ====
function* changeConfig(action) {
    try{
        let typeValue = action.payload.type
        delete action.payload.type
        const response = yield call(changeCustomerConfig, action.payload, typeValue)
        if(response.data.status === true){
            yield put(changeConfigSuccess(response.data.data))
        }else{
            yield put(changeConfigFailure(response.data.message))
        }
    }catch(error){
        yield put(changeConfigFailure(checkError(error)))
    }
}

// ==== Function to Get User Logs ====
function* fetchUserLogs(action) {
    try{
        let body = action.payload.inputActivityLogsDto
        delete action.payload.inputActivityLogsDto
        const response = yield call(getLogs, action.payload, body)
        if(response.data.status === true){
            yield put(getUserLogsSuccess(response.data.data))
        }else{
            yield put(getUserLogsFailure(response.data.message))
        }
    }catch(error){
        yield put(getUserLogsFailure(checkError(error)))
    }
}

// ==== Function to Get Portal Logs ====
function* fetchPortalLogs(action) {
    try{
        let body = action.payload.inputActivityLogsDto
        delete action.payload.inputActivityLogsDto
        const response = yield call(getLogs, action.payload, body)
        if(response.data.status === true){
            yield put(getPortalLogsSuccess(response.data.data))
        }else{
            yield put(getPortalLogsFailure(response.data.message))
        }
    }catch(error){
        yield put(getPortalLogsFailure(checkError(error)))
    }
}

// ==== Function to Get User Log Filters ====
function* fetchUserLogFilters(action){
    try{
        const response = yield call(getLogFilters, action.payload)
        if(response.data.status === true){
            yield put(getUserLogFiltersSuccess(response.data.data))
        }else{
            yield put(getUserLogFiltersFailure(response.data.message))
        }
    }catch(error){
        yield put(getUserLogFiltersFailure(checkError(error)))
    }
}

// ==== Function to Get Portal Log Filters ====
function* fetchPortalLogFilters(action){
    try{
        const response = yield call(getLogFilters, action.payload)
        if(response.data.status === true){
            yield put(getPortalLogFiltersSuccess(response.data.data))
        }else{
            yield put(getPortalLogFiltersFailure(response.data.message))
        }
    }catch(error){
        yield put(getPortalLogFiltersFailure(checkError(error)))
    }
}

// ==== Function to Download Logs ====
function* downloadLogs(action) {
    try{
        let body = action.payload.inputActivityLogsDto
        delete action.payload.inputActivityLogsDto
        const response = yield call(downloadLogsService, action.payload, body)
        yield put(downloadLogsSuccess(response.data))
    }catch(error){
        yield put(downloadLogsFailure(checkError(error)))
    }
}



// ==== Function to Search Customer Groups ====
function* fetchCustomersGroups(action) {
    try{
        const response = yield call(searchCustomersGroupsService, action.payload)
        if(response.data.status === true){
            yield put(searchCustomersGroupsSuccess(response.data.data))
        }else{
            yield put(searchCustomersGroupsFailure(response.data.message))
        }
    }catch(error){
        yield put(searchCustomersGroupsFailure(checkError(error)))
    }
}

// ==== Function to Assign Customers Group ====
function* updateCustomersGroups(action) {
    try{
        const response = yield call(assignCustomerGroupsService, action.payload)
        if(response.data.status === true){
            yield put(assignCustomerGroupsSuccess(response.data.data))
        }else{
            yield put(assignCustomerGroupsFailure(response.data.message))
        }
    }catch(error){
        yield put(assignCustomerGroupsFailure(checkError(error)))
    }
}



// ==== FUNCTIONS FOR SEARCH USER BASED ACTIVITIES ====

// ==== Function for Search User ====
function* fetchUser(action) {
    try{
        const response = yield call(searchUserService, action.payload)
        if(response.data.status === true){
            yield put(searchUserSuccess(response.data.data))
        }else{
            yield put(searchUserFailure(response.data.message))
        }
    }catch(error){
        yield put(searchUserFailure(checkError(error)))
    }
}

// ==== Function for Search Devices ====
function* fetchDevices(action) {
    try{
        const response = yield call(searchDevicesService, action.payload.userId)
        if(response.data.status === true){
            yield put(searchDevicesSuccess(response.data.data))
        }else{
            yield put(searchDevicesFailure(response.data.message))
        }
    }catch(error){
        yield put(searchDevicesFailure(checkError(error)))
    }
}



// ==== Watcher functions ====
export function* watchFetchCustomers() {
    yield takeEvery(SEARCH_CUSTOMERS, fetchCustomers)
}
export function* watchFetchCustomerDetails() {
    yield takeEvery(GET_CUSTOMER_DETAILS, fetchCustomerDetails)
}
export function* watchChangeConfig() {
    yield takeEvery(CHANGE_CONFIG, changeConfig)
}
export function* watchFetchUserLogs() {
    yield takeEvery(GET_USER_LOGS, fetchUserLogs)
}
export function* watchFetchPortalLogs() {
    yield takeEvery(GET_PORTAL_LOGS, fetchPortalLogs)
}
export function* watchDownloadLogs() {
    yield takeEvery(DOWNLOAD_LOGS, downloadLogs)
}
export function* watchFetchUserLogFilters() {
    yield takeEvery(GET_USER_LOG_FILTERS, fetchUserLogFilters)
}
export function* watchFetchPortalLogFilters() {
    yield takeEvery(GET_PORTAL_LOG_FILTERS, fetchPortalLogFilters)
}
export function* watchFetchCustomersGroup() {
    yield takeEvery(SEARCH_CUSTOMERS_GROUPS, fetchCustomersGroups)
}
export function* watchUpdateCustomersGroup() {
    yield takeEvery(ASSIGN_CUSTOMER_GROUP, updateCustomersGroups)
}
export function* watchFetchUser() {
    yield takeEvery(SEARCH_USER, fetchUser)
}
export function* watchFetchDevices() {
    yield takeEvery(SEARCH_DEVICES, fetchDevices)
}

// ==== Exporter Default functions ====
function* CustomerSaga() {
    yield all([
        fork(watchFetchCustomers),
        fork(watchFetchCustomerDetails),
        fork(watchChangeConfig),
        fork(watchFetchUserLogs),
        fork(watchFetchPortalLogs),
        fork(watchDownloadLogs),
        fork(watchFetchUserLogFilters),
        fork(watchFetchPortalLogFilters),
        fork(watchFetchCustomersGroup),
        fork(watchUpdateCustomersGroup),
        fork(watchFetchUser),
        fork(watchFetchDevices)
    ]);
}

export default CustomerSaga;