import React, { Component } from "react";
import { Container, Card, CardBody, CardTitle, PopoverBody, UncontrolledPopover } from "reactstrap";
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Calendar, DateRange } from 'react-date-range'
import { changePreloader  } from '../../store/layout/actions';
import {Tabs, Tab, TabPanel, TabList} from 'react-web-tabs'
import moment from 'moment';
import DropdownList from 'react-widgets/lib/DropdownList';
import { createAndDownloadBlobFile } from '../../helpers/downloadHelper';

// Components
import Overview from './overview'
import FirmwareUpload from './firmwareUpload'
import Group from './group'

// Actions and Redux components 
import * as SystemActions from '../../store/firmware/actions'
import * as LayoutActions from '../../store/layout/actions'
import * as DeviceActions from '../../store/devices/actions'
import * as CustomerActions from '../../store/customer/actions'
import * as LoginActions from '../../store/auth/login/actions'

class System extends Component {

    constructor(props){
        super (props)
        this.state = {
            selectedTab : "overview" // overview | firmware | group
        }
    }

    componentDidMount(){

    }

    switchTabs(tabName){
        this.setState({
            selectedTab : tabName
        })
    }

    render() {
        let { selectedTab } = this.state
        return (
            <React.Fragment>
            <div className="page-content overview-page">
                <div>
                    <Tabs
                    defaultTab = "overview"
                    onChange={(tabName)=>{this.switchTabs(tabName)}}
                    >
                    <TabList className="custom-tab-button">
                        <Tab tabFor="overview">Overview</Tab>
                        <Tab tabFor="firmware">Firmware Management</Tab>
                        <Tab tabFor="group">Group Management</Tab>
                    </TabList>
                    </Tabs>

                    {/* <Overview></Overview> */}
                    {/* <FirmwareUpload></FirmwareUpload> */}

                </div>

                {
                    selectedTab === "overview" ? <Overview></Overview> : selectedTab === "firmware" ? <FirmwareUpload></FirmwareUpload> : <Group></Group>
                }
            </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    layout : state.Layout,
    system : state.System

})

const mapDispatchToProps = dispatch => ({
    
    getOverview : (data) => dispatch(SystemActions.getOverview(data)),
    getOverviewInit : () => dispatch(SystemActions.getOverviewInit()),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(System)

