import React, { Component } from "react";
import { Container, Card, CardBody, CardTitle, PopoverBody, UncontrolledPopover, Button, Modal } from "reactstrap";
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Calendar, DateRange } from 'react-date-range'
import { changePreloader  } from '../../store/layout/actions';
import {Tabs, Tab, TabPanel, TabList} from 'react-web-tabs'
import moment from 'moment';
import DropdownList from 'react-widgets/lib/DropdownList';
import { createAndDownloadBlobFile } from '../../helpers/downloadHelper';
import InputBox from "../../components/Common/input-box";
import TextBox from "../../components/Common/text-box"
import { SvgIcon } from "@material-ui/core";


class AddUpdatePlan extends Component {

    constructor(props){
        super(props)
        this.state = {
            viewOptions : null,

            planDetails : {
                planName : "",
                description : "",
                promoOffer : null,
                trialPlan : null,
                retentionPeriod : '',
                trialReset: false,
            },
            lnPlanList: [],
            selectedLnPlan: null,
            billingFrequency : {
                monthly : false,
                twoMonths: false,
                sixMonths : false,
                yearly : false
            },
            monthlyPrice : "",
            twoMonthPrice: "",
            sixMonthPrice : "",
            yearlyPrice : "",

            validationCheck : {
                planName : false,
                description : false,
                billingFrequency : false,
                monthlyPrice : false,
                sixMonthPrice : false,
                twoMonthPrice: false,
                yearlyPrice : false,
                promoOffer : false,
                trialPlan : false,
                retentionPeriod : false,
                lnPlan: false
            },
            optionalArr : [
                {optionName : "Yes", optionValue : 1},
                {optionName : "No", optionValue : 0}
            ],
            frequencyList : [],    
            
            disablePlanModal: false,
            trialResetModal: false,
        }
    }

    componentDidMount(){

        console.log("##### CALLING FROM COMPONENT DID MOUNT")
        console.log(this.props.viewType)
        console.log(this.props.planInfo)
        console.log(this.props.alpha)
        if(this.props.viewType === "view"){
            this.initializeViewMode()
        }else{
            this.setState({
                viewOptions: this.props.viewType,
                lnPlanList : this.props.lnPlanInfo
            })
        }
    }

    initializeViewMode(){
        let {viewOptions, planDetails, monthlyPrice, yearlyPrice, sixMonthPrice, billingFrequency, twoMonthPrice, selectedLnPlan} = this.state
        let planInfo = this.props.planInfo
        selectedLnPlan = planInfo.billingFrequency[0]
        selectedLnPlan.retentionPeriod = planInfo.recordingDays
        selectedLnPlan.price = planInfo.billingFrequency[0].subscriptionPrice

        planDetails.planName = planInfo.planName
        planDetails.description = planInfo.planDesc
        planDetails.retentionPeriod = planInfo.recordingDays
        planInfo.isPromotionalEligible === 0 ? planDetails.promoOffer = {optionName : "No", optionValue : 0} : planDetails.promoOffer = {optionName : "Yes", optionValue : 1}
        planInfo.isTrialPlan === 0 ? planDetails.trialPlan = {optionName : "No", optionValue : 0} : planDetails.trialPlan = {optionName : "Yes", optionValue : 1}
        planInfo.isTrialReset === 0 ? planDetails.trialReset = false : planDetails.trialReset = true
        planInfo.billingFrequency.map((value)=>{
            if(value.billingFrequencyId === 1){
                billingFrequency.monthly = true
                monthlyPrice = value.subscriptionPrice
            }
            else if(value.billingFrequencyId === 2){
                billingFrequency.sixMonths = true
                sixMonthPrice = value.subscriptionPrice
            }
            else if(value.billingFrequencyId === 3){
                billingFrequency.yearly = true
                yearlyPrice = value.subscriptionPrice
            }
            else if(value.billingFrequencyId === 4){
                billingFrequency.twoMonths = true
                twoMonthPrice = value.subscriptionPrice
            }
        })
        this.setState({
            viewOptions : this.props.viewType,
            lnPlanList: [...this.props.lnPlanInfo, ...planInfo.billingFrequency],
            selectedLnPlan: selectedLnPlan,
            planDetails : planDetails,
            billingFrequency : billingFrequency,
            monthlyPrice : monthlyPrice,
            sixMonthPrice : sixMonthPrice,
            yearlyPrice : yearlyPrice,
            twoMonthPrice: twoMonthPrice
        })   
    }

    inputHandler(e) {
        if(this.state.viewOptions === "view"){
            return
        }
        let {validationCheck, planDetails, monthlyPrice, sixMonthPrice, yearlyPrice, twoMonthPrice } = this.state


        if(e.target.name === "planName"){
            if(e.target.value.length > 25){
                return
            }
            validationCheck.planName = false
            planDetails.planName = e.target.value
            this.setState({ planDetails : planDetails, validationCheck : validationCheck })
        }
        else if(e.target.name === "retentionPeriod"){
            let regexValue = new RegExp(/^\d*$/)
            if(parseInt(e.target.value) < 1 || parseInt(e.target.value) > 90 || !regexValue.test(e.target.value)){
                return
            }
            validationCheck.retentionPeriod = false
            planDetails.retentionPeriod = e.target.value
            this.setState({ planDetails : planDetails, validationCheck : validationCheck })
        }
        else if(e.target.name === "planDescription"){
            validationCheck.description = false
            planDetails.description = e.target.value
            this.setState({ planDetails : planDetails, validationCheck : validationCheck })
        }
        else if(e.target.name === "monthlyPrice"){            
            let regexValue = new RegExp(/^\d*$/)
            if(!regexValue.test(e.target.value) || e.target.value > 100000){
                return
            }
            validationCheck.monthlyPrice = false
            monthlyPrice = e.target.value
            this.setState({ monthlyPrice : monthlyPrice, validationCheck : validationCheck })
        }
        else if(e.target.name === "6MonthsPrice"){
            let regexValue = new RegExp(/^\d*$/)
            if(!regexValue.test(e.target.value) || e.target.value > 100000){
                return
            }
            validationCheck.sixMonthPrice = false
            sixMonthPrice = e.target.value
            this.setState({ sixMonthPrice : sixMonthPrice, validationCheck : validationCheck })
        }
        else if(e.target.name === "2MonthsPrice"){
            let regexValue = new RegExp(/^\d*$/)
            if(!regexValue.test(e.target.value) || e.target.value > 100000){
                return
            }
            validationCheck.twoMonthPrice = false
            twoMonthPrice = e.target.value
            this.setState({ twoMonthPrice : twoMonthPrice, validationCheck : validationCheck })
        }
        else if(e.target.name === "yearlyPrice"){
            let regexValue = new RegExp(/^\d*$/)
            if(!regexValue.test(e.target.value) || e.target.value > 100000){
                return
            }
            validationCheck.yearlyPrice = false
            yearlyPrice = e.target.value
            this.setState({ yearlyPrice : yearlyPrice, validationCheck : validationCheck })
        }
    }

    checkAction(checkName){
        if(this.state.viewOptions === "view"){
            return
        }    
        let { billingFrequency, monthlyPrice, sixMonthPrice, yearlyPrice, validationCheck, twoMonthPrice, planDetails } = this.state
        let newBillingFreq = billingFrequency
                
        if(checkName === "monthly"){
            billingFrequency.monthly = !billingFrequency.monthly
            newBillingFreq = this.resetPlanType()
            newBillingFreq.monthly = billingFrequency.monthly
            monthlyPrice = ""
        }
        else if(checkName === "twoMonth"){
            billingFrequency.twoMonths = !billingFrequency.twoMonths
            newBillingFreq = this.resetPlanType()
            newBillingFreq.twoMonths = billingFrequency.twoMonths
            twoMonthPrice = ""
        }
        else if(checkName === "sixMonth"){
            billingFrequency.sixMonths = !billingFrequency.sixMonths
            newBillingFreq = this.resetPlanType()
            newBillingFreq.sixMonths = billingFrequency.sixMonths
            sixMonthPrice = ""
        }
        else if(checkName === "yearly"){
            billingFrequency.yearly = !billingFrequency.yearly
            newBillingFreq = this.resetPlanType()
            newBillingFreq.yearly = billingFrequency.yearly
            yearlyPrice = ""
        }
        else if(checkName === "trialReset"){
            planDetails.trialReset = !planDetails.trialReset
        }
        validationCheck.billingFrequency = false
        
        this.setState({ billingFrequency : newBillingFreq, monthlyPrice : monthlyPrice, sixMonthPrice : sixMonthPrice, yearlyPrice : yearlyPrice, twoMonthPrice: twoMonthPrice,
            planDetails: planDetails,
            validationCheck : validationCheck
        })
    }

    resetPlanType = () => {
        return {
            monthly : false,
            twoMonths: false,
            sixMonths : false,
            yearly : false
        }
    }

    selectOption(optionType, e){
        console.log(e)
        if(this.state.viewOptions === "view"){
            return
        }
        let {planDetails, validationCheck, selectedLnPlan} = this.state
        if(optionType === "promoEligible"){
            planDetails.promoOffer = e
            validationCheck.promoOffer = false
        }
        else if(optionType === "isTrial"){
            console.log(e)
            planDetails.trialPlan = e
            validationCheck.trialPlan = false
        }
        else if(optionType === "lnPlan"){
            console.log(e)
            selectedLnPlan = e
            validationCheck.lnPlan = false
        }

        this.setState({ planDetails : planDetails, validationCheck : validationCheck, selectedLnPlan: selectedLnPlan })
    }


    submitDetails() {
        console.log("Starting edit!!!")
        let { planDetails, billingFrequency, monthlyPrice, sixMonthPrice, yearlyPrice, twoMonthPrice, validationCheck, viewOptions, selectedLnPlan } = this.state
        let validationError = false
        delete planDetails.retentionPeriod
        let planDetailKeys = Object.keys(planDetails)

        for(let value of planDetailKeys){
            if(planDetails[value] === "" || planDetails[value] === null ){
                validationError = true
                validationCheck[value] = true
            }
        }
        if(selectedLnPlan === null){
            validationError = true
            validationCheck.lnPlan = true
        }
        // if(planDetails.retentionPeriod < 2){
        //     validationError = true
        //     validationCheck.retentionPeriod = true
        // }

        // if(billingFrequency.monthly === true && monthlyPrice.toString().trim() === ""){
        //     validationCheck.monthlyPrice = true
        //     validationError = true
        // }
        // if(billingFrequency.sixMonths === true && sixMonthPrice.toString().trim() === ""){
        //     validationCheck.sixMonthPrice = true
        //     validationError = true
        // }
        // if(billingFrequency.twoMonths === true && twoMonthPrice.toString().trim() === ""){
        //     validationCheck.twoMonthPrice = true
        //     validationError = true
        // }
        // if(billingFrequency.yearly === true && yearlyPrice.toString().trim() === ""){
        //     validationCheck.yearlyPrice = true
        //     validationError = true
        // }
        // if(billingFrequency.monthly === false && billingFrequency.sixMonths === false && billingFrequency.yearly === false && billingFrequency.twoMonths === false){
        //     validationCheck.billingFrequency = true
        //     validationError = true
        // }

        console.log(validationCheck)
        console.log(validationError)
        console.log("Phase 1")

        this.setState({ validationCheck : validationCheck})
        if(validationError){            
            return
        }

        console.log("Phase 2")
        
        let planFrequency = []
        // if(monthlyPrice.toString().trim() !== ""){
        //     planFrequency.push({
        //         "billingFrequencyId": 1,
        //         "subscriptionPrice": parseInt(monthlyPrice.toString().trim())
        //       })
        // }
        // if(sixMonthPrice.toString().trim() !== ""){
        //     planFrequency.push({
        //         "billingFrequencyId": 2,
        //         "subscriptionPrice": parseInt(sixMonthPrice.toString().trim())
        //       })
        // }
        // if(yearlyPrice.toString().trim() !== ""){
        //     planFrequency.push({
        //         "billingFrequencyId": 3,
        //         "subscriptionPrice": parseInt(yearlyPrice.toString().trim())
        //       })
        // }
        // if(twoMonthPrice.toString().trim() !== ""){
        //     planFrequency.push({
        //         "billingFrequencyId": 4,
        //         "subscriptionPrice": parseInt(twoMonthPrice.toString().trim())
        //       })
        // }

        let billingFrequencyObj = {
            billingFrequencyId: selectedLnPlan.billingFrequencyId,
            itemCode: selectedLnPlan.itemCode,
            subscriptionPrice: selectedLnPlan.price,
            taxPercentage: selectedLnPlan.taxPercentage
        }
        if(planDetails.trialPlan.optionValue === 1){
            billingFrequencyObj.itemCode = null
            billingFrequencyObj.subscriptionPrice = null
            billingFrequencyObj.taxPercentage = null
        }
        planFrequency.push(billingFrequencyObj)

        console.log("Phase 3")


        let data = {
            isPromotionalEligible : planDetails.promoOffer.optionValue,
            isTrialPlan : planDetails.trialPlan.optionValue,
            planName : planDetails.planName,
            planDesc : planDetails.description,
            recordingDays : selectedLnPlan.retentionPeriod,
            billingFrequency : planFrequency
        }
        let isTrialReset = planDetails.trialReset ? 1 : 0
        let details = {
            isTrialReset: isTrialReset,
            data: data
        }
        console.log("Phase 4")

        console.log(data)
        if(viewOptions === "edit"){
            this.props.updatePlanCallback(details, this.props.planInfo.planId)
        }else{
            this.props.newPlanCallback(details)
        }
        
    }


    toggleDisablePlanModal = () => {
        let {disablePlanModal} = this.state
        this.setState({
            disablePlanModal: !disablePlanModal
        })
    }

    toggleTrialResetModal = () => {
        let {trialResetModal} = this.state
        this.setState({
            trialResetModal: !trialResetModal
        })
    }


    disablePlan = () => {
        this.toggleDisablePlanModal()
        this.props.disablePlan(this.props.planInfo.planId)
    }

    render(){
        let { validationCheck, billingFrequency, planDetails, monthlyPrice, sixMonthPrice, twoMonthPrice, yearlyPrice, optionalArr, viewOptions, lnPlanList, selectedLnPlan } = this.state

        return (
            <React.Fragment>
                <div className="subscription-page">
                    <div className="individual-block">
                        <div className="modal-body-frame">
                            <div>
                                {/* <div className="subscription-plan-list"><span>New Plan Details</span></div> */}

                                <InputBox className="m-b-three" type="text" label="Plan Name*" name="planName" key={`planNameKey`} onChange={(e)=>{this.inputHandler(e)}} value={planDetails.planName} hasError={validationCheck.planName} disabled={viewOptions === "view" ? true : false} />

                                <TextBox rows="4" cols="50" className="m-b-three height-auto" type="text" label="Plan Description*" name="planDescription" key={`planDesc`} onChange={(e)=>{this.inputHandler(e)}} value={planDetails.description} hasError={validationCheck.description} disabled={viewOptions === "view" ? true : false} />

                                {/* <InputBox className="m-b-three" type="text" label="Retention Period (2-90 days)*" name="retentionPeriod" key={`retentionPeriod`} onChange={(e)=>{this.inputHandler(e)}} value={planDetails.retentionPeriod} hasError={validationCheck.retentionPeriod} disabled={viewOptions === "view" ? true : false} /> */}
                                <div className="m-b-three">
                                        <div className="font-fourteen"><span>Select Item Code * :</span></div>
                                        {/* <div className="m-b-two"><span><em>Only one plan can be marked as Trial Plan</em></span></div> */}
                                        <DropdownList
                                            className={ "t-a-l " + ((validationCheck.lnPlan) ? 'select-error' : '')}
                                            placeholder="Select Item Code"
                                            data={lnPlanList}
                                            // defaultValue={searchType}
                                            disabled={viewOptions === "view" || viewOptions === "edit" ? true : false}
                                            textField="lnPlanName"
                                            valueField="optionValue"
                                            value={selectedLnPlan}
                                            onChange={(e) => {
                                                this.selectOption("lnPlan", e)
                                            }}                                
                                        />
                                    </div>


                                <div className="custom-group-body">
                                    <div className="custom-group-header"><span>Eligibility</span></div>
                                    {/* ==== ROWING PANEL - 01 */}
                                    
                                    <div className="custom-group-body ln-group-margin">
                                        <div className="custom-group-header"><span>Item Code Details</span></div>
                                        <div>
                                            <div className="ln-box m-b-three">
                                                <div className="m-b-two  font-fourteen"><span>LN Plan Name :</span></div>
                                                <div className="m-b-two  font-fourteen"><span>{selectedLnPlan ? (selectedLnPlan.lnPlanName ? selectedLnPlan.lnPlanName : "--") : "--"}</span></div>
                                            </div>

                                            <div className="ln-box m-b-three">
                                                <div className="m-b-two  font-fourteen"><span>Billing Frequency :</span></div>
                                                <div className="m-b-two  font-fourteen"><span>{selectedLnPlan ? selectedLnPlan.billingFrequency : "--"}</span></div>
                                            </div>

                                            <div className="ln-box m-b-three">
                                                <div className="m-b-two  font-fourteen"><span>Validity :</span></div>
                                                <div className="m-b-two  font-fourteen"><span>{selectedLnPlan ? 
                                                                                                    "Valid from "+selectedLnPlan.effectiveFrom + " upto " + selectedLnPlan.validUpto
                                                                                                    : "--"
                                                                                                }</span></div>
                                            </div>

                                            <div className="ln-box m-b-three">
                                                <div className="m-b-two  font-fourteen"><span>Retention Period :</span></div>
                                                <div className="m-b-two  font-fourteen"><span>{selectedLnPlan ? selectedLnPlan.retentionPeriod + " Days" : "--"}</span></div>
                                            </div>

                                            <div className="ln-box m-b-three">
                                                <div className="m-b-two  font-fourteen"><span>Base Price (without tax) :</span></div>
                                                <div className="m-b-two  font-fourteen"><span>{selectedLnPlan ? "Rs."+selectedLnPlan.price : "--"}</span></div>
                                            </div>

                                            <div className="ln-box m-b-three">
                                                <div className="m-b-two  font-fourteen"><span>Tax Slab (%) :</span></div>
                                                <div className="m-b-two  font-fourteen"><span>{selectedLnPlan ? selectedLnPlan.taxPercentage+"%" : "--"}</span></div>
                                            </div>

                                            <div className="ln-box m-b-three">
                                                <div className="m-b-two  font-fourteen"><span>Total Price (with tax) :</span></div>
                                                <div className="m-b-two  font-fourteen"><span>{selectedLnPlan ? (selectedLnPlan.totalAmount ? "Rs."+selectedLnPlan.totalAmount : "--") : "--"}</span></div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* ==== ROWING PANEL - 02 */}

                                    <div className="m-b-three">
                                        <div className="m-b-two  font-fourteen"><span>Eligible for Promotional Offers? * : </span></div>

                                        <DropdownList
                                            className={ "t-a-l " + ((validationCheck.promoOffer) ? 'select-error' : '')}
                                            placeholder="Eligible for Promotional Offers?"
                                            data={optionalArr}
                                            // defaultValue={searchType}
                                            disabled={viewOptions === "view" ? true : false}
                                            textField="optionName"
                                            valueField="optionValue"
                                            value={planDetails.promoOffer}
                                            onChange={(e) => {
                                                this.selectOption("promoEligible", e)
                                            }}                                
                                        />

                                        {
                                            validationCheck.promoOffer ? <div className="action-validation-text"><span>Please select Eligibility</span></div> : ''
                                        }

                                    </div>

                                    <div className="m-b-three">
                                        <div className="font-fourteen"><span>Mark this as Trial Plan? * :</span></div>
                                        <div className="m-b-two"><span><em>Only one plan can be marked as Trial Plan</em></span></div>
                                        <DropdownList
                                            className={ "t-a-l " + ((validationCheck.trialPlan) ? 'select-error' : '')}
                                            placeholder="Mark as Trial?"
                                            data={optionalArr}
                                            // defaultValue={searchType}
                                            disabled={viewOptions === "view" ? true : false}
                                            textField="optionName"
                                            valueField="optionValue"
                                            value={planDetails.trialPlan}
                                            onChange={(e) => {
                                                this.selectOption("isTrial", e)
                                            }}                                
                                        />
                                    </div>

                                    {
                                        // (planDetails.trialPlan !== null && (viewOptions === "add" || viewOptions === "edit")) &&
                                        planDetails.trialPlan !== null &&
                                            <React.Fragment>
                                                {
                                                    planDetails.trialPlan.optionValue === 1 ? 
                                                        <React.Fragment>
                                                            {
                                                                viewOptions === "add" ?
                                                                    <div className="m-b-three">
                                                                        <input type="checkbox" className="filter-checkbox checkbox-input" name="trialReset" checked={planDetails.trialReset} onChange={()=>{this.checkAction("trialReset")}}/>&nbsp;
                                                                        <label className="font-fourteen"> &nbsp; Enable Reset on Trial plan?</label>
                                                                    </div>
                                                                    :
                                                                    <div className= "m-b-three select-hover-not-allowed">
                                                                        <input type="checkbox" className="filter-checkbox checkbox-input select-hover-not-allowed" name="trialReset" checked={planDetails.trialReset} onChange={()=>{console.log("not possible")}}/>&nbsp;
                                                                        <label className="font-fourteen select-hover-not-allowed"> &nbsp; Enable Reset on Trial plan?</label>
                                                                    </div>
                                                            }
                                                            </React.Fragment>
                                                        :
                                                        ''
                                                }
                                            </React.Fragment>
                                    }
                                </div>

                                {
                                    viewOptions === "view" && this.props.planInfo.isDisabled === 1 ? 
                                    ''
                                    :
                                    <div className="button-grid-level-1">

                                        {
                                            viewOptions === "view" ? 
                                                <Button name="editPlan" onClick={()=>{this.setState({viewOptions : "edit"})}}>Edit Plan</Button> : viewOptions === "edit" ?
                                                    <Button name="updatePlan" onClick={()=>{planDetails.trialReset ? this.toggleTrialResetModal() : this.submitDetails()}}>Update Plan</Button> : 
                                                    <Button name="createPlan" onClick={()=>{planDetails.trialReset ? this.toggleTrialResetModal() : this.submitDetails()}}>Create Plan</Button>
                                        }
                                        <Button name="Cancel" onClick={this.props.cancel}>Cancel</Button>
                                        {/* <Button onClick={()=>{console.log(this.props.lnPlanInfo)}}>Cancel</Button> */}
                                        {
                                            viewOptions === "view" ? 
                                            <Button name="disable Plan" onClick={this.toggleDisablePlanModal}>Disable Plan</Button>
                                            :
                                            ''
                                        }
                                    </div>
                                }



                            </div>
                        </div>

                    </div>
                </div>

                {/* ==== This modal is used for Confirmation if the user wants to definately delete the Plan (Disable it) */}
                <Modal
                    isOpen={this.state.disablePlanModal}
                    toggle={this.toggleDisablePlanModal}
                    className="modal-md "
                >

                    <div className="m-t-5 m-b-five modal-padding">
                        <div className="font-size-20 m-b-five">
                            <center>
                            <span>
                                <b>Are you Sure you wish to disable this Plan? </b>
                            </span>
                            </center>
                        </div>

                            <center>
                                <Button onClick={()=>{this.disablePlan()}}>Disable Plan</Button>
                                &nbsp; &nbsp;
                                <Button onClick={()=>{this.toggleDisablePlanModal()}}>Cancel</Button>                                
                            </center>

                    </div>
                </Modal>

                {/* ==== This Modal is for notifiying the user, that is Trial reset is enabled ==== */}
                <Modal
                    isOpen={this.state.trialResetModal}
                    toggle={this.toggleTrialResetModal}
                    className="modal-md "
                >

                    <div className="m-t-5 m-b-five modal-padding">
                        <div className="font-size-20 m-b-five">
                            <center>
                            <span>
                                <b>You have Enabled Reset on the Trial plan </b><br></br>
                                <span>Do you wish to continue?</span>
                            </span>
                            </center>
                        </div>

                            <center>
                                <Button onClick={()=>{this.submitDetails(); this.toggleTrialResetModal()}}>Yes, Continue</Button>
                                &nbsp; &nbsp;
                                <Button onClick={()=>{this.toggleTrialResetModal()}}>Cancel</Button>
                                
                            </center>

                    </div>
                </Modal>

            </React.Fragment>
        )
    }
}

export default AddUpdatePlan;