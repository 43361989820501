// ==== Action types for users ====
export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE"
export const GET_ALL_USERS_INIT = "GET_ALL_USERS_INIT"

export const GET_USER_DETAILS = "GET_USER_DETAILS"
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS"
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE"
export const GET_USER_DETAILS_INIT = "GET_USER_DETAILS_INIT"

export const EDIT_USER = "EDIT_USER"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE"
export const EDIT_USER_INIT = "EDIT_USER_INIT"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE"
export const DELETE_USER_INIT = "DELETE_USER_INIT"

export const INVITE_USER = "INVITE_USER"
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS"
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE"
export const INVITE_USER_INIT = "INVITE_USER_INIT"

// ==== Ation types for other activities ====
export const GET_ROLES = "GET_ROLES"
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS"
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE"
export const GET_ROLES_INIT = "GET_ROLES_INIT"

export const GET_PERMISSIONS = "GET_PERMISSIONS"
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS"
export const GET_PERMISSIONS_FAILURE = "GET_PERMISSIONS_FAILURE"
export const GET_PERMISSIONS_INIT = "GET_PERMISSIONS_INIT"




