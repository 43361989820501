import { GET_ACTIVATIONS, GET_ACTIVATIONS_SUCCESS ,  GET_ONLINE_DEVICES , SAVE_ONLINE_DEVICES, SET_ISLOADING , DISABLE_LOADING} from './actionTypes';

export const getActivations = (params) => {
    return {
        type: GET_ACTIVATIONS,
        payload: params
    }
}


export const getActivationsSuccess = (data) => {
    return {
        type: GET_ACTIVATIONS_SUCCESS,
        payload: data
    }
}

export const getOnlineDevices = (data) => {
    return {
        type: GET_ONLINE_DEVICES,
        payload: data
    }
}

export const saveOnlineDevices = (data) => {
    return {
        type: SAVE_ONLINE_DEVICES,
        payload: data
    }
}

