import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
    GET_NEW_ACTIVATIONS, GET_ONLINE_DEVICES,
    GET_TREND_GRAPH, GET_STORAGE_STATUS,
    GET_DEVICES_INFO, DOWNLOAD_DEVICE_INFO_REPORT
} from './actionTypes'

import {
    getNewActivationsSuccess, getNewActivationsFailure,
    getOnlineDevicesSuccess, getOnlineDevicesFailure,
    getTrendGraphSuccess, getTrendGraphFailure,
    getStorageStatusSuccess, getStorageStatusFailure,
    getDevicesInfoSuccess, getDevicesInfoFailure,
    downloadDeviceInfoReportSuccess, downloadDeviceInfoReportFailure
} from './actions'

import { checkError } from '../../helpers/errorHelper'
import { 
    getNewActivationService, getOnlineDevicesService, getTrendGraphService, 
    getDeviceInfoService, downloadDeviceInfoReportService
 } from '../../services/devicesService'

// ==== Functions for Saga start here ====

// ==== Function for Get new Activation ====
function* fetchNewActivations(action){
    try{
        const response = yield call(getNewActivationService, action.payload)
        if(response.data.status === true){
            yield put(getNewActivationsSuccess(response.data.data))
        }else{
            yield put(getNewActivationsFailure(response.data.message))
        }
    }catch(error){
        yield put(getNewActivationsFailure(checkError(error)))
    }
}

// ==== Function for Get online Devices ====
function* fetchOnlineDevices(action){
    try{
        const response = yield call(getOnlineDevicesService)
        if(response.data.status === true){
            yield put(getOnlineDevicesSuccess(response.data.data))
        }else{
            yield put(getOnlineDevicesFailure(response.data.message))
        }
    }catch(error){
        yield put(getOnlineDevicesFailure(checkError(error)))
    }
}

// ==== Function for Get Trend Graph ====
function* fetchTrendGraph(action){
    try{
        const response = yield call(getTrendGraphService, action.payload)
        if(response.data.status === true){
            yield put(getTrendGraphSuccess(response.data.data))
        }else{
            yield put(getTrendGraphFailure(response.data.message))
        }
    }catch(error){
        yield put(getTrendGraphFailure(checkError(error)))
    }
}

// ==== Function for Get storage status ====
function* fetchStorageStatus(action){
    try{
        const response = yield call()
        if(response.data.status === true){
            yield put(getStorageStatusSuccess(response.data.data))
        }else{
            yield put(getStorageStatusFailure(response.data.message))
        }
    }catch(error){
        yield put(getStorageStatusFailure(checkError(error)))
    }
}

// ==== Function for Get Device info ====
function* fetchDeviceInfo(action){
    try{
        const response = yield call(getDeviceInfoService)
        if(response.data.status === true){
            yield put(getDevicesInfoSuccess(response.data.data))
        }else{
            yield put(getDevicesInfoFailure(response.data.message))
        }
    }catch(error){
        yield put(getDevicesInfoFailure(checkError(error)))
    }
}

// ==== Function for Download Device info Report ====
function* downloadDeviceInfo(action){
    try{
        const response = yield call(downloadDeviceInfoReportService)
        yield put(downloadDeviceInfoReportSuccess(response.data))
    }catch(error){
        yield put(downloadDeviceInfoReportFailure(checkError(error)))
    }
}


// ==== Watcher functions ====
export function* watchFetchNewActivations() {
    yield takeEvery(GET_NEW_ACTIVATIONS, fetchNewActivations)
}
export function* watchFetchOnlineDevices() {
    yield takeEvery(GET_ONLINE_DEVICES, fetchOnlineDevices)
}
export function* watchFetchTrendGraph() {
    yield takeEvery(GET_TREND_GRAPH, fetchTrendGraph)
}
export function* watchFetchStorageStatus() {
    yield takeEvery(GET_STORAGE_STATUS, fetchStorageStatus)
}
export function* watchFetchDeviceInfo() {
    yield takeEvery(GET_DEVICES_INFO, fetchDeviceInfo)
}
export function* watchDownloadDeviceInfoReport() {
    yield takeEvery(DOWNLOAD_DEVICE_INFO_REPORT, downloadDeviceInfo)
}

// ==== Exporter default function ====
function* DevicesSaga() {
    yield all([
        fork(watchFetchNewActivations),
        fork(watchFetchOnlineDevices),
        fork(watchFetchTrendGraph),
        fork(watchFetchStorageStatus),
        fork(watchFetchDeviceInfo),
        fork(watchDownloadDeviceInfoReport)
    ])
}

export default DevicesSaga;