import API from './interceptor';
import constants from '../common/constants'
const urlDevicePrefix = constants.urlDevicePrefix
const urlPrefix = constants.urlPrefix

export function getRolesService(){
    return API({
        method: 'GET',
        url: urlPrefix+`admins/roles`
    })
}

export function getAllUserService(){
    return API({
        method: 'GET',
        url: urlPrefix+`admins`
    })
}

export function getUserDetailService(userId){
    return API({
        method: 'GET',
        url: urlPrefix+`admins/${userId}`
    })
}

export function editUserService(details){
    return API({
        method: 'PUT',
        url: urlPrefix+`admins`,
        data: JSON.stringify(details)
    })
}

export function inviteUserService(details){
    return API({
        method: 'POST',
        url: urlPrefix+`admins`,
        data: JSON.stringify(details)
    })
}

export function deleteUserService(userId){
    return API({
        method: 'DELETE',
        url: urlPrefix+`admins/${userId}`
    })
}

export function getPermissionsService(roleId){
    let params = {
        roleId: roleId
    }
    return API({
        method: 'GET',
        // url: urlPrefix+`admins/roles/${roleId}/permissions`
        url: urlPrefix+`admins/roles/permissions`,
        params: params
    })
}