export const GET_OVERVIEW = "GET_OVERVIEW"
export const GET_OVERVIEW_SUCCESS = "GET_OVERVIEW_SUCCESS"
export const GET_OVERVIEW_FAILURE = "GET_OVERVIEW_FAILURE"
export const GET_OVERVIEW_INIT = "GET_OVERVIEW_INIT"

export const GET_FIRMWARE_DETAILS = "GET_FIRMWARE_DETAILS"
export const GET_FIRMWARE_DETAILS_SUCCESS = "GET_FIRMWARE_DETAILS_SUCCESS"
export const GET_FIRMWARE_DETAILS_FAILURE = "GET_FIRMWARE_DETAILS_FAILURE"
export const GET_FIRMWARE_DETAILS_INIT = "GET_FIRMWARE_DETAILS_INIT"

export const DOWNLOAD_RELEASE_NOTE = "DOWNLOAD_RELEASE_NOTE"
export const DOWNLOAD_RELEASE_NOTE_SUCCESS = "DOWNLOAD_RELEASE_NOTE_SUCCESS"
export const DOWNLOAD_RELEASE_NOTE_FAILURE = "DOWNLOAD_RELEASE_NOTE_FAILURE"
export const DOWNLOAD_RELEASE_NOTE_INIT = "DOWNLOAD_RELEASE_NOTE_INIT"

export const DOWNLOAD_QA_REPORT = "DOWNLOAD_QA_REPORT"
export const DOWNLOAD_QA_REPORT_SUCCESS = "DOWNLOAD_QA_REPORT_SUCCESS"
export const DOWNLOAD_QA_REPORT_FAILURE = "DOWNLOAD_QA_REPORT_FAILURE"
export const DOWNLOAD_QA_REPORT_INIT = "DOWNLOAD_QA_REPORT_INIT"

export const DOWNLOAD_FIRMWARE = "DOWNLOAD_FIRMWARE"
export const DOWNLOAD_FIRMWARE_SUCCESS = "DOWNLOAD_FIRMWARE_SUCCESS"
export const DOWNLOAD_FIRMWARE_FAILURE = "DOWNLOAD_FIRMWARE_FAILURE"
export const DOWNLOAD_FIRMWARE_INIT = "DOWNLOAD_FIRMWARE_INIT"

export const ROLLBACK_FIRMWARE = "ROLLBACK_FIRMWARE"
export const ROLLBACK_FIRMWARE_SUCCESS = "ROLLBACK_FIRMWARE_SUCCESS"
export const ROLLBACK_FIRMWARE_FAILURE = "ROLLBACK_FIRMWARE_FAILURE"
export const ROLLBACK_FIRMWARE_INIT = "ROLLBACK_FIRMWARE_INIT"

export const STOP_FIRMWARE_TEST = "STOP_FIRMWARE_TEST"
export const STOP_FIRMWARE_TEST_SUCCESS = "STOP_FIRMWARE_TEST_SUCCESS"
export const STOP_FIRMWARE_TEST_FAILURE = "STOP_FIRMWARE_TEST_FAILURE"
export const STOP_FIRMWARE_TEST_INIT = "STOP_FIRMWARE_TEST_INIT"

// ==== Firmware Upload Action Types ====

export const SEARCH_FIRMWARE = "SEARCH_FIRMWARE"
export const SEARCH_FIRMWARE_SUCCESS = "SEARCH_FIRMWARE_SUCCESS"
export const SEARCH_FIRMWARE_FAILURE = "SEARCH_FIRMWARE_FAILURE"
export const SEARCH_FIRMWARE_INIT = "SEARCH_FIRMWARE_INIT"

export const UPLOAD_FIRMWARE_BUILD = "UPLOAD_FIRMWARE_BUILD"
export const UPLOAD_FIRMWARE_BUILD_SUCCESS = "UPLOAD_FIRMWARE_BUILD_SUCCESS"
export const UPLOAD_FIRMWARE_BUILD_FAILURE = "UPLOAD_FIRMWARE_BUILD_FAILURE"
export const UPLOAD_FIRMWARE_BUILD_INIT = "UPLOAD_FIRMWARE_BUILD_INIT"

export const GET_BUILD_TYPES = "GET_BUILD_TYPES"
export const GET_BUILD_TYPES_SUCCESS = "GET_BUILD_TYPES_SUCCESS"
export const GET_BUILD_TYPES_FAILURE = "GET_BUILD_TYPES_FAILURE"
export const GET_BUILD_TYPES_INIT = "GET_BUILD_TYPES_INIT"
