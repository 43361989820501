import constants from '../common/constants'

export function checkError(error) {
    console.log(`#####  Error from errorHelper : ${JSON.stringify(error)}`)
    if(error.data !== null && error.data !== undefined){
        if(error.data.message !== null && error.data.message !== undefined){
            return error.data.message
        }
    }
    else if(error.message !== null && error.message !== undefined){
        return error.message 
    }else{
        return constants.default_error_message
    }

}