import { addMinutes } from "date-fns";
import React, { Component } from "react";
import { Container, Button, Modal, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import dragScroll from 'dragscroll'
import DragScrollProvider from 'drag-scroll-provider'

class Scrubber extends Component {

    constructor(props){        
        super(props)
        this.state = {
            activeTime : "00:00:00",
            activeSecondValue : 0,
            divPercentage :  0, //(0.25 * 3600 * 60),//300, //300,
            contentWidth : 0,
            gridArr : "",
            hourWidth : 0,
            minuteWidth : 0,
            secWidth : 0.05,
            recordingSlotDetails : {},
            patchStyles : [],
            autoScrollingActivated : false,
            additionalScrollStyle : "",
        }
        // contentWidth = 400
        this.divRef = React.createRef() 
        this.drillLevels = []
        this.intervalToken = null
        this.timerInterval = null
        
    }

    componentDidMount(){
        this.initializeWidth()
        // this.initializeTimestamps()
        
    }

    // ==== Test Scroll ====
    autoScrollTimer(totalDuration){
        let x = 0
        this.setState({autoScrollingActivated : true})

        console.log("Value from inside AutoScroll")
        console.log(this.state.secWidth)
        let scrollTime = 0
        let labelTime = 0
        this.intervalToken = setInterval(()=>{
            console.log("Runs")
         
            x = x + 10
            if(x === totalDuration * 100){
                this.stopInterval()
            }
            if(scrollTime >= (1000 / this.state.secWidth) ){
                document.getElementById("scrollerKey").scrollBy({
                    left : 1,       //parseFloat( totalSecValue * secWidth),
                    behavior : "smooth"
                })
                scrollTime = 0
            }else{
                scrollTime += 100
            }

            if(labelTime >= 1000){            
                labelTime = 0     
                let tempTimeArr = this.state.activeTime.split(":")
                let hr = parseInt(tempTimeArr[0]) * 3600
                let min = parseInt(tempTimeArr[1]) * 60
                let sec = parseInt(tempTimeArr[2])
        
                sec += 1
                let newTimeValue = hr + min + sec   

                let newHour = parseInt(newTimeValue / 3600)
                let newMin = parseInt(newTimeValue / 60) % 60
                let newSec = (newTimeValue % 60)
                
                newHour = ("0" + newHour).slice(-2)
                newMin = ("0" + newMin).slice(-2)
                newSec = ("0" + newSec).slice(-2)
                this.setState({activeTime : `${newHour}:${newMin}:${newSec}`})
                
                
            }else{
                labelTime += 100
            }
        },100)


    }

    stopInterval(){
        this.setState({autoScrollingActivated : false})
        clearInterval(this.intervalToken)
    }

    scrollTo(oldSecWidth){
        let {activeTime, secWidth} = this.state    
        console.log("##### Printing from inside the scrollTo bar #####")    
        let tempTimeArr = activeTime.split(":")
        let hr = parseInt(tempTimeArr[0]) * 3600
        let min = parseInt(tempTimeArr[1]) * 60
        let sec = parseInt(tempTimeArr[2])

        let totalSecValue = hr + min + sec
        let totalGap = (totalSecValue * this.state.secWidth) - (totalSecValue * oldSecWidth)
        console.log(`Total gap : ${totalGap}`)
        document.getElementById("scrollerKey").scrollBy({
            left : totalGap,
            // behavior : "smooth"
        })
        this.filterRecordings()    
    }

    initializeWidth(inputSecWidth = null){    
        console.log("##### INITIALIZE WIDTH -01")   
        let {secWidth, activeSecondValue} = this.state
        if(inputSecWidth !== null){
            secWidth = inputSecWidth
        }
        let divWidth = this.divRef.current.offsetWidth //This is the 60% div
        console.log(divWidth)
        // debugger;
        // let scrollWidth = divWidth * divPercentage/100 //This is the brown scroll area
        let scrollWidth = secWidth * 86400
        console.log(scrollWidth)    
        // debugger;
        // let tempContentWidth = scrollWidth - divWidth // This is the sky blue scale area
        // console.log(tempContentWidth)
        // debugger;
        // ==== Test starts ====
        // let secWidth = (tempContentWidth / 86400).toFixed(5)
        let minuteWidth = secWidth * 60
        let hourWidth = minuteWidth * 60
        // ==== Test ends ====

        // let hourWidth = tempContentWidth / 24
        // let minuteWidth = hourWidth / 60
        this.setState({
            divPercentage : scrollWidth + divWidth,
            contentWidth: scrollWidth,
            hourWidth : hourWidth,
            minuteWidth : minuteWidth,
            secWidth : secWidth,
            // secWidth : (hourWidth / 3600),
        }, ()=>{
            console.log("##### INITIALIZE WIDTH -02")
            // this.scrollToCurrentTime()
            if(inputSecWidth === null && this.props.initialTime !== null && this.props.initialTime !== undefined){                
                this.initialJump(this.props.initialTime) // ==== initialTime from props explain the Time to scroll on first load. ====
            }else{
                this.initialJump(activeSecondValue)
            }
        })
    }

    initialJump(secondValue){
        if(secondValue === undefined || secondValue === null ){
            return
        }
        let {secWidth} = this.state
        this.setState({
            scrollLeft : secondValue * secWidth
        }, ()=>{
            document.getElementById("scrollerKey").scrollLeft = secondValue * secWidth
        })
    }

    jumpToTime(secondValue){
        if(secondValue === undefined || secondValue === null){
            return
        }
        let {secWidth} = this.state
        // document.getElementById("scrollerKey").scrollTo({
        //     left :  5249.2, //secondValue * secWidth,//parseFloat( totalSecValue * secWidth),
        //     // behavior : "smooth"
        // })
        
        // let scrollVar = this.props.initialTime * this.state.secWidth
        // let roundedScrollValue = Math.ceil(scrollVar)
        // let newSecWidth = roundedScrollValue * this.state.secWidth / scrollVar
        // let divWidth = this.divRef.current.offsetWidth
        // let newScrollWidth = newSecWidth * 86400
        // this.setState({
        //     secWidth : newSecWidth,
        //     scrollWidth : newScrollWidth,
        //     minuteWidth : newSecWidth * 60,
        //     hourWidth : newSecWidth * 3600,
        //     divPercentage : newScrollWidth + divWidth,
        //     contentWidth : newScrollWidth
        // },()=>{
        //     console.log("#### Printing from JumpToTime " + roundedScrollValue) 
        //     document.getElementById("scrollerKey").scrollLeft = scrollVar
        // } )

        document.getElementById("scrollerKey").scrollLeft = secondValue * secWidth
    
        // document.getElementById("scrollerKey").scrollLeft = this.props.initialTime * secWidth
        
    }

    

    incrementWidth(){
        let {secWidth} = this.state
        if(secWidth >= 1){
            return
        }
        secWidth = secWidth + 0.05
        this.initializeWidth(secWidth)
    }
    decrementWidth(){
        // this.autoScrollTimer(120)
        // console.log(this.state.secWidth)
        // console.log(this.state.hourWidth)
        // console.log(this.state.minuteWidth)
        // this.jumpToTime(59532)
        // return
        let {secWidth} = this.state
        if(secWidth <= 0.1){
            return;
        }
        secWidth = secWidth - 0.05
        this.initializeWidth(secWidth)
    }

    initializeTimestamps(){
        // ==== preparing drill level-01 ====
        let levelOneVariable = [...(new Array(24)).keys()]
        let levelOneTimeStamp = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "23:59"]
        this.drillLevels.push({levelVariable : levelOneVariable, levelTimeStamp : levelOneTimeStamp})
        
        // ==== preparing drill level-02 ====s
        let tempMinuteCount = [...(new Array(6)).keys()] // ==== 6, 10min per hour ====
        let levelTwoVariable = [...(new Array(144)).keys()]
        let levelTwoTimeStamp = []
        for(let x in levelOneVariable){
            for(let y in tempMinuteCount){
                let hr = ("0" + x).slice(-2)
                let min = (y+"0")
                levelTwoTimeStamp.push(`${hr}:${min}`)
            }
        }
        levelTwoTimeStamp.push("23:59")
        this.drillLevels.push({levelVariable : levelTwoVariable, levelTimeStamp : levelTwoTimeStamp})

        // ==== preparing drill level-03 ====
        let tempTwoMinuteCount = [...(new Array(30)).keys()]
        let levelThreeVariable = [...(new Array(30*24)).keys()]
        let levelThreeTimeStamp = levelTwoTimeStamp
        this.drillLevels.push({levelVariable : levelThreeVariable, levelTimeStamp : levelThreeTimeStamp})

    }

    scrollToCurrentTime(){
        let {activeTime, secWidth, hourWidth, minuteWidth} = this.state    
        console.log("##### Printing from inside the scrollTo bar #####")          
        let tempTimeArr = activeTime.split(":")
        let hour = parseInt(tempTimeArr[0])
        let minute = parseInt(tempTimeArr[1])
        let hr = parseInt(tempTimeArr[0]) * 3600
        let min = parseInt(tempTimeArr[1]) * 60
        let sec = parseInt(tempTimeArr[2])

        let totalSecValue = hr + min + sec
        console.log(totalSecValue * secWidth)
        document.getElementById("scrollerKey").scrollTo({
            left : totalSecValue * secWidth,//parseFloat( totalSecValue * secWidth),
            // behavior : "smooth"
        })
        console.log("##### SCROLLTO CURRENT TIME -01")   
        
        this.filterRecordings()   


        // 1. scrollTo hour
        // 2. scrollBy minuute
        // 3. scrollBy second
    }




    scrollHandler(e){
        if(this.state.autoScrollingActivated){
            return
        }
        console.log("Start of an Event") 
        console.log(e.target.scrollWidth)
        console.log(e.target.scrollHeight)
        console.log(e.target.scrollLeft)
        console.log(e.target.clientWidth)
        console.log("End of an Event") 

        // let totalLength = Math.round(e.target.scrollWidth - e.target.clientWidth)
        let totalLength = this.state.secWidth * 86400
        let originalScrollLeft = this.state.scrollLeft
        // let scrollValue = e.target.scrollLeft
        let scrollValue = document.getElementById("scrollerKey").scrollLeft
        console.log("Round 2")
        console.log(totalLength)
        console.log(originalScrollLeft)
        console.log(scrollValue)
        console.log("Round 2 ends!!")


        if(originalScrollLeft <= 0){
            originalScrollLeft = scrollValue
        }
        // let scrollValue = document.getElementById("scrollerKey").scrollLeft          
        // scrollValue = scrollValue - (this.divRef.current.offsetWidth / 2)
        // let totalTime = scrollValue * (1440 * 60) / totalLength
        let newSecWidth = 0 //scrollValue * this.state.secWidth / originalScrollLeft
        if(scrollValue < 1){
            newSecWidth = this.state.secWidth
        }else{
            newSecWidth = scrollValue * this.state.secWidth / originalScrollLeft
        }        
        let totalTime =  originalScrollLeft / this.state.secWidth 
        let initialTime = this.props.initialTime

        console.log("Round 3")
        console.log(originalScrollLeft)
        console.log(newSecWidth)
        console.log(totalTime)
        console.log("Round 3 ends")

        // totalTime = initialTime
        // let totalTime = (scrollValue * (1440 * 60) / totalLength).toFixed(5)
        let hr = parseInt(totalTime / 3600)
        let min = parseInt(totalTime / 60) % 60
        let sec = parseInt(totalTime % 60)
        if(hr === 24){ 
            hr = 23
            min = 59
            sec = 0
        }
        hr = ("0" + hr).slice(-2)
        min = "0" + min
        min = min.slice(-2)
        sec = ("0" + sec).slice(-2)

        console.log(`Total Time : ${hr}:${min}:${sec}`)
        // this.setState({activeTime : `${hr}:${min}:${sec}`})
        this.props.callBackTime(`${hr}:${min}:${sec}`)
        console.log("###### Mentioning auto scroll scroll left")
        console.log(scrollValue)
        console.log(totalLength)
        console.log(totalTime)

        let scrollVar = this.props.initialTime * newSecWidth
        // let roundedScrollValue = Math.ceil(scrollVar)
        // let newSecWidth = roundedScrollValue * this.state.secWidth / scrollVar
        let divWidth = this.divRef.current.offsetWidth
        let newScrollWidth = newSecWidth * 86400
        this.setState({
            secWidth : newSecWidth,
            scrollWidth : newScrollWidth,
            minuteWidth : newSecWidth * 60,
            hourWidth : newSecWidth * 3600,
            divPercentage : newScrollWidth + divWidth,
            contentWidth : newScrollWidth,
            activeTime : `${hr}:${min}:${sec}`,
            scrollLeft : -10,
            activeSecondValue : totalTime
        },()=>{
            // console.log("#### Printing from JumpToTime " + roundedScrollValue) 
            // document.getElementById("scrollerKey").scrollLeft = scrollVar
        } )

    }


    filterRecordings(){
        console.log("######### From filter record !!")
        let patchArr = this.props.recordedPatchArr
        let disableBatch = this.props.isDisabled
        // if(patchArr.length === 0 || disableBatch) { 
        //     let emptyRecordingStyle = {
        //         "background-color" : "#dcdcdc"
        //     }
        //     this.setState({ additionalScrollStyle : emptyRecordingStyle })
        //     console.log("######### From filter record empty!!")
        //     // return 
        // }
        let patchStyles = []
        let startPosition = null
        let patchWidth = null
        let styleValue = {}
        let disableStyle = {}

        // if(disableBatch){
        //     disableStyle = {
        //         "background-color" : "#777"
        //     }
        // }

        patchArr.map((value) => {            
            startPosition = value.startTimeSec * this.state.secWidth
            patchWidth = (value.endTimeSec - value.startTimeSec) * this.state.secWidth  
            styleValue = {
                position : `absolute`,
                left : `${startPosition}px`,
                width : `${patchWidth}px`,
                ...disableStyle
            }               
            patchStyles.push(styleValue)
        })

        this.setState({patchStyles : patchStyles})
    }

    onMouseUpTrigger() {
        console.log("THIS IS ON MOUSE UP!! CONGRATS!!")
    }


    render(){
        this.initializeTimestamps()
        let {divPercentage, hourWidth, minuteWidth, additionalScrollStyle, secWidth} = this.state
        let variableDistanceFactor = 0
        let timeStampDistanceFactor = 0
        let activeDrillLevel = null
        
        if(secWidth <= 0.25){
            activeDrillLevel = this.drillLevels[0];
            variableDistanceFactor = hourWidth
            timeStampDistanceFactor = variableDistanceFactor
        }else if(secWidth > 0.3 && secWidth < 0.7){
            activeDrillLevel = this.drillLevels[1]
            variableDistanceFactor = minuteWidth * 10
            timeStampDistanceFactor = variableDistanceFactor
        }else{
            activeDrillLevel = this.drillLevels[2]
            variableDistanceFactor = minuteWidth * 2
            timeStampDistanceFactor = minuteWidth * 10
        }
        
        let compoStyle={
            position : 'absolute',
            left : `${20704 * this.state.secWidth}px`,
            width : `${7200 * this.state.secWidth}px`
        }


        return(
            <React.Fragment>
                <div className="page-content scrubber-page">
                    {/* ==== Timeline Scroller begins from here === */}
                    <div className="individual-block">
                        {/* ==== Complete external block ====  */}
                        <div className="test-01">
                            {/* ==== Mid block to support test-02 and to hold the scrubber bar ==== */}
                            <div className="test-mid-01" ref={this.divRef}>
                                {/* ==== Main track holding panel that scrolls ==== */}
                                <DragScrollProvider>
                                    {({onMouseDown, ref}) => (
                                    <div id="scrollerKey" className="test-02" onScroll={(e)=>{this.scrollHandler(e)}} onMouseDown={(event)=>{onMouseDown(event); this.stopInterval();}} ref={ref} onMouseUp={this.onMouseUpTrigger}>
                                        <div className="test-mid-03" style={{width : `${this.state.divPercentage}px`}}>

                                            {/* ==== panel holding track and divided bars ==== */}
                                            <center>
                                                <div className="test-03" style={{ ...additionalScrollStyle, width: `${this.state.contentWidth}px`}}>

                                                    {/* <div 
                                                        className="test-04"
                                                        style={compoStyle}
                                                    ></div> */}

                                                    {
                                                        this.state.patchStyles.map((value)=>{
                                                            return(
                                                                <div 
                                                                    className="test-04"
                                                                    style = {value}
                                                                ></div>
                                                            )
                                                        })
                                                    }

                                                    {
                                                        activeDrillLevel.levelVariable.map((value, index)=>{
                                                            return(
                                                                <div className="line-col" style={{left : index * variableDistanceFactor}}></div>             
                                                            )
                                                        })
                                                    }
                                                    {
                                                    activeDrillLevel.levelTimeStamp.map((value, index) => {
                                                        return(
                                                                <div className="time-stamp-box" style={{left : index * timeStampDistanceFactor}}><div className="time-stamp-string">{value}</div></div>                                                        
                                                        )
                                                    })
                                                    
                                                    }

                                                </div>
                                            </center>


                                        </div>  
                                    </div>
                                    )}
                                </DragScrollProvider>

                                <div className="scrubber-panel">         
                                    <div className="scrubber-text">{this.state.activeTime}</div>
                                    <div className="scrubber-bar-02"></div>
                                </div>
                            </div>
                            

                        </div>

                        <Button onClick={()=>{this.incrementWidth()}}> + Add </Button>
                        <Button onClick={()=>{this.decrementWidth()}}> - Sub</Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default Scrubber;