export default {
    default_error_message: 'An application error occured',
    urlDevicePrefix: "adminDevice-management/",
    urlPrefix: 'admin-management/',

    // ==== Pointing Servers ====

    AWSregion: 'ap-south-1',

    // ==== QA Server ====
    // constUserPoolId: "ap-south-1_VP3cMdnDF",
    // constClientId: "itfsd4an45icocc1umpd3vv84",
    // constBaseUrl: "https://qaapi.godrejhomecam.in",

    // ==== UAT Server ====
    // constUserPoolId : "ap-south-1_PQBcTmELr",
    // constClientId : "1euji717kj2hi379citqpigt74",
    // constBaseUrl : "https://uatapi.godrejhomecam.in",

    // ==== Staging / Beta Server ====
    // constUserPoolId: "ap-south-1_BbecERM6C",
    // constClientId: "2root6mjb0di8vg4lmdiq9advn",
    // constBaseUrl: "https://stagingapi.godrejhomecam.in/",

    // ==== Dev Server ====
    // constUserPoolId: "ap-south-1_RcMrTWW9Y",
    // constClientId: "3jbs9g78ce4c9t0vrthvc4nv9u",
    // constBaseUrl: "https://devapi.godrejhomecam.in",

    // ==== Production Server ====
    // constUserPoolId: "ap-south-1_l56PYaabK",
    // constClientId: "3k8af78cv4vvrjgtaq9aepi9v1",
    // constBaseUrl: "https://spotlightapi.godrejhomecam.in",

    // ==== Final Production Server ====
    constUserPoolId: "ap-south-1_l56PYaabK",
    constClientId: "3k8af78cv4vvrjgtaq9aepi9v1",
    constBaseUrl: "https://prodapi.godrejspotlight.in/",


    transactionTypes: {
        pending: 0,
        rejected: 1,
        accepted: 2
    },
    pageSize: 10
}