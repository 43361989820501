import React, { Component } from 'react';
import { Container, Button, Modal, Popover, PopoverHeader, PopoverBody} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import InputBox from '../../components/Common/input-box'
import DropdownList from 'react-widgets/lib/DropdownList';
import { connect } from 'react-redux';
import FileUpload from '../../components/Common/file-upload';
import productImage from '../../assets/images/product/product.png';

// ==== Actions ====
import * as CustomerActions from '../../store/customer/actions'
import * as LayoutActions from '../../store/layout/actions'
import * as FirmwareActions from '../../store/firmware/actions'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/breadcrumb';
import { is } from 'immutable';


class Group extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterValues : [
                {displayValue : "Mobile No", searchFilter : "Mobile No"},
                {displayValue : "Email Id", searchFilter : "Email ID"},
                {displayValue : "Serial No", searchFilter : "Serial No"}
            ],
            allGroups : [],
            userGroupList : [],
            disabledGroup : null,
            searchValue : '',
            searchParamFilter : null,
            searchParamFilterObj : null,
            searchDepthFilter : "Exact",
            validationErrors : {
                searchValueError : false,
                filterError : false
            },
            customerGroupDetails : null,
            newAssignedGroup : null,
            assignedGroupObj : null,
        }
    }

    componentDidMount() {

        this.props.getBuildTypes()

    }


    onSearch = (e) => {

        if(e.keyCode === 13 || e.target.name === "searchButton"){
            let { searchValue, searchParamFilter, searchDepthFilter, validationErrors } = this.state
            let validationFail = false
            console.log("#### INSIDE SEARCH")
            console.log(searchValue)
            console.log(searchParamFilter)
    
            // ==== Validations check ==== 
            let trimmedSearchValue = searchValue.trim()
            if(trimmedSearchValue === ""){
                validationErrors.searchValueError = true
                validationFail = true
            }
            if(searchParamFilter === null){
                validationErrors.filterError = true
                validationFail = true
            }else if(searchParamFilter === "Email ID"){
                let regexEmail = new RegExp(/@+\S+\./)
                if(!regexEmail.test(trimmedSearchValue)){
                    validationErrors.searchValueError = true
                    validationFail = true
                }
            }
    
            if(validationFail) { 
                this.setState({validationErrors : validationErrors})
                return 
            }
    
            // ==== Validation passed and continuing to API call ====
            if(searchParamFilter === "Mobile No"){
                let regexNumber = /^\+91/
                console.log("Inside Mobile  No")
                if(!regexNumber.test(trimmedSearchValue)){
                    trimmedSearchValue = "+91" + trimmedSearchValue
                    this.setState({ searchValue : trimmedSearchValue })
                }
            }
            let params = {
                search: searchParamFilter,
                filter: searchDepthFilter,
                value: trimmedSearchValue,
                pageCount: 1,
                pageSize: 10
            }
            
            this.setState({ searchValue : trimmedSearchValue })
            this.props.searchCustomersGroups(params)
            this.props.changePreloader(true)
        }
    }

    onClear() {
        this.setState({
            searchValue : '',
            disabledGroup : null,
            customerGroupDetails : null,
            searchParamFilter : null,
            searchParamFilterObj : null,
            validationErrors : {
                searchValueError : false,
                filterError : false
            },
        })
    }

    assignNewGroup(e){
        console.log(e)
        this.setState({
            newAssignedGroup : e,
            assignedGroupObj : e,
        })
    }

    updateGroup(userId){

        let {newAssignedGroup } = this.state
        let data = {
            userId : userId,
            groupId : newAssignedGroup.groupId
        }

        this.props.assignCustomerGroups(data)
        this.props.changePreloader(true)
    }

    localUpdateGroup(){
        let {customerGroupDetails, newAssignedGroup, disabledGroup} = this.state
        customerGroupDetails.groupName = newAssignedGroup.groupName
        customerGroupDetails.groupId = newAssignedGroup.groupId
        disabledGroup = [newAssignedGroup]
        this.setState({
            customerGroupDetails : customerGroupDetails,
            disabledGroup : disabledGroup,
            assignedGroupObj : null
        })
    }

    inputHandler = (e) => {
        let {validationErrors} = this.state

        if(e.target.name === "searchValue"){
            let reg = new RegExp(/^([0-9]|\+)*$/)
            if(this.state.searchParamFilter === "Mobile No"){
                if(!reg.test(e.target.value) || (e.target.value).length > 13){
                    return
                }
            }
            validationErrors.searchValueError = false
            this.setState({ searchValue : e.target.value, validationErrors : validationErrors })
        }
    }

    setUpCustomerDetails(){
        console.log("Inside Setup!!")
        let { customerGroupDetails, allGroups } = this.state
        let tempDisabledGroup = allGroups.filter(group => group.groupId === customerGroupDetails.groupId)
        this.setState({
            disabledGroup : tempDisabledGroup,
        })
        console.log(tempDisabledGroup)
        console.log("Setup done!!")

    }


    // ==== CHECKER FUNCTIONS ====

    checkGetBuildTypes(){
        if (this.props.firmware.getBuildTypesSuccess === true) {
            this.setState({ allGroups : this.props.firmware.buildTypes });
            this.props.getBuildTypesInit();
        } else if (this.props.firmware.getBuildTypesFailure === true) {
            toast.error(this.props.firmware.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getBuildTypesInit();
        }
    }

    checkSearchCustomerGroups(){
        if(this.props.layout.isPreloader){
            if(this.props.customer.searchCustomersGroupsSuccess === true){
                this.setState({ customerGroupDetails : this.props.customer.customerGroup}, ()=>{this.setUpCustomerDetails()})
                this.props.changePreloader(false)
                this.props.searchCustomersGroupsInit()
                
            }
            else if (this.props.customer.searchCustomersGroupsFailure === true) {
                this.props.changePreloader(false)
                toast.error(this.props.customer.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.searchCustomersGroupsInit();
            }
        }
    }

    checkUpdateGroup(){
        if(this.props.customer.assignCustomerGroupSuccess === true){
            this.props.assignCustomerGroupsInit()
            this.localUpdateGroup()
            this.props.changePreloader(false)
            toast.success("Group Updated Successfully")
            
        }
        else if (this.props.customer.assignCustomerGroupFailure === true) {
            this.props.changePreloader(false)
            toast.error(this.props.customer.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.assignCustomerGroupsInit();
        }
    }



    render() {

        let { filterValues, searchValue, validationErrors, userGroupList , allGroups, disabledGroup, customerGroupDetails, searchParamFilter, searchParamFilterObj, assignedGroupObj } = this.state

        // ====  Calling checker functions ====
        this.checkSearchCustomerGroups()
        this.checkGetBuildTypes()
        this.checkUpdateGroup()


        return (
            <React.Fragment>
                <div className="page-content-with-tabs group-page">

                    <div className="search-section">
                        <h5 className="font-weight-bold no-mar">Search Users</h5>
                        <p>Search using Mobile No / Email ID / Device Serial Number</p>
                        <div className="input-grid-section">
                            <DropdownList
                                className={"t-a-l " + ((validationErrors.filterError) ? 'select-error' : '')}
                                placeholder="Search by"
                                data={filterValues}
                                // defaultValue={searchType}
                                textField="displayValue"
                                valueField="searchFilter"
                                value={searchParamFilterObj}
                                onChange={(e) => {
                                    // console.log(e)
                                    this.setState({ searchParamFilter: e.searchFilter, searchParamFilterObj : e, searchValue : "", validationErrors : {...validationErrors, filterError : false} });
                                }}                                
                            />
                            <InputBox className={searchParamFilter === null ? "select-hover-not-allowed" : ""} type="text" label="Search User" key={`searchText`} name="searchValue" value={searchValue} onChange={(e) => { this.inputHandler(e) }} onKeyDown={(e)=>{this.onSearch(e)}} hasError={validationErrors.searchValueError} disabled={ searchParamFilter === null ? true : false } />
                            <Button name="searchButton" onClick={this.onSearch} >Search</Button>
                            <Button onClick={()=>{this.onClear()}} > Clear </Button>
                        </div>
                    </div>

                    {
                        customerGroupDetails !== null ? 
                            <div>
                                <div className="current-group-block">
                                    <div><span className="font-size-18">Customer Details</span></div>
                                    <div className="level-one-grid">
                                        
                                        <div>
                                            <div className="level-two-grid">
                                                <div className="user-description"><span>User's Name : </span></div>
                                                <div><span className="user-description"><b>{customerGroupDetails.userName}</b></span></div>                                        
                                            </div>

                                            <div className="level-two-grid">
                                                <div><span className="user-description">Email Address : </span></div>
                                                <div><span className="user-description"><b>{customerGroupDetails.emailId}</b></span></div>                                        
                                            </div>
                                        </div>


                                        <div>
                                            <div className="level-two-grid">
                                                <div><span className="user-description">Mobile No : </span></div>
                                                <div><span className="user-description"><b>{customerGroupDetails.mobNumber}</b></span></div>                                        
                                            </div>

                                            <div className="level-two-grid">
                                                <div><span className="user-description">Current Assigned Group : </span></div>
                                                <div><span className="user-description"><b>{customerGroupDetails.groupName}</b></span></div>                                        
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="current-group-block">
                                    <div><span className="font-size-18"> Mapped Device Types: </span></div>
                                    <div>
                                        <ul>
                                            {
                                                customerGroupDetails.deviceType.map((deviceType) => {
                                                    return(
                                                        <li className="font-size-16">{deviceType.categoryName}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className="current-group-block">
                                    <div><span className="font-size-18">Change Group : </span></div>
                                    
                                    <div className="group-selection"> 
                                        <DropdownList
                                            className=" t-a-l"
                                            placeholder="Group Types"
                                            data={allGroups}
                                            // defaultValue={searchType}
                                            textField="groupName"
                                            valueField="groupId"
                                            value={assignedGroupObj}
                                            disabled={disabledGroup}
                                            dropUp={true}
                                            onChange={(e) => {
                                                this.assignNewGroup(e)
                                            }}                                
                                        />
                                        <div className="m-l-10">
                                            <Button onClick={()=>{this.updateGroup(customerGroupDetails.userId)}}>Change Group</Button>
                                        </div>
                                    </div>

                                </div>
                            </div> : ''
                    }



                </div>
                <ToastContainer/>
            </React.Fragment>
        )
    }
}



const mapStateToProps = state => ({
    customer : state.Customer,
    layout : state.Layout,
    firmware : state.Firmware
})

const mapDispatchToProps = dispatch => ({

    searchCustomersGroups : (data) => dispatch(CustomerActions.searchCustomersGroups(data)),
    searchCustomersGroupsInit : () => dispatch(CustomerActions.searchCustomersGroupsInit()),

    assignCustomerGroups : (data) => dispatch(CustomerActions.assignCustomerGroups(data)),
    assignCustomerGroupsInit : () => dispatch(CustomerActions.assignCustomerGroupsInit()),

    getBuildTypes : (data) => dispatch(FirmwareActions.getBuildTypes(data)),
    getBuildTypesInit : () => dispatch(FirmwareActions.getBuildTypesInit()),

    changePreloader: (flag) => dispatch(LayoutActions.changePreloader(flag)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Group)