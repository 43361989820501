import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { CREATE_PRODUCT, EDIT_PRODUCT, DELETE_PRODUCT, GET_PRODUCT, GET_PRODUCT_TYPE, GET_PRODUCT_BY_CATEGORY } from './actionTypes';
import { 
    createProductSuccess, createProductFailure,
    editProductSuccess, editProductFailure, 
    deleteProductSuccess, deleteProductFailure,
    getProductSuccess, getProductFailure,
    getProductTypeSuccess, getProductTypeFailure,
    getProductByCategorySuccess, getProductByCategoryFailure
 } from './actions';
import { addProduct, editProduct, deleteProduct, searchProduct, fetchProductTypes, searchProductByCategory } from "../../services/productService";
import constants from '../../common/constants'
import { checkError } from '../../helpers/errorHelper'

// ==== Function to Create product ====
function* insertProduct(action) {
    try{
        const response = yield call (
            addProduct,
            action.payload.formBoxData,
            action.payload.categoryId
        )
        if(response.data.status === true){
            yield put(createProductSuccess(response.data.data))
        }else{
            yield put(createProductFailure(response.data.message))
        }
    }catch(error){
        yield put(createProductFailure(checkError(error)))
    }
}

// ==== Function to Edit product ====
function* updateProduct(action) {
    try{
        const response = yield call (
            editProduct,
            action.payload.categoryId,
            action.payload.productId,
            action.payload.formData
            )
        if(response.data.status === true){
            yield put(editProductSuccess(response.data.data))
        }else{
            yield put(editProductFailure(response.data.message))
        }
    }catch(error){
        yield put(editProductFailure(checkError(error)))
    }
}

// ==== Function to Delete Product ====
function* removeProduct(action) {
    try{
        const response = yield call (
            deleteProduct,
            action.payload.categoryId,
            action.payload.productId
        )
        if(response.data.status === true){
            yield put(deleteProductSuccess(response.data.data))
        }else{
            yield put(deleteProductFailure(response.data.message))
        }
    }catch(error){
        yield put(deleteProductFailure(checkError(error)))
    }
}

// ==== Function to Get Product ====
function* fetchProduct(action) {
    try{
        const response = yield call (searchProduct, action.payload)
        // let response
        // action.payload ? response = yield call(searchProduct) : response = yield call(searchProduct, action.payload)
        if(response.data.status === true){
            yield put(getProductSuccess(response.data.data))
        }else{
            yield put(getProductFailure(response.data.message))
        }
    }catch(error){
        yield put(getProductFailure(checkError(error)))
    }
}

// ==== Function to Get Product types ====
function* fetchProductType(action) {
    try{
        const response = yield call(fetchProductTypes)
        if(response.data.status === true){
            yield put(getProductTypeSuccess(response.data.data))
        }else{
            yield put(getProductTypeFailure(response.data.message))
        }
    }catch(error){
        yield put(getProductTypeFailure(checkError(error)))
    }
}

// ==== Function to Get product by Category for Batches ====
function* fetchProductByCategory(action) {
    try{
        const response = yield call(
            searchProductByCategory, 
            Number(action.payload.categoryId)
            )
        if(response.data.status === true){
            yield put(getProductByCategorySuccess(response.data.data))
        }else{
            yield put(getProductByCategoryFailure(response.data.message))
        }
    }catch(error){
        yield put(getProductByCategoryFailure(checkError(error)))
    }
}


// ===== Watcher Functions ====
export function* watchCreateProduct() {
    yield takeEvery(CREATE_PRODUCT, insertProduct)
}
export function* watchEditProduct() {
    yield takeEvery(EDIT_PRODUCT, updateProduct)
}
export function* watchDeleteProduct() {
    yield takeEvery(DELETE_PRODUCT, removeProduct)
}
export function* watchGetProduct() {
    yield takeEvery(GET_PRODUCT, fetchProduct)
}
export function* watchGetProductType() {
    yield takeEvery(GET_PRODUCT_TYPE, fetchProductType)
}
export function* watchGetProductByCategory() {
    yield takeEvery(GET_PRODUCT_BY_CATEGORY, fetchProductByCategory)
}

// ==== Exporter default function ====
function* ProductSaga() {
    yield all([
        fork(watchCreateProduct),
        fork(watchEditProduct),
        fork(watchDeleteProduct),
        fork(watchGetProduct),
        fork(watchGetProductType),
        fork(watchGetProductByCategory)
    ])
}

export default ProductSaga;