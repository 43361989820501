import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
    GET_OVERVIEW, GET_FIRMWARE_DETAILS,
    DOWNLOAD_RELEASE_NOTE, DOWNLOAD_QA_REPORT,
    DOWNLOAD_FIRMWARE, ROLLBACK_FIRMWARE, 
    STOP_FIRMWARE_TEST, SEARCH_FIRMWARE, 
    UPLOAD_FIRMWARE_BUILD, GET_BUILD_TYPES
} from './actionTypes'

import {
    getOverviewSuccess, getOverviewFailure,
    getFirmwareDetailsSuccess, getFirmwareDetailsFailure,
    downloadReleaseNoteSuccess, downloadReleaseNoteFailure,
    downloadQaReportSuccess, downloadQaReportFailure,
    downloadFirmwareSuccess, downloadFirmwareFailure,
    rollbackFirmwareSuccess, rollbackFirmwareFailure,
    stopFirmwareTestSuccess, stopFirmwareTestFailure,
    searchFirmwareSuccess, searchFirmwareFailure,
    uploadFirmwareBuildSuccess, uploadFirmwareBuildFailure,
    getBuildTypesSuccess, getBuildTypesFailure
} from './actions'

import { getOverviewService, getFirmwareDetailsService, rollbackFirmwareService, stopFirmwareTestService, searchFirmwareService, getBuildTypesService, updateFirmwareBuildService } from '../../services/firmwareService'
import {checkError} from '../../helpers/errorHelper'
import { act } from 'react-dom/test-utils';



// ==== Functions for Saga ====

// ==== Function to Get Overview ====
function* fetchOverview(action){
    try{
        const response = yield call(getOverviewService, action.payload)
        if(response.data.status === true){
            yield put(getOverviewSuccess(response.data.data))
        }else{
            yield put(getOverviewFailure(response.data.message))
        }
    }catch(error){
        yield put(getOverviewFailure(checkError(error)))
    }
}

// ==== Function to Get Firmware details ====
function* fetchFirmwareDetails(action){
    try{
        const response = yield call(getFirmwareDetailsService, action.payload)
        if(response.data.status === true){
            yield put(getFirmwareDetailsSuccess(response.data.data))
        }else{
            yield put(getFirmwareDetailsFailure(response.data.message))
        }
    }catch(error){
        yield put(getFirmwareDetailsFailure(checkError(error)))
    }
}

// ==== Function for Rollback Firmware ====
function* rollbackFirmware(action){
    try{
        const response = yield call(rollbackFirmwareService, action.payload)
        if(response.data.status === true){
            yield put(rollbackFirmwareSuccess(response.data.data))
        }else{
            yield put(rollbackFirmwareFailure(response.data.message))
        }
    }catch(error){
        yield put(rollbackFirmwareFailure(checkError(error)))
    }
}

// ==== Function for Stop Firmware Test ====
function* stopFirmwareTest(action){
    try{
        const response = yield call(stopFirmwareTestService, action.payload)
        if(response.data.status === true){
            yield put(stopFirmwareTestSuccess(response.data.data))
        }else{
            yield put(stopFirmwareTestFailure(response.data.message))
        }
    }catch(error){
        yield put(stopFirmwareTestFailure(checkError(error)))
    }
}

// ==== Function for Search Firmware ====
function* searchFirmware(action){
    try{
        const response = yield call(searchFirmwareService, action.payload)
        if(response.data.status === true){
            yield put(searchFirmwareSuccess(response.data.data))
        }else{
            yield put(searchFirmwareFailure(response.data.message))
        }
    }catch(error){
        yield put(searchFirmwareFailure(checkError(error)))
    }
}

// ==== Function for Upload Firmware Build
function* uploadFirmwareBuild(action){
    try{
        const response = yield call(
            updateFirmwareBuildService,
            action.payload.formBoxData,
            action.payload.productId
        )
        if(response.data.status === true){
            yield put(uploadFirmwareBuildSuccess(response.data.data))
        }else{
            yield put(uploadFirmwareBuildFailure(response.data.message))
        }
    }catch(error){
        yield put(uploadFirmwareBuildFailure(checkError(error)))
    }
}

// ==== Function for Get Build Types ====
function* fetchBuildTypes(action){
    try{
        const response = yield call(getBuildTypesService, action.payload)
        if(response.data.status === true){
            yield put(getBuildTypesSuccess(response.data.data))
        }else{
            yield put(getBuildTypesFailure(response.data.message))
        }
    }catch(error){
        yield put(getBuildTypesFailure(checkError(error)))
    }
}


// ==== Watcher functions ====
export function* watchFetchOverview() {
    yield takeEvery(GET_OVERVIEW, fetchOverview)
}
export function* watchFetchFirmwareDetails() {
    yield takeEvery(GET_FIRMWARE_DETAILS, fetchFirmwareDetails)
}
export function* watchRollbackFirmware() {
    yield takeEvery(ROLLBACK_FIRMWARE, rollbackFirmware)
}
export function* watchStopFirmwareTest() {
    yield takeEvery(STOP_FIRMWARE_TEST, stopFirmwareTest)
}
export function* watchSearchFirmware() {
    yield takeEvery(SEARCH_FIRMWARE, searchFirmware)
}
export function* watchUploadFirmwareBuild() {
    yield takeEvery(UPLOAD_FIRMWARE_BUILD, uploadFirmwareBuild)
}
export function* watchFetchBuildTypes() {
    yield takeEvery(GET_BUILD_TYPES, fetchBuildTypes)
}

// ==== Default exporter function ====
function* FirmwareSaga(){
    yield all([
        fork(watchFetchOverview),
        fork(watchFetchFirmwareDetails),
        fork(watchRollbackFirmware),
        fork(watchStopFirmwareTest),
        fork(watchSearchFirmware),
        fork(watchUploadFirmwareBuild),
        fork(watchFetchBuildTypes)
    ])
}

export default FirmwareSaga;