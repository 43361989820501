import React, { Component } from "react";
import { Container, Card, CardBody, CardTitle, PopoverBody, UncontrolledPopover, Button, Modal } from "reactstrap";
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Calendar, DateRange } from 'react-date-range'
import { changePreloader  } from '../../store/layout/actions';
import {Tabs, Tab, TabPanel, TabList} from 'react-web-tabs'
import moment from 'moment';
import DropdownList from 'react-widgets/lib/DropdownList';
import { createAndDownloadBlobFile } from '../../helpers/downloadHelper';
import Scrubber from "../../components/Common/scrubber";

import * as LayoutActions from '../../store/layout/actions'
import * as SubscriptionActions from '../../store/subscription/actions'
import * as CustomerActions from '../../store/customer/actions'

import AddUpdatePlan from './addUpdatePlan'
import AddUpdatePromo from './addPromo'


class Subscription extends Component {

    constructor(props){
        super(props)
        this.state = {
            planList : [],
            lnPlanList: [],
            deadPlanList: [],

            activePromotionList : [],
            expiredPromotionList : [],
            planFrequencies : [],
            planInfo : null,
            promoInfo : null,
            viewType : "add", // enum : add | edit | view
            expandExpiredPromo : false,
            modalView : "plan",     // ==== enum : plan | promo
            showModal : false,

            planModalKey : 0,
            alpha : 10,
            selectedPlanIndex : 0,
            selectedPromoIndex : 0,
            recordingDaysObject : {},
            planIdMap : {},
        }

        this.promoComponentRef = React.createRef()
    }

    componentDidMount() {
        this.props.getAllPlans()
        this.props.getAllPromo()
        this.props.getPlanFrequency()
        this.props.changePreloader(true)

    }

    callBackTime(timeStampSent){
        console.log("##### Printing from subscription")
        console.log(timeStampSent)
    }

    toggleExpandExpiredPromo(){
        this.setState({
            expandExpiredPromo : !this.state.expandExpiredPromo
        })
    }

    buildRecordDaysObj(){
        let {planList, recordingDaysObject, planIdMap} = this.state
        let recordingDays = null
        planList.map((value)=>{
            if(value.isDisabled !== 1){ 
                recordingDays = value.recordingDays
                if(recordingDaysObject[recordingDays] !== null && recordingDaysObject[recordingDays] !== undefined){
                    recordingDaysObject[recordingDays].push({ "planId" : value.planId})
                }else{
                    recordingDaysObject[recordingDays] = []
                    recordingDaysObject[recordingDays].push({ "planId" : value.planId})
                }   

                planIdMap[value.planId] = value.recordingDays
            }
        })

        this.setState({recordingDaysObject : recordingDaysObject, planIdMap : planIdMap })
    }

    filterPlans(allPlans){
        console.log("Starting filter plans !!")
        console.log(allPlans[0])
        // debugger;
        let allNames = []
        let activePlans = []
        let deactivePlans = []
        allPlans.map((value)=> {
            allNames = []
            value.billingFrequency.map((freq)=>{
                allNames.push(freq.billingFrequency)
            })
            value.allFrequencies = allNames
            value.isDisabled === 1 ? deactivePlans.push(value) : activePlans.push(value)
        })

        this.setState({ planList : activePlans, deadPlanList: deactivePlans }, ()=>{this.buildRecordDaysObj()})
    }

    filterNewPlan(planDetails, planType){
        let {planList, selectedPlanIndex} = this.state
        let allNames = []
        console.log(planDetails)
        planDetails.billingFrequency.map((freq)=>{
            allNames.push(freq.billingFrequency)
        })
        planDetails.allFrequencies = allNames

        if(planType === "new"){
            planList.push(planDetails)
        }
        else{
            planList[selectedPlanIndex] = planDetails
        }
        this.setState({planList : planList, selectedPlanIndex : 0},()=>{
            this.buildRecordDaysObj()
            this.toggleModal()
        })
    }

    filterDisablePlan(){
        let {planList, deadPlanList, selectedPlanIndex} = this.state
        let planDetails = planList[selectedPlanIndex]
        deadPlanList.push(planDetails)
        planList.splice(selectedPlanIndex, 1)
        this.setState({
            planList: planList,
            deadPlanList: deadPlanList,
            selectedPlanIndex: 0
        }, ()=>{
            this.props.changePreloader(false)
            this.toggleModal()            
        })
    }

    filterUpdatedPromo(newDetails){
        let {selectedPromoIndex, activePromotionList} = this.state

        activePromotionList[selectedPromoIndex] = newDetails
        this.setState({
            activePromotionList : activePromotionList
        })

    }

    extractRecordingDays(promoDetails){
        let {recordingDaysObject, planIdMap } = this.state
        let daySet = new Set()
        promoDetails["planIds"].map((value)=>{
            daySet.add(planIdMap[value["planId"]]) 
        })
        daySet = [...daySet]
        return daySet
    }

    showModalAddInit (){
        // this.props.getLnPlan()
        this.toggleModal()
    }

    selectAddUpdate(modalValue){
        // this.props.getLnPlan()
        this.setState({
            modalView : modalValue,
            viewType : 'add',
            alpha : 20,
        }, ()=>{this.props.getLnPlan()})
    }

    toggleModal = () => {
        console.log(`Modal details : ${this.state.modalView} & ${this.state.viewType}`)
        let stateObj = {
            showModal : !this.state.showModal
        }
        if(this.state.showModal){
            stateObj.viewType = "add"
        }
        this.setState(stateObj)
    }

    getPlanInfo(plan, index){
        let planId = plan.planId
        this.setState({ 
            selectedPlanIndex : index
        })
        this.props.getPlanDetails(planId)        
    }

    updatePlan(details, planId){
        let info = {
            data : details,
            planId : planId
        }
        this.props.editPlan(info)
    }

    disablePlan(planId){
        console.log("Entering Disable plan in index")
        let data = {
            planId: planId
        }
        this.props.changePreloader(true)
        this.props.disablePlan(data)
    }

    updatePromo(details, promoId){
        let data = {
            details : details,
            promoId : promoId
        }
        this.props.editPromo(data)
        this.props.changePreloader(true)
    }

    getPromoInfo(promo, index){
        console.log(promo)
        let details = {
            promoId : promo.promoCodeId
        }
        this.setState({
            selectedPromoIndex : index
        })
        this.props.getPromoDetails(details)
    }

    // ==== User based functions ====
    searchUserDetails(emailValue){
        let data = {
            email : emailValue
        }
        this.props.searchUser(data)
    }

    forwardSearchUser(userList, isFail=false){
        console.log("#### Printing from forward")
        console.log(userList)
        if(isFail){
            let dummyMsg = {emailId : "No users found", userId: "na"}
            this.promoComponentRef.current.updateEmailList([dummyMsg])
            return
        }
        this.promoComponentRef.current.updateEmailList(userList)
    }

    searchDeviceList(userId){
        let data ={
            userId : userId
        } 
        this.props.searchDevices(data)
    }

    forwardSearchDevices(deviceList){        
        this.promoComponentRef.current.updateDeviceList(deviceList)
    }

    

    // ==== Prop response methods ====
    addNewPlan(details){
        console.log(details)

        this.props.createPlan(details)
        this.props.changePreloader(true)
    }

    showPlanDetails(planDetails, closePanel=null){
        this.setState({
            viewType : "view",
            planInfo : planDetails,
            modalView : 'plan'
        }, ()=>{
            closePanel ? 
            this.toggleModal() 
            :
            this.props.getLnPlan()
        })
    }

    addNewPromo(details){
        this.props.createPromo(details)
        this.props.changePreloader(true)
    }

    showPromoDetails(promoDetails){
        // console.log(promoDetails)
        // console.log(this.state.planList)
        // return
        let promoDays = this.extractRecordingDays(promoDetails)
        promoDetails.promoDays = promoDays
        this.setState({
            viewType : 'view',
            modalView : 'promo',
            promoInfo : promoDetails
        }, ()=> {this.toggleModal()})
    }

    // ==== Check Methods ====
    checkGetAllPlans(){
        if(this.props.subscription.getAllPlansSuccess === true){
            console.log(this.props.subscription.allPlans)
            this.filterPlans(this.props.subscription.allPlans)
            this.props.getAllPlansInit()
        }else if(this.props.subscription.getAllPlansFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.subscription.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getAllPlansInit()
        }
    }

    checkGetAllPromo(){
        if(this.props.layout.isPreloader){
            if(this.props.subscription.getAllPromoSuccess === true){
                console.log(this.props.subscription.allPromo)
                this.setState({
                    activePromotionList : this.props.subscription.allPromo.activePromoCode,
                    expiredPromotionList : this.props.subscription.allPromo.expiredPromoCode
                })
                this.props.getAllPromoInit()
                this.props.changePreloader(false)
            }else if(this.props.subscription.getAllPromoFailure === true){
                this.props.changePreloader(false)
                toast.error(this.props.subscription.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getAllPromoInit()
            }
        } 
    }

    checkGetPlanFrequency(){
        if(this.props.subscription.getPlanFrequencySuccess === true){
            console.log(this.props.subscription.planFrequency)
            this.setState({ planFrequencies : this.props.subscription.planFrequency})
            this.props.getPlanFrequencyInit()
        }else if(this.props.subscription.getPlanFrequencyFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.subscription.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getPlanFrequencyInit()
        }
    }


    checkCreatePlan(){
        if(this.props.subscription.createPlanSuccess === true){
            console.log(this.props.subscription.newEditedPlan)
            this.filterNewPlan(this.props.subscription.newEditedPlan, "new")
            toast.success("Plan Created Successfully")
            this.props.changePreloader(false)
            this.props.createPlanInit()            
        }else if(this.props.subscription.createPlanFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.subscription.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.createPlanInit()
        }
    }

    
    checkGetPlanDetails(){
        if(this.props.subscription.getPlanDetailsSuccess === true){
            console.log(this.props.subscription.planDetails)
            // this.filterNewPlan(this.props.subscription.planDetails)
            this.showPlanDetails(this.props.subscription.planDetails)
            // this.toggleModal()
            this.props.getPlanDetailsInit()        
        }else if(this.props.subscription.getPlanDetailsFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.subscription.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getPlanDetailsInit()
        }
    }


    checkUpdatePlan(){
        if(this.props.subscription.editPlanSuccess === true){
            this.showPlanDetails(this.props.subscription.newEditedPlan, true)
            toast.success("Plan Updated successfully.")
            this.filterNewPlan(this.props.subscription.newEditedPlan, "update")
            this.props.editPlanInit()        
        }else if(this.props.subscription.editPlanFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.subscription.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.editPlanInit()        
        }
    }

    checkDisablePlan(){
        if(this.props.subscription.disablePlanSuccess === true){
            toast.success("Plan Disabled successfully.")
            this.filterDisablePlan()
            this.props.disablePlanInit()
            this.props.changePreloader(false)
        }else if(this.props.subscription.disablePlanFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.subscription.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.disablePlanInit()  
        }
    }




    checkSearchUser(){
        if(this.props.customer.searchUserSuccess === true){
            this.forwardSearchUser(this.props.customer.searchedUsers)
            this.props.searchUserInit()  
        }else if(this.props.customer.searchUserFailure === true){
            this.props.changePreloader(false)
            this.forwardSearchUser(null, true)
            // toast.error(this.props.customer.errorMessage, {
            //     position: "bottom-right",
            //     autoClose: 2000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            this.props.searchUserInit()      
        }
    }

    checkSearchDevices(){
        if(this.props.customer.searchDevicesSuccess === true){
            this.forwardSearchDevices(this.props.customer.searchedDevices)
            this.props.searchDevicesInit()
        }else if(this.props.customer.searchDevicesFailure === true){
            this.props.changePreloader(false)         
            this.forwardSearchDevices([])   
            toast.error(this.props.customer.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.searchDevicesInit()
        }
    }

    checkCreatePromo(){
        if(this.props.subscription.createPromoSuccess === true){
            this.props.changePreloader(false)    
            toast.success("Promo Code created successfully.")
            console.log(this.props.subscription.newEditedPromo)
            let {activePromotionList} = this.state
            activePromotionList.push(this.props.subscription.newEditedPromo)
            this.setState({activePromotionList : activePromotionList})
            this.toggleModal()
            this.props.createPromoInit()              
        }else if(this.props.subscription.createPromoFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.subscription.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.createPromoInit() 
        }
    }


    checkUpdatePromo(){
        if(this.props.subscription.editPromoSuccess === true){
            this.props.changePreloader(false)    
            toast.success("Promo Code Updated successfully.")

            console.log(this.props.subscription.newEditedPromo)
            this.filterUpdatedPromo(this.props.subscription.newEditedPromo)
            // let {activePromotionList} = this.state
            // activePromotionList.push(this.props.subscription.newEditedPromo)
            // this.setState({activePromotionList : activePromotionList})
            this.toggleModal()


            this.props.editPromoInit()             
        }else if(this.props.subscription.editPromoFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.subscription.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.editPromoInit()
        }
    }


    checkGetPromoDetails(){
        if(this.props.subscription.getPromoDetailsSuccess === true){
            this.props.changePreloader(false)    
            this.showPromoDetails(this.props.subscription.promoDetails)
            this.props.getPromoDetailsInit()          
        }else if(this.props.subscription.getPromoDetailsFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.subscription.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getPromoDetailsInit()          
        }
    }


    checkGetLnPlanDetails(){
        
        if(this.props.subscription.getLnPlanSuccess === true){
            console.log("Congratulations!!")
            // let lnPlanDetails = this.props.subscription.lnPlanDetails
            let validPlans = this.props.subscription.lnPlanDetails.filter(plan => plan.isValid === 1)
            this.setState({
                lnPlanList: validPlans
            },()=>{
                this.showModalAddInit()               
            })
            this.props.getLnPlanInit()
        }else if(this.props.subscription.getLnPlanFailure === true){
            toast.error(this.props.subscription.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getLnPlanInit()          
        }
    }


    render(){
        let { planList, activePromotionList, expiredPromotionList, expandExpiredPromo, modalView, showModal, viewType, planInfo, planModalKey, recordingDaysObject, promoInfo,
            deadPlanList, lnPlanList } = this.state


        let timeList=[
            {startTimeSec : 6620, endTimeSec : 8420},
            {startTimeSec : 11410, endTimeSec : 12600}
        ]
        // timeList = []

        let expiredPromoArr = []
        expandExpiredPromo ? expiredPromoArr = expiredPromotionList : expiredPromoArr = expiredPromotionList.slice(0,12) 


        this.checkGetAllPlans()
        this.checkGetAllPromo()
        this.checkGetPlanFrequency()
        this.checkCreatePlan()
        this.checkGetPlanDetails()
        this.checkUpdatePlan()
        this.checkSearchDevices()
        this.checkSearchUser()
        this.checkCreatePromo()
        this.checkGetPromoDetails()
        this.checkUpdatePromo()
        this.checkDisablePlan()
        this.checkGetLnPlanDetails()

        return (
            <React.Fragment>
                <div className="page-content subscription-page">

                    <div className="individual-block">
                        <div className="sectional-grid-level-1">
                            <div className="font-twenty"><span>Subscription Plans & Promotions</span></div>
                            <div className=" font-twenty" onClick={this.props.addNew}>
                                <div className="add-btn-holder justify-end">
                                    <div className="add-btn-grid">
                                        {/* <Button onClick={()=>{this.selectAddUpdate("plan")}}><i class="fas fa-plus"></i> &nbsp; <span>Add Plan</span></Button> */}
                                        <Button onClick={()=>{this.selectAddUpdate("plan")}}><i class="fas fa-plus"></i> &nbsp; <span>Add Plan</span></Button>
                                        <Button onClick={()=>{this.selectAddUpdate("promo")}}><i class="fas fa-plus"></i> &nbsp; <span>Add Promotion</span></Button>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="individual-block">
                        <div className="subscription-plan-list"><span>Current Subscription Plans</span></div>
                        <div className="card-grid-level-1">

                        {  
                            planList.length !== 0 ? 
                                planList.map((value, index) => {
                                    return(
                                        <div className="plan-card-container" onClick={()=>{this.getPlanInfo(value, index)}}>
                                            <div className="card-header"><span className="subscription-plan-name">{value.isTrialPlan === 1 ? "Trial Plan - " : ""} {`${value.planName} - ${value.recordingDays} Days`}</span></div>
                                            <div className="card-footer"><b>Validity</b> : <br></br>{value.allFrequencies.join(" | ")}</div>
                                        </div>
                                    )
                                }) : 

                                <div className="plan-card-container" onClick={()=>{this.props.viewScreen("planView")}}>
                                    <div className="card-header"><span>Trial Plan- 7 days</span></div>
                                    <div className="card-footer">Validity : <br></br> 30 Days</div>
                                </div>
                        }

                        </div>
                    </div>

                    {/* ==== Active Promo codes ==== */}
                    <div className="individual-block">
                    <div className="subscription-plan-list"><span>Current Active Promotions</span></div>
                        <div className="chip-grid-level-1">

                            {
                                activePromotionList.length !== 0 ? 
                                    activePromotionList.map((value, index)=> {
                                        return(
                                            <div className="promotion-tag active-tag select-hover" onClick={()=>{this.getPromoInfo(value, index)}}><span>{value.promoCodeName}</span></div>
                                        )
                                    }) : 
                                    <div className="promotion-tag active-tag"><span>Godrej20</span></div>
                            }
                            {/* <div className="promotion-tag active-tag" onClick={()=>{this.props.viewScreen('promoView')}}><span>Godrej20</span></div>*/}
                        </div>
                    </div>


                    {/* ==== Adding Deactivated plan list ==== */}
                    <div className="individual-block">
                        <div className="subscription-plan-list"><span>Disabled Subscription Plans</span></div>
                        <div className="card-grid-level-1">

                        {  
                            deadPlanList.length !== 0 ? 
                                deadPlanList.map((value, index) => {
                                    return(
                                        <div className="plan-card-container" onClick={()=>{this.getPlanInfo(value, index)}}>
                                            <div className="card-header"><span className="subscription-plan-name">{value.isTrialPlan === 1 ? "Trial Plan - " : ""} {`${value.planName} - ${value.recordingDays} Days`}</span></div>
                                            <div className="card-footer"><b>Validity</b> : <br></br>{value.allFrequencies.join(" | ")}</div>
                                        </div>
                                    )
                                }) : 

                                <div className="plan-card-container" onClick={()=>{this.props.viewScreen("planView")}}>
                                    <div className="card-header"><span>Trial Plan- 7 days</span></div>
                                    <div className="card-footer">Validity : <br></br> 30 Days</div>
                                </div>
                        }

                        </div>
                    </div>

                    {/* ==== Expired Promo codes. ==== */}
                    <div className="individual-block">
                        <div className="subscription-plan-list"><span>Expired Promotions</span> &nbsp; &nbsp; {expandExpiredPromo ? <span className="expanding-text select-hover" onClick={()=>{this.toggleExpandExpiredPromo()}}><u>Show Less</u></span> : ""}</div>
                        <div className="chip-grid-level-1">
                            {
                                expiredPromoArr.length !== 0 ?
                                    expiredPromoArr.map((value, index) => {
                                        return(
                                            <div className="promotion-tag inactive-tag"><span>{value.promoCodeName}</span></div>                
                                        )
                                    }) : 
                                    <div className="promotion-tag inactive-tag"><span>Godrej20</span></div>
                            }
                        </div>
                        {expandExpiredPromo ? "" : <div className="expanding-text select-hover" onClick={()=>{this.toggleExpandExpiredPromo()}}><span><u>Show All</u></span></div>}
                    </div>

                    

                </div>
                {/* <Scrubber callBackTime={(time)=>{this.callBackTime(time)}} recordedPatchArr={timeList} isDisabled={false} initialTime={null} ></Scrubber> */}
                <Modal
                    isOpen={showModal}
                    toggle={this.toggleModal}
                    className="modal-lg"
                >
                    {modalView === "plan" ? 
                        <AddUpdatePlan 
                            key={planModalKey} 
                            viewType={viewType} 
                            planInfo={planInfo} 
                            lnPlanInfo = {lnPlanList}
                            newPlanCallback={(details)=>{this.addNewPlan(details)}}                                 
                            updatePlanCallback={(details, planId)=>{this.updatePlan(details, planId)}}
                            cancel={this.toggleModal}
                            disablePlan={(planId)=>{this.disablePlan(planId)}}
                             ></AddUpdatePlan> : 
                        <AddUpdatePromo 
                            ref={this.promoComponentRef}
                            viewType={viewType}
                            promoInfo={promoInfo}
                            recordingDays={recordingDaysObject}
                            allPlanList={planList}
                            searchUser={(emailValue)=>{this.searchUserDetails(emailValue)}}
                            searchDevices={(userId)=>{this.searchDeviceList(userId)}}
                            cancel={this.toggleModal}  
                            newPromoCallback={(details)=>{this.addNewPromo(details)}}
                            updatePromoCallback={(details, promoId)=>{this.updatePromo(details, promoId)}}
                        ></AddUpdatePromo>}
                </Modal>
                <ToastContainer/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    subscription : state.Subscription,
    layout : state.Layout,
    customer : state.Customer
})

const mapDispatchToProps = dispatch => ({

    changePreloader: (flag) => dispatch(LayoutActions.changePreloader(flag)),

    getAllPlans: () => dispatch(SubscriptionActions.getAllPlans()),
    getAllPlansInit: () => dispatch(SubscriptionActions.getAllPlansInit()),

    getPlanDetails: (data) => dispatch(SubscriptionActions.getPlanDetails(data)),
    getPlanDetailsInit: () => dispatch(SubscriptionActions.getPlanDetailsInit()),

    getPlanFrequency: () => dispatch(SubscriptionActions.getPlanFrequency()),
    getPlanFrequencyInit: () => dispatch(SubscriptionActions.getPlanFrequencyInit()),

    createPlan: (data) => dispatch(SubscriptionActions.createPlan(data)),
    createPlanInit: () => dispatch(SubscriptionActions.createPlanInit()),

    editPlan: (data) => dispatch(SubscriptionActions.editPlan(data)),
    editPlanInit: () => dispatch(SubscriptionActions.editPlanInit()),

    disablePlan: (data) => dispatch(SubscriptionActions.disablePlan(data)),
    disablePlanInit: () => dispatch(SubscriptionActions.disablePlanInit()),

    getLnPlan: (data) => dispatch(SubscriptionActions.getLnPlan(data)),
    getLnPlanInit: (data) => dispatch(SubscriptionActions.getLnPlanInit(data)),


    getAllPromo: () => dispatch(SubscriptionActions.getAllPromo()),
    getAllPromoInit: () => dispatch(SubscriptionActions.getAllPromoInit()),

    createPromo: (data) => dispatch(SubscriptionActions.createPromo(data)),
    createPromoInit: () => dispatch(SubscriptionActions.createPromoInit()),

    getPromoDetails: (data) => dispatch(SubscriptionActions.getPromoDetails(data)),
    getPromoDetailsInit: () => dispatch(SubscriptionActions.getPromoDetailsInit()),

    editPromo: (data) => dispatch(SubscriptionActions.editPromo(data)),
    editPromoInit: () => dispatch(SubscriptionActions.editPromoInit()),

    searchUser: (data) => dispatch(CustomerActions.searchUser(data)),
    searchUserInit: () => dispatch(CustomerActions.searchUserInit()),

    searchDevices: (data) => dispatch(CustomerActions.searchDevices(data)),
    searchDevicesInit: () => dispatch(CustomerActions.searchDevicesInit()),


})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Subscription)
