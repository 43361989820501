import API from './interceptor';
import constants from '../common/constants'
import { disablePlan } from '../store/subscription/actions';
const urlDevicePrefix = constants.urlDevicePrefix
const urlPrefix = constants.urlPrefix

// ==== Services for Plans ====

export function getAllPlanService(){
    return API({
        method : 'GET',
        url : urlPrefix + 'plans'
    })
}

export function getPlanFrequencyService(){
    return API({
        method : 'GET',
        url : urlPrefix + 'plans/frequency'
    })
}

export function getPlanDetailsService(planId){
    return API({
        method : 'GET',
        url : urlPrefix + `plans/${planId}`
    })
}

export function createPlanService(data, isTrialReset){
    let params = {
        isTrialReset: isTrialReset
    }
    return API({
        method : 'POST',
        url : urlPrefix + 'plans',
        params: params,
        data : JSON.stringify(data)
    })
}

export function editPlanService(data, isTrialReset, planId){
    let params= {
        isTrialReset: isTrialReset
    }
    return API({
        method : 'PUT',
        url : urlPrefix + `plans/${planId}`,
        params: params,
        data : JSON.stringify(data)
    })
}

export function disablePlanService(planId){
    return API({
        method: "PUT",
        url: urlPrefix + `plans/${planId}/disable`
    })
}

export function fetchLnPlanListService(){
    return API({
        method: "GET",
        url: urlPrefix + `ln/itemCodes`
    })
}



// ==== Services for Promo ====

export function getAllPromoService(){
    return API({
        method : 'GET',
        url : urlPrefix + 'promocodes'
    })
}

export function getPromoDetailsService(promoId){
    return API({
        method : 'GET',
        url : urlPrefix + `promocodes/${promoId}`
    })
}

export function createPromoService(data, params){
    return API({
        method : 'POST',
        url : urlPrefix + 'promocodes',
        data : JSON.stringify(data),
        params : params,
    })
}

export function updatePromoService(data, params, planId){
    return API({
        method :'PUT',
        url : urlPrefix + `promocodes/${planId}`,
        data : JSON.stringify(data),
        params : params
    })
}

