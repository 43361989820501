import { all } from 'redux-saga/effects';

//public
// import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import dashboardSaga from './dashboard/saga';
import LayoutSaga from './layout/saga';
import GetDatabaseSaga from './database/get/saga';
import VendorSaga from './vendor/saga';
import MasterSaga from './master/saga';
import ProductCategorySaga from './productCategory/saga'
import ProductSaga from './product/saga';
import BatchSaga from './batch/saga'
import CustomerSaga from './customer/saga'
import DevicesSaga from './devices/saga'
import FirmwareSaga from './firmware/saga'
import SubscriptionSaga from './subscription/saga'
import ManageAccessSaga from './manageAccess/saga'
import LNDashboardSaga from './lnDashboard/saga'



export default function* rootSaga() {
    yield all([
        //public
        // AccountSaga(),
        AuthSaga(),
        dashboardSaga(),
        LayoutSaga(),
        GetDatabaseSaga(),
        VendorSaga(),
        MasterSaga(),
        ProductCategorySaga(),
        ProductSaga(),
        BatchSaga(),
        CustomerSaga(),
        DevicesSaga(),
        FirmwareSaga(),
        SubscriptionSaga(),
        ManageAccessSaga(),
        LNDashboardSaga()
    ])
}