import {

    GET_NEW_ACTIVATIONS,
    GET_NEW_ACTIVATIONS_SUCCESS,
    GET_NEW_ACTIVATIONS_FAILURE,
    GET_NEW_ACTIVATIONS_INIT,

    GET_ONLINE_DEVICES,
    GET_ONLINE_DEVICES_SUCCESS,
    GET_ONLINE_DEVICES_FAILURE,
    GET_ONLINE_DEVICES_INIT,

    GET_TREND_GRAPH,
    GET_TREND_GRAPH_SUCCESS,
    GET_TREND_GRAPH_FAILURE,
    GET_TREND_GRAPH_INIT,

    GET_STORAGE_STATUS,
    GET_STORAGE_STATUS_SUCCESS,
    GET_STORAGE_STATUS_FAILURE,
    GET_STORAGE_STATUS_INIT,

    GET_DEVICES_INFO,
    GET_DEVICES_INFO_SUCCESS,
    GET_DEVICES_INFO_FAILURE,
    GET_DEVICES_INFO_INIT,

    DOWNLOAD_DEVICE_INFO_REPORT,
    DOWNLOAD_DEVICE_INFO_REPORT_SUCCESS,
    DOWNLOAD_DEVICE_INFO_REPORT_FAILURE,
    DOWNLOAD_DEVICE_INFO_REPORT_INIT

} from './actionTypes'

const initialState = {
    totalNewActivations : {},
    totalOnlineDevices : {},
    trendGraphDetails : [],
    deviceInfo : {},
    deviceInfoReport : null,
    errorMessage : '',
    getNewActivationsSuccess : false,
    getNewActivationsFailure : false,
    getOnlineDevicesSuccess : false,
    getOnlineDevicesFailure : false,
    getTrendGraphSuccess : false,
    getTrendGraphFailure : false,
    getStorageStatusSuccess : false,
    getStorageStatusFailure : false,
    getDeviceInfoSuccess : false,
    getDeviceInfoFailure : false,
    downloadDeviceInfoReportSuccess : false,
    downloadDeviceInfoReportFailure : false,
}

const Devices = (state = initialState, action) => {
    switch(action.type){

        // === Cases for Get new Activation ====
        case GET_NEW_ACTIVATIONS : 
            return {
                ...state,
                getNewActivationsSuccess : false,
                getNewActivationsFailure : false,
            }
        case GET_NEW_ACTIVATIONS_SUCCESS : 
            return {
                ...state,
                totalNewActivations : action.payload,
                getNewActivationsSuccess : true,
                getNewActivationsFailure : false,
            }
        case GET_NEW_ACTIVATIONS_FAILURE : 
            return {
                ...state,
                getNewActivationsSuccess : false,
                getNewActivationsFailure : true,
                errorMessage : action.payload
            }
        case GET_NEW_ACTIVATIONS_INIT : 
            return {
                ...state,
                totalNewActivations : {},
                getNewActivationsSuccess : false,
                getNewActivationsFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Get Online Devices ====
        case GET_ONLINE_DEVICES : 
            return {
                ...state,
                getOnlineDevicesSuccess : false,
                getOnlineDevicesFailure : false
            }
        case GET_ONLINE_DEVICES_SUCCESS : 
            return {
                ...state,
                totalOnlineDevices : action.payload,
                getOnlineDevicesSuccess : true,
                getOnlineDevicesFailure : false
            }
        case GET_ONLINE_DEVICES_FAILURE : 
            return {
                ...state,
                getOnlineDevicesSuccess : false,
                getOnlineDevicesFailure : true,
                errorMessage : action.payload
            }
        case GET_ONLINE_DEVICES_INIT : 
            return {
                ...state,
                totalOnlineDevices : {},
                getOnlineDevicesSuccess : false,
                getOnlineDevicesFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Get Trend Graph ====
        case GET_TREND_GRAPH : 
            return {
                ...state,
                getTrendGraphSuccess : false,
                getTrendGraphFailure : false
            }
        case GET_TREND_GRAPH_SUCCESS : 
            return {
                ...state,
                trendGraphDetails : action.payload,
                getTrendGraphSuccess : true,
                getTrendGraphFailure : false
            }
        case GET_TREND_GRAPH_FAILURE : 
            return {
                ...state,
                getTrendGraphSuccess : false,
                getTrendGraphFailure : true,
                errorMessage : action.payload
            }
        case GET_TREND_GRAPH_INIT : 
            return {
                ...state,
                trendGraphDetails : [],
                getTrendGraphSuccess : false,
                getTrendGraphFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Get Device Info ====
        case GET_DEVICES_INFO : 
            return {
                ...state,
                getDeviceInfoSuccess : false,
                getDeviceInfoFailure : false,
            }
        case GET_DEVICES_INFO_SUCCESS : 
            return {
                ...state,
                deviceInfo : action.payload,
                getDeviceInfoSuccess : true,
                getDeviceInfoFailure : false,
            }
        case GET_DEVICES_INFO_FAILURE : 
            return {
                ...state,
                getDeviceInfoSuccess : false,
                getDeviceInfoFailure : true,
                errorMessage : action.payload
            }
        case GET_DEVICES_INFO_INIT : 
            return {
                ...state,
                deviceInfo : {},
                getDeviceInfoSuccess : false,
                getDeviceInfoFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Download Device Info Report ====
        case DOWNLOAD_DEVICE_INFO_REPORT :
            return {
                ...state,
                downloadDeviceInfoReportSuccess : false,
                downloadDeviceInfoReportFailure : false,
            }
        case DOWNLOAD_DEVICE_INFO_REPORT_SUCCESS :
            return {
                ...state,
                deviceInfoReport : action.payload,
                downloadDeviceInfoReportSuccess : true,
                downloadDeviceInfoReportFailure : false,
            }
        case DOWNLOAD_DEVICE_INFO_REPORT_FAILURE :
            return {
                ...state,
                downloadDeviceInfoReportSuccess : false,
                downloadDeviceInfoReportFailure : true,
                errorMessage : action.payload
            }
        case DOWNLOAD_DEVICE_INFO_REPORT_INIT :
            return {
                ...state,
                deviceInfoReport : null,
                downloadDeviceInfoReportSuccess : false,
                downloadDeviceInfoReportFailure : false,
                errorMessage : ''
            }

        default : 
            return {...state}
    }
}


export default Devices;