import API from './interceptor';
import constants from '../common/constants'
const urlDevicePrefix = constants.urlDevicePrefix
const urlPrefix = constants.urlPrefix

export function fetchPendingTransactions(params) {
    return API({
        method: 'GET',
        url: urlPrefix + 'ln/transactions',
        params
    });
}

export function downloadPendingTransactionsList(params) {
    return API({
        method: 'GET',
        url: urlPrefix + '/transactions/download',
        params
    });
}

export function fetchBilldeskTransactions(params) {
    return API({
        method: 'GET',
        url: urlPrefix + 'billdesk/transaction',
        params
    });
}

export function fetchTransactionDetails(transactionId) {
    return API({
        method: 'GET',
        url: urlPrefix + `transaction/${transactionId}/detail`
    });
}

export function fetchPaymentDetails(transactionId) {
    return API({
        method: 'GET',
        url: urlPrefix + `transaction/${transactionId}/payment/detail`
    });
}

export function downloadTransactionListExcel(params) {
    let paramData = {...params };
    delete paramData['fromDate'];
    delete paramData['toDate'];
    delete paramData['value'];

    let data = {
        fromDate: params.fromDate,
        toDate: params.toDate,
        value: params.value
    };
    console.log('Check Download params and data:', params, data);
    return API({
        method: 'POST',
        url: urlPrefix + `transactions/download`,
        params: paramData,
        data: data,
        responseType: 'arraybuffer'
    });
}

export function fetchBook(params) {
    return API({
        method: 'GET',
        url: urlPrefix + `compare/book`,
        params
    });
}

export function downloadBookFile(params) {
    let paramData = {...params };
    delete paramData['fromDate'];
    delete paramData['toDate'];

    let data = {
        fromDate: params.fromDate,
        toDate: params.toDate
    };
    console.log('Check Download params and data:', params, data);
    return API({
        method: 'POST',
        url: urlPrefix + `compare/book/download`,
        params: paramData,
        data: data,
        responseType: 'arraybuffer'
    });
}

export function downloadPaymentDetailsFile(transactionId) {

    return API({
        method: 'POST',
        url: urlPrefix + `transaction/${transactionId}/payment/detail`,
        responseType: 'arraybuffer'
    });
}

export function downloadBilldeskTransactionsFile(params) {

    return API({
        method: 'POST',
        url: urlPrefix + `billdesk/transaction/download`,
        responseType: 'arraybuffer',
        data: params
    });
}