import API from './interceptor';
import constants from '../common/constants'
const urlDevicePrefix = constants.urlDevicePrefix
const urlPrefix = constants.urlPrefix


export function addProduct(payload, categoryID) {
    console.log(payload);
    return API({
        method: 'POST',
        url: urlDevicePrefix+`categories/${categoryID}/products`,
        data: payload,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function searchProduct(data) {
    return API({
        method: 'GET',
        url: urlDevicePrefix+`products/search`,
        params: data
    });
}
 
export function searchProductByCategory(categoryID) {
    return API({
        method: 'GET',
        url: urlDevicePrefix+`categories/${categoryID}/products`,
        // params: { vendorId: 1}
    });
}

export function deleteProduct(categoryID, productID) {
    return API({
        method: 'DELETE',
        url: urlDevicePrefix+`categories/${categoryID}/products/${productID}`,
    });
}

export function editProduct(categoryID, productID, data) {
    return API({
        method: 'PUT',
        url: urlDevicePrefix+`categories/${categoryID}/products/${productID}`,
        data: data
    });
}

export function fetchProductTypes() {
    return API({
        method: 'GET',
        url: urlDevicePrefix+'products/types'
    });
}