export const CREATE_PRODUCT = "CREATE_PRODUCT"
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS"
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE"
export const CREATE_PRODUCT_INIT = "CREATE_PRODUCT_INIT"

export const EDIT_PRODUCT = "EDIT_PRODUCT"
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS"
export const EDIT_PRODUCT_FAILURE = "EDIT_PRODUCT_FAILURE"
export const EDIT_PRODUCT_INIT = "EDIT_PRODUCT_INIT"

export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE"
export const DELETE_PRODUCT_INIT = "DELETE_PRODUCT_INIT"

export const GET_PRODUCT = "GET_PRODUCT"
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS"
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE"
export const GET_PRODUCT_INIT = "GET_PRODUCT_INIT"

export const GET_PRODUCT_TYPE = "GET_PRODUCT_TYPE"
export const GET_PRODUCT_TYPE_SUCCESS = "GET_PRODUCT_TYPE_SUCCESS"
export const GET_PRODUCT_TYPE_FAILURE = "GET_PRODUCT_TYPE_FAILURE"
export const GET_PRODUCT_TYPE_INIT = "GET_PRODUCT_TYPE_INIT"

export const GET_PRODUCT_BY_CATEGORY = "GET_PRODUCT_BY_CATEGORY"
export const GET_PRODUCT_BY_CATEGORY_SUCCESS = "GET_PRODUCT_BY_CATEGORY_SUCCESS"
export const GET_PRODUCT_BY_CATEGORY_FAILURE = "GET_PRODUCT_BY_CATEGORY_FAILURE"
export const GET_PRODUCT_BY_CATEGORY_INIT = "GET_PRODUCT_BY_CATEGORY_INIT"


