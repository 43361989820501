import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';
import { loginAPI } from "../../../services/userService";


function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(loginAPI, user);
        yield put(loginSuccess(response));
        console.log(response);
        history.push('/dashboard');
    } catch (error) {
        yield put(apiError(error.message));
    }
}



export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}


function* authSaga() {
    yield all([
        fork(watchUserLogin),
        // fork(watchUserLogout),
    ]);
}

export default authSaga;