import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAndDownloadBlobFile } from '../helpers/downloadHelper';
import { toast } from 'react-toastify';
import { downloadInit } from '../store/lnDashboard/actions';

export const useCheckDownloadStatus = () => {
    const dispatch = useDispatch();
    const downloadSuccessState = useSelector(state => state.LNDashboard.downloadSuccessful);
    const downloadFailureState = useSelector(state => state.LNDashboard.downloadFailed);
    const excelArrayBufferData = useSelector(state => state.LNDashboard.downloadFile);

    useEffect(() => {
        if (downloadSuccessState === true || downloadFailureState === true) {
            checkDownloadStatus();
        }
    }, [downloadSuccessState, downloadFailureState]);

    function checkDownloadStatus() {
        if (downloadSuccessState === true) {
            console.log('checking download status', downloadSuccessState);
            if (excelArrayBufferData !== null) {
                createAndDownloadBlobFile(excelArrayBufferData, 'Transactions')
                toast.success("Report Downloaded")
                dispatch(downloadInit())
            }
        } else if (downloadFailureState === true) {
            console.log('checking download status', downloadFailureState);
            toast.error('Failed to download', {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(downloadInit());
        }
    }
}