import React, { Component, Fragment } from 'react';
import { Tabs, Tab, TabList } from 'react-web-tabs';

// Components
import Overview from './overview';
import Finance from './finance';
import Billdesk from './billdesk';

class LnReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'overview',
    };
  }
  switchTabs(tabName) {
    this.setState({
      selectedTab: tabName,
    });
  }

  render() {
    let { selectedTab } = this.state;
    return (
      <Fragment>
        <div className='page-content overview-page'>
          <div>
            <Tabs
              defaultTab='overview'
              onChange={(tabName) => {
                this.switchTabs(tabName);
              }}>
              <TabList className='custom-tab-button'>
                <Tab tabFor='overview'>Overview</Tab>
                <Tab tabFor='finance'>
                  Finance Dashboard
                </Tab>
                <Tab tabFor='billdesk'>
                  Billdesk Dashboard
                </Tab>
              </TabList>
            </Tabs>
          </div>
          {selectedTab === 'overview' ? (
            <Overview></Overview>
          ) : selectedTab === 'finance' ? (
            <Finance></Finance>
          ) : (
            <Billdesk></Billdesk>
          )}
        </div>
      </Fragment>
    );
  }
}

export default LnReport;
