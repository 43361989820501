export const CREATE_PRODUCT_CATEGORY = "CREATE_PRODUCT_CATEGORY";
export const CREATE_PRODUCT_CATEGORY_SUCCESS = "CREATE_PRODUCT_CATEGORY_SUCCESS"
export const CREATE_PRODUCT_CATEGORY_FAILURE = "CREATE_PRODUCT_CATEGORY_FAILURE" 
export const CREATE_PRODUCT_CATEGORY_INIT = "CREATE_PRODUCT_CATEGORY_INIT"

export const EDIT_PRODUCT_CATEGORY = "EDIT_PRODUCT_CATEGORY"
export const EDIT_PRODUCT_CATEGORY_SUCCESS = "EDIT_PRODUCT_CATEGORY_SUCCESS"
export const EDIT_PRODUCT_CATEGORY_FAILURE = "EDIT_PRODUCT_CATEGORY_FAILURE"
export const EDIT_PRODUCT_CATEGORY_INIT = "EDIT_PRODUCT_CATEGORY_INIT"

export const DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY"
export const DELETE_PRODUCT_CATEGORY_SUCCESS = "DELETE_PRODUCT_CATEGORY_SUCCESS"
export const DELETE_PRODUCT_CATEGORY_FAILURE = "DELETE_PRODUCT_CATEGORY_FAILURE"
export const DELETE_PRODUCT_CATEGORY_INIT = "DELETE_PRODUCT_CATEGORY_INIT"

export const GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY"
export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_SUCCESS"
export const GET_PRODUCT_CATEGORY_FAILURE = "GET_PRODUCT_CATEGORY_FAILURE"
export const GET_PRODUCT_CATEGORY_INIT = "GET_PRODUCT_CATEGORY_INIT"

export const GET_PRODUCT_CATEGORY_BY_NAME = "GET_PRODUCT_CATEGORY_BY_NAME"
export const GET_PRODUCT_CATEGORY_BY_NAME_SUCCESS = "GET_PRODUCT_CATEGORY_BY_NAME_SUCCESS"
export const GET_PRODUCT_CATEGORY_BY_NAME_FAILURE = "GET_PRODUCT_CATEGORY_BY_NAME_FAILURE"
export const GET_PRODUCT_CATEGORY_BY_NAME_INIT = "GET_PRODUCT_CATEGORY_BY_NAME_INIT"