import {
    GET_PENDING_TRANSACTIONS,
    GET_PENDING_TRANSACTIONS_SUCCESS,
    GET_PENDING_TRANSACTIONS_FAILURE,
    CLEAR_PENDING_TRANSACTIONS,
    GET_PENDING_TRANSACTIONS_EXCEL,
    GET_PENDING_TRANSACTIONS_EXCEL_SUCCESS,
    GET_PENDING_TRANSACTIONS_EXCEL_FAILURE,
    GET_PENDING_TRANSACTIONS_EXCEL_INIT,
    GET_TRANSACTION_DETAILS,
    GET_TRANSACTION_DETAILS_FAILURE,
    GET_TRANSACTION_DETAILS_SUCCESS,
    GET_PAYMENT_DETAILS,
    GET_PAYMENT_DETAILS_SUCCESS,
    GET_PAYMENT_DETAILS_FAILURE,
    GET_BOOK,
    GET_BOOK_SUCCESS,
    GET_BOOK_FAILURE,
    DOWNLOAD_INIT,
    DOWNLOAD_BOOK,
    DOWNLOAD_BOOK_SUCCESS,
    DOWNLOAD_BOOK_FAILURE,
    DOWNLOAD_PAYMENT_DETAILS,
    DOWNLOAD_PAYMENT_DETAILS_SUCCESS,
    DOWNLOAD_PAYMENT_DETAILS_FAILURE,
    GET_BILLDESK_TRANSACTIONS,
    GET_BILLDESK_TRANSACTIONS_SUCCESS,
    GET_BILLDESK_TRANSACTIONS_FAILURE,
    GET_BILLDESK_TRANSACTIONS_INIT,
    DOWNLOAD_BILLDESK_TRANSACTIONS,
    DOWNLOAD_BILLDESK_TRANSACTIONS_SUCCESS,
    DOWNLOAD_BILLDESK_TRANSACTIONS_FAILURE,
    CLEAR_ERROR
} from './actionTypes';

export const getPendingTransactions = (params) => {
    return {
        type: GET_PENDING_TRANSACTIONS,
        payload: params
    }
}

export const getPendingTransactionsSuccess = (params) => {
    return {
        type: GET_PENDING_TRANSACTIONS_SUCCESS,
        payload: params
    }
}

export const getPendingTransactionsFailure = (params) => {
    return {
        type: GET_PENDING_TRANSACTIONS_FAILURE,
        payload: params
    }
}

export const getPendingTransactionsExcel = (params) => {
    return {
        type: GET_PENDING_TRANSACTIONS_EXCEL,
        payload: params
    }
}

export const getPendingTransactionsExcelInit = () => {
    return {
        type: GET_PENDING_TRANSACTIONS_EXCEL_INIT
    }
}

export const getPendingTransactionsExcelSuccess = (params) => {
    return {
        type: GET_PENDING_TRANSACTIONS_EXCEL_SUCCESS,
        payload: params
    }
}

export const getPendingTransactionsExcelFailure = () => {
    return {
        type: GET_PENDING_TRANSACTIONS_EXCEL_FAILURE
    }
}

export const clearPendingTransactions = () => {
    return {
        type: CLEAR_PENDING_TRANSACTIONS
    }
}

export const getTransactionDetails = (params) => {
    return {
        type: GET_TRANSACTION_DETAILS,
        payload: params
    }
}

export const getTransactionDetailsSuccess = (params) => {
    return {
        type: GET_TRANSACTION_DETAILS_SUCCESS,
        payload: params
    }
}

export const getTransactionDetailsFailure = (params) => {
    return {
        type: GET_TRANSACTION_DETAILS_FAILURE,
        payload: params
    }
}

export const getPaymentDetails = (params) => {
    return {
        type: GET_PAYMENT_DETAILS,
        payload: params
    }
}

export const getPaymentDetailsSuccess = (params) => {
    return {
        type: GET_PAYMENT_DETAILS_SUCCESS,
        payload: params
    }
}

export const getPaymentDetailsFailure = (params) => {
    return {
        type: GET_PAYMENT_DETAILS_FAILURE,
        payload: params
    }
}

export const getBook = (params) => {
    return {
        type: GET_BOOK,
        payload: params
    }
}

export const getBookSuccess = (params) => {
    return {
        type: GET_BOOK_SUCCESS,
        payload: params
    }
}

export const getBookFailure = (params) => {
    return {
        type: GET_BOOK_FAILURE,
        payload: params
    }
}

export const downloadInit = (params) => {
    return {
        type: DOWNLOAD_INIT,
        payload: params
    }
}


export const downloadBook = (params) => {
    return {
        type: DOWNLOAD_BOOK,
        payload: params
    }
}

export const downloadBookSuccess = (params) => {
    return {
        type: DOWNLOAD_BOOK_SUCCESS,
        payload: params
    }
}

export const downloadBookFailure = (params) => {
    return {
        type: DOWNLOAD_BOOK_FAILURE,
        payload: params
    }
}

export const downloadPaymentDetails = (params) => {
    return {
        type: DOWNLOAD_PAYMENT_DETAILS,
        payload: params
    }
}

export const downloadPaymentDetailsSuccess = (params) => {
    return {
        type: DOWNLOAD_PAYMENT_DETAILS_SUCCESS,
        payload: params
    }
}

export const downloadPaymentDetailsFailure = (params) => {
    return {
        type: DOWNLOAD_PAYMENT_DETAILS_FAILURE,
        payload: params
    }
}

export const getBilldeskTransactions = (params) => {
    return {
        type: GET_BILLDESK_TRANSACTIONS,
        payload: params
    }
}

export const getBilldeskTransactionSuccess = (params) => {
    return {
        type: GET_BILLDESK_TRANSACTIONS_SUCCESS,
        payload: params
    }
}

export const getBilldeskTransactionFailure = (params) => {
    return {
        type: GET_BILLDESK_TRANSACTIONS_FAILURE,
        payload: params
    }
}

export const getBilldeskTransactionInit = (params) => {
    return {
        type: GET_BILLDESK_TRANSACTIONS_INIT,
        payload: params
    }
}

export const downloadBilldeskTransactions = (params) => {
    return {
        type: DOWNLOAD_BILLDESK_TRANSACTIONS,
        payload: params
    }
}

export const downloadBilldeskTransactionsSuccess = (params) => {
    return {
        type: DOWNLOAD_BILLDESK_TRANSACTIONS_SUCCESS,
        payload: params
    }
}

export const downloadBilldeskTransactionsFailure = (params) => {
    return {
        type: DOWNLOAD_BILLDESK_TRANSACTIONS_FAILURE,
        payload: params
    }
}

export const clearError = () => {
    return {
        type: CLEAR_ERROR
    }
}