export const GET_NEW_ACTIVATIONS = "GET_NEW_ACTIVATIONS"
export const GET_NEW_ACTIVATIONS_SUCCESS = "GET_NEW_ACTIVATIONS_SUCCESS"
export const GET_NEW_ACTIVATIONS_FAILURE = "GET_NEW_ACTIVATIONS_FAILURE"
export const GET_NEW_ACTIVATIONS_INIT = "GET_NEW_ACTIVATIONS_INIT"

export const GET_ONLINE_DEVICES = "GET_ONLINE_DEVICES"
export const GET_ONLINE_DEVICES_SUCCESS = "GET_ONLINE_DEVICES_SUCCESS"
export const GET_ONLINE_DEVICES_FAILURE = "GET_ONLINE_DEVICES_FAILURE"
export const GET_ONLINE_DEVICES_INIT = "GET_ONLINE_DEVICES_INIT"

export const GET_TREND_GRAPH = "GET_TREND_GRAPH"
export const GET_TREND_GRAPH_SUCCESS = "GET_TREND_GRAPH_SUCCESS"
export const GET_TREND_GRAPH_FAILURE = "GET_TREND_GRAPH_FAILURE"
export const GET_TREND_GRAPH_INIT = "GET_TREND_GRAPH_INIT"

export const GET_STORAGE_STATUS = "GET_STORAGE_STATUS"
export const GET_STORAGE_STATUS_SUCCESS = "GET_STORAGE_STATUS_SUCCESS"
export const GET_STORAGE_STATUS_FAILURE = "GET_STORAGE_STATUS_FAILURE"
export const GET_STORAGE_STATUS_INIT = "GET_STORAGE_STATUS_INIT"

export const GET_DEVICES_INFO = "GET_DEVICES_INFO"
export const GET_DEVICES_INFO_SUCCESS = "GET_DEVICES_INFO_SUCCESS"
export const GET_DEVICES_INFO_FAILURE = "GET_DEVICES_INFO_FAILURE"
export const GET_DEVICES_INFO_INIT = "GET_DEVICES_INFO_INIT"

export const DOWNLOAD_DEVICE_INFO_REPORT = "DOWNLOAD_DEVICE_INFO_REPORT"
export const DOWNLOAD_DEVICE_INFO_REPORT_SUCCESS = "DOWNLOAD_DEVICE_INFO_REPORT_SUCCESS"
export const DOWNLOAD_DEVICE_INFO_REPORT_FAILURE = "DOWNLOAD_DEVICE_INFO_REPORT_FAILURE"
export const DOWNLOAD_DEVICE_INFO_REPORT_INIT = "DOWNLOAD_DEVICE_INFO_REPORT_INIT"
