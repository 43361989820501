import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { convertUTCToTimezone } from '../../helpers/utcToIstConverter';
import { formatDate } from '../../helpers/formatDateHelper';

// SVGs
import Next from '../../assets/images/angle-right-solid.svg';
import NextMore from '../../assets/images/angle-double-right-solid.svg';
import PreviousMore from '../../assets/images/angle-double-left-solid.svg';
import Previous from '../../assets/images/angle-left-solid.svg';

const Table = (props) => {
  const dispatch = useDispatch();
  const pendingTransactions = useSelector(state => state.LNDashboard.pendingTransactions);
  const transactionIds = useSelector(state => state.LNDashboard.pendingTransactionsIds);

  const billdeskTransactions = useSelector(state => state.LNDashboard.billdeskTransactions);
  const billdeskTransactionIds = useSelector(state => state.LNDashboard.billdeskTransactionsIds);


  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onShowDetails = (transactionId) => {
    if (props.pageType == 'billdesk') return
    props.onSelectTransaction(transactionId);
    scrollTop();
  };


  const renderLNTable = () => {
    if (props.pageType == 'ln') {
      let prevRecords = props.page == 1 ? 0 : (props.page - 1) * 10;
      return (<div className='table-body'>
        {
          transactionIds !== null && transactionIds.length > 0 ? transactionIds.map((transactionId, i) => {

            return (
              <div className="table-row-section">
                <div
                  className='custom-table-body extra table-data-style'
                  onClick={() => { onShowDetails(transactionId) }}>
                  <div className='table-data'>
                    {i + 1 + prevRecords}
                    {/* <div className={`round`}></div> */}
                  </div>

                  {/* <div className='table-data'>{pendingTransactions[transactionId].serialNo}</div> */}
                  {/* <div className='table-data'>{moment(pendingTransactions[transactionId].transactionDate).format('DD/MM/YYYY')}</div> */}
                  <div className='table-data'>{convertUTCToTimezone(pendingTransactions[transactionId].transactionDate)}</div>
                  <div className='table-data'>{transactionId}</div>
                  <div className='table-data'>{pendingTransactions[transactionId].typeOfTransaction}</div>
                  <div className='table-data'>{pendingTransactions[transactionId].status}</div>
                  <div className='table-data'>Rs.{pendingTransactions[transactionId].amount}</div>
                  {/* <div className='table-data'>3</div> */}
                </div>
                {/* <hr /> */}
              </div>
            )
          })
            : transactionIds !== null ? <p className="centered-p">No Data Found</p> : <p className="centered-p"></p>
        }

        {
          transactionIds !== null && transactionIds.length > 0 ? (
            <div className='bottom'>
              <button onClick={() => { props.onDownload(true) }}>Download as Excel</button>
              <div className='pages'>
                <span className={`${props.page == 1 ? 'not-allowed white-overlay' : ''}`} onClick={() => { props.onPageChange('prev') }}>
                  <img src={Previous} />
                </span>
                <div className='seperator'></div>
                <span>{props.page}</span>
                <div className='seperator'></div>
                <span className={`${props.page == props.totalPages ? 'not-allowed white-overlay' : ''}`} onClick={() => { props.onPageChange('next') }}>
                  <img src={Next} />
                </span>

              </div>
            </div>
          ) : null
        }

      </div>)
    }
  }

  const renderColor = (status) => {
    switch (status) {
      case 'Pending/Abandoned':
        return 'average-orange';
      case 'Success':
        return 'free-green';
      case 'Failure':
        return 'danger-red';
    }
  }

  const renderBilldeskTable = () => {
    if (props.pageType == 'billdesk') {
      let prevRecords = props.page == 1 ? 0 : (props.page - 1) * 10;
      return (<div className='table-body'>
        {
          billdeskTransactionIds !== null && billdeskTransactionIds.length > 0 ? billdeskTransactionIds.map((transactionId, i) => {

            // if (i == 0) {
            let dateString = billdeskTransactions[transactionId].transactionDate.toLowerCase() !== 'not initiated' ? formatDate(billdeskTransactions[transactionId].transactionDate) : '--';
            // let utcToIstConvertedDate = convertUTCToTimezone(billdeskTransactions[transactionId].transactionDate)
            return (
              <div className="table-row-section">
                <div
                  className='custom-table-body table-data-style'
                  onClick={() => { onShowDetails(transactionId) }}>
                  <div className='table-data'>
                    {i + 1 + prevRecords}<div className={`round ${renderColor(billdeskTransactions[transactionId].status)}`}></div>
                  </div>

                  {/* <div className='table-data'>{pendingTransactions[transactionId].serialNo}</div> */}
                  <div className='table-data'>{dateString === '--' ? '--' : convertUTCToTimezone(dateString)}</div>
                  <div className='table-data'>{transactionId}</div>
                  {/* <div className='table-data'>{billdeskTransactions[transactionId].typeOfTransaction}</div> */}
                  <div className='table-data'>{billdeskTransactions[transactionId].status}</div>
                  <div className='table-data'>Rs.{billdeskTransactions[transactionId].amount}</div>
                  {/* <div className='table-data'>3</div> */}
                </div>
                {/* <hr /> */}
              </div>
            )
            // }
          }) : billdeskTransactionIds !== null ? <p className="centered-p">No Data Found</p> : <p className="centered-p"></p>
        }

        {
          billdeskTransactionIds !== null && billdeskTransactionIds.length > 0 ? (
            <div className='bottom'>
              <button onClick={() => { props.onDownload(true) }}>Download as Excel</button>
              <div className='pages'>
                <span className={`${props.page == 1 ? 'not-allowed white-overlay' : ''}`} onClick={() => { props.onPageChange('prev') }}>
                  <img src={Previous} />
                </span>
                <div className='seperator'></div>
                <span>{props.page}</span>
                <div className='seperator'></div>
                <span className={`${props.page == props.totalPages ? 'not-allowed white-overlay' : ''}`} onClick={() => { props.onPageChange('next') }}>
                  <img src={Next} />
                </span>

              </div>
            </div>
          ) : null
        }

      </div>)
    }
  }

  return (
    <Fragment>
      <table className='table-section'>
        <div className={`${props.pageType == 'ln' ? 'custom-table-head extra' : 'custom-table-head'}`}>
          <div>Sr. No</div>
          {/* <div className='table-heading'>
            Device Serial No.
          </div> */}
          <div>
            Transaction Date
          </div>
          <div>
            Transaction ID
          </div>
          {
            props.pageType == 'ln' ? (
              <div style={{ paddingRight: '20px' }}>
                Type of transaction
              </div>
            ) : null
          }
          <div
            style={{ paddingRight: '25px' }}>
            Status
          </div>
          <div>Amount</div>
          {/* <div className='table-heading'>Reruns</div> */}
        </div>

        {renderLNTable()}
        {renderBilldeskTable()}

      </table>
      <hr />
    </Fragment>
  );
};
export default Table;
