import secureStorage from '../services/securedStorage';
import Amplify, { Auth } from 'aws-amplify';

export const loadUser = () => {
    try {
        const serializedState = secureStorage.getItem('user');
        if (serializedState === undefined) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch {
        return undefined
    }
}

export const saveUser = (user) => {
    try {
        const serializedState = JSON.stringify(user);
        secureStorage.setItem('user', serializedState);
    } catch (err) {
        console.log(err);
    }
}

export function logout() {
    // window.location.href = "/login";
    // secureStorage.setItem('user', null);
    // localStorage.clear();
    Auth.signOut().then(()=>{
        console.log("Success logout")
    }).catch((err)=>{
        console.log(err)
    })
    secureStorage.setItem('user', null);
    localStorage.clear();
    console.log('called logout')
}