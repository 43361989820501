import {

    GET_OVERVIEW,
    GET_OVERVIEW_SUCCESS,
    GET_OVERVIEW_FAILURE,
    GET_OVERVIEW_INIT,

    GET_FIRMWARE_DETAILS,
    GET_FIRMWARE_DETAILS_SUCCESS,
    GET_FIRMWARE_DETAILS_FAILURE,
    GET_FIRMWARE_DETAILS_INIT,

    DOWNLOAD_RELEASE_NOTE,
    DOWNLOAD_RELEASE_NOTE_SUCCESS,
    DOWNLOAD_RELEASE_NOTE_FAILURE,
    DOWNLOAD_RELEASE_NOTE_INIT,

    DOWNLOAD_QA_REPORT,
    DOWNLOAD_QA_REPORT_SUCCESS,
    DOWNLOAD_QA_REPORT_FAILURE,
    DOWNLOAD_QA_REPORT_INIT,

    DOWNLOAD_FIRMWARE,
    DOWNLOAD_FIRMWARE_SUCCESS,
    DOWNLOAD_FIRMWARE_FAILURE,
    DOWNLOAD_FIRMWARE_INIT,

    ROLLBACK_FIRMWARE,
    ROLLBACK_FIRMWARE_SUCCESS,
    ROLLBACK_FIRMWARE_FAILURE,
    ROLLBACK_FIRMWARE_INIT,

    STOP_FIRMWARE_TEST,
    STOP_FIRMWARE_TEST_SUCCESS,
    STOP_FIRMWARE_TEST_FAILURE,
    STOP_FIRMWARE_TEST_INIT,

    SEARCH_FIRMWARE,
    SEARCH_FIRMWARE_SUCCESS,
    SEARCH_FIRMWARE_FAILURE,
    SEARCH_FIRMWARE_INIT,

    UPLOAD_FIRMWARE_BUILD,
    UPLOAD_FIRMWARE_BUILD_SUCCESS,
    UPLOAD_FIRMWARE_BUILD_FAILURE,
    UPLOAD_FIRMWARE_BUILD_INIT,

    GET_BUILD_TYPES,
    GET_BUILD_TYPES_SUCCESS,
    GET_BUILD_TYPES_FAILURE,
    GET_BUILD_TYPES_INIT

} from './actionTypes'

// ==== Actions start here ====

// ==== Actions for Get Overview ====
export const getOverview = (data) => {
    return {
        type : GET_OVERVIEW,
        payload : data
    }
}
export const getOverviewSuccess = (data) => {
    return {
        type : GET_OVERVIEW_SUCCESS,
        payload : data
    }
}
export const getOverviewFailure = (data) => {
    return {
        type : GET_OVERVIEW_FAILURE,
        payload : data
    }
}
export const getOverviewInit = (data) => {
    return {
        type : GET_OVERVIEW_INIT,
        payload : data
    }
}

// ==== Actions for Get Firmware Details ====
export const getFirmwareDetails = (data) => {
    return{
        type : GET_FIRMWARE_DETAILS,
        payload : data
    }
}
export const getFirmwareDetailsSuccess = (data) => {
    return{
        type : GET_FIRMWARE_DETAILS_SUCCESS,
        payload : data
    }
}
export const getFirmwareDetailsFailure = (data) => {
    return{
        type : GET_FIRMWARE_DETAILS_FAILURE,
        payload : data
    }
}
export const getFirmwareDetailsInit = (data) => {
    return{
        type : GET_FIRMWARE_DETAILS_INIT,
        payload : data
    }
}

// ==== Actions for Download Release Note ====
export const downloadReleaseNote = (data) => {
    return {
        type : DOWNLOAD_RELEASE_NOTE,
        payload : data
    }
}
export const downloadReleaseNoteSuccess = (data) => {
    return {
        type : DOWNLOAD_RELEASE_NOTE_SUCCESS,
        payload : data
    }
}
export const downloadReleaseNoteFailure = (data) => {
    return {
        type : DOWNLOAD_RELEASE_NOTE_FAILURE,
        payload : data
    }
}
export const downloadReleaseNoteInit = (data) => {
    return {
        type : DOWNLOAD_RELEASE_NOTE_INIT,
        payload : data
    }
}

// ==== Actions for Download QA report ====
export const downloadQaReport = (data) => {
    return {
        type : DOWNLOAD_QA_REPORT,
        payload : data
    }
}
export const downloadQaReportSuccess = (data) => {
    return {
        type : DOWNLOAD_QA_REPORT_SUCCESS,
        payload : data
    }
}
export const downloadQaReportFailure = (data) => {
    return {
        type : DOWNLOAD_QA_REPORT_FAILURE,
        payload : data
    }
}
export const downloadQaReportInit = (data) => {
    return {
        type : DOWNLOAD_QA_REPORT_INIT,
        payload : data
    }
}

// ==== Actions for Download Firmware ====
export const downloadFirmware = (data) => {
    return {
        type : DOWNLOAD_FIRMWARE,
        payload : data
    }
}
export const downloadFirmwareSuccess = (data) => {
    return {
        type : DOWNLOAD_FIRMWARE_SUCCESS,
        payload : data
    }
}
export const downloadFirmwareFailure = (data) => {
    return {
        type : DOWNLOAD_FIRMWARE_FAILURE,
        payload : data
    }
}
export const downloadFirmwareInit = (data) => {
    return {
        type : DOWNLOAD_FIRMWARE_INIT,
        payload : data
    }
}

// ==== Actions for Rollback Firmware ====
export const rollbackFirmware = (data) => {
    return {
        type : ROLLBACK_FIRMWARE,
        payload : data
    }
}
export const rollbackFirmwareSuccess = (data) => {
    return {
        type : ROLLBACK_FIRMWARE_SUCCESS,
        payload : data
    }
}
export const rollbackFirmwareFailure = (data) => {
    return {
        type : ROLLBACK_FIRMWARE_FAILURE,
        payload : data
    }
}
export const rollbackFirmwareInit = (data) => {
    return {
        type : ROLLBACK_FIRMWARE_INIT,
        payload : data
    }
}

// ==== Actions for Stop Firmware Test ====
export const stopFirmwareTest = (data) => {
    return {
        type : STOP_FIRMWARE_TEST,
        payload : data
    }
}
export const stopFirmwareTestSuccess = (data) => {
    return {
        type : STOP_FIRMWARE_TEST_SUCCESS,
        payload : data
    }
}
export const stopFirmwareTestFailure = (data) => {
    return {
        type : STOP_FIRMWARE_TEST_FAILURE,
        payload : data
    }
}
export const stopFirmwareTestInit = (data) => {
    return {
        type : STOP_FIRMWARE_TEST_INIT,
        payload : data
    }
}

// ==== Actions for Search Firmware ====
export const searchFirmware = (data) => {
    return {
        type : SEARCH_FIRMWARE,
        payload : data
    }
}
export const searchFirmwareSuccess = (data) => {
    return {
        type : SEARCH_FIRMWARE_SUCCESS,
        payload : data
    }
}
export const searchFirmwareFailure = (data) => {
    return {
        type : SEARCH_FIRMWARE_FAILURE,
        payload : data
    }
}
export const searchFirmwareInit = (data) => {
    return {
        type : SEARCH_FIRMWARE_INIT,
        payload : data
    }
}

// ==== Actions for Update Firmware Build ====
export const uploadFirmwareBuild = (data) => {
    return {
        type : UPLOAD_FIRMWARE_BUILD,
        payload : data
    }
}
export const uploadFirmwareBuildSuccess = (data) => {
    return {
        type : UPLOAD_FIRMWARE_BUILD_SUCCESS,
        payload : data
    }
}

export const uploadFirmwareBuildFailure = (data) => {
    return {
        type : UPLOAD_FIRMWARE_BUILD_FAILURE,
        payload : data
    }
}

export const uploadFirmwareBuildInit = (data) => {
    return {
        type : UPLOAD_FIRMWARE_BUILD_INIT,
        payload : data
    }
}

// ==== Actions for Get Build Types ====
export const getBuildTypes = (data) => {
    return {
        type : GET_BUILD_TYPES,
        payload : data
    }
}
export const getBuildTypesSuccess = (data) => {
    return {
        type : GET_BUILD_TYPES_SUCCESS,
        payload : data
    }
}
export const getBuildTypesFailure = (data) => {
    return {
        type : GET_BUILD_TYPES_FAILURE,
        payload : data
    }
}
export const getBuildTypesInit = (data) => {
    return {
        type : GET_BUILD_TYPES_INIT,
        payload : data
    }
}

