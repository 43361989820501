import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment';
import Const from '../../common/constants';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Common/ln-table';
import DropdownList from 'react-widgets/lib/DropdownList';
import { Calendar, DateRange } from 'react-date-range';
import { Container, Button, Modal, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import InputBox from '../../components/Common/input-box';
import TransactionDetails from '../../components/Common/transaction-details';
import { generateTransactionMonths } from '../../services/transactionMasterService';
import { convertDateStringToUTCDateTimeString } from '../../helpers/formatDateHelper';

import BackIcon from '../../assets/images/arrow-left-solid.svg';
import Search from '../../assets/images/search-solid.svg';
import CloseBtn from '../../assets/images/close.svg';
import Filter from '../../assets/images/filter-solid.svg';
import CloseIco from '../../assets/images/times-solid.svg';

// Actions
import { changePreloader } from '../../store/layout/actions';
import { clearPendingTransactions, getPendingTransactions, getPendingTransactionsExcel, getBilldeskTransactions, getBilldeskTransactionInit, downloadBilldeskTransactions, clearError } from '../../store/lnDashboard/actions';

const AdvancedSearch = (props) => {

  const dispatch = useDispatch();
  const [selectFilter, setSelectFilter] = useState(false); // For filter dropdown 
  const [showTable, setSelectedTable] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState(null);
  const [filterTypes, setFilterTypes] = useState([{ filterName: 'Mobile No', id: 2, value: 'MobileNo' }, { filterName: 'Transaction ID', id: 0, value: 'TransactionId' }, { filterName: 'Email ID', id: 1, value: 'EmailId' }]);
  const [transactionType, setTransactionType] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [filterMonth, setFilterMonth] = useState(null);
  const [searchBy, setSearchBy] = useState('date');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const recordCount = useSelector(state => state.LNDashboard.recordCount);

  // const errorMessageService = useErrorHandlingService();
  // const downloadStatus = useCheckDownloadStatus();

  useEffect(() => {
    calculatePagesFromRecordCount();
  }, [recordCount]);

  const calculatePagesFromRecordCount = () => {
    if (recordCount !== 0) {
      let totalPages = parseInt(recordCount / 10);
      let lastPage = recordCount % 10 === 0 ? false : true;
      if (recordCount <= 10) {
        setTotalPages(1);
      } else {
        if (lastPage == true) {
          setTotalPages(totalPages + 1);
        } else {
          setTotalPages(totalPages);
        }
      }
    }
  }

  const setSearchTypeFilter = (filter) => {
    setSearchType(filter);
    setSearchValue('');
  }

  const setSearchValueByType = (val) => {
    if (searchType == null || searchType.id == 0 || searchType.id == 1) {
      setSearchValue(val);
    } else if (searchType.id == 2) {
      if (val - val === 0) {
        setSearchValue(val);
      }
    }
  }

  const setTransactionTypeFilter = (filter) => {
    console.log(filter);
    setTransactionType(filter);
  }

  const dateHandler = (type, event) => {
    switch (type) {
      case 'startDate':
        setFilterStartDate(moment(event.target.value).format('YYYY/MM/DD'));
        break;
      case 'endDate':
        setFilterEndDate(moment(event.target.value).format('YYYY/MM/DD'));
    }
  }

  const renderSearchCTAState = () => {
    if (searchType !== null && searchValue == '') {
      return false
    }

    if (searchBy == 'date') {
      if (filterStartDate !== null && filterEndDate !== null) {
        return true
      } else {
        return false
      }
    } else {
      if (filterMonth !== null) {
        return true
      } else {
        return false
      }
    }
  }

  const setSearchRadio = (type) => {
    setSearchBy(type);
  }

  const onSearch = (isDownload = false) => {
    let isEnabled = renderSearchCTAState();
    if (isEnabled && !isDownload) {

      let params;
      if (searchBy == 'date') {
        params = {
          fromDate: convertDateStringToUTCDateTimeString(filterStartDate),
          toDate: convertDateStringToUTCDateTimeString(filterEndDate),
          TransactionType: props.defaultTransactionType !== undefined && props.defaultTransactionType !== null ? props.defaultTransactionType : transactionType !== null ? transactionType.id : null,
          pageCount: 1,
          pageSize: Const.pageSize
        }
      } else {
        params = {
          month: filterMonth.value,
          year: filterMonth.year,
          TransactionType: props.defaultTransactionType !== undefined && props.defaultTransactionType !== null ? props.defaultTransactionType : transactionType !== null ? transactionType.id : null,
          pageCount: 1,
          pageSize: Const.pageSize
        }
      }

      if (searchValue !== '' && searchType !== null) {
        params.Search = searchType.value;
        if (searchType.id == 2) {
          params.value = '+91' + searchValue;
        } else {
          params.value = searchValue;
        }
      }
      setCurrentPage(1);
      setSearchParams(params);
      switch (props.pageType) {
        case 'ln':
          dispatch(clearPendingTransactions());
          dispatch(getPendingTransactions(params));
          setSelectedTable(true);
          break;
        case 'billdesk':
          dispatch(getBilldeskTransactionInit());
          dispatch(getBilldeskTransactions(params));
          setSelectedTable(true);
          break;
      }


    } else if (isEnabled && isDownload) {
      let downloadParams;
      if (searchBy == 'date') {

        downloadParams = {
          fromDate: convertDateStringToUTCDateTimeString(filterStartDate),
          toDate: convertDateStringToUTCDateTimeString(filterEndDate),
          TransactionType: props.defaultTransactionType !== undefined && props.defaultTransactionType !== null ? props.defaultTransactionType : transactionType !== null ? transactionType.id : null
        }
      } else {
        downloadParams = {
          month: filterMonth.value,
          year: filterMonth.year,
          TransactionType: props.defaultTransactionType !== undefined && props.defaultTransactionType !== null ? props.defaultTransactionType : transactionType !== null ? transactionType.id : null
        }
      }

      if (searchValue !== '' && searchType !== null) {
        downloadParams.Search = searchType.value;
        if (searchType.id == 2) {
          downloadParams.value = '+91' + searchValue;
        } else {
          downloadParams.value = searchValue;
        }
      }

      switch (props.pageType) {
        case 'ln':
          dispatch(getPendingTransactionsExcel(downloadParams));
          break;
        case 'billdesk':
          dispatch(downloadBilldeskTransactions(downloadParams));
          break;
      }
    }
  }

  const isSearchInChecked = (filterId) => {
    if (searchType !== null && searchType.id === filterId) {
      return true;
    } else {
      return false;
    }
  }

  const isTransactionTypeChecked = (transactionTypeId) => {
    if (transactionType !== null && transactionType.id === transactionTypeId) {
      return true;
    } else {
      return false;
    }
  }

  const checkValidationState = (fieldType) => {
    switch (fieldType) {
      case 'search_field':
        let isInvalid = searchValue === '' && searchType !== undefined && searchType !== null ? true : false;
        return isInvalid;
    }
  }

  const getMaxLength = () => {
    if (searchType == null) {
      return 30;
    } else if (searchType.id == 0) {
      return 15;
    } else if (searchType.id == 1) {
      return 25;
    } else if (searchType.id == 2) {
      return 10;
    }
  }

  const onClearSearch = () => {
    setTransactionType(null);
    setSearchType(null);
    setSearchValue('');
    // setFilterStartDate(null);
    // setFilterEndDate(null);
    // setFilterMonth(null)
  }

  const onPagination = (type) => {
    let newPage;
    switch (type) {
      case 'prev':
        if (currentPage == 1) return
        newPage = currentPage - 1;
        setCurrentPage(newPage);
        // console.log(lastPage);
        break;
      case 'next':
        if (currentPage == totalPages) return
        newPage = currentPage + 1;
        setCurrentPage(newPage);
        console.log(currentPage);
        break;
    }

    let params = { ...searchParams };
    params.pageCount = newPage;

    dispatch(changePreloader(true));
    switch (props.pageType) {
      case 'ln':
        dispatch(clearPendingTransactions());
        dispatch(getPendingTransactions(params));
        setSelectedTable(true);
        break;
      case 'billdesk':
        dispatch(getBilldeskTransactionInit());
        dispatch(getBilldeskTransactions(params));
        setSelectedTable(true);
        break;
    }
  }

  return (
    <Fragment>
      {
        selectedTransaction !== undefined && selectedTransaction !== null ? (
          <TransactionDetails
            onResetSelectedTransaction={
              setSelectedTransaction
            }
            selectedTransactionId={selectedTransaction}
          />
        ) : (
          <Fragment>
            <div className='advancedSearch'>
              <div className='back-btn'>
                <div onClick={() => props.onBack(null)}>
                  <img src={BackIcon} />
                  <span>Advance Search</span>
                </div>
              </div>

              {/* New Searchbar */}
              <div className="actions-section">
                <div className="actions-grid">

                  <DropdownList
                    // className={"t-a-l " + ((searchTypeError) ? 'select-error' : '')}
                    className={"t-a-l"}
                    placeholder="Search by"
                    data={filterTypes}
                    // defaultValue={searchType}
                    textField="filterName"
                    valueField="id"
                    value={searchType}
                    onChange={(e) => {
                      setSearchTypeFilter({ label: e.filterName, id: e.id, value: e.value });
                    }}
                  />
                  {/* hasError={checkValidationState('search_field')} */}
                  <InputBox className={searchType == null ? 'not-allowed' : ''} type="text" label={'Search'} key={`searchText`} name="searchValue" value={searchValue} onChange={(e) => { setSearchValueByType(e.target.value) }} onKeyDown={(e) => console.log('s')} hasError={false} maxLength={getMaxLength()} disabled={searchType == null ? true : false} />

                  {
                    props.showTransactionTypeFilter ? (
                      <Fragment>
                        <Button id="FilterPopover" ><i className="fas fa-sliders-h font-sixteen"></i></Button>
                        <UncontrolledPopover trigger="legacy" placement="bottom" isOpen={selectFilter} target="FilterPopover" toggle={() => { setSelectFilter(!selectFilter) }} popperClassName={props.showTransactionTypeFilter ? 'wid-335' : ''}>
                          <PopoverHeader>
                            <div className="multi-col-header">
                              {/* <span>
                          Search By :
                        </span> */}

                              {props.showTransactionTypeFilter ? (
                                <span>
                                  Transaction Type :
                                </span>) : null}

                            </div>
                          </PopoverHeader>
                          <PopoverBody>
                            <div className="multi-col-dropdown">
                              {/* <div className="font-fourteen">
                          <div onClick={(e) => { setSearchTypeFilter({ label: 'TransactionId', id: 0 }); }}>
                            <input type="radio" className="filter-checkbox" checked={isSearchInChecked(0)} />&nbsp;
                            <label>Transaction ID</label>
                          </div>
                          <div onClick={(e) => { setSearchTypeFilter({ label: 'EmailId', id: 1 }); }}>
                            <input type="radio" className="filter-checkbox" checked={isSearchInChecked(1)} />&nbsp;
                            <label>Email ID</label>
                          </div>
                          <div onClick={(e) => { setSearchTypeFilter({ label: 'MobileNo', id: 2 }); }}>
                            <input type="radio" className="filter-checkbox" checked={isSearchInChecked(2)} />&nbsp;
                            <label>Mobile No.</label>
                          </div>
                        </div> */}

                              {props.showTransactionTypeFilter ? (
                                <div className="font-fourteen">
                                  <div onClick={(e) => { setTransactionTypeFilter({ label: 'Successful', id: 2 }) }}>
                                    <input type="radio" className="filter-checkbox" checked={isTransactionTypeChecked(2)} />&nbsp;
                                    <label>Successful</label>
                                  </div>
                                  <div onClick={(e) => { setTransactionTypeFilter({ label: 'Failed', id: 1 }) }}>
                                    <input type="radio" className="filter-checkbox" checked={isTransactionTypeChecked(1)} />&nbsp;
                                    <label>Failed</label>
                                  </div>
                                  <div onClick={(e) => { setTransactionTypeFilter({ label: 'Pending', id: 0 }) }}>
                                    <input type="radio" className="filter-checkbox" checked={isTransactionTypeChecked(0)} />&nbsp;
                                    <label>Pending</label>
                                  </div>
                                </div>
                              ) : null}

                            </div>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </Fragment>
                    ) : null
                  }

                  <div className="clear-btn" onClick={() => { onClearSearch() }}>
                    <span>Clear</span>
                  </div>
                </div>
              </div>
              {/* Tags and Filter */}
              <div className='search-body'>
                <div className='tags'>
                  {/* {
                    searchType !== null ? (
                      <span className='tag'>
                        {searchType.label}
                        <img
                          src={CloseBtn}
                          onClick={() => setSearchType(null)}
                          alt=''
                        />
                      </span>
                    ) : null
                  } */}

                  {
                    transactionType !== null ? (
                      <span className='tag'>
                        {transactionType.label}
                        <img
                          src={CloseBtn}
                          onClick={() => setTransactionType(null)}
                          alt=''
                        />
                      </span>
                    ) : null
                  }
                </div>

              </div>
              {/* Radio buttons and inputs */}
              <div className='select-wrapper-1'>
                <div className="action-box-wrapper">
                  <div onClick={() => { setSearchRadio('date'); }}>
                    <input type="radio" className="filter-checkbox" checked={searchBy === 'date' ? true : false} />&nbsp;
                    <label className={`label radio ${searchBy === 'date' ? '' : 'light'}`}>Transaction date, From : </label>
                  </div>
                  <div className={searchBy === 'date' ? 'inp-field' : 'inp-field not-allowed blur'}>
                    <input
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => dateHandler('startDate', e)}
                      type='date'
                      disabled={searchBy === 'date' ? false : true}
                    />
                    <label className='inline-label'>To</label>
                    <input
                      min={moment(filterStartDate).format('YYYY-MM-DD')}
                      max={moment().format('YYYY-MM-DD')}
                      type='date'
                      onChange={(e) => dateHandler('endDate', e)}
                      disabled={searchBy === 'date' ? false : true}
                    />
                  </div>
                </div>

                <div className="action-box-wrapper">
                  <div onClick={() => { setSearchRadio('month'); }}>
                    <input type="radio" className="filter-checkbox" checked={searchBy === 'month' ? true : false} />&nbsp;
                    <label className={`label radio ${searchBy === 'month' ? '' : 'light'}`}>Transaction Month : </label>
                  </div>
                  <div className={searchBy === 'month' ? 'inp-field' : 'inp-field not-allowed blur'}>
                    <DropdownList
                      className='drop-menu'
                      data={generateTransactionMonths()}
                      placeholder='Select month'
                      onChange={(val) => { setFilterMonth(val) }}
                      dataKey=''
                      textField='label'
                      disabled={searchBy === 'month' ? false : true}
                    />
                  </div>
                </div>

              </div>
              <div className="ml-15">
                <Button name="searchButton" onClick={(e) => { onSearch() }} disabled={renderSearchCTAState() ? false : true}>Search</Button>
              </div>
            </div>
            {showTable ?
              (<Table
                page={currentPage}
                totalPages={totalPages}
                pageType={props.pageType}
                onPageChange={(type) => onPagination(type)}
                onSelectTransaction={setSelectedTransaction}
                onDownload={onSearch}
              />)
              : null}
          </Fragment>
        )
      }
      <ToastContainer />
    </Fragment >
  );
};

export default AdvancedSearch;
