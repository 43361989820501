import {

    GET_OVERVIEW,
    GET_OVERVIEW_SUCCESS,
    GET_OVERVIEW_FAILURE,
    GET_OVERVIEW_INIT,

    GET_FIRMWARE_DETAILS,
    GET_FIRMWARE_DETAILS_SUCCESS,
    GET_FIRMWARE_DETAILS_FAILURE,
    GET_FIRMWARE_DETAILS_INIT,

    DOWNLOAD_RELEASE_NOTE,
    DOWNLOAD_RELEASE_NOTE_SUCCESS,
    DOWNLOAD_RELEASE_NOTE_FAILURE,
    DOWNLOAD_RELEASE_NOTE_INIT,

    DOWNLOAD_QA_REPORT,
    DOWNLOAD_QA_REPORT_SUCCESS,
    DOWNLOAD_QA_REPORT_FAILURE,
    DOWNLOAD_QA_REPORT_INIT,

    DOWNLOAD_FIRMWARE,
    DOWNLOAD_FIRMWARE_SUCCESS,
    DOWNLOAD_FIRMWARE_FAILURE,
    DOWNLOAD_FIRMWARE_INIT,

    ROLLBACK_FIRMWARE,
    ROLLBACK_FIRMWARE_SUCCESS,
    ROLLBACK_FIRMWARE_FAILURE,
    ROLLBACK_FIRMWARE_INIT,

    STOP_FIRMWARE_TEST,
    STOP_FIRMWARE_TEST_SUCCESS,
    STOP_FIRMWARE_TEST_FAILURE,
    STOP_FIRMWARE_TEST_INIT,

    SEARCH_FIRMWARE,
    SEARCH_FIRMWARE_SUCCESS,
    SEARCH_FIRMWARE_FAILURE,
    SEARCH_FIRMWARE_INIT,

    UPLOAD_FIRMWARE_BUILD,
    UPLOAD_FIRMWARE_BUILD_SUCCESS,
    UPLOAD_FIRMWARE_BUILD_FAILURE,
    UPLOAD_FIRMWARE_BUILD_INIT,

    GET_BUILD_TYPES,
    GET_BUILD_TYPES_SUCCESS,
    GET_BUILD_TYPES_FAILURE,
    GET_BUILD_TYPES_INIT

} from './actionTypes'

const initialState = {
    overviewDetails : [],
    firmwareDetails : {},
    releaseNoteFile : null,
    qaReportFile : null,
    firmwareFile : null,
    firmwareInfo : {},
    buildTypes : [],
    errorMessage : '',
    getOverviewSuccess : false,
    getOverviewFailure : false,
    getFirmwareDetailsSuccess : false,
    getFirmwareDetailsFailure : false,
    downloadReleaseNoteSuccess : false,
    downloadReleaseNoteFailure : false,
    downloadQaReportSuccess : false,
    downloadQaReportFailure : false,
    downloadFirmwareSuccess : false,
    downloadFirmwareFailure : false,
    rollbackFirmwareSuccess : false,
    rollbackFirmwareFailure : false,
    stopFirmwareTestSuccess : false,
    stopFirmwareTestFailure : false,
    searchFirmwareSuccess : false,
    searchFirmwareFailure : false,
    uploadFirmwareBuildSuccess : false,
    uploadFirmwareBuildFailure : false,
    getBuildTypesSuccess : false,
    getBuildTypesFailure : false
}

const Firmware = (state = initialState, action) => {

    switch(action.type){

        // ==== Cases for Get Overview ====
        case GET_OVERVIEW : 
            return{
                ...state,
                getOverviewSuccess : false,
                getOverviewFailure : false
            }
        case GET_OVERVIEW_SUCCESS : 
            return{
                ...state,
                overviewDetails : action.payload,
                getOverviewSuccess : true,
                getOverviewFailure : false
            }
        case GET_OVERVIEW_FAILURE : 
            return{
                ...state,
                getOverviewSuccess : false,
                getOverviewFailure : true,
                errorMessage : action.payload
            }
        case GET_OVERVIEW_INIT : 
            return{
                ...state,
                overviewDetails : [],
                getOverviewSuccess : false,
                getOverviewFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Get firmware details ====
        case GET_FIRMWARE_DETAILS :
            return {
                ...state,
                getFirmwareDetailsSuccess : false,
                getFirmwareDetailsFailure : false,
            }
        case GET_FIRMWARE_DETAILS_SUCCESS :
            return {
                ...state,
                firmwareDetails : action.payload,
                getFirmwareDetailsSuccess : true,
                getFirmwareDetailsFailure : false,
            }
        case GET_FIRMWARE_DETAILS_FAILURE :
            return {
                ...state,
                getFirmwareDetailsSuccess : false,
                getFirmwareDetailsFailure : true,
                errorMessage : action.payload
            }
        case GET_FIRMWARE_DETAILS_INIT :
            return {
                ...state,
                firmwareDetails : {},
                getFirmwareDetailsSuccess : false,
                getFirmwareDetailsFailure : false,
                errorMessage : ''
            }

        // ==== Cases for download Release Notes ====
        case DOWNLOAD_RELEASE_NOTE : 
            return {
                ...state,
                downloadReleaseNoteSuccess : false,
                downloadReleaseNoteFailure : false
            }
        case DOWNLOAD_RELEASE_NOTE_SUCCESS : 
            return {
                ...state,
                releaseNoteFile : action.payload,
                downloadReleaseNoteSuccess : true,
                downloadReleaseNoteFailure : false
            }
        case DOWNLOAD_RELEASE_NOTE_FAILURE : 
            return {
                ...state,
                downloadReleaseNoteSuccess : false,
                downloadReleaseNoteFailure : true,
                errorMessage : action.payload
            }
        case DOWNLOAD_RELEASE_NOTE_INIT : 
            return {
                ...state,
                releaseNoteFile : null,
                downloadReleaseNoteSuccess : false,
                downloadReleaseNoteFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Download QA Report ====
        case DOWNLOAD_QA_REPORT : 
            return {
                ...state,
                downloadQaReportSuccess : false,
                downloadQaReportFailure : false,
            }
        case DOWNLOAD_QA_REPORT_SUCCESS : 
            return {
                ...state,
                qaReportFile : action.payload,
                downloadQaReportSuccess : true,
                downloadQaReportFailure : false,
            }
        case DOWNLOAD_QA_REPORT_FAILURE : 
            return {
                ...state,
                downloadQaReportSuccess : false,
                downloadQaReportFailure : true,
                errorMessage : ''
            }
        case DOWNLOAD_QA_REPORT_INIT : 
            return {
                ...state,
                qaReportFile : null,
                downloadQaReportSuccess : false,
                downloadQaReportFailure : false,
                errorMessage : ''
            }

        // ==== Cases for download firmware ====
        case DOWNLOAD_FIRMWARE : 
            return {
                ...state,
                downloadFirmwareSuccess : false,
                downloadFirmwareFailure : false,
            }
        case DOWNLOAD_FIRMWARE_SUCCESS : 
            return {
                ...state,
                firmwareFile : action.payload,
                downloadFirmwareSuccess : true,
                downloadFirmwareFailure : false,
            }
        case DOWNLOAD_FIRMWARE_FAILURE : 
            return {
                ...state,
                downloadFirmwareSuccess : false,
                downloadFirmwareFailure : true,
                errorMessage : action.payload
            }
        case DOWNLOAD_FIRMWARE_INIT : 
            return {
                ...state,
                firmwareFile : null,
                downloadFirmwareSuccess : false,
                downloadFirmwareFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Rollback Firmware ====
        case ROLLBACK_FIRMWARE : 
            return {
                ...state,
                rollbackFirmwareSuccess : false,
                rollbackFirmwareFailure : false,
            }
        case ROLLBACK_FIRMWARE_SUCCESS : 
            return {
                ...state,
                rollbackFirmwareSuccess : true,
                rollbackFirmwareFailure : false,
            }
        case ROLLBACK_FIRMWARE_FAILURE : 
            return {
                ...state,
                rollbackFirmwareSuccess : false,
                rollbackFirmwareFailure : true,
                errorMessage : action.payload
            }
        case ROLLBACK_FIRMWARE_INIT : 
            return {
                ...state,
                rollbackFirmwareSuccess : false,
                rollbackFirmwareFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Stop Test ====
        case STOP_FIRMWARE_TEST : 
            return {
                ...state,
                stopFirmwareTestSuccess : false,
                stopFirmwareTestFailure : false
            }
        case STOP_FIRMWARE_TEST_SUCCESS : 
            return {
                ...state,
                stopFirmwareTestSuccess : true,
                stopFirmwareTestFailure : false
            }
        case STOP_FIRMWARE_TEST_FAILURE : 
            return {
                ...state,
                stopFirmwareTestSuccess : false,
                stopFirmwareTestFailure : true,
                errorMessage : action.payload
            }
        case STOP_FIRMWARE_TEST_INIT : 
            return {
                ...state,
                stopFirmwareTestSuccess : false,
                stopFirmwareTestFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Search Firmware ====
        case SEARCH_FIRMWARE : 
            return {
                ...state,
                searchFirmwareSuccess : false,
                searchFirmwareFailure : false
            }
        case SEARCH_FIRMWARE_SUCCESS : 
            return {
                ...state,
                firmwareInfo : action.payload,
                searchFirmwareSuccess : true,
                searchFirmwareFailure : false
            }
        case SEARCH_FIRMWARE_FAILURE : 
            return {
                ...state,
                searchFirmwareSuccess : false,
                searchFirmwareFailure : true,
                errorMessage : action.payload
            }
        case SEARCH_FIRMWARE_INIT : 
            return {
                ...state,
                firmwareInfo : {},
                searchFirmwareSuccess : false,
                searchFirmwareFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Upload Firmware Build ====
        case UPLOAD_FIRMWARE_BUILD : 
            return {
                ...state,
                uploadFirmwareBuildSuccess : false,
                uploadFirmwareBuildFailure : false
            }
        case UPLOAD_FIRMWARE_BUILD_SUCCESS : 
            return {
                ...state,
                uploadFirmwareBuildSuccess : true,
                uploadFirmwareBuildFailure : false
            }
        case UPLOAD_FIRMWARE_BUILD_FAILURE : 
            return {
                ...state,
                uploadFirmwareBuildSuccess : false,
                uploadFirmwareBuildFailure : true,
                errorMessage : action.payload
            }
        case UPLOAD_FIRMWARE_BUILD_INIT : 
            return {
                ...state,
                uploadFirmwareBuildSuccess : false,
                uploadFirmwareBuildFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Get Build Types ====
        case GET_BUILD_TYPES :
            return{
                ...state,
                getBuildTypesSuccess : false,
                getBuildTypesFailure : false
            }
        case GET_BUILD_TYPES_SUCCESS :
            return{
                ...state,
                buildTypes : action.payload,
                getBuildTypesSuccess : true,
                getBuildTypesFailure : false
            }
        case GET_BUILD_TYPES_FAILURE :
            return{
                ...state,
                getBuildTypesSuccess : false,
                getBuildTypesFailure : true,
                errorMessage : action.payload
            }
        case GET_BUILD_TYPES_INIT :
            return{
                ...state,
                buildTypes : [],
                getBuildTypesSuccess : false,
                getBuildTypesFailure : false,
                errorMessage : ''
            }
        
        

        // ==== Default Cases ====
        default : 
            return { ...state }
    }
}

export default Firmware;