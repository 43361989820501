import {

    GET_ALL_PLANS,
    GET_ALL_PLANS_SUCCESS,
    GET_ALL_PLANS_FAILURE,
    GET_ALL_PLANS_INIT,

    GET_PLAN_DETAILS,
    GET_PLAN_DETAILS_SUCCESS,
    GET_PLAN_DETAILS_FAILURE,
    GET_PLAN_DETAILS_INIT,

    GET_PLAN_FREQUENCY,
    GET_PLAN_FREQUENCY_SUCCESS,
    GET_PLAN_FREQUENCY_FAILURE,
    GET_PLAN_FREQUENCY_INIT,

    CREATE_PLAN,
    CREATE_PLAN_SUCCESS,
    CREATE_PLAN_FAILURE,
    CREATE_PLAN_INIT,

    EDIT_PLAN,
    EDIT_PLAN_SUCCESS,
    EDIT_PLAN_FAILURE,
    EDIT_PLAN_INIT,

    DISABLE_PLAN,
    DISABLE_PLAN_SUCCESS,
    DISABLE_PLAN_FAILURE,
    DISABLE_PLAN_INIT,

    GET_LN_PLAN,
    GET_LN_PLAN_SUCCESS,
    GET_LN_PLAN_FAILURE,
    GET_LN_PLAN_INIT,

    GET_ALL_PROMO,
    GET_ALL_PROMO_SUCCESS,
    GET_ALL_PROMO_FAILURE,
    GET_ALL_PROMO_INIT,

    GET_PROMO_DETAILS,
    GET_PROMO_DETAILS_SUCCESS,
    GET_PROMO_DETAILS_FAILURE,
    GET_PROMO_DETAILS_INIT,

    CREATE_PROMO,
    CREATE_PROMO_SUCCESS,
    CREATE_PROMO_FAILURE,
    CREATE_PROMO_INIT,

    EDIT_PROMO,
    EDIT_PROMO_SUCCESS,
    EDIT_PROMO_FAILURE,
    EDIT_PROMO_INIT,

    DISABLE_PROMO,
    DISABLE_PROMO_SUCCESS,
    DISABLE_PROMO_FAILURE,
    DISABLE_PROMO_INIT

} from './actionTypes'


const initialState = {
    allPlans : [],
    planDetails : null,
    planFrequency : null,
    newEditedPlan : null,
    allPromo : [],
    promoDetails : null,
    newEditedPromo : null,    
    lnPlanDetails: [],
    errorMessage : '',

    getAllPlansSuccess : false,
    getAllPlansFailure : false,
    getPlanDetailsSuccess : false,
    getPlanDetailsFailure : false,
    getPlanFrequencySuccess : false,
    getPlanFrequencyFailure : false,
    createPlanSuccess : false,
    createPlanFailure : false,
    editPlanSuccess : false,
    editPlanFailure : false,
    disablePlanSuccess : false,
    disablePlanFailure : false,
    getLnPlanSuccess: false,
    getLnPlanFailure: false,

    getAllPromoSuccess : false,
    getAllPromoFailure : false,
    getPromoDetailsSuccess : false, 
    getPromoDetailsFailure : false,
    createPromoSuccess : false,
    createPromoFailure : false,
    editPromoSuccess : false,
    editPromoFailure : false,
    disablePromoSuccess : false,
    disablePromoFailure : false

}


const Subscription = (state = initialState, action) => {

    switch(action.type) {

        // ==== Cases for Get All Plans ==== 
        case GET_ALL_PLANS:
            return{
                ...state,
                getAllPlansSuccess : false,
                getAllPlansFailure : false,
            }
        case GET_ALL_PLANS_SUCCESS:
            return{
                ...state,
                allPlans : action.payload,
                getAllPlansSuccess : true,
                getAllPlansFailure : false,
            }
        case GET_ALL_PLANS_FAILURE:
            return{
                ...state,
                getAllPlansSuccess : false,
                getAllPlansFailure : true,
                errorMessage : action.payload,
            }
        case GET_ALL_PLANS_INIT:
            return{
                ...state,
                allPlans : [],
                getAllPlansSuccess : false,
                getAllPlansFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Get Plan Details ====
        case GET_PLAN_DETAILS:
            return{
                ...state,
                getPlanDetailsSuccess : false,
                getPlanDetailsFailure : false,
            }
        case GET_PLAN_DETAILS_SUCCESS:
            return{
                ...state,
                planDetails : action.payload,
                getPlanDetailsSuccess : true,
                getPlanDetailsFailure : false,
            }
        case GET_PLAN_DETAILS_FAILURE:
            return{
                ...state,
                getPlanDetailsSuccess : false,
                getPlanDetailsFailure : true,
                errorMessage : action.payload
            }
        case GET_PLAN_DETAILS_INIT:
            return{
                ...state,
                planDetails : null,
                getPlanDetailsSuccess : false,
                getPlanDetailsFailure : false,
                errorMessage : ''
            } 
        
        // ==== Cases for Get Plan Frequency ====
        case GET_PLAN_FREQUENCY:
            return{
                ...state,
                getPlanFrequencySuccess : false,
                getPlanFrequencyFailure : false,
            }
        case GET_PLAN_FREQUENCY_SUCCESS:
            return{
                ...state,
                planFrequency : action.payload,
                getPlanFrequencySuccess : true,
                getPlanFrequencyFailure : false,
            }
        case GET_PLAN_FREQUENCY_FAILURE:
            return{
                ...state,
                getPlanFrequencySuccess : false,
                getPlanFrequencyFailure : true,
                errorMessage : action.payload
            }
        case GET_PLAN_FREQUENCY_INIT:
            return{
                ...state,
                planFrequency : null,
                getPlanFrequencySuccess : false,
                getPlanFrequencyFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Create Plan ====
        case CREATE_PLAN:
            return{
                ...state,
                createPlanSuccess : false,
                createPlanFailure : false,
            }
        case CREATE_PLAN_SUCCESS:
            return{
                ...state,
                newEditedPlan : action.payload,
                createPlanSuccess : true,
                createPlanFailure : false,
            }
        case CREATE_PLAN_FAILURE:
            return{
                ...state,
                createPlanSuccess : false,
                createPlanFailure : true,
                errorMessage : action.payload
            }
        case CREATE_PLAN_INIT:
            return{
                ...state,
                newEditedPlan : null,
                createPlanSuccess : false,
                createPlanFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Edit Plan ====
        case EDIT_PLAN:
            return{
                ...state,
                editPlanSuccess : false,
                editPlanFailure : false,
            }
        case EDIT_PLAN_SUCCESS:
            return{
                ...state,
                newEditedPlan : action.payload,
                editPlanSuccess : true,
                editPlanFailure : false,
            }
        case EDIT_PLAN_FAILURE:
            return{
                ...state,
                editPlanSuccess : false,
                editPlanFailure : true,
                errorMessage : action.payload,
            }
        case EDIT_PLAN_INIT:
            return{
                ...state,
                newEditedPlan : null,
                editPlanSuccess : false,
                editPlanFailure : false,
                errorMessage : ''
            }

        
        // ==== Cases for Disable Plan ==== 
        case DISABLE_PLAN:
            return{
                ...state,
                disablePlanSuccess : false,
                disablePlanFailure : false,
            }
        case DISABLE_PLAN_SUCCESS:
            return{
                ...state,                
                disablePlanSuccess : true,
                disablePlanFailure : false,
            }
        case DISABLE_PLAN_FAILURE:
            return{
                ...state,
                disablePlanSuccess : false,
                disablePlanFailure : true,
                errorMessage : action.payload
            }
        case DISABLE_PLAN_INIT:
            return{
                ...state,
                disablePlanSuccess : false,
                disablePlanFailure : false,
                errorMessage : ''
            }


        // ==== Cases for LN Plan ====
        case GET_LN_PLAN:
            return {
                ...state,
                getLnPlanSuccess: false,
                getLnPlanFailure: false
            }
        case GET_LN_PLAN_SUCCESS:
            return {
                ...state,
                lnPlanDetails: action.payload,
                getLnPlanSuccess: true,
                getLnPlanFailure: false                
            }
        case GET_LN_PLAN_FAILURE:
            return {
                ...state,
                getLnPlanSuccess: false,
                getLnPlanFailure: true,
                errorMessage: action.payload
            }
        case GET_LN_PLAN_INIT:
            return {
                ...state,
                lnPlanDetails: [],
                getLnPlanSuccess: false,
                getLnPlanFailure: false,                
                errorMessage: ""
            }



        // ==== CASES FOR PROMO CODE ====

        // ==== Cases for Get All Promo ====
        case GET_ALL_PROMO:
            return{
                ...state,
                getAllPromoSuccess : false,
                getAllPromoFailure : false,
            }
        case GET_ALL_PROMO_SUCCESS:
            return{
                ...state,
                allPromo : action.payload,
                getAllPromoSuccess : true,
                getAllPromoFailure : false,
            }
        case GET_ALL_PROMO_FAILURE:
            return{
                ...state,
                getAllPromoSuccess : false,
                getAllPromoFailure : true,
                errorMessage : action.payload
            }
        case GET_ALL_PROMO_INIT:
            return{
                ...state,
                allPromo : [],
                getAllPromoSuccess : false,
                getAllPromoFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Get Promo Details ====
        case GET_PROMO_DETAILS:
            return{
                ...state,
                getPromoDetailsSuccess : false,
                getPromoDetailsFailure : false,                
            }
        case GET_PROMO_DETAILS_SUCCESS:
            return{
                ...state,
                promoDetails : action.payload,
                getPromoDetailsSuccess : true,
                getPromoDetailsFailure : false,                
            }
        case GET_PROMO_DETAILS_FAILURE:
            return{
                ...state,
                getPromoDetailsSuccess : false,
                getPromoDetailsFailure : true,
                errorMessage : action.payload                
            }
        case GET_PROMO_DETAILS_INIT:
            return{
                ...state,
                promoDetails : null,
                getPromoDetailsSuccess : false,
                getPromoDetailsFailure : false,                
                errorMessage : ''
            }

        // ==== Cases for Create Promo ====
        case CREATE_PROMO:
            return{
                ...state,
                createPromoSuccess : false,
                createPromoFailure : false,
            }
        case CREATE_PROMO_SUCCESS:
            return{
                ...state,
                newEditedPromo : action.payload,
                createPromoSuccess : true,
                createPromoFailure : false,
            }
        case CREATE_PROMO_FAILURE:
            return{
                ...state,
                createPromoSuccess : false,
                createPromoFailure : true,
                errorMessage : action.payload
            }
        case CREATE_PROMO_INIT:
            return{
                ...state,
                newEditedPromo : null,
                createPromoSuccess : false,
                createPromoFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Edit Promo ====
        case EDIT_PROMO:
            return{
                ...state,
                editPromoSuccess : false,
                editPromoFailure : false,
            }
        case EDIT_PROMO_SUCCESS:
            return{
                ...state,
                newEditedPromo : action.payload,
                editPromoSuccess : true,
                editPromoFailure : false,
            }
        case EDIT_PROMO_FAILURE:
            return{
                ...state,
                editPromoSuccess : false,
                editPromoFailure : true,
                errorMessage : action.payload
            }
        case EDIT_PROMO_INIT:
            return{
                ...state,
                newEditedPromo : null,
                editPromoSuccess : false,
                editPromoFailure : false,
                errorMessage : ''
            }

        // ==== Cases for Disable Promo ====
        case DISABLE_PROMO:
            return{
                ...state,
                disablePromoSuccess : false,
                disablePromoFailure : false,
            }
        case DISABLE_PROMO_SUCCESS:
            return{
                ...state,                
                disablePromoSuccess : true,
                disablePromoFailure : false,
            }
        case DISABLE_PROMO_FAILURE:
            return{
                ...state,
                disablePromoSuccess : false,
                disablePromoFailure : true,
                errorMessage : action.payload
            }
            case DISABLE_PROMO_INIT:
            return{
                ...state,
                disablePromoSuccess : false,
                disablePromoFailure : false,
                errorMessage : ''
            }

        // ==== Default case ====
        default:
            return{ ...state }
    }
}

export default Subscription;