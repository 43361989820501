import React, { Component } from 'react';
import InputBox from '../../components/Common/input-box';
import { Row, Col, CardBody, Card, Alert } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser, apiError } from '../../store/actions';

// import images
import profile from "../../assets/images/intro-02.png";
import logo from "../../assets/images/logo/Spot_Light_Black.png";



class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email:'',
            password:''
        }

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        let { email , password } = this.state;
        this.props.loginUser({ email , password }, this.props.history);
    }

    inputHandler = (e) => {
        if (e.target.name === 'email') {
            this.setState({ email: e.target.value });
        }

        if (e.target.name === 'password') {
            this.setState({ password: e.target.value });
        }
    }

    componentDidMount() {
        // this.props.apiError("");
    }

    render() {
        let { email , password } = this.state;
        return (
            <React.Fragment>
                {/* <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark"><i className="fas fa-home h2"></i></Link>
                </div> */}
                <div className="account-pages full-screen-login">
                    <div className="container">
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden b-1">
                                    <div className="m-b-two">
                                        <Row>
                                            <Col className="col-6 p-tl">
                                                <div className="p-b">
                                                    <Link to="/">
                                                        <div className="">
                                                            <img src={logo} alt="" height="44" />
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="">
                                                    <h5 className="">Welcome back !</h5>
                                                    <p className="no-mar">Sign in to continue to the portal.</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-start logo-container">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0 m-t-3">

                                        <div className="p-2">

                                            <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}

                                                <div className="form-group">
                                                    {/* <AvField name="email" label="Email" value="" className="form-control" placeholder="Enter email" type="email" required /> */}
                                                    <InputBox type="email" className="m-b-five" label="Email" name="email" value={email} hasError={false} onChange={(e)=>{ this.inputHandler(e)}} />

                                                </div>

                                                <div className="form-group">
                                                    {/* <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" /> */}
                                                    <InputBox type="password" className="m-b-six" label="Password" name="password" value={password} hasError={false} onChange={(e)=>{ this.inputHandler(e)}}/>
                                                </div>


                                                {/* <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                    <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                                </div> */}

                                                <div className="mt-3">
                                                    <button className="btn btn-secondary btn-block waves-effect waves-light" type="submit">Log In</button>
                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                    <Link to="/forget-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                </div> */}
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                {/* <div className="mt-5 text-center">
                                    <p>Don't have an account ? <Link to="register" className="font-weight-medium text-primary"> Signup now </Link> </p>
                                    <p>© {new Date().getFullYear()} Skote. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                                </div> */}
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser, apiError })(Login));

