import React, { Component } from 'react';
import { Container, Button, Modal } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import InputBox from '../../components/Common/input-box'
import DropdownList from 'react-widgets/lib/DropdownList';
import { connect } from 'react-redux';

import productImage from '../../assets/images/product/product.png';
import { sortIntegerTypeData, sortStringTypeData } from '../../helpers/sortHelper';

// Actions and Redux Components 
import * as FirmwareActions from '../../store/firmware/actions'
import * as LayoutActions from '../../store/layout/actions'
import * as VendorActions from '../../store/vendor/actions'
import * as ProductCategoryActions from '../../store/productCategory/actions'
import * as ProductActions from '../../store/product/actions'


import { flatten } from 'lodash';
import { toDate } from 'date-fns';


class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productList : [],
            showModal : false,
            selectedVendor : null,
            selectedCategory : null,
            searchValue : null,
            searchValueObj : null,
            validationErrors : {
                searchValue : false,
                vendorError : false,
                categoryError : false,
                productError : false
            },
            selectedModalTab :  "final", // ==== final | alpha | beta ====
            finalReleaseData : {
                latest : [],
                expired : []
            },
            alphaReleaseData : {
                latest : [],
                expired : []
            },
            betaReleaseData : {
                latest : [],
                expired : []
            },
            buildCommonData : {
                productImage : null,
                productName : null
            },
            vendorDropdownList : [],
            categoryDropdownList : [],
            productDropdownList : [],
            sortDirection : 1,
            stopOrRollbackKey : null,
        }
        this.myRef = React.createRef();
    }

    componentDidMount() {

        // this.props.getOverview()
        this.props.getVendors()
        this.props.changePreloader(true)
    }

    inputHandler(e){
        console.log(e.target)
        let { validationErrors } = this.state
        if(e.target.name === "SearchInputTag"){
            validationErrors.searchValue = false
            this.setState({ searchValue : e.target.value, validationErrors : validationErrors})
        }
    }

    toggleModal = () => {
        let { showModal } = this.state
        this.setState({
            showModal : !showModal
        })
    }

    onSearch () {
        let { searchValue, validationErrors, selectedCategory, selectedVendor } = this.state
        let validationFailed = false
        if(searchValue === null){
            validationErrors.productError = true
            validationFailed = true
        }
        if(selectedCategory === null){
            validationErrors.categoryError = true
            validationFailed = true
        }
        if(selectedVendor === null){
            validationErrors.vendorError = true
            validationFailed = true
        }
        
        // ==== check if validations have failed ====
        if(validationFailed){
            this.setState({
                validationErrors : validationErrors
            })
            return
        }
        let data = {
            productId : searchValue
        }
        this.props.getOverview(data)
        this.props.changePreloader(true)
    }

    onClear() {
        this.setState({
            // selectVendor : null,
            productList : [],
            selectedVendor : null,
            selectedCategory : null,
            searchValue : null,
            searchValueObj : null,
            validationErrors : {
                searchValue : false,
                vendorError : false,
                categoryError : false,
                productError : false
            },

        }, () => {
            this.props.getOverview()
            this.props.changePreloader(true)
        })
    }

    fetchFirmwareDetails(productId) {
        console.log(productId)
        let data = {
            productId : productId
        }
        this.props.getFirmwareDetails(data)

    }

    filterFirmwareDetails(details){
        let {selectedModalTab} = this.state
        if(details.betaBuild === null || details.finalBuild === null){
            selectedModalTab = "alpha"
        }
        this.setState({
            alphaReleaseData : details.alphaBuild,
            betaReleaseData : details.betaBuild,
            finalReleaseData : details.finalBuild,
            buildCommonData : { productImage : details.productImage, productName : details.productName},
            showModal : true,
            selectedModalTab : selectedModalTab
        })
    }

    selectVendor(e) {
        let {validationErrors} = this.state
        validationErrors.vendorError = false
        console.log(e)
        let data = {
            vendorId : e.vendorId
        }
        this.setState({selectedVendor : e, validationErrors : validationErrors})
        this.props.getProductCategory(data)
    }

    selectCategory(e) {
        let {validationErrors} = this.state
        validationErrors.categoryError = false
        console.log(e)
        let data = {
            categoryId : e.productCategoryId
        }
        this.setState({ selectedCategory : e, validationErrors : validationErrors})
        this.props.getProductByCategory(data)
    }

    selectProduct(e){
        let {validationErrors} = this.state
        validationErrors.productError = false
        this.setState({
            searchValueObj : e,
            searchValue : e.productId,
            validationErrors : validationErrors
        })
    }

    rollbackOrStopFirmware(firmwareId, action, actionKey){
        let intendedAction
        action === "final" ? intendedAction = "rollback" : intendedAction = "stopTest"
        let data = {
            firmwareId : firmwareId
        }
        this.setState({ stopOrRollbackKey : actionKey })
        if(intendedAction === "rollback"){
            this.props.rollbackFirmware(data)   
        }
        else if(intendedAction === "stopTest"){
            this.props.stopFirmwareTest(data)
        }

    }

    updateOnStopOrRollback(){
        let {stopOrRollbackKey, alphaReleaseData, betaReleaseData, finalReleaseData} = this.state

        if(stopOrRollbackKey === "alpha"){
            alphaReleaseData.latest[0].isRollbackOrStop = 0
            this.setState({ alphaReleaseData : alphaReleaseData, stopOrRollbackKey : null })

        }else if(stopOrRollbackKey === "beta"){
            betaReleaseData.latest[0].isRollbackOrStop = 0
            this.setState({ betaReleaseData : betaReleaseData, stopOrRollbackKey : null })
        }
        else if(stopOrRollbackKey === "final"){
            finalReleaseData.latest[0].isRollbackOrStop = 0
            this.setState({ finalReleaseData : finalReleaseData, stopOrRollbackKey : null })
        }

    }



    // ==== CHECKER FUNCTIONS ====

    checkGetVendors = () => {
        if (this.props.layout.isPreloader) {
            if (this.props.vendor.getVendorsSuccess === true) {
                // this.props.changePreloader(false);
                this.setState({ vendorDropdownList: this.props.vendor.vendors });
                this.props.getVendorsInit();
                this.props.getOverview()
            } else if (this.props.vendor.getVendorsFailure === true) {
                this.props.changePreloader(false);
                toast.error(this.props.vendor.errorMessage, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.getVendorsInit();
            }
        }
    }


    checkGetOverview () {
        if(this.props.layout.isPreloader){
            if(this.props.firmware.getOverviewSuccess === true){
                this.setState({ productList : this.props.firmware.overviewDetails})
                this.props.getOverviewInit()
                this.props.changePreloader(false)
            }
            else if(this.props.firmware.getOverviewFailure === true){
                this.props.changePreloader(false)
                // toast.error(this.props.firmware.errorMessage, {
                //     position: "bottom-right",
                //     autoClose: 2000,
                //     hideProgressBar: true,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // });
                this.props.getOverviewInit()
                this.setState({productList : []})
            }
        }
    }

    checkGetFirmwareDetails () {
        if(this.props.firmware.getFirmwareDetailsSuccess === true){
            this.filterFirmwareDetails(this.props.firmware.firmwareDetails)
            this.props.getFirmwareDetailsInit()
        }
        else if(this.props.firmware.getFirmwareDetailsFailure === true){
            toast.error(this.props.firmware.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getFirmwareDetailsInit()
        }
    }

    checkGetProductCategory() {
        if (this.props.productCategory.getProductCategorySuccess === true) {
            this.setState({ categoryDropdownList : this.props.productCategory.productCategoriesList })
            this.props.getProductCategoryInit()
        } else if (this.props.productCategory.getProductCategoryFailure === true) {
            toast.error(this.props.productCategory.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getProductCategoryInit()
        }
    }

    checkGetProductByCategory() {
        if (this.props.product.getProductByCategorySuccess === true) {
            this.setState({ productDropdownList: this.props.product.productList })
            this.props.getProductByCategoryInit()
        } else if (this.props.product.getProductByCategoryFailure === true) {
            toast.error(this.props.product.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getProductByCategoryInit()
        }
    }

    checkRollbackFirmware(){
        if(this.props.firmware.rollbackFirmwareSuccess === true){
            // ==== Actions on success rollback ====
            this.props.changePreloader(false)
            toast.success("Firmware Rolled Back Successfully.")
            // this.toggleModal()
            this.updateOnStopOrRollback()
            this.props.rollbackFirmwareInit()
        }
        else if(this.props.firmware.rollbackFirmwareFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.firmware.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.rollbackFirmwareInit()                
        }
        
    }

    checkStopFirmwareTest(){
        if(this.props.firmware.stopFirmwareTestSuccess === true){
            // ==== Actions on success rollback ====
            this.props.changePreloader(false)
            toast.success("Test Firmware Stopped Successfully.")
            this.updateOnStopOrRollback()
            this.props.stopFirmwareTestInit()
        }
        else if(this.props.firmware.stopFirmwareTestFailure === true){
            this.props.changePreloader(false)
            toast.error(this.props.firmware.errorMessage, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.stopFirmwareTestInit()                
        }
        
    }


    // ==== Sorting Function ====
    sortList = (sortBy) => {
        let { sortDirection, productList } = this.state
        let order 
        sortDirection === 1 ? order = "asc" : order = "desc"
        sortDirection = sortDirection * -1
        let sortedData = sortStringTypeData(productList, sortBy, order)
        this.setState({
            productList : sortedData,
            sortDirection : sortDirection
        })

    }



    render() {

        let { productList, searchValue, validationErrors, showModal, selectedModalTab, alphaReleaseData, betaReleaseData, finalReleaseData, buildCommonData,
        vendorDropdownList, categoryDropdownList, productDropdownList, selectedVendor, selectedCategory, searchValueObj } = this.state

        // ==== checker methods being called ====
        this.checkGetVendors()
        this.checkGetOverview()
        this.checkGetFirmwareDetails()
        this.checkGetProductCategory()
        this.checkGetProductByCategory()
        this.checkRollbackFirmware()
        this.checkStopFirmwareTest()

        let displayReleaseData = {}
        selectedModalTab === "alpha" ? displayReleaseData = alphaReleaseData : selectedModalTab === "beta" ? displayReleaseData = betaReleaseData : displayReleaseData = finalReleaseData


        return (
            <React.Fragment>
                <div className="page-content-with-tabs overview-page">
                    <div className="actions-section">
                        <div className="actions-grid">
                            <DropdownList
                                ref={this.myRef}
                                className={"t-a-l " + ((validationErrors.vendorError) ? 'select-error' : '')}
                                name='vendorDropdown'
                                placeholder="Select Vendor"
                                data={vendorDropdownList}
                                // defaultValue={selectedVendor}
                                textField="vendorName"
                                valueField="vendorId"
                                value={selectedVendor}
                                onChange={(e) => {
                                    this.selectVendor(e)
                                }}
                            />

                            <DropdownList
                                className={"t-a-l " + ((validationErrors.categoryError) ? 'select-error' : '')}
                                name='categoryDropdown'
                                placeholder="Select Category"
                                data={categoryDropdownList}
                                // defaultValue={originCountryId}
                                textField="productCategoryName"
                                valueField="productCategoryId"
                                value={selectedCategory}
                                onChange={(e) => {
                                    this.selectCategory(e)
                                }}
                            />

                            
                            <DropdownList
                                className={"t-a-l " + ((validationErrors.productError) ? 'select-error' : '')}
                                name='productDropdown'
                                placeholder="Select Product"
                                data={productDropdownList}
                                // defaultValue={originCountryId}
                                textField="productName"
                                valueField="productId"
                                value={searchValueObj}
                                onChange={(e) => {
                                    this.selectProduct(e)
                                }}
                            />
                            {/* <InputBox type="text" label="Search Product" key={98} name="SearchInputTag" value={searchValue}  onChange={(e) => {this.inputHandler(e)}} hasError={validationErrors.searchValue} /> */}
                            <Button name="searchButton" onClick={(e) => {this.onSearch()}}>Search</Button>
                            <Button name="clearButton" onClick={(e)=>{this.onClear()}}>Clear</Button>
                        </div>
                    </div>

                    <div className="table-section">
                        <div className="custom-table-head">
                            <div className="p-10">Product Name <i className="fas fa-sort cur-pointer" onClick={() => {this.sortList("productName")}} ></i></div>
                            <div className="p-10">Vendor <i className="fas fa-sort cur-pointer" onClick={() => {this.sortList("vendorName")}}></i></div>
                            <div className="p-10">Category <i className="fas fa-sort cur-pointer" onClick={() => {this.sortList("productCategory")}}></i></div>
                            <div className="p-10">Latest Final Release </div>
                            <div className="p-10">Latest Development Release </div>
                        </div>

                        {
                            productList.length !== 0 ? productList.map((product) => {
                                return(
                                    <div className="table-row-section listing-table-variable" onClick={() => {this.fetchFirmwareDetails(product.productId)}}>
                                        <div className="custom-table-body table-data-style">
                                            <div className="p-10">{product.productName}</div>
                                            <div className="p-10">{product.vendorName}</div>
                                            <div className="p-10">{product.productCategory}</div>
                                            <div className="p-10">{product.latestFinalBuild !== null ? product.latestFinalBuild+ ", "+product.latestFinalBuildType : "   -- " }</div>
                                            <div className="p-10">{product.latestDevelopementBuild}, {product.latestDevelopementBuildType}</div>
                                        </div>
                                    </div>
                                )
                            }) : 
                            <div><center> No Products Found</center></div>
                        }
                    </div>

                    <Modal
                    isOpen={showModal}
                    toggle={this.toggleModal}
                    className="modal-xl m-t-15 overview-page"
                    >
                    <div className="modal-interior-grid p-10">
                        <div>
                            <center><img className="image-details" src={buildCommonData.productImage}></img></center>
                            <span className={finalReleaseData === null ? "menu-btn-disabled" : selectedModalTab === "final" ? "active-menu" : "menu-btn"} onClick={() => { finalReleaseData !== null ?  this.setState({ selectedModalTab: 'final' }) : console.log("Not Clickable") }}>Final Releases</span>
                            <span className={selectedModalTab === "alpha" ? "active-menu" : "menu-btn"} onClick={() => {this.setState({ selectedModalTab : 'alpha' })}} >Alpha Builds</span>
                            <span className={betaReleaseData === null ? "menu-btn-disabled" : selectedModalTab === "beta" ? "active-menu" : "menu-btn"} onClick={() => { betaReleaseData !== null ? this.setState({ selectedModalTab : 'beta' }) : console.log("Not Clickable")}} >Beta Builds</span>
                        </div>
                        <div className="m-l-2">
                            <div className="m-t-5">
                                <span className="font-size-20">Latest</span>
                                
                                <div className="table-section">
                                    <div className="modal-custom-table-head">
                                        <div className="p-10">Version No</div>
                                        <div className="p-10">Release Date</div>
                                        <div className="p-10">Release Note</div>
                                        <div className="p-10">QA Report</div>
                                        <div className="p-10">Firmware</div>
                                        <div className="p-10">Action</div>
                                    </div>

                                {
                                    displayReleaseData.latest.length !== 0 ? displayReleaseData.latest.map((build) => {
                                        return(
                                            <div className="table-row-section custom-table-row">
                                                <div className="modal-custom-table-body table-data-style">
                                                    <div className="p-18">{build.firmwareVersion}</div>
                                                    <div className="p-18">{build.dateOfRelease} </div>
                                                    <div className="p-18"><a href={build.releaseNotesUrl} target="_blank" download><i className="fa fa-download fa-lg"></i></a></div>
                                                    <div className="p-18"><a href={build.qaPreviousReportUrl} target="_blank" download><i className={'fa fa-download fa-lg ' + (build.qaPreviousReportUrl === null ? 'select-hover-not-allowed' : '')}></i></a></div>
                                                    <div className="p-18"><a href={build.binFileUrl} target="_blank" download><i className="fa fa-download fa-lg"></i></a></div>
                                                    <Button className={build.isRollbackOrStop === 1 ? "" : "select-hover-not-allowed"} disabled={build.isRollbackOrStop === 1 ? false : true} onClick={()=>{this.rollbackOrStopFirmware(build.firmwareId, selectedModalTab, selectedModalTab)}}>{selectedModalTab === "final" ? "Rollback" : "Stop Test"}</Button>                                                    

                                                </div>
                                            </div>
                                        )
                                    }) : ''
                                }
                                </div>

                            </div>

                            <div className="m-t-5">
                                <span className="font-size-20">Expired</span>
                                
                                <div className="table-section">
                                    <div className="modal-custom-table-head">
                                        <div className="p-10">Version No</div>
                                        <div className="p-10">Release Date</div>
                                        <div className="p-10">Release Note</div>
                                        <div className="p-10">QA Report</div>
                                        <div className="p-10">Firmware</div>
                                        {/* <div className="p-10">Action</div> */}
                                    </div>

                                    <div className="secondary-scroll-section">
                                    {
                                        displayReleaseData.expired.length !== 0 ? displayReleaseData.expired.map((build) => {
                                            return(
                                                <div className="table-row-section custom-table-row">
                                                    <div className="modal-custom-table-body table-data-style">
                                                        <div className="p-18">{build.firmwareVersion}</div>
                                                        <div className="p-18">{build.dateOfRelease} </div>
                                                        <div className="p-18"><a href={build.releaseNotesUrl} target="_blank" download><i className="fa fa-download fa-lg"></i></a></div>
                                                        <div className="p-18"><a href={build.qaPreviousReportUrl} target="_blank" download><i className={'fa fa-download fa-lg ' + (build.qaPreviousReportUrl === null ? 'select-hover-not-allowed' : '')}></i></a></div>
                                                        <div className="p-18"><a href={build.binFileUrl} target="_blank" download><i className="fa fa-download fa-lg"></i></a></div>
                                                        {/* <Button>Rollback</Button> */}
                                                    </div>
                                                </div>
                                            )
                                        }) : ''
                                    }
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </Modal>
                </div>
                <ToastContainer/>
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => ({
    layout : state.Layout,
    firmware : state.Firmware,
    productCategory : state.ProductCategory,
    vendor : state.Vendor,
    product : state.Product

})

const mapDispatchToProps = dispatch => ({
    
    getOverview : (data) => dispatch(FirmwareActions.getOverview(data)),
    getOverviewInit : () => dispatch(FirmwareActions.getOverviewInit()),

    getFirmwareDetails : (data) => dispatch(FirmwareActions.getFirmwareDetails(data)),
    getFirmwareDetailsInit : () => dispatch(FirmwareActions.getFirmwareDetailsInit()),

    rollbackFirmware : (data) => dispatch(FirmwareActions.rollbackFirmware(data)),
    rollbackFirmwareInit : () => dispatch(FirmwareActions.rollbackFirmwareInit()),

    stopFirmwareTest : (data) => dispatch(FirmwareActions.stopFirmwareTest(data)),
    stopFirmwareTestInit : () => dispatch(FirmwareActions.stopFirmwareTestInit()),

    getProductCategory: (data) => dispatch(ProductCategoryActions.getProductCategory(data)),
    getProductCategoryInit: () => dispatch(ProductCategoryActions.getProductCategoryInit()),

    getVendors: (data) => dispatch(VendorActions.getVendors(data)),
    getVendorsInit: () => dispatch(VendorActions.getVendorsInit()),

    getProductByCategory: (data) => dispatch(ProductActions.getProductByCategory(data)),
    getProductByCategoryInit: () => dispatch(ProductActions.getProductByCategoryInit()),

    changePreloader: (flag) => dispatch(LayoutActions.changePreloader(flag)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Overview)