import axios from 'axios';
import { loadUser, logout } from '../helpers/authUtils';
import { saveNewToken } from "../store/auth/login/actions";
import store from '../store';
import * as lo from 'lodash'
import Amplify, { Auth } from 'aws-amplify';

import constants from '../common/constants'

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;

let isRefreshing = false;
let refreshSubscribers = [];
     
// ==== QA Link values ====
// let constantParams = {
//     BaseUrl : "https://qaapi.godrejhomecam.in",
//     UserPool : "ap-south-1_VP3cMdnDF",
//     ClientId : "itfsd4an45icocc1umpd3vv84"
// }

// ==== UAT Link values ====
// ==== Custom Link values ====
let constantParams = {
    BaseUrl : constants.constBaseUrl,
    UserPool : constants.constUserPoolId, //"ap-south-1_PQBcTmELr",
    ClientId : constants.constClientId, //"1euji717kj2hi379citqpigt74",
}

let instance = axios.create({
    // baseURL: 'https://qaapi.godrejhomecam.in/adminDevice-management',
    baseURL: constantParams.BaseUrl,
    headers: { 'Content-Type': 'application/json' }
});


function checkError(err) {
    console.log("#####   Getting into Error interceptor.")
    console.log(`#####  ERROR before : ${JSON.stringify(err)} `)
    let error = lo.cloneDeep(err)

    console.log(`#####  Error Msg changed : ${JSON.stringify(error)}`)
    console.log("Starting checks")
    if (error.response.data === null || error.response.data === undefined) {
        error.response.data = {};
    }
    if (error.response.data.message === null || error.response.data.message === undefined) {
        error.response.data.message = 'test message';
    }
    console.log("#### Error MSG flipped")
    console.log(`#####  new ERROR : ${JSON.stringify(error)}`)
    return error;
}

instance.interceptors.request.use(
    async(config) => {
        // let curState = loadUser();
        // let token = (curState !== undefined && curState !== null) ? curState.Login.access_token : null;
        console.log('Getting new token******************')
        let response = await Auth.currentSession(); //1. Make a synchronous API call to refresh token 
        console.log('Getting new token again!!******************, ', response)
        let token = response.getIdToken().getJwtToken(); //2. Once successful, set in config.headers.Authorization as Bearer token
        // let token = "eyJraWQiOiJCT1Z6V2pQelNyNHY4Q1dSSDM5aTFkbWJNRldBUFwvVTZGSkdQQXBwM3A4WT0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiWjJ0WTRQRGRjdEU4S0VSYUQzX25xUSIsInN1YiI6IjAxMTFiZjI4LTQ2ZGYtNGQ3Ny05NWQ3LThmNzY5NzQ4ZWUxZCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX1BRQmNUbUVMciIsImNvZ25pdG86dXNlcm5hbWUiOiIwMTExYmYyOC00NmRmLTRkNzctOTVkNy04Zjc2OTc0OGVlMWQiLCJhdWQiOiIxZXVqaTcxN2tqMmhpMzc5Y2l0cXBpZ3Q3NCIsInVzZXJfaWQiOiIyMTAiLCJ0b2tlbl91c2UiOiJpZCIsInBlcm1pc3Npb25zIjoiU3Vic2NyaXB0aW9ufFBvcnRhbCBBY3Rpdml0eXxNYW5hZ2UgQWNjZXNzfERhdGFiYXNlfEN1c3RvbWVyc3xEYXNoYm9hcmR8U3lzdGVtIiwiYXV0aF90aW1lIjoxNjA0NDg0MTAyLCJleHAiOjE2MDQ0ODc3MDIsImlhdCI6MTYwNDQ4NDEwMiwiZW1haWwiOiJhbmlydWRoYS5rb3JkZUBmbGVudGFzLmNvbSJ9.YZh5qFsU2hfAweu4FBmaSOpGiXsuLap3NyjB0i3eCDBI1roxQNHIo_xEzpi0sJ0b5kpQ9lu1y_vCzY-9GBahZEQnCNPxzKM4wkmMLPJNGctdNz8bYHdac3qve8tg7POtl1M4JbSh-2HTpcG_Kj3kfLaFGcfdqV3s2JxWL53-udognnyLoGOxmp299uyHs0tz4j0jmCLSwKIaEpTwnu-rtVx4v5Mt08yamABcCiD6ZxVzTBcX-KK1E3sAXB3FdxhtNZeIn3v9rXz6NoKjJEsWEFijKRHL_eV-HM8lcCJ8t53HUbhytrnXMBDg2L6FFFyFp8hHxx1479Mh35_rGOlxpg"
        config.headers.Authorization = `Bearer ${token}`;

        return config;
    }, (error) => {
        console.log('Error in interceptor request***************************');
        if (error.name !== undefined && error.name === "NotAuthorizedException") {
            localStorage.removeItem('user');
        }
        logout();
        return Promise.reject(error);
    });


instance.interceptors.response.use(
    response => {
        console.log(response);
        return response
    },
    (error) => {
        const originalRequest = error.config;
        console.log(JSON.stringify(error));
        console.log("Error Spotted!!", error.name)

        if (error.name !== undefined && error.name === "NotAuthorizedException") {
            localStorage.removeItem('user');
            logout();
        }
        if (error.response.status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                const poolData = {
                    UserPoolId: constantParams.UserPool, // Your user pool id here,
                    ClientId: constantParams.ClientId // Your client id here
                };
                const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
                const cognitoUser = userPool.getCurrentUser();
                cognitoUser.getSession((err, session) => {
                    if (err) {
                        console.log('Error while getting session:********', err);
                        console.log(JSON.stringify(err));
                        if (error.name !== undefined && error.name === "NotAuthorizedException") {
                            localStorage.removeItem('user');
                            logout();
                        }
                        return Promise.reject(error);
                    }

                    const refresh_token = session.getRefreshToken();

                    cognitoUser.refreshSession(refresh_token, (refErr, refSession) => {
                        if (refErr) {
                            console.log('Error while refreshing session************* : ' + JSON.stringify(refErr));
                            localStorage.removeItem('user');
                            logout();
                            throw refErr
                        } else {
                            //Here we get the new access token
                            // dispatching an action to save the new token to the redux store and local storage.
                            store.dispatch({
                                type: 'SET_ACCESS_TOKEN',
                                payload: refSession.idToken.jwtToken
                            });

                            //retrying the original request with new token.
                            console.log('got new token');
                            originalRequest.headers['Authorization'] = `Bearer ${refSession.idToken.jwtToken}`;
                            onRrefreshed(refSession.idToken.jwtToken);
                            return new Promise((resolve, reject) => {
                                axios.request(originalRequest).then((res) => { resolve(res) }).catch((err) => {

                                    console.log('test-3 : ****' + JSON.stringify(err));
                                    reject(err)

                                });
                            })

                        }
                    });
                });
            }

            const retryOrigReq = new Promise((resolve, reject) => {
                subscribeTokenRefresh(token => {
                    // replace the expired token and retry
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    resolve(axios(originalRequest));
                });
            });
            return retryOrigReq;
        } else {
            return Promise.reject(error.response);
        }
    }
)


function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
    refreshSubscribers.map(cb => cb(token));
}

export default instance;