import { takeEvery, fork, put, all, call, takeLatest } from 'redux-saga/effects';
import { GET_COUNTRIES_LIST, GET_VENDORS_LIST, GET_PRODUCT_TYPES, GET_BATCHES } from '../actionTypes';
import { saveCountriesList, saveVendorList, saveProductTypes, saveBatches } from './actions';
import { fetchCountriesList, fetchVendorsList } from "../../../services/vendorService";
import { fetchProductTypes } from "../../../services/productService";
import { fetchBatches } from "../../../services/batchService";

import { changePreloader } from '../../layout/actions';

function* fetchCountries() {
    try {
        const response = yield call(fetchCountriesList);
        yield put(saveCountriesList(response.data.data));
    } catch (error) {
        // yield put(apiError(error.message));
        console.log(error);
    }
}

function* fetchVendors() {
    try {
        const response = yield call(fetchVendorsList);
        yield put(saveVendorList(response.data.data));
        yield put(changePreloader(false));
    } catch (error) {
        console.log(error);
    }
}


function* fetchProductType() {
    try {
        const response = yield call(fetchProductTypes);
        yield put(saveProductTypes(response.data.data));

    } catch (error) {
        // yield put(apiError(error.message));
        console.log(error);
    }
}

function* fetchAllBatches() {
    try {
        const response = yield call(fetchBatches);
        yield put(saveBatches(response.data.data));
        // yield put(changePreloader(false));
    } catch (error) {
        // yield put(apiError(error.message));
        console.log(error);
    }
}


export function* watchGetCountryList() {
    yield takeEvery(GET_COUNTRIES_LIST, fetchCountries)
}

export function* watchGetVendorList() {
    yield takeLatest(GET_VENDORS_LIST, fetchVendors)
}

export function* watchGetProductTypes() {
    yield takeEvery(GET_PRODUCT_TYPES, fetchProductType)
}

export function* watchGetBatches() {
    yield takeEvery(GET_BATCHES, fetchAllBatches)
}

// export function* watchGetProducts() {
//     yield takeEvery(GET_PRODUCTS, fetchProduct)
// }



function* GetDatabaseSaga() {
    yield all([
        fork(watchGetCountryList),
        fork(watchGetVendorList),
        fork(watchGetProductTypes),
        fork(watchGetBatches),
        // fork(watchGetProducts),
    ]);
}

export default GetDatabaseSaga;