import API from './interceptor';
import constants from '../common/constants'
const urlDevicePrefix = constants.urlDevicePrefix
const urlPrefix = constants.urlPrefix


export function addVendor(payload) {
    return API({
        method: 'POST',
        url: urlDevicePrefix+`vendors`,
        data: JSON.stringify(payload)
            // params: { vendorId: 1}
    });
}

export function searchVendor(vendorID) {
    return API({
        method: 'GET',
        url: urlDevicePrefix+`vendor/${vendorID}`,
        // params: { vendorId: 1}
    });
}

export function searchVendorByName(payload) {
    return API({
        method: 'GET',
        url: urlDevicePrefix+`vendorByName`,
        params: payload
    });
}

export function deleteVendor(vendorID) {
    return API({
        method: 'DELETE',
        url: urlDevicePrefix+`vendors/${vendorID}`,
        // params: { vendorId: 1}
    });
}

export function editVendor(vendorID, vendor) {
    console.log(vendor);
    return API({
        method: 'PUT',
        url: urlDevicePrefix+`vendors/${vendorID}`,
        data: JSON.stringify(vendor)
            // params: { vendorId: 1}
    });
}

export function fetchVendorsList() {
    return API({
        method: 'GET',
        url: urlDevicePrefix+'vendors'
    });
}

export function fetchCountriesList() {
    return API({
        method: 'GET',
        url: urlDevicePrefix+'countries'
    });
}