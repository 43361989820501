import API from './interceptor';
import constants from '../common/constants'
const urlDevicePrefix = constants.urlDevicePrefix
const urlPrefix = constants.urlPrefix


export function getCustomer(params) {
    return API({
        method: 'GET',
        url: urlDevicePrefix+'customers',
        params
    });
}

export function getCustomerDetails(serialNo){
    return API({
        method : 'GET',
        url : urlDevicePrefix+`customers/${serialNo}`
    })
}

export function changeCustomerConfig(dataObj, typeValue){
    let params = {
        type : typeValue
    }
    return API({
        method : 'POST',
        url : urlDevicePrefix+'customers/changeConfig',        
        data : JSON.stringify(dataObj),
        params : params
    })
}

export function getLogs(payload, body){
    return API({
        method : 'POST',
        url : urlPrefix+'activity/logs',
        data : JSON.stringify(body),
        params : payload
    })
}

export function getLogFilters(payload){
    let params = {
        permission : payload.userType
    }
    return API({
        method : 'GET',
        url : urlDevicePrefix+'dashboard/role',
        params
    })
}

export function downloadLogsService(payload, body){
    return API({
        method : 'POST',
        url : urlPrefix+'activity/logs/download',
        data : JSON.stringify(body),
        params : payload,
        responseType : 'arraybuffer'
    })
}

 
// ==== Services for Group Management ====
export function searchCustomersGroupsService(params) {
    return API({
        method : 'GET',
        url : urlDevicePrefix + `systems/users`,
        params
    })
}

export function assignCustomerGroupsService(params){
    return API({
        method : 'PUT',
        url : urlDevicePrefix + `systems/users/${params.userId}/groups/${params.groupId}`
    })
}


// ==== Services for User and devices ====

export function searchUserService(payload){
    return API({
        method : 'GET',
        url : urlPrefix + 'search/users',
        params : payload
    })
}

export function searchDevicesService(userId){
    return API({
        method : 'GET',
        url : urlPrefix + `search/devices/${userId}`
    })
}